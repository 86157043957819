import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { NewsService } from '@services/news';
import { LoadingService } from '@services/loading';
import { GaleriasService } from '@services/galerias';
import * as $ from 'jquery';

@Component({
  selector: 'app-comite-paritario-de-higiene-y-seguiridad-oficina-central',
  templateUrl: './comite-paritario-de-higiene-y-seguiridad-oficina-central.component.html',
  styleUrls: ['./comite-paritario-de-higiene-y-seguiridad-oficina-central.component.scss'],
})
export class ComiteParitarioDeHigieneYSeguridadOficinaCentral implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  galeriaVideosSeguridad: any;
  newsSeguridadInformacion: any;
  galeriaSeguridad: any;
  
  carouselCharlas2 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselCharlas3 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };

  modalVideo: any;
  modalSrcVideo: any;
  carouselBanner = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 1,
      autoHeight: true,
      init: true,
      slidesPerView: 1,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
    },
  };
  carouselActas = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 1,
      autoHeight: true,
      init: true,
      slidesPerView: 1,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
    },
  };
  info: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private landingService: LandingService,
    private NewsService: NewsService,
    private title: Title,
    private galeriasService: GaleriasService,
    private sanitized: DomSanitizer,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
    $("body").delegate(".item-funciones", "click", function () {
      var titleModal = $(this).find("h4").text();
      var textModal = $(this).find("p").html();
      // console.log([titleModal, textModal])
      $(".modal-custom3-text").fadeIn();
      $('.modal-custom3-text .title-custom3-text').text(titleModal);
      $('.modal-custom3-text .box-text-custome-text').html(textModal);
    });
    $("body").delegate(".modal-custom3-text .close", "click", function () {
      $(".modal-custom3-text").fadeOut();
    });
    $("body").delegate(".modal-custom3-text .overlay", "click", function () {
      $(".modal-custom3-text").fadeOut();
    });
  }
  init(): void {
    this.title.setTitle('Comité Paritario de Higiene y Seguridad | Intranet Abastible');
    this.slug = this.params['slug'];
    let that = this;

    this.landingService.getInfoPage(87790).subscribe(
      (res) => {
        console.log(res);
        that.info = res.fields;
      },
      (err) => {
        console.error(err);
      }
    );

    // Seguridad de Informacion

    this.NewsService.getNews("comite-paritario-de-higiene-y-seguridad-oficina-central", 1).subscribe(
      (res) => {
        this.newsSeguridadInformacion = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.galeriasService.galeriaByCategory("comite-paritario-de-higiene-y-seguridad-oficina-central", 1, "").subscribe(
      (res) => {
        this.galeriaSeguridad = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.galeriasService.galeriaVideosByCategory("comite-paritario-de-higiene-y-seguridad-oficina-central", 1, 8, "").subscribe(
      (res) => {
        this.galeriaVideosSeguridad = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }

  
} 
