import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Router } from '@angular/router';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  myId = uuid.v4();
  user: Object = {};
  constructor(private router: Router, private httpClient: HttpClient) { }

  login(username: string, password: string) {
    return this.httpClient
      .post(`${environment.apiUrl}/token/`, { username, password })
      .pipe(
        map((result) => {
          localStorage.setItem('token', result['token']);
          localStorage.setItem('user', JSON.stringify(result));
          return result;
        })
      );
  }

  validate_sesion(hash: string) {
    const formData = new FormData();
    formData.append('hash', hash);
    return this.httpClient
      .post(`${environment2.apiUrl}/validate_sesion/`, formData)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  login2(hash: string) {
    const formData = new FormData();
    formData.append('hash', hash);
    return this.httpClient
      .post(`${environment2.apiUrl}/user-hash/`, formData)
      .pipe(
        map((result) => {
          console.log(result);
          this.user = result;
          localStorage.setItem('user', JSON.stringify(result));
          return result;
        })
      );
  }

  checkIsLogged() {
    let result = false;

    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));

      this.user = user;
      result = true;
    }

    return result;
  }

  analitics(date_post, url_post) {
    console.log(url_post)
    return this.httpClient
      .post(`${environment2.apiUrl}/set-statistics-page?fecha=${date_post}&url=${url_post}`, null)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  redirectToLogin(urlRedirect) {
    window.location.href = urlRedirect + this.myId;
  }

  logOut() {
    return this.httpClient
      .post(`${environment2.apiUrl}/logout/`, null)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
