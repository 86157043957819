<div class="bg-white bg-white-custom">
    <div class="container">
        <section class="section-concurso-header">
            <div class="section-concurso-header-content">
                <img src="https://intranet.abastible.cl/wp/wp-content/themes/intranet-abastible/assets/img/concurso-verano/titulo-concurso.png"
                    alt="" class="section-concurso-header-content-img">
                <form class="section-concurso-header-content-form" [formGroup]="myForm" (ngSubmit)="submitForm()"
                    id="form-denuncia" enctype="multipart/form-data">
                    <div class="section-concurso-header-content-form-input">
                        <label for="file_concurso">Participa con tu foto aquí</label>
                        <input type="file" (change)="this.onFilechange($event)" formControlName="async-upload"
                            name="async-upload" id="file_concurso">
                    </div>
                    <div class="section-concurso-header-content-form-button">
                        <button class="section-concurso-header-content-form-button-btn">Subir Foto</button>
                    </div>
                </form>
                <a href="#" class="section-concurso-header-content-bases">Ver Bases</a>
            </div>
        </section>
        <section class="section-concurso-body">
            <h2 class="section-concurso-body-title">Galeria Fotos {{ logged_user_id }}</h2>
            <div class="section-concurso-body-items">
                <div class="section-concurso-body-item" *ngFor="let photo of photos; let i = index">
                    <img [src]="photo.image" alt="" class="section-concurso-body-item-img">
                    <div class="section-concurso-body-item-content">
                        <h4 class="section-concurso-body-item-content-email" [innerHTML]="photo.email"></h4>
                    </div>
                    <button class="section-concurso-body-item-content-like"
                        (click)="likePhoto($event,logged_user_id, photo.id, i)">
                        <img src="https://intranet.abastible.cl/wp/wp-content/plugins/wp-ulike/assets/img/icons/add-heart-hover.png"
                            alt="" class="section-concurso-body-item-content-like-icon" *ngIf="photo.liked == 0">
                        <img src="https://intranet.abastible.cl/wp/wp-content/plugins/wp-ulike/assets/img/icons/add-heart-hover.png"
                            alt=""
                            class="section-concurso-body-item-content-like-icon section-concurso-body-item-content-like-icon-success"
                            *ngIf="photo.liked == 1">
                        <span class="section-concurso-body-item-content-like-count-box">{{ photo.total_likes }}+</span>
                    </button>
                </div>
            </div>
        </section>
    </div>
</div>