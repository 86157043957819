<section class="page-agilidad">
  <div class="page-agilidad-banner">
    <img src="assets/img/agilidad/banner-agilidad.png" alt="" class="page-agilidad-banner-full">
    <div class="page-agilidad-banner-box-title">
      <h3 class="page-agilidad-banner-title">SER</h3>
      <svg xmlns="http://www.w3.org/2000/svg" width="364.385" height="142.692" viewBox="0 0 364.385 142.692"
        class="page-agilidad-banner-title2">
        <g id="Group_4" data-name="Group 4" transform="translate(-763.026 -151.578)">
          <path id="Path_10" data-name="Path 10"
            d="M860.9,292.658H835.742l-5.8-20.96H793.983l-5.8,20.96H763.026l32.731-107.382H828Zm-61.429-41.114h24.669L811.88,206.559Zm5-85.132c7.9-5,15.64-10,21.928-14.834l8.7,13.543a177.71,177.71,0,0,1-22.572,13.545Z"
            fill="#fff" />
          <path id="Path_11" data-name="Path 11"
            d="M924.42,294.27c-26.119,0-39.34-13.382-39.34-32.891V216.556c0-19.833,13.221-32.892,39.34-32.892a190.331,190.331,0,0,1,34.343,3.385l-2.9,21.122a252.2,252.2,0,0,0-31.441-2.579c-12.092,0-15.155,6.288-15.155,14.671v36.924c0,8.383,3.063,15.316,15.155,15.316a97.108,97.108,0,0,0,14.35-.967V238.644h22.735V289.11A136.387,136.387,0,0,1,924.42,294.27Z"
            fill="#fff" />
          <path id="Path_12" data-name="Path 12" d="M998.587,292.658V185.276h24.185V292.658Z" fill="#fff" />
          <path id="Path_13" data-name="Path 13" d="M1060.661,292.658V185.276h24.185v85.937h42.565v21.445Z"
            fill="#fff" />
        </g>
      </svg>
      <h5 class="page-agilidad-banner-title3">es un pilar de nuestro propósito</h5>
    </div>
    <img src="assets/img/agilidad/icon-banner1.svg" alt="" class="page-agilidad-banner-icon page-agilidad-banner-icon1">
    <img src="assets/img/agilidad/icon-banner2.svg" alt="" class="page-agilidad-banner-icon page-agilidad-banner-icon2">
  </div>
  <!-- <section id="breadcrumbs">
    <div class="container">
      <div class="breadcrumbs">
        <div class="breadcrumbs-item">
          <a [routerLink]="['/muro']"><span class="material-icons">
              home
            </span></a>
          <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
        </div>
        <div class="breadcrumbs-item">
          <span>Agilidad</span>
        </div>
      </div>
    </div>
  </section> -->
  <div class="page-agilidad-section1">
    <div class="container">
      <div class="page-agilidad-section1-container">
        <div class="page-agilidad-section1-container-left">
          <img src="assets/img/agilidad/text-agilidad1.png" alt="" class="page-agilidad-section1-container-left-img">
        </div>
        <div class="page-agilidad-section1-container-right">
          <ul class="page-agilidad-section1-container-right-list">
            <li
              class="page-agilidad-section1-container-right-list-item page-agilidad-section1-container-right-list-item-bold">
              • Es una mentalidad</li>
            <li class="page-agilidad-section1-container-right-list-item">- Mindset de mejora continua, iterativo,
              evolutivo</li>
            <li
              class="page-agilidad-section1-container-right-list-item page-agilidad-section1-container-right-list-item-bold">
              • Nuevas formas de trabajar</li>
            <li
              class="page-agilidad-section1-container-right-list-item page-agilidad-section1-container-right-list-item-bold">
              • Utilizar herramientas de Play</li>
            <li class="page-agilidad-section1-container-right-list-item">- SCRUM marco metodológico/herramienta</li>
            <li class="page-agilidad-section1-container-right-list-item">- Desarrollos iterativos e incrementales</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="page-agilidad-section2">
    <img src="assets/img/agilidad/bg-section1.png" alt="" class="page-agilidad-section2-bg">
    <div class="container">
      <div class="page-agilidad-section2-container">
        <h5 class="page-agilidad-section2-container-title1">Es una</h5>
        <h3 class="page-agilidad-section2-container-title2">mentalidad</h3>
        <div class="page-agilidad-section2-container-content">
          <img src="assets/img/agilidad/image-agilidad2.svg"
            class="page-agilidad-section2-container-content-img page-agilidad-section2-container-content-img1" alt="">
          <img src="assets/img/agilidad/image-agilidad3.svg"
            class="page-agilidad-section2-container-content-img page-agilidad-section2-container-content-img2" alt="">
        </div>
      </div>
    </div>
  </div>
  <div class="page-agilidad-section3">
    <div class="container">
      <div class="page-agilidad-section3-container">
        <div class="page-agilidad-section3-container-left">
          <h3 class="page-agilidad-section3-container-left-title">Es un mínimo producto viable</h3>
          <div class="page-agilidad-section3-container-left-text">
            <p><span>•</span> Una versión <b>inicial</b> de un producto <b>funcionando</b></p>
            <p><span>•</span> Una versión básica de mi producto o servicio con el menor
              gasto posible</p>
            <p><span>•</span> Busca crear valor al cliente con las mínimas funcionalidades</p>
          </div>
        </div>
        <div class="page-agilidad-section3-container-right">
          <img src="assets/img/agilidad/image-agilidad1.png" alt="" class="page-agilidad-section3-container-right-img">
        </div>
      </div>
    </div>
    <img src="assets/img/agilidad/bg-banner-separate1.svg" alt="" class="page-agilidad-section3-separate-bg">
  </div>
  <div class="page-agilidad-section4">
    <div class="container">
      <div class="page-agilidad-section4-container">
        <div class="page-agilidad-section4-container-items">
          <img src="assets/img/agilidad/gif-agilidad1.gif" alt="" class="page-agilidad-section4-container-item">
          <img src="assets/img/agilidad/gif-agilidad2.gif" alt="" class="page-agilidad-section4-container-item">
        </div>
        <div class="page-agilidad-section4-container-content">
          <h5 class="page-agilidad-section4-container-content-title">¿Se puede aplicar a cualquier NECESIDAD?</h5>
          <div class="page-agilidad-section4-container-content-text">
            <p>¡SÍ! Inclusive para soluciones no sistémicas</p>
          </div>
          <div class="page-agilidad-section4-container-content-int">
            <div class="page-agilidad-section4-container-content-int-left">
              <img src="assets/img/agilidad/relog-agilidad.svg" alt=""
                class="page-agilidad-section4-container-content-int-left-img">
            </div>
            <div class="page-agilidad-section4-container-content-int-right">
              <h5 class="page-agilidad-section4-container-content-title">¿Cuáles son los BENEFICIOS?</h5>
              <div class="page-agilidad-section4-container-content-int-right-text">
                <p>• Al lanzar mi producto o solución en un corto plazo, obtengo respuesta de su
                  funcionamiento en el menor plazo.</p>
                <p>• Al lanzar antes mi producto o solución y veo un error es menos costoso el
                  solucionarlo.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><img src="assets/img/agilidad/bg-banner-separate2.svg" alt="" class="page-agilidad-section3-separate-bg">
  </div>
  <div class="page-agilidad-section7">
    <div class="container">
      <div class="page-agilidad-section7-container">
        <div class="page-agilidad-section7-container-left">
          <h3 class="page-agilidad-section7-container-left-title">TODOS <br>PARTIERON <br>DE UNA IDEA <br>O UN DOLOR
          </h3>
        </div>
        <div class="page-agilidad-section7-container-right">
          <img src="assets/img/agilidad/image-agilidad4.png" alt="" class="page-agilidad-section7-container-right-img">
        </div>
      </div>
    </div>
  </div>
  <div class="page-agilidad-section5">
    <div class="container">
      <div class="page-agilidad-section5-container">
        <h3 class="page-agilidad-section5-container-title">Valores de la Agilidad</h3>
        <div class="page-agilidad-section5-container-items">
          <div class="page-agilidad-section5-container-item">
            <div class="page-agilidad-section5-container-item-left">
              <h4 class="page-agilidad-section5-container-item-title">Individuos <br>e interacciones</h4>
            </div>
            <div class="page-agilidad-section5-container-item-center">
              <img src="assets/img/agilidad/icon-agilidad1.svg" alt=""
                class="page-agilidad-section5-container-item-center-image">
              <h6 class="page-agilidad-section5-container-item-center-text">sobre</h6>
              <img src="assets/img/agilidad/icon-agilidad2.svg" alt=""
                class="page-agilidad-section5-container-item-center-image">
            </div>
            <div class="page-agilidad-section5-container-item-right">
              <h4 class="page-agilidad-section5-container-item-title">Procesos y <br>herramientas</h4>
            </div>
          </div>
          <div class="page-agilidad-section5-container-item">
            <div class="page-agilidad-section5-container-item-left">
              <h4 class="page-agilidad-section5-container-item-title">Colaboración <br>con el cliente</h4>
            </div>
            <div class="page-agilidad-section5-container-item-center">
              <img src="assets/img/agilidad/icon-agilidad3.svg" alt=""
                class="page-agilidad-section5-container-item-center-image">
              <h6 class="page-agilidad-section5-container-item-center-text">sobre</h6>
              <img src="assets/img/agilidad/icon-agilidad4.svg" alt=""
                class="page-agilidad-section5-container-item-center-image">
            </div>
            <div class="page-agilidad-section5-container-item-right">
              <h4 class="page-agilidad-section5-container-item-title">Negociación <br>Contractual</h4>
            </div>
          </div>
          <div class="page-agilidad-section5-container-item">
            <div class="page-agilidad-section5-container-item-left">
              <h4 class="page-agilidad-section5-container-item-title">Software <br>funcionando</h4>
            </div>
            <div class="page-agilidad-section5-container-item-center">
              <img src="assets/img/agilidad/icon-agilidad5.svg" alt=""
                class="page-agilidad-section5-container-item-center-image">
              <h6 class="page-agilidad-section5-container-item-center-text">sobre</h6>
              <img src="assets/img/agilidad/icon-agilidad6.svg" alt=""
                class="page-agilidad-section5-container-item-center-image">
            </div>
            <div class="page-agilidad-section5-container-item-right">
              <h4 class="page-agilidad-section5-container-item-title">Documentación <br>extensiva</h4>
            </div>
          </div>
          <div class="page-agilidad-section5-container-item">
            <div class="page-agilidad-section5-container-item-left">
              <h4 class="page-agilidad-section5-container-item-title">Respuesta <br>ante el cambio</h4>
            </div>
            <div class="page-agilidad-section5-container-item-center">
              <img src="assets/img/agilidad/icon-agilidad7.svg" alt=""
                class="page-agilidad-section5-container-item-center-image">
              <h6 class="page-agilidad-section5-container-item-center-text">sobre</h6>
              <img src="assets/img/agilidad/icon-agilidad8.svg" alt=""
                class="page-agilidad-section5-container-item-center-image">
            </div>
            <div class="page-agilidad-section5-container-item-right">
              <h4 class="page-agilidad-section5-container-item-title">Seguir un plan</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-agilidad-section6">
    <div class="container">
      <div class="page-agilidad-section6-container">
        <h3 class="page-agilidad-section6-container-title">¡Te invitamos a nuestros Talleres de Agilidad!</h3>
        <div class="page-agilidad-section6-container-text">
          <p>Son una excelente oportunidad para abrir la mente a nuevas metodologías y alcanzar metas a través de
            herramientas de mejora continua. </p>
          <p><b>Inscripciones: sandra.benites@abastible.cl</b></p>
        </div>
      </div>
    </div>
  </div>
  <div class="section-design2"
    *ngIf="newsSeguridadInformacion.length > 0 || galeriaSeguridad.length > 0 || galeriaVideosSeguridad.length > 0">
    <div class="tabs-search">
      <button data-tab="#news-seguridad-informacion" class="item-tab-search active"
        *ngIf="newsSeguridadInformacion.length > 0">Noticias</button>
      <button data-tab="#galeries-seguridad-informacion" class="item-tab-search "
        *ngIf="galeriaSeguridad.length > 0">Galería</button>
      <button data-tab="#videos-seguridad-informacion" class="item-tab-search "
        *ngIf="galeriaVideosSeguridad.length > 0">Videos</button>
    </div>
    <div class="tabs-content-search">
      <div class="item-tab-content-search" id="news-seguridad-informacion" *ngIf="newsSeguridadInformacion.length > 0">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas2.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide"
                  *ngFor="let newSeguridadInformacion of newsSeguridadInformacion; let i = index">
                  <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ newSeguridadInformacion.img }}" alt="">
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="newSeguridadInformacion.title"></h4>
                      <div class="card-text">
                        <p [innerHTML]="newSeguridadInformacion.text"></p>
                      </div>
                      <a [routerLink]="['/noticia/' + newSeguridadInformacion.slug ]" class="card-link">Leer
                        más</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'agilidad' }" class="btn-custom-new">
                  <span>VER MÁS</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="item-tab-content-search" id="galeries-seguridad-informacion" *ngIf="galeriaSeguridad.length > 0"
        style="display: none;">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas2.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of galeriaSeguridad; let i = index">
                  <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                    class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                    data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ item.feature_img }}" alt="">
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                    </div>
                  </a>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
              <a [routerLink]="['/galeria-image-section/agilidad' ]" class="btn-custom-new">
                <span>VER MÁS</span>
              </a>
            </div>
          </div>
        </section>
      </div>
      <div class="item-tab-content-search" id="videos-seguridad-informacion" *ngIf="galeriaVideosSeguridad.length > 0"
        style="display: none;">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas2.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of galeriaVideosSeguridad; let i = index">
                  <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                    (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ item.img }}" alt="">
                      <div class="diagonal-cut"></div>
                      <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                        class="btn-play-desing1">
                    </div>
                    <div class="card-content">
                      <h4 class="card-title">{{ item.title }}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
              <a [routerLink]="['/galeria-videos-section/agilidad' ]" [queryParams]="1" class="btn-custom-new">
                <span>VER MÁS</span>
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <div class="page-agilidad-section8">
    <img src="assets/img/agilidad/bg-section2.png" alt="" class="page-agilidad-section8-bg">
    <div class="container">
      <div class="page-agilidad-section8-container">
        <h3 class="page-agilidad-section8-container-title">POTENCIAR <br>EL ESPÍRITU <br>EMPRENDEDOR</h3>
        <h3 class="page-agilidad-section8-container-title2">#ApoyaLaPyme</h3>
        <img src="assets/img/agilidad/logo-abastible-agilidad.svg" alt="" class="page-agilidad-section8-container-img">
      </div>
    </div>
  </div>
</section>