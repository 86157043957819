<section class="navbar-custom2">
  <div class="container">
    <div id="carousel-navbar">
      <div (click)="scroll(section.slug)" class="item-navbar-custom2 item-directorio-administracion"
        *ngFor="let section of sections; let i = index">
        <h6 class="title-navbar-custom">{{ section.titulo }}</h6>
      </div>
      <div (click)="scroll('control-interno')" class="item-navbar-custom2 item-directorio-administracion">
        <h6 class="title-navbar-custom">Control Interno</h6>
      </div>
    </div>
  </div>
</section>
<div class="bg-white bg-white-custom">
  <section class="section-banner-custom-new" id="section0" style="background-image: url(assets/img/img-test2.jpg);">
    <div class="overlay-banner-custom-new"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <h2 class="title-banner-custom-new" data-aos="fade-right" data-aos-duration="700">Gobierno Corporativo</h2>
          <span class="line-title-custom-new-banner" data-aos="fade-right" data-aos-duration="700"
            data-aos-delay="400"></span>
          <div class="box-text-banner-custon-new" data-aos="fade-right" data-aos-duration="700">
            <p>El Gobierno Corporativo es el conjunto de normas, principios, políticas y procedimientos que regulan la
              estructura y el
              funcionamiento de los órganos de gobierno de una empresa -como por ejemplo el funcionamiento del
              Directorio-, y mediante
              el cual se establecen los estándares que guían el comportamiento de todos los miembros de una compañía,
              determinando,
              entre otras, las obligaciones y responsabilidades en su relación con la comunidad, el mercado y las
              autoridades. <br><br>En este contexto, el rol del cumplimiento normativo o <i>compliance</i> de Abastible
              es el
              establecimiento de las políticas y
              procedimientos adecuados para garantizar que tanto la empresa, como sus directivos, colaboradores y
              terceros cumplen con
              el ordenamiento jurídico vigente.</p>
          </div>
        </div>
        <div class="col-lg-7 mt-auto">
          <div class="box-content-custom-new box-content-custom-new-iframe" data-aos="fade-up" data-aos-duration="700"
            data-aos-delay="200">
            <iframe class="_vs_ictr_player" [src]="urlSafe" width=720 height=380 frameborder="0" allow="autoplay *"
              scrolling="no"></iframe>
            <script src="https://vsplayer.global.ssl.fastly.net/player-wrapper-v4.js"></script>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div id="animationWindow"></div>
  <section id="breadcrumbs">
    <div class="container">
      <div class="breadcrumbs breadcrumbs-sections">
        <div class="breadcrumbs-item">
          <a [routerLink]="['/muro']"><span class="material-icons">
              home
            </span></a>
          <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
        </div>
        <div class="breadcrumbs-item">
          <span>Gobierno Corporativo</span>
        </div>
      </div>
    </div>
  </section>
  <section class="section-custom-new3">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">DOCUMENTOS</h3>
          <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
          <div class="content-documents-custom-new3">
            <a href="{{ documento.link }}" class="item-document-custom-new3"
              *ngFor="let documento of documentos; let i = index" target="_blank" data-aos="fade-up"
              data-aos-duration="700" data-aos-delay="0">
              <h3 class="title-document-custom-new3">{{ documento.titulo }}</h3>
              <div class="box-acctions-custom-new3">
                <span class="view-document">
                  <img src="assets/img/file.svg" alt="">
                </span>
                <span class="download-document">
                  <img src="assets/img/download.svg" alt="">
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div *ngFor="let section of sections; let i = index" class="sections-custom-new">
    <section class="section-custom-new" id="{{ section.slug }}">
      <div class="container">
        <div class="row">
          <div class=""
            [ngClass]="{'col-lg-3': section.video || section.imagen, 'col-lg-4': !section.video && !section.imagen }">
            <div class="box-title-custom-new">
              <h3 class="title-custom-new" data-aos="fade-up" data-aos-duration="700">{{ section.titulo }}</h3>
              <span class="line-title-custom-new" data-aos="fade-right" data-aos-duration="700"
                data-aos-delay="400"></span>
            </div>
          </div>
          <div class=""
            [ngClass]="{'col-lg-6': section.video || section.imagen, 'col-lg-8': !section.video && !section.imagen }">
            <div class="box-content-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="200">
              <!-- <div class="text-destaqued-custom-new">
                <h5>{{ section.subtitulo }}</h5>
              </div> -->
              <div class="text-custom-new">
                <p [innerHTML]="textMin(section.descripcion_corta)" *ngIf="section.descripcion_corta"></p>
              </div>
              <span *ngIf="section.titulo == 'Canal de denuncias'">
                <a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1" target="_blank"
                  class="btn-custom-new" *ngIf="section.enlace">
                  <span>VER MÁS</span>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                    style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                      </g>
                    </g>
                  </svg>
                </a>
              </span>
              <span *ngIf="section.titulo != 'Canal de denuncias'">
                <span (click)="fullTextModal(section.titulo , section.texto, section.slug,section.documentos)"
                  class="btn-custom-new" *ngIf="!section.enlace">
                  <span>VER MÁS</span>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                    style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                      </g>
                    </g>
                  </svg>
                </span>
                <a [routerLink]="[section.enlace]" class="btn-custom-new" *ngIf="section.enlace">
                  <span>VER MÁS</span>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                    style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                      </g>
                    </g>
                  </svg>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="box-img-section-custom-new" *ngIf="section.video">
        <div class="diagonal-cut"></div>
        <video autoplay muted loop>
          <source src="{{ section.video }}">
        </video>
      </div>
      <div class="box-img-section-custom-new" *ngIf="section.imagen && !section.video">
        <div class="diagonal-cut"></div>
        <img [src]="section.imagen" alt="" class="img-fluid">
      </div>
    </section>

    <section class="section-image-divider" *ngIf="section.imagen_separacion"
      [ngStyle]="{ 'background-image': 'url(' + section.imagen_separacion + ')'}">
    </section>
  </div>
  <div class="sections-custom-new">
    <section class="section-custom-new" id="control-interno">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="box-title-custom-new">
              <h3 class="title-custom-new" data-aos="fade-up" data-aos-duration="700">Control Interno</h3>
              <span class="line-title-custom-new" data-aos="fade-right" data-aos-duration="700"
                data-aos-delay="400"></span>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="box-content-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="200">
              <div class="text-custom-new">
                <p>Revisa las secciones de Auditoría Interna, Seguridad de la Información, Gestión de Riesgo, Gestión de
                  Procesos y Dueños
                  de Procesos.</p>
              </div>
              <a [routerLink]="['/control-interno']" class="btn-custom-new">
                <span>VER MÁS</span>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                  style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                  <g>
                    <g>
                      <path
                        d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                    </g>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

</div>

<div class="modal-img-full modal-single-content" [ngClass]="modalTextShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullTextModal()"></div>
  <div class="box-modal-text-full">
    <div class="modal-header-single-content">
      <button class="btn-back-single-content" (click)="closeFullTextModal()">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"
          fill="#ff7000">
          <g>
            <g>
              <path
                d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
            </g>
          </g>
        </svg>
      </button>
      <h3 class="title-header-modal">Gobierno Corporativo / <span [innerHTML]="modalTitulo"></span></h3>
      <!-- <button class="close" (click)="closeFullTextModal()">
        <img src="assets/img/btn-close.svg" alt="">
      </button> -->
    </div>
    <div class="modal-text">
      <div class="modal-content-text">
        <div [innerHTML]="modalText" *ngIf="modalTitulo != 'Compliance' && modalTitulo != 'Libre Competencia'"></div>

        <div *ngIf="modalTitulo == 'Compliance'">
          <div class="banner-compliance-custom1">
            <img src="assets/img/banner-compliance.jpg" alt="">
          </div>
          <div class="section-compliance-custom1">
            <div class="container">
              <div class="content-aboutus-compliance">
                <img src="assets/img/icon-nosotros.png" alt="">
                <p>El principal objetivo de esta área es gestionar el cumplimiento de la normativa aplicable a
                  Abastible, supervisando el entorno para prevenir situaciones de riesgo, lo que incluye el cumplimiento
                  normativo sectorial, no sectorial, libre competencia y el Modelo de Prevención de Delitos y Fraude
                  (MPDF). Asimismo, se encarga de fomentar que exista una cultura de Integridad al interior de Abastible
                  a través de actividades de difusión y capacitación periódica a toda la organización.</p>
              </div>
            </div>
          </div>
          <div class="section-compliance-custom2">
            <img src="assets/img/bg-separate1.png" alt="" class="bg-separate-compliance-section1">
            <div class="container">
              <div class="content-encargada-compliance">
                <img src="assets/img/photo-about-compliance.png" alt="">
                <p>La Encargada de Prevención de Delitos y Fraude es la Gerente Legal y Asuntos Corporativos de
                  Abastible, Paula Jervis Ortiz. <br>La misión de la Encargada de Prevención de Delitos y Fraude es dar
                  cumplimiento a lo dispuesto en la Ley N° 20.393 de Responsabilidad Penal de la Persona Jurídica, y
                  además, es la encargada de gestionar el Modelo de Prevención de Delitos y Fraude (MPDF), su diseño,
                  implementación, actualización y control.</p>
              </div>
            </div>
          </div>
          <div class="section-compliance-custom3">
            <div class="container">
              <div class="content-equipo-compliance">
                <div class="box-title-equipo-compliance">
                  <img src="assets/img/icon-equipo-compliance.png" alt="">
                  <h3>Equipo</h3>
                </div>
                <div class="box-equipo-items">
                  <div class="box-equipo-custom1 box-equipo-custom1-3 box-equipo-custom1-2">
                    <div class="overlay-equipo-custom1"></div>
                    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/Rodrigo-Alfaro.jpg" alt=""
                      class="img-box-equipo-custom1">
                    <div class="box-content-equipo-custom1">
                      <h3 class="title-equipo-custom1">Rodrigo Alfaro</h3>
                      <span class="line-title-equipo-custom1"></span>
                      <div class="box-text-equipo-custom1">
                        <p>Subgerente de Compliance</p>
                      </div>
                    </div>
                  </div>
                  <div class="box-equipo-custom1 box-equipo-custom1-3 box-equipo-custom1-2">
                    <div class="overlay-equipo-custom1"></div>
                    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/Alfonso-Tapia.jpg" alt=""
                      class="img-box-equipo-custom1">
                    <div class="box-content-equipo-custom1">
                      <h3 class="title-equipo-custom1">Alfonso Tapia
                      </h3>
                      <span class="line-title-equipo-custom1"></span>
                      <div class="box-text-equipo-custom1">
                        <p>Abogado Compliance</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img src="assets/img/bg-separate2.png" alt="" class="bg-separate-compliance-section2">
          </div>
          <div class="section-compliance-custom4">
            <div class="container">
              <div class="row">
                <div class="col-lg-7">
                  <div class="box-text-section-compliance-custom4">
                    <p>Dentro de las actividades de difusión continua en materia de Integridad, asociado al MPDF, se
                      destaca el envío de dilemas éticos a toda la Compañía, con el objeto de que todos los
                      colaboradores permanentemente cuenten con información al respecto, conozcan cómo actuar en
                      determinados escenarios complejos, y estén informados respecto de los canales que pone a
                      disposición la Empresa para consultar o comunicar cualquier tema relativo a estas materias.</p>
                  </div>
                </div>
                <div class="col-lg-5">
                  <img src="assets/img/img-section-compliance1.png" alt="" class="img-section-compliance-custom4">
                </div>
              </div>
            </div>
            <img src="assets/img/bg-separate3.png" alt="" class="bg-separate-compliance-section3">
          </div>
          <div class="section-compliance-custom4">
            <div class="container">
              <div class="row">
                <div class="col-lg-5">
                  <img src="assets/img/img-section-compliance2.png" alt="" class="img-section-compliance-custom4">
                </div>
                <div class="col-lg-7">
                  <div class="box-text-section-compliance-custom4">
                    <p>Anualmente Abastible participa en un estudio que mide la cultura ética y de cumplimiento,
                      incluidos los indicadores del MPDF. Esta encuesta permite gestionar la integridad corporativa, a
                      partir de un diagnóstico en el que participan diversas empresas, consultando a los propios
                      colaboradores respecto de materias asociadas a temas de Integridad en la organización.</p>
                    <p>En línea con lo anterior, el principal consejo en estas materias es consultar siempre en caso de
                      dudas a la Gerencia Legal y Asuntos Corporativos, para que a través del Área de Compliance, puedan
                      canalizar sus inquietudes, y en caso de ser pertinente, gestione las investigaciones sobre estas y
                      otras materias, recibidas a través del “Canal de Denuncias”.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="section-tabs-compliance">
            <img src="assets/img/bg-separate4.png" alt="" class="bg-separate-compliance-section4">

            <!-- Documentos -->
            <div *ngIf="modalDocuments.length > 0" class="mt-5 pb-5">
              <div class="tabs-search">
                <button data-tab="#documentos-compliance" class="item-tab-search active">Documentos</button>
              </div>
              <div class="tabs-content-search">
                <div class="item-tab-content-search" id="documentos-compliance">
                  <div class="col-lg-8 mx-auto mb-5">
                    <div class="content-documents-custom-new3">
                      <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
                        data-aos-duration="700" data-aos-delay="0"
                        *ngFor="let document of modalDocuments; let i = index">
                        <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                        <div class="box-acctions-custom-new3">
                          <span class="view-document">
                            <img src="assets/img/file.svg" alt="">
                          </span>
                          <span class="download-document">
                            <img src="assets/img/download.svg" alt="">
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tabs-search">
              <button data-tab="#news-compliance" class="item-tab-search active">Noticias</button>
              <button data-tab="#galeries-compliance" class="item-tab-search ">Galería</button>
              <button data-tab="#videos-compliance" class="item-tab-search ">Videos</button>
              <button data-tab="#news2-compliance" class="item-tab-search" *ngIf="news3.length > 0">Cumplimiento
                normativo</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="news-compliance">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselGalerias.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let new1 of news1; let i = index">
                          <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                            data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ new1.img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ new1.title }}</h4>
                              <div class="card-text">
                                <p [innerHTML]="new1.text"></p>
                              </div>
                              <a [routerLink]="['/noticia/' + new1.slug ]" class="card-link">Leer más</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'compliance' }"
                        class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="galeries-compliance" style="display: none;">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselGalerias2.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galerias1; let i = index">
                          <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                            class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                            data-aos="fade-up" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.feature_img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="100">
                      <a [routerLink]="['/galeria-image-section/compliance' ]" class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="videos-compliance" style="display: none;">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselGalerias3.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galeriaCompliance; let i = index">
                          <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                            (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                              <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                                class="btn-play-desing1">
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ item.title }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/galeria-videos-section/compliance' ]" [queryParams]="1"
                        class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="news2-compliance" style="display: none;"
                *ngIf="news3.length > 0">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselGalerias.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let new1 of news3; let i = index">
                          <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                            data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ new1.img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ new1.title }}</h4>
                              <div class="card-text">
                                <p [innerHTML]="new1.text"></p>
                              </div>
                              <a [routerLink]="['/noticia/' + new1.slug ]" class="card-link">Leer más</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'cumplimiento-normativo' }"
                        class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <img src="assets/img/bg-separate5.png" alt="" class="bg-separate-compliance-section5">
          </div>
        </div>


        <div *ngIf="modalTitulo == 'Libre Competencia'">
          <div class="banner-compliance-custom1">
            <img src="assets/img/banner-libre-competencia.png" alt="">
          </div>
          <div class="section-compliance-custom1">
            <div class="container">
              <div class="content-aboutus-compliance">
                <img src="assets/img/icon-nosotros-integridad.png" alt="">
                <p>La Libre Competencia entre los agentes económicos es, sin duda, un pilar fundamental para el correcto
                  y natural funcionamiento de los mercados. La Libre Competencia incrementa el bienestar de los
                  consumidores, y hace eficaz su proceso de elegir entre las mejores opciones, lo que en último término
                  fomenta la competitividad y la innovación en las empresas.</p>
              </div>
            </div>
          </div>
          <div class="section-compliance-custom4">
            <img src="assets/img/bg-separate6.png" class="bg-separate-compliance-section3-1" alt="">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <img src="assets/img/img-section-competencia.png" alt="" class="img-section-compliance-custom4">
                </div>
                <div class="col-lg-6">
                  <div class="box-text-section-compliance-custom4">
                    <p>Para Abastible y sus filiales, la Libre Competencia es una base esencial para el desarrollo de
                      los mercados en los que participa. En ese sentido, la Empresa está convencida que la Libre
                      Competencia permite la generación de mayor bienestar, la igualdad de oportunidades, mayor
                      eficiencia en la producción y un incremento de la innovación. Para los consumidores, la existencia
                      de una efectiva Libre Competencia protege sus derechos y valida el rol de la empresa privada.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section-compliance-custom4 section-compliance-custom4-2">
            <img src="assets/img/bg-separate7.png" class="bg-separate-compliance-section3-2" alt="">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="box-text-section-compliance-custom4">
                    <div class="box-icon-custom4">
                      <img src="assets/img/icon-balanza.png" alt="">
                    </div>
                    <p>En su permanente compromiso por cumplir con todos los aspectos del ordenamiento jurídico en donde
                      opera, Abastible, ha adoptado el respeto de la Libre Competencia como uno de sus principios
                      básicos junto con el cumplimento de toda la normativa aplicable. Así lo recoge expresamente su
                      Código de Ética.</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <img src="assets/img/img-section-competencia2.png" alt="" class="img-section-compliance-custom4">
                </div>
              </div>
            </div>
          </div>
          <div class="section-compliance-custom4">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <img src="assets/img/img-section-competencia3.png" alt="" class="img-section-compliance-custom4">
                </div>
                <div class="col-lg-5 mx-auto">
                  <div class="box-text-section-compliance-custom4">
                    <div class="box-icon-custom4">
                      <img src="assets/img/icon-notes.png" alt="">
                    </div>
                    <p>Además, dando un paso adicional, Abastible ha adoptado el compromiso de fomentar una cultura de
                      respeto de la Libre Competencia, que permita interiorizar a todos sus trabajadores con la
                      normativa existente, así como promover las buenas prácticas que deben seguirse.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="section-tabs-compliance">
            <img src="assets/img/bg-separate4.png" alt="" class="bg-separate-compliance-section4">
            <!-- Documentos -->
            <div *ngIf="modalDocuments.length > 0" class="mt-5 pb-5">
              <div class="tabs-search">
                <button data-tab="#documentos-libre-competencia" class="item-tab-search active">Documentos</button>
              </div>
              <div class="tabs-content-search">
                <div class="item-tab-content-search" id="documentos-libre-competencia">
                  <div class="col-lg-8 mx-auto mb-5">
                    <div class="content-documents-custom-new3">
                      <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
                        data-aos-duration="700" data-aos-delay="0"
                        *ngFor="let document of modalDocuments; let i = index">
                        <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                        <div class="box-acctions-custom-new3">
                          <span class="view-document">
                            <img src="assets/img/file.svg" alt="">
                          </span>
                          <span class="download-document">
                            <img src="assets/img/download.svg" alt="">
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tabs-search">
              <button data-tab="#news-libre-competencia" class="item-tab-search active">Noticias</button>
              <button data-tab="#galeries-libre-competencia" class="item-tab-search ">Galería</button>
              <button data-tab="#videos-libre-competencia" class="item-tab-search "
                *ngIf="galeriaVideos.length > 0">Videos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="news-libre-competencia">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselGalerias.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let new1 of news2; let i = index">
                          <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                            data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ new1.img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ new1.title }}</h4>
                              <div class="card-text">
                                <p [innerHTML]="new1.text"></p>
                              </div>
                              <a [routerLink]="['/noticia/' + new1.slug ]" class="card-link">Leer más</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'libre-competencia' }"
                        class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="galeries-libre-competencia" style="display: none;">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselGalerias2.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galerias2; let i = index">
                          <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                            class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                            data-aos="fade-up" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.feature_img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="100">
                      <a [routerLink]="['/galeria-image-section/libre-competencia' ]" class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="videos-libre-competencia" style="display: none;"
                *ngIf="galeriaVideos.length > 0">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselGalerias3.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galeriaVideos; let i = index">
                          <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                            (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                              <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                                class="btn-play-desing1">
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ item.title }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/galeria-videos-section/libre-competencia' ]" [queryParams]="1"
                        class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <img src="assets/img/bg-separate5.png" alt="" class="bg-separate-compliance-section5">
          </div>
        </div>


        <footer>
          <div class="menu-mobile">
            <div class="content-menu-mobile">
              <a href="#menu-mobile1" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#menu-mobile2" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#" class="btn-design-float"><span></span></a>
              <a href="#menu-mobile3" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#menu-mobile4" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
            </div>
          </div>
          <div class="container box-footer">
            <div class="box-list-footer">
              <div class="list-footer">
                <h3 class="title-list-footer">Conoce Abastible</h3>
                <ul>
                  <li><a [routerLink]="['/conoce-abastible']"
                      fragment="presentacion-de-la-empresa-historia">Presentación de la
                      empresa/historia</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="mision-vision-valores">Misión -Visión -
                      Valores</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="directorio-y-administracion">Directorio
                      y Administración</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="productos-y-servicios">Productos y Servicios</a>
                  </li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="proceso-productivo">Proceso Productivo</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="soluciones-energeticas">Soluciones
                      Energéticas</a></li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Control Interno</h3>
                <ul>
                  <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1"
                      target="_blank">Canal de
                      Denuncias</a></li>
                  <li><a [routerLink]="['/control-interno']" fragment="auditoria-interna">Auditoría interna</a>
                  </li>
                  <li><a [routerLink]="['/control-interno']" fragment="seguridad-de-la-informacion">Seguridad de la
                      información</a></li>
                  <li><a [routerLink]="['/control-interno']" fragment="gestion-de-riesgo">Gestión de Riesgo</a>
                  </li>
                  <li><a [routerLink]="['/control-interno']" fragment="gestion-de-procesos">Gestión de Procesos</a>
                  </li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Seguridad</h3>
                <ul>
                  <li><a [routerLink]="['/seguridad']" fragment="integridad-operacional">Integridad Operacional</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="gestion-de-calidad">Gestión de Calidad</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="servicio-nacional-de-emergencias">Servicio Nacional de
                      Emergencias</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="manejo-manual-de-carga">Manejo Manual de Carga</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="medio-ambiente">Medio Ambiente</a></li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Beneficios</h3>
                <ul>
                  <li><a [routerLink]="['/beneficios']" fragment="beneficios-para-colaboradores">Beneficios para
                      colaboradores</a></li>
                  <li><a [routerLink]="['/beneficios']" fragment="clima-organizacional">Clima organizacional</a></li>
                </ul>
              </div>
              <!--<div class="list-footer">
                    <h3 class="title-list-footer">Gobierno Corporativo</h3>
                    <ul>
                      <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1" target="_blank">Canal de Denuncias</a></li>
                      <li><a [routerLink]="['/gobierno-corporativo']" fragment="section2">Compliance</a></li>
                  </ul>
                </div> -->
              <div class="list-footer">
                <h3 class="title-list-footer">Soluciones
                  Energéticas</h3>
                <ul>
                  <li><a [routerLink]="['/soluciones-energeticas']" fragment="soluciones-energeticas">Soluciones
                      Energéticas</a></li>
                </ul>
              </div>
              <!-- <div class="list-footer">
                <h3 class="title-list-footer">Innovación</h3>
                <ul>
                  <li><a [routerLink]="['/innovacion']" fragment="i-now">I-NOW</a></li>
                  <li><a [routerLink]="['/innovacion']" fragment="laboratorio-de-innovacion">Laboratorio de
                      Innovación</a></li>
                  <li><a [routerLink]="['/innovacion']" fragment="soluciones-energeticas">Soluciones Energéticas</a>
                  </li>
                </ul>
              </div> -->
              <div class="list-footer list-footer-rd">
                <h3 class="title-list-footer">Social</h3>
                <ul>
                  <li><a href="https://www.facebook.com/AbastibleCL" target="_blank"><img
                        src="assets/img/icons/icon-facebook.svg" alt="">AbastibleCL</a></li>
                  <li><a href="https://www.instagram.com/laenergiadevivir/" target="_blank"><img
                        src="assets/img/icons/icon-instagram.svg" alt="">/laenergiadevivir</a></li>
                  <li><a href="https://www.linkedin.com/company/abastible-s.a./" target="_blank"><img
                        src="assets/img/icons/icon-linkedin.svg" alt="">/company/abastible-s.a.</a></li>
                  <li><a href="https://www.youtube.com/user/AbastibleChileSA" target="_blank"><img
                        src="assets/img/icons/icon-youtube.svg" alt="">/AbastibleChileSA</a></li>
                  <li><a href="https://www.abastible.cl/" target="_blank"><img src="assets/img/icons/logo-abastible.svg"
                        alt="">abastible.cl</a></li>
                </ul>
              </div>
            </div>
            <a href="index.html" class="logo-footer"><img src="assets/img/logo.png" alt="" /></a>
          </div>
        </footer>
      </div>
    </div>
  </div>
</div>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>