<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Procurement</span>
      </div>
    </div>
  </div>
</section>
<section class="section-custom-new3">
  <div class="container">
    <!-- <div class="text-center mb-3">
      <h2 class="title1 text-center mb-5">Procurement</h2>
    </div> -->
    <div class="text-center mb-3">
      <h2 class="title1 text-center mb-4">Solicitudes PRO</h2>
    </div>
    <div class="section-custom-new3-box-btn">
      <a href="https://apps.powerapps.com/play/e/01c7b590-0d82-e52a-a783-6f905aa891e9/a/c1cdf20c-65d2-4e4d-a5c0-f69ab0fe9092?tenantId=13aff87e-8ecf-406b-926f-b1c5df477126&hint=eec41453-dfe5-49cd-9dc3-722605c69a94&sourcetime=1706188316074&source=portal"
        class="section-custom-new3-btn btn-design1" target="_blank">Link acceso Power Apps</a>
    </div>
    <div class="text-center mb-3" *ngIf="documentos2.length > 0">
      <h2 class="title1 text-center mb-4">Paso a paso Ticketera Procurement</h2>
    </div>
    <div class="row mb-5" *ngIf="documentos2.length > 0">
      <div class="col-lg-8 mx-auto">
        <div class="content-documents-custom-new3 mb-5">
          <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
            data-aos-duration="700" data-aos-delay="0" *ngFor="let document of documentos2; let i = index">
            <h3 class="title-document-custom-new3" [innerHTML]="document.titulo"></h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="">
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="">
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="text-center mb-3">
      <h2 class="title1 text-center mb-4">Formularios Modulo MM</h2>
    </div>
    <div class="row mb-5">
      <div class="col-lg-8 mx-auto">
        <div class="content-documents-custom-new3 mb-5">
          <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
            data-aos-duration="700" data-aos-delay="0" *ngFor="let document of documentos1; let i = index">
            <h3 class="title-document-custom-new3" [innerHTML]="document.titulo"></h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="">
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="">
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>