import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NoticiasService {

    constructor(private httpClient: HttpClient) { }
    getNews(slug: string, paged: number, search: string): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/news-by-slug/?slug=${slug}&paged=${paged}&per_page=6&search=${search}`, null)
            .pipe((result) => {
                return result;
            });
    }
    getCategoriesNews(): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/get-categories`, null)
            .pipe((result) => {
                return result;
            });
    }
    getInfoPuntoPrensa(paged: number, search: string): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/punto-prensa/?paged=${paged}&per_page=6&search=${search}`, null)
            .pipe((result) => {
                return result;
            });
    }
    getInfoPuntoPrensaByCategory(category: string): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/punto-prensa-category/?cat_slug=${category}`, null)
            .pipe((result) => {
                return result;
            });
    }
    getInfoComunicados(paged: number, search: string): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/comunicados/?paged=${paged}&per_page=6&search=${search}`, null)
            .pipe((result) => {
                return result;
            });
    }
    getInfoConcursos(paged: number): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/get-concursos/?paged=${paged}`, null)
            .pipe((result) => {
                return result;
            });
    }
}
