import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';

// Services
import { ActivitiesService } from '@services/activities';
import { UsersService } from '@services/users';
import { VotarService } from '@services/vota';
import * as $ from 'jquery';

@Component({
  selector: 'app-revista',
  templateUrl: './revista.component.html',
  styleUrls: ['./revista.component.scss'],
})
export class RevistaComponent implements OnInit {
  urlSafe: SafeResourceUrl;

  constructor(
    private activitiesService: ActivitiesService,
    private usersService: UsersService,
    public sanitizer: DomSanitizer,
    private title: Title,
    private http: HttpClient,
    private votarService: VotarService
  ) {
  }

  ngOnInit() {
    this.title.setTitle('Book Potencial | Intranet Abastible');
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl("https://intranet.abastible.cl/wp/abastible-revista/");
  }

}
