import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { AppComponent } from '../../../app.component';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { PerfilService } from '@services/perfil';
import { NotificacionesService } from '@services/notificaciones';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilPageComponent implements OnInit {
  notification;
  userLogged: any;
  avatar_logged: any;
  name_logged: any;
  profileSidebar: false;
  commentCounter = 0;
  likesCounter = 0;
  notifications: any;
  notificationsRes: any;
  currentPage1 = 1;
  firstName: any;
  lastName: any;
  nickname: any;
  cargo: any;
  resultForm: any;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  form: FormGroup;
  modalSuccess = false;
  loggedIn = false;
  file: File = null;
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private perfilService: PerfilService,
    private landingService: LandingService,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private NotificacionesService: NotificacionesService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private title: Title,
    private appComponent: AppComponent
  ) {
  }

  ngOnInit(): void {
    var that = this;
    this.title.setTitle('Perfil | Intranet Abastible');
    this.form = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      nickname: ['', [Validators.required, Validators.minLength(3)]],
      cargo: ['', [Validators.minLength(3)]],
      "async-upload": ['', []],
    });
    this.appComponent.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.userLogged = JSON.parse(localStorage.getItem('user'));
        this.avatar_logged = this.userLogged['avatar_url'];
        this.name_logged = this.userLogged['display_name'];
        console.log(this.userLogged.ID);
        console.log(this.avatar_logged);
        $("#edit-profile").click(function (e) {
          e.preventDefault();
          $(".modal-edit-perfil").fadeIn(300).css("display", "flex")
        })
        $(".overlay-modal-edit, .btn-close-modal").click(function (e) {
          e.preventDefault();
          $(".modal-edit-perfil").fadeOut(300)
        })
        $('#image_profile').change(function (e) {
          let fileElement = (<HTMLInputElement>document.getElementById('image_profile')).files;
          console.log(fileElement);
          if (fileElement && fileElement[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
              const csv: string = e.target.result as string;
              $(".form-group-file label img, .img-box-profile2").attr("src", csv)
            }
            reader.readAsDataURL(fileElement[0]);
            console.log(reader);
          }
        });
        this.perfilService.infoPerfil(this.userLogged.ID).subscribe(
          (res) => {
            console.log(res);
            this.commentCounter = res[0]
            this.likesCounter = res[1]
            this.firstName = res[2]
            this.lastName = res[3]
            this.nickname = res[4]
            this.cargo = res[5]
            this.form.setValue({
              first_name: this.firstName,
              last_name: this.lastName,
              nickname: this.nickname,
              cargo: this.cargo,
              "async-upload": ""
            });
          },
          (err) => {
            console.error(err);
          }
        );
        this.NotificacionesService.getNotifications(1).subscribe(
          (res) => {
            console.log(res);
            this.notifications = res
          },
          (err) => {
            console.error(err);
          }
        )
      }

      this.loggedIn = loggedIn;
    });
  }

  onFilechange(event: any) {
    console.log(event.target.files[0])
    this.file = event.target.files[0]
  }

  submitForm() {
    if (this.form.invalid) {
      this.form.setValue({
        first_name: "",
        last_name: "",
        nickname: "",
        cargo: "",
        "async-upload": ""
      });
      return;
    }
    var formData: any = new FormData();
    formData.append("first_name", this.form.get('first_name').value);
    formData.append("last_name", this.form.get('last_name').value);
    formData.append("nickname", this.form.get('nickname').value);
    formData.append("cargo", this.form.get('cargo').value);
    formData.append("user_id", this.userLogged.ID);
    formData.append("async-upload", this.file);

    let that = this;

    this.perfilService.editarPerfil(formData).subscribe(
      (res) => {
        console.log(res);
        $(".modal-edit-perfil").hide();
        that.nickname = that.form.get('nickname').value;
        that.cargo = that.form.get('cargo').value;
        that.userLogged['avatar_url'] = '<img src="' + res.image + '" class="avatar user-1-avatar avatar-150 photo" width="150" height="150" alt="Profile photo of ' + res.nickname + '">';
        that.userLogged['display_name'] = that.nickname;

        this.avatar_logged = that.userLogged['avatar_url'];
        this.name_logged = that.nickname;

        let user_set = JSON.stringify(that.userLogged);

        console.log(that.userLogged);
        console.log(user_set);

        localStorage.setItem('user', user_set);

        that.successModal();
      },
      (err) => {
        console.error(err);
      }
    );

  }
  init(): void {
  }
  noHtml(content) {
    var plainText = content.replace(/<[^>]*>/g, '');
    var plainTextRes = plainText;
    // var plainTextRes = plainText.replace('You have a new like from', 'Tienes un nuevo like de');
    return plainTextRes;
  }
  nextPage() {
    this.currentPage1 = this.currentPage1 + 1;

    this.NotificacionesService.getNotifications(this.currentPage1).subscribe(
      (res) => {
        this.notificationsRes = res;
        this.notifications = [...this.notifications, ...this.notificationsRes];
        console.log([...this.notifications, res]);
        console.log(this.notificationsRes)
      },
      (err) => {
        console.error(err);
      }
    );
  }
  getPostDate(date_notified): string {
    return moment(date_notified, 'YYYY-MM-DD HH:mm:ss').format(
      'dddd,D MMMM'
    );
  }
  successModal() {
    this.modalSuccess = true;
  }
  closeSuccessModal() {
    this.modalSuccess = false;
  }

  deleteNotification(idNotification: string) {
    var idNotificationResult = parseInt(idNotification);
    this.NotificacionesService.deleteNotifications(idNotificationResult).subscribe(
      (res) => {
        document.getElementById('notification' + idNotificationResult).remove()
      },
      (err) => {
        console.error(err);
      }
    );
  }


}
