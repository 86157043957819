<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="back_title_page">
        <a href="{{back_route_link}}"><span>{{
            back_title_page }}</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Desafío i-NOW 2022 “Desafío masivo 2022”</span>
      </div>
    </div>
  </div>
</section>
<section id="canal-denuncias">
  <div class="container">
    <div class="box-canal-denuncias">
      <div class="box-header-canal-denuncias">
        <!-- <h2 class="title1">Ingresa y participa del VI Desafío i-NOW 2021 “Somos Innovación, tú eres innovación”</h2>
        <p class="text1-canal-denuncias text-center">Participa en nuestro nuevo desafio</p> -->
        <img src="assets/img/logo-inow-desafio7.png" alt="" class="img-header-desafio6">
      </div>
      <form [formGroup]="myForm" (ngSubmit)="submitForm()" id="form-denuncia" enctype="multipart/form-data">
        <div class="box-steps-canal-denuncias box-step1-canal-denuncias" id="step1">
          <h4 class="title1-canal-denuncias">BIENVENIDO AL DESAFÍO <br>I-NOW 2022</h4>
          <div class="box-white-canal-denuncias">
            <a href="#step2" class="btn-canal-denuncias">COMENZAR</a>
          </div>
        </div>
        <div class="box-steps-canal-denuncias box-step2-canal-denuncias" id="step2">
          <!-- <h4 class="title1-canal-denuncias">Datos Personales</h4> -->
          <div class="box-white-canal-denuncias">
            <div class="input-custom2">
              <label for="name_colaborador">Nombre del colaborador</label>
              <input type="text" class="input-custom-canal-denuncias" placeholder="" id="name_colaborador"
                formControlName="name_colaborador">
            </div>
            <div class="input-custom2">
              <label for="planta_oficina">Planta u Oficina</label>
              <select class="input-custom-canal-denuncias" id="planta_oficina" formControlName="planta_oficina">
                <option value="Oficina Distribución y Planta Arica">Oficina Distribución y Planta Arica</option>
                <option value="Oficina Distribución y Planta Iquique">Oficina Distribución y Planta Iquique</option>
                <option value="Oficina Distribución y Planta Antofagasta">Oficina Distribución y Planta Antofagasta
                </option>
                <option value="Of. distribución Copiapo">Of. distribución Copiapo</option>
                <option value="Planta Peñon">Planta Peñon</option>
                <option value="Oficina Distribución y Planta Con-Con">Oficina Distribución y Planta Con-Con</option>
                <option value="Of. Distribución San Fernando">Of. Distribución San Fernando</option>
                <option value="Of. Distribución Curicó">Of. Distribución Curicó</option>
                <option value="Planta Talca">Planta Talca</option>
                <option value="Of. Distribución Linares">Of. Distribución Linares</option>
                <option value="Of. Distribución Los Angeles">Of. Distribución Los Angeles</option>
                <option value="Of. Distribución Chillan">Of. Distribución Chillan</option>
                <option value="Of. ventas Zona sur">Of. ventas Zona sur</option>
                <option value="Planta Lenga">Planta Lenga</option>
                <option value="Oficina Distribución Lenga">Oficina Distribución Lenga</option>
                <option value="Of Distribución Temuco">Of Distribución Temuco</option>
                <option value="Local Ventas Temuco">Local Ventas Temuco</option>
                <option value="Of. Distribución Villarrica">Of. Distribución Villarrica</option>
                <option value="Of. Distribución Valdivia">Of. Distribución Valdivia</option>
                <option value="Oficina Distribución y Planta Osorno">Oficina Distribución y Planta Osorno</option>
                <option value="Of. Distribución Puerto Montt">Of. Distribución Puerto Montt</option>
                <option value="Planta Coyhaique">Planta Coyhaique</option>
                <option value="Oficina Distribución Castro">Oficina Distribución Castro</option>
                <option value="Planta Maipú">Planta Maipú</option>
                <option value="Oficina Central">Oficina Central</option>
                <option value="Estación Recarga Pudahuel">Estación Recarga Pudahuel</option>
              </select>
            </div>
          </div>
        </div>
        <div class="box-steps-canal-denuncias box-step2-canal-denuncias" id="step3">
          <!-- <h4 class="title1-canal-denuncias">Datos Personales</h4> -->
          <div class="box-white-canal-denuncias">
            <div class="input-custom2">
              <label for="name_idea">Nombre de la idea</label>
              <input type="text" class="input-custom-canal-denuncias" placeholder="" id="name_idea"
                formControlName="name_idea">
            </div>
            <div class="input-custom2">
              <label for="descripcion">Descripción <small>¿De qué se trata precisamente la idea?</small></label>
              <textarea id="descripcion" cols="30" rows="5" formControlName="descripcion" placeholder=""></textarea>
            </div>
            <!-- <div class="box-checks-custom1">
              <h3 class="title-check-custom1">Selecciona la categoría</h3>
              <div class="items-checks-custom1" id="cat_idea_box">
                <div class="item-check-custom1">
                  <input type="radio" name="cat_idea" value="Oportunidad de mejora y solución de problemas"
                    id="cat_idea1">
                  <label for="cat_idea1">Oportunidad de mejora y solución de problemas</label>
                </div>
                <div class="item-check-custom1">
                  <input type="radio" name="cat_idea" value="Nuevos Productos o Servicios" id="cat_idea2">
                  <label for="cat_idea2">Nuevos Productos o Servicios</label>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="box-steps-canal-denuncias box-step3-canal-denuncias" id="step4">
          <!-- <h4 class="title1-canal-denuncias">Datos de la Irregularidad</h4> -->
          <div class="box-white-canal-denuncias">
            <div class="input-custom2">
              <label for="beneficios">Beneficios
                <small>Nombre los beneficios concretos y evidentes de la idea. Esta puede tener distintos tipos de
                  beneficios; para el
                  consumidor final, para la compañía, etc.</small>
              </label>
              <textarea id="beneficios" cols="30" rows="5" formControlName="beneficios" placeholder=""></textarea>
            </div>
          </div>
        </div>
        <div class="box-steps-canal-denuncias box-step4-canal-denuncias" id="step5">
          <!-- <h4 class="title1-canal-denuncias">Datos de la Irregularidad</h4> -->
          <div class="box-white-canal-denuncias">
            <div class="input-custom2">
              <label for="retos">Retos
                <small>Identifique retos de implementación; cuestiones que requieren más averiguaciones; sugiera maneras
                  para superarlos.</small>
              </label>
              <textarea id="retos" cols="30" rows="5" formControlName="retos" placeholder=""></textarea>
            </div>
          </div>
        </div>
        <div class="box-steps-canal-denuncias box-step4-canal-denuncias" id="step6">
          <!-- <h4 class="title1-canal-denuncias">Datos de la Irregularidad</h4> -->
          <div class="box-white-canal-denuncias">
            <div class="input-custom2">
              <label for="costos">Costos
                <small>¿Cuánto crees que cuesta implementar tu idea?</small>
              </label>
              <textarea id="costos" cols="30" rows="5" formControlName="costos" placeholder=""></textarea>
            </div>
          </div>
        </div>

        <div class="box-steps-canal-denuncias box-step6-canal-denuncias" id="step7">
          <div class="overlay-step-5" *ngIf="!progressSendMail">
            <span></span><span></span>
          </div>
          <div class="text-center mb-3">
            <h2 class="title1" *ngIf="haveSendMail">¡Gracias por Participar!</h2>
            <h2 class="title1" *ngIf="!haveSendMail">Ha ocurrido un error</h2>
          </div>
          <p class="text2-canal-denuncias" *ngIf="haveSendMail">Su idea ha sido enviada exitosamente</p>
          <p class="text2-canal-denuncias" *ngIf="!haveSendMail">Ha fallado el envio del correo</p>
        </div>

        <div class="box-buttons-canal-denuncias">
          <div class="row">
            <div class="col-6 box-btn-volver box-btn-volver-centrar" style="display: none;">
              <a href="#" id="volver" class="btn-design2">Volver</a>
            </div>
            <div class="col-6 box-btn-siguiente">
              <button type="submit" id="siguiente" class="btn-design2">Siguiente</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="box-footer-canal-denuncias">
    <img src="assets/img/footer-desafio7.jpg" alt="" class="img-footer-desafio6">
  </div>
</section>

<div id="modal-success" class="modal-success" *ngIf="modalSuccess">
  <div class="overlay-modal" (click)="closeSuccessModal()"></div>
  <div class="modal-content">
    <button type="button" class="close" aria-label="Close" (click)="closeSuccessModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
      <h2 class="title-success">Su mensaje ha sido enviado exitosamente</h2>
    </div>
  </div>
</div>