import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { NewsService } from '@services/news';
import { InnovacionService } from '@services/innovacion';
import { GaleriasService } from '@services/galerias';
import { NoticiasService } from '@services/noticias';
import { LoadingService } from '@services/loading';
import * as $ from 'jquery';

@Component({
  selector: 'app-innovacion',
  templateUrl: './innovacion.component.html',
  styleUrls: ['./innovacion.component.scss'],
})
export class InnovacionComponent implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  addClassFixed = false;
  urlSafe: SafeResourceUrl;
  selectedIndex: any[] = [];
  carouselEmbajadores = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 3,
        },
        570: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselCharlas = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselDesafios = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselGalerias = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselGalerias2 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselGalerias3 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselGalerias4 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselGalerias5 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselGalerias6 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  videoinBanner: any;
  sections: any;
  documentos: any;
  noticias_manual: any;
  currentPageGaleria = 1;
  currentPageEmbajadores = 1;
  currentPage1 = 1;
  currentPage2 = 1;
  news1: any;
  news2: any;
  news2Res: any;
  news1Res: any;
  embajadores: any;
  embajadoresRes: any;
  galerias: any;
  galeriasRes: any;
  modalTextShow: any;
  modalText: any;
  modalTitulo: any;
  modalVideoIframe = false;
  modalSrcVideoIframe: string = "";
  modalVideo = false;
  modalSrcVideo: any;
  sections_desafios: any;
  newContentModal: any;
  linkTo: SafeUrl;
  carouselWins: any;
  inowTalks: any;
  videosInow: any;
  galeriaVideosTestimoniales: any;
  titlePage: any;
  routeLink: any;
  news3: any;
  carouselBanner = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 1,
      init: true,
      slidesPerView: 1,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
    },
  };
  modalDocuments: [];
  @ViewChild('carouselGaleriasOne') carouselGaleriasOne: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private landingService: LandingService,
    private NewsService: NewsService,
    private NoticiasService: NoticiasService,
    private innovacionService: InnovacionService,
    private galeriasService: GaleriasService,
    private title: Title,
    public sanitizer: DomSanitizer,
    private sanitized: DomSanitizer,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
    $("body").delegate(".content-ejes-estrategicos", "mousemove", function (e) {
      console.log($('img.icon-mano-dark', this).offset().left);
      console.log(e.pageX - $(this).offset().left);
      var xOld = e.pageX - $(this).offset().left + 50;
      if (xOld > $('img.icon-mano-dark', this).offset().left) {
        var x = e.pageX - $(this).offset().left + 50;
        $('.content-ejes-estrategicos-gray', this).css({ width: x });
        $('img.icon-mano-dark', this).css({ left: x });
      }
    });
    $("body").delegate(".pin-map", "click", function () {
      var dataPin = $(this).attr("href");
      $(".pin-map, .box-text-modal-map").removeClass("active")
      $(this).addClass("active");
      $("." + dataPin).addClass("active");
    });
    $("body").delegate(".box-item-tab-content-tecnology", "click", function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active")
        $(this).find(".box-text-tab-content-tecnology").slideUp();
      } else {
        $(".box-item-tab-content-tecnology").removeClass("active");
        $(".box-item-tab-content-tecnology .box-text-tab-content-tecnology").slideUp();
        $(this).addClass("active")
        $(this).find(".box-text-tab-content-tecnology").slideDown();
      }
    });

    $("body").delegate(".item-sostenibilidad-custom1", "click", function () {
      var titleModal = $(this).find(".box-item-sostenibilidad-custom1 h3").text();
      var textModal = $(this).find(".box-item-sostenibilidad-custom1 p").html();
      $(".modal-custom3-text").fadeIn();
      $('.modal-custom3-text .title-custom3-text').text(titleModal);
      $('.modal-custom3-text .box-text-custome-text').html(textModal);
    });
    $("body").delegate(".modal-custom3-text .close", "click", function () {
      $(".modal-custom3-text").fadeOut();
    });
    $("body").delegate(".modal-custom3-text .overlay", "click", function () {
      $(".modal-custom3-text").fadeOut();
    });

  }
  init(): void {
    this.title.setTitle('Soluciones Energéticas | Intranet Abastible');
    this.paramsID = 23094;
    this.slug = this.params['slug'];
    this.modalSrcVideoIframe = "https://www.youtube.com/embed/JOlzam6RSTY";
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);

    this.titlePage = "Soluciones Energéticas";
    this.routeLink = "soluciones-energeticas";
    localStorage.setItem('back_title_page', this.titlePage);
    localStorage.setItem('back_route_link', this.routeLink);
    localStorage.removeItem('back_section_route_title');
    localStorage.removeItem('back_section_route_link');
    this.landingService.getInfoNew(this.paramsID).subscribe(
      (res) => {
        let that = this;

        this.landing = res;
        this.sections = res.sections;
        this.documentos = res.documentos;
        this.noticias_manual = res.noticias_manual;
        this.sections_desafios = res.sections_desafios;
        console.log(this.sections)
        this.openModalHash();

        setTimeout(function () {
          that.carouselGaleriasOne.nativeElement.swiper.slideTo(0);
        }, 700);
      },
      (err) => {
        console.error(err);
      }
    );
    this.NewsService.getNews("i-now", 1).subscribe(
      (res) => {
        this.news1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    // this.NewsService.getNews("soluciones-energeticas", 1).subscribe(
    //   (res) => {
    //     this.news2 = res;
    //     console.log(res);
    //   },
    //   (err) => {
    //     console.error(err);
    //   }
    // );
    this.NoticiasService.getInfoPuntoPrensaByCategory("soluciones-energeticas").subscribe(
      (res) => {
        this.news2 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.NewsService.getNews("i-now", 1).subscribe(
      (res) => {
        this.news3 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.galeriasService.galeriaImagenes("galerias_inow", 1, "").subscribe(
      (res) => {
        this.galerias = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.innovacionService.getEmbajadores(1).subscribe(
      (res) => {
        this.embajadores = res
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaVideosByCategory("i-now", 1, 8, "").subscribe(
      (res) => {
        this.videosInow = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaVideosByCategory("i-now-talk", 1, 8, "").subscribe(
      (res) => {
        this.inowTalks = res
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaVideosByCategory("testimoniales-soluciones-energetica", 1, 8, "").subscribe(
      (res) => {
        this.galeriaVideosTestimoniales = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

  }
  openModalHash() {
    setTimeout(() => {
      this.route.fragment.subscribe((fragment: string) => {
        localStorage.setItem('back_section_route_link', '#' + fragment);
        $("#" + fragment + " .btn-custom-new").trigger("click");
        console.log("My hash fragment is here => ", fragment)
        console.log($("#" + fragment + " .btn-custom-new").length);
      })
    }, 5);
  }
  nextPageEmbajadores() {
    this.currentPageEmbajadores = this.currentPageEmbajadores + 1;

    this.innovacionService.getEmbajadores(this.currentPageEmbajadores).subscribe(
      (res) => {
        this.embajadoresRes = res;
        this.embajadores = [...this.embajadores, ...this.embajadoresRes];
        console.log([...this.embajadores, res]);
        console.log(this.embajadoresRes)
      },
      (err) => {
        console.error(err);
      }
    );
  }

  nextPage() {
    this.currentPage1 = this.currentPage1 + 1;

    this.NewsService.getNews("i-now", this.currentPage1).subscribe(
      (res) => {
        this.news1Res = res;
        this.news1 = [...this.news1, ...this.news1Res];
        console.log([...this.news1, res]);
        console.log(this.news1Res)
      },
      (err) => {
        console.error(err);
      }
    );
  }

  nextPageGaleria() {
    this.currentPageGaleria = this.currentPageGaleria + 1;

    this.galeriasService.galeriaImagenes("galerias_inow", this.currentPageGaleria, "").subscribe(
      (res) => {
        this.galeriasRes = res;
        this.galerias = [...this.galerias, ...this.galeriasRes];
        console.log([...this.galerias, res]);
        console.log(this.galeriasRes)
      },
      (err) => {
        console.error(err);
      }
    );
  }

  scroll(id) {
    console.log(`scrolling to ${id}`);
    let el = document.getElementById(id);
    this.modalTextShow = false;
    var body = document.body;
    body.classList.remove("modal-open");
    body.classList.remove("modal-single-content-open");
    el.scrollIntoView({
      behavior: 'smooth'
    });
  }

  textMin(text: string) {
    var str = text;
    var res = str.substring(0, 200);//get first 5 chars
    return res + '...';;
  }

  contentFilter(content: string): string {
    return content.replace(/<img .*?>/g, '');
  }

  setSelected(id: number) {
    if (this.selectedIndex[id] == true) {
      this.selectedIndex[id] = false;
    } else {
      this.selectedIndex[id] = true;
    }
  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }
  goToLinkTo(url: string) {
    this.linkTo = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return this.linkTo;
  }
  showModalVideoIframe(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideoIframe = true;
    this.modalSrcVideoIframe = srcVideo;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
  }
  closeModalVideoIframe() {
    this.modalVideoIframe = false;
    this.modalSrcVideoIframe = '';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
    $("#modal-video-youtube").removeClass("active-modal");
  }
  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }
  fullTextModal(titulo: string, text: string, section: string, documentos: any) {
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "#" + section;
    var newurlSet = window.location.protocol + "//" + window.location.host + window.location.pathname + "-" + section;
    const date = new Date();
    function n(n) {
      return n > 9 ? "" + n : "0" + n;
    }
    let day = date.getDate();
    let month = n((date.getMonth() + 1));
    let year = date.getFullYear();
    let currentDate = `${day}/${month}/${year}`;

    this.authService.analitics(currentDate, newurlSet).subscribe(
      (data) => {
        console.log('data ', data);
      },
      (err) => {
        console.log('err ', err);
      }
    );
    window.history.pushState({ path: newurl }, '', newurl);
    localStorage.setItem('back_section_route_link', '#' + section);
    localStorage.setItem('back_section_route_title', titulo);
    this.modalTextShow = true;
    this.modalTitulo = titulo;
    this.modalText = text;
    this.modalDocuments = documentos;
    console.log("modalDocuments", this.modalDocuments);
    this.newContentModal = this.sanitized.bypassSecurityTrustHtml(this.modalText);
    var body = document.body;
    body.classList.add("modal-open");
    body.classList.add("modal-single-content-open");
    if (this.modalTitulo == 'i-NOW') {

    }
  }
  closeFullTextModal() {
    this.modalTextShow = false;
    var body = document.body;
    body.classList.remove("modal-open");
    body.classList.remove("modal-single-content-open");
  }
}
