<div class="bg-white bg-white-custom">
  <section class="section-banner-custom-new" id="section0" style="background-image: url(assets/img/img-test2.jpg);">
    <div class="overlay-banner-custom-new"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-10">
          <h2 class="title-banner-custom-new" data-aos="fade-right" data-aos-duration="700">El Futuro eres tú</h2>
          <span class="line-title-custom-new-banner" data-aos="fade-right" data-aos-duration="700"
            data-aos-delay="400"></span>
          <!-- <div class="box-text-banner-custon-new" data-aos="fade-right" data-aos-duration="700">
            <p>El Futuro eres tú</p>
          </div> -->
        </div>
      </div>
    </div>
  </section>
  <section id="breadcrumbs">
    <div class="container">
      <div class="breadcrumbs breadcrumbs-sections">
        <div class="breadcrumbs-item">
          <a [routerLink]="['/muro']"><span class="material-icons">
              home
            </span></a>
          <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
        </div>
        <div class="breadcrumbs-item">
          <a [routerLink]="['/innovacion']">Innovación</a>
          <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
        </div>
        <div class="breadcrumbs-item">
          <span>El Futuro eres tú</span>
        </div>
      </div>
    </div>
  </section>
  <div class="modal-section-custom1">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 mx-auto">
          <h3 class="title-custom-new3 mb-5" data-aos="fade-up" data-aos-duration="700">El Futuro eres tú</h3>
        </div>
        <div class="modal-section-custom1-text col-lg-6">
          <p>Este año i-NOW para todos, tiene como objetivo continuar con el traspaso de capacidades y metodologías
            relacionadas a la
            innovación, como también comenzar a generar un cambio cultural para fomentar la adopción de diversas
            tecnologías que
            tienen como propósito prepararnos para una transformación digital.</p>
          <p>El desafío de este año consiste en que cada Planta y Oficina, representada por el embajador
            correspondiente, encuentre
            una solución que mejore de manera sustancial algún proceso. La solución debe incorporar el uso de alguna
            de las
            tecnologías exponenciales enseñadas durante el taller realizado por su(s) embajador(es) y/o videos
            explicativos
            (“cápsulas”) que fueron enviados en los meses de agosto y septiembre por el equipo i-NOW.</p>
        </div>
        <div class="col-lg-6 modal-section-custom1-img">
          <div class="cut-diagonal cut-diagonal-invert"></div>
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/2019.jpg" alt="">
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-4 mb-3"><img
            src="https://intranet.abastible.cl/wp/wp-content/themes/intranet-abastible/assets/img/etapas/1.png"
            class="img-fluid" alt="">
        </div>
        <div class="col-lg-4 mb-3"><img
            src="https://intranet.abastible.cl/wp/wp-content/themes/intranet-abastible/assets/img/etapas/2.png"
            class="img-fluid" alt="">
        </div>
        <div class="col-lg-4 mb-3"><img
            src="https://intranet.abastible.cl/wp/wp-content/themes/intranet-abastible/assets/img/etapas/3.png"
            class="img-fluid" alt="">
        </div>
        <div class="col-lg-4 mb-3"><img
            src="https://intranet.abastible.cl/wp/wp-content/themes/intranet-abastible/assets/img/etapas/4.png"
            class="img-fluid" alt="">
        </div>
        <div class="col-lg-4 mb-3"><img
            src="https://intranet.abastible.cl/wp/wp-content/themes/intranet-abastible/assets/img/etapas/5.png"
            class="img-fluid" alt="">
        </div>
        <div class="col-lg-4 mb-3"><img
            src="https://intranet.abastible.cl/wp/wp-content/themes/intranet-abastible/assets/img/etapas/6.png"
            class="img-fluid" alt="">
        </div>
        <div class="col-12 box-btn-news mt-4" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
          <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2019/10/FINAL-Bases-Rectificaci%C3%B3n-Bases.pdf"
            target="_blank" class="btn-custom-new"><span>Descargar bases</span></a>
        </div>
      </div>
    </div>
  </div>

  <div class="content-video">
    <div class="container">
      <video controls poster="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/Captura14.png"
        src="https://intranet.abastible.cl/wp/wp-content/uploads/2019/10/Video-4to.Desaf%C3%ADo-i-NOW.mp4"
        class="video-desafio"></video>
    </div>
  </div>

  <img src="https://intranet.abastible.cl/wp/wp-content/themes/intranet-abastible/assets/img/banner-landing1.jpg" alt=""
    class="img-fluid mt-5" style="width: 100%;">
  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="swiper-container" [swiper]="carouselCharlas.config">
          <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let item of videosDesafio4; let i = index">
              <div class="card-design-notice card-design-notice-nm card-design-embajadores item-galeria-youtube"
                data-aos="fade-up" data-aos="fade-up" data-aos-duration="700"
                (click)="showModalVideoIframe($event, item.video_url)">
                <div class="card-img">
                  <img [src]="item.img" alt="">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                    class="btn-play-desing1">
                </div>
                <div class="card-content">
                  <h4 class="card-title" [innerHTML]="item.title"></h4>
                  <div class="card-text" *ngIf="item.texto">
                    <p [innerHTML]="item.texto"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-prev">
            <span class="material-icons">
              keyboard_arrow_left
            </span>
          </div>
          <div class="swiper-button-next">
            <span class="material-icons">
              keyboard_arrow_right
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="section-custom5">
    <div class="container">
      <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">Procesos</h3>
      <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
      <div class="row">
        <div class="col-12">
          <div class="swiper-container" [swiper]="carouselProcesos.config">
            <div class="swiper-wrapper">
              <div class="swiper-slide" *ngFor="let item of procesosDesafio4; let i = index">
                <div (click)="openModalProceso(item.title, item.imagen_portada, item.imagen, item.pdf)"
                  class="card-design-notice card-design-notice-desafio">
                  <div class="card-img">
                    <img [src]="item.imagen_portada" alt="">
                    <div class="diagonal-cut"></div>
                  </div>
                  <div class="card-content">
                    <h4 class="card-title" [innerHTML]="item.title"></h4>
                    <span class="card-link">Leer más</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev">
              <span class="material-icons">
                keyboard_arrow_left
              </span>
            </div>
            <div class="swiper-button-next">
              <span class="material-icons">
                keyboard_arrow_right
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-custom5">
    <div class="container">
      <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">Soluciones</h3>
      <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
      <div class="row">
        <div class="col-lg-3" *ngFor="let item of solucionesDesafio4; let i = index">
          <div
            (click)="openModalSolucion(item.titulo, item.texto, item.autor, item.imagen, item.integrantes, item.proceso, item.dolores, item.tecnologias, item.documentos,item.video, item.video_youtube, item.video_youtube2, item.planta_oficina)"
            class="card-design-notice card-design-notice-desafio">
            <div class="card-img">
              <img [src]="item.imagen" alt="">
              <div class="diagonal-cut"></div>
            </div>
            <div class="card-content">
              <h4 class="card-title" [innerHTML]="item.titulo"></h4>
              <div class="card-text">
                <p [innerHTML]="item.planta_oficina"></p>
              </div>
              <span class="card-link">Leer más</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div id="modal-eventos" class="modal-eventos modal-procesos" *ngIf="modalProcesos">
  <div class="overlay-modal" (click)="closeProcesosModal()"></div>
  <div class="modal-content">
    <button type="button" class="close" aria-label="Close" (click)="closeProcesosModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-header modal-header-eventos">
      <h3 class="title-modal" [innerHTML]="modalProcesosTitle"></h3>
    </div>
    <div class="modal-body modal-body-eventos">
      <img [src]="modalProcesosImagen" (click)=fullImgSingleModal(modalProcesosImagen) alt="" class="img-modal">
      <a href="{{modalProcesosPdf}}" target="_blank" class="card-pdf">
        <img src="https://intranet.abastible.cl/wp/wp-content/themes/intranet-abastible/assets/img/icon-pdf.png" alt="">
        <p>Descarga el <span [innerHTML]="modalProcesosTitle"></span></p>
      </a>
    </div>
  </div>
</div>

<div class="modal-img-full" [ngClass]="modalImgSingleShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullImgSingleModal()"></div>
  <button class="close" (click)="closeFullImgSingleModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-img-full">
  </div>
</div>

<div id="modal-eventos" class="modal-eventos" *ngIf="modalSoluciones">
  <div class="overlay-modal" (click)="closeSolucionesModal()"></div>
  <div class="modal-content">
    <button type="button" class="close" aria-label="Close" (click)="closeSolucionesModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body modal-body-eventos modal-body-soluciones">
      <div class="item-left-body-soluciones">
        <img [src]="modalSolucionesImagen" alt="" class="img-modal">
        <div class="box-item-soluciones" *ngIf="modalSolucionesAutor">
          <h2 class="title-modal1">Autor</h2>
          <p [innerHTML]="modalSolucionesAutor"></p>
        </div>
        <div class="box-item-soluciones" *ngIf="modalSolucionesIntegrantes">
          <h2 class="title-modal1">Integrantes</h2>
          <p [innerHTML]="modalSolucionesIntegrantes"></p>
        </div>
        <div class="box-item-soluciones" *ngIf="planta_oficina">
          <h2 class="title-modal1">Planta/Oficina</h2>
          <p [innerHTML]="planta_oficina"></p>
        </div>
      </div>
      <div class="item-right-body-soluciones">
        <div class="box-content-body-modal ">
          <div class="box-item-soluciones" *ngIf="modalSolucionesTitle">
            <h2 class="title-modal1">Titulo</h2>
            <p [innerHTML]="modalSolucionesTitle"></p>
          </div>
          <div class="box-item-soluciones" *ngIf="modalSolucionesTexto">
            <h2 class="title-modal1">Descripción</h2>
            <p [innerHTML]="modalSolucionesTexto"></p>
          </div>
          <div class="box-item-soluciones" *ngIf="modalSolucionesProceso">
            <h2 class="title-modal1">Proceso</h2>
            <p [innerHTML]="modalSolucionesProceso"></p>
          </div>
          <div class="box-item-soluciones" *ngIf="modalSolucionesDolores">
            <h2 class="title-modal1">Dolores</h2>
            <p [innerHTML]="modalSolucionesDolores"></p>
          </div>
          <div class="box-item-soluciones" *ngIf="modalSolucionesTecnologias">
            <h2 class="title-modal1">Tecnologías</h2>
            <p [innerHTML]="modalSolucionesTecnologias"></p>
          </div>
          <div class="box-item-soluciones" *ngIf="modalSolucionesDocumentos">
            <h2 class="title-modal1">Adjuntos</h2>
            <ul class="list-adjuntos">
              <li *ngFor="let item of modalSolucionesDocumentos; let i = index">
                <a href="item">Documento {{ i + 1 }}</a>
              </li>
            </ul>
          </div>
          <div class="box-item-soluciones"
            *ngIf="modalSolucionesVideo || modalSolucionesVideoYoutube1 || modalSolucionesVideoYoutube2">
            <h2 class="title-modal1">Videos</h2>
          </div>
          <div class="box-item-soluciones" *ngIf="modalSolucionesVideo">
            <video controls>
              <source [src]="modalSolucionesVideo">
            </video>
          </div>
          <div class="box-item-soluciones" *ngIf="modalSolucionesVideoYoutube1">
            <iframe width="560" height="315" [src]="urlSafe2" class="iframe-video" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
          <div class="box-item-soluciones" *ngIf="modalSolucionesVideoYoutube2">
            <iframe width="560" height="315" [src]="urlSafe3" class="iframe-video" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <a [routerLink]="['/formulario-desafio6' ]" class="btn-mailto-inow"><img src="assets/img/icons/mail.svg" alt=""><span>Cuéntanos
    tu idea</span></a> -->
<div id="modal-video-youtube" class="modal-video" [ngClass]="modalVideoIframe == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideoIframe()"></div>
  <button class="close" (click)="closeModalVideoIframe()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <iframe width="560" height="315" [src]="urlSafe" class="iframe-video" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>