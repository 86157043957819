import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { NewsService } from '@services/news';
import { InnovacionService } from '@services/innovacion';
import { LoadingService } from '@services/loading';
import * as $ from 'jquery';

@Component({
  selector: 'app-ideas-desafio5',
  templateUrl: './ideas-desafio5.component.html',
  styleUrls: ['./ideas-desafio5.component.scss'],
})
export class IdeasDesafios5Component implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  addClassFixed = false;
  selectedIndex: any[] = [];
  linksScrollSwiper = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        570: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  videoinBanner: any;
  sections: any;
  documentos: any;
  noticias_manual: any;
  currentPage1 = 1;
  currentPageEmbajadores = 1;
  currentPage2 = 1;
  news1: any;
  news2: any;
  news2Res: any;
  news1Res: any;
  embajadores: any;
  embajadoresRes: any;
  modalTextShow: any;
  modalText: any;
  modalTitulo: any;
  carouselGalerias2 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  modalVideo = false;
  modalSrcVideo: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private landingService: LandingService,
    private NewsService: NewsService,
    private InnovacionService: InnovacionService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Legado Creativo | Intranet Abastible');
    this.paramsID = 23094;
    this.slug = this.params['slug'];
  }

  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }
}
