import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { NoticiasService } from '@services/noticias';

@Component({
  selector: 'app-desafios',
  templateUrl: './desafios.component.html',
  styleUrls: ['./desafios.component.scss'],
})
export class DesafiosAbastibleComponent implements OnInit {
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  selectedIndex: any[] = [];
  news1: any;
  news1Res: any;
  currentPage1 = 1;
  catNews = "all";
  categoriesFilter: any;
  categorySlug: any;
  hasCategorySlug: any;
  valueSearch = "";
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private NoticiasService: NoticiasService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
    $("body").removeClass("modal-open");
    $("body").removeClass("modal-single-content-open");
  }
  init(): void {
    this.title.setTitle('Desafios | Intranet Abastible');
    this.paramsID = 29622;
    this.slug = this.params['slug'];
    var that = this;
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['categoryslug']) {
        this.categorySlug = queryParams['categoryslug'];
        this.hasCategorySlug = true;
      }
    });

  }
} 
