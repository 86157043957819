<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"
          ><span class="material-icons">
            home
          </span></a
        >
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Indicadores</span>
      </div>
    </div>
  </div>
</section>

<section class="section-custom-new3" *ngIf="post">
  <div class="container">
    <div class="text-center mb-3">
      <h1 class="title1 text-center mb-5">Indicadores</h1>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="card-indicadores">
          <h3 class="title-indicadores">Indice <br />Gastos Generales</h3>
          <div class="box-int-indicadores">
            <h6>{{ post.porcentaje_gastos2 }}%</h6>
            <p class="text-center">Meta ({{ post.meta_gastos }})</p>
          </div>
          <div class="box-int-indicadores border-0">
            <h6>{{ post.porcentaje_gastos }}%</h6>
            <p class="text-center">
              a {{ post.mes_gastos }} {{ post.ano_gastos }}
            </p>
          </div>
          <div class="box-indicadores">
            <div
              class="item-indicadores item-indicadores1"
              [ngClass]="{ active: post.nivel_gastos === '1' }"
            >
              <span></span>
            </div>
            <div
              class="item-indicadores item-indicadores2"
              [ngClass]="{ active: post.nivel_gastos === '2' }"
            >
              <span></span>
            </div>
            <div
              class="item-indicadores item-indicadores3"
              [ngClass]="{ active: post.nivel_gastos === '3' }"
            >
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card-indicadores">
          <h3 class="title-indicadores">Indice <br />de Seguridad</h3>
          <div class="box-int-indicadores">
            <h6>{{ post.valor_seguridad_meta }}</h6>
            <p class="text-center">Meta ({{ post.meta_seguridad }})</p>
          </div>
          <div class="box-int-indicadores border-0">
            <h6>{{ post.valor_seguridad }}</h6>
            <p class="text-center">
              a {{ post.mes_seguridad }} {{ post.ano_seguridad }}
            </p>
          </div>
          <div class="box-indicadores">
            <div
              class="item-indicadores item-indicadores1"
              [ngClass]="{ active: post.nivel_seguridad === '1' }"
            >
              <span></span>
            </div>
            <div
              class="item-indicadores item-indicadores2"
              [ngClass]="{ active: post.nivel_seguridad === '2' }"
            >
              <span></span>
            </div>
            <div
              class="item-indicadores item-indicadores3"
              [ngClass]="{ active: post.nivel_seguridad === '3' }"
            >
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
