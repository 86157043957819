<section class="page-emprendimientos">
  <div class="page-emprendimientos-banner">
    <img src="assets/img/emprendimientos/banner.jpg" alt="" class="page-emprendimientos-banner-img">
  </div>
  <div class="page-emprendimientos-section1">
    <div class="container">
      <div class="page-emprendimientos-section1-container">
        <div class="page-emprendimientos-section1-container-left">
          <label for="order_by" class="page-emprendimientos-section1-container-left-label">Ordenar por</label>
          <div class="page-emprendimientos-section1-container-left-select">
            <select name="order_by" id="order_by" class="page-emprendimientos-section1-container-left-select-input"
              #mySelect (change)='onOptionsSelected(mySelect.value)'>
              <option value="" selected>Todos</option>
              <option value="Recomendación de Colaborador">Recomendaciones de Colaboradores</option>
              <option value="Emprendimiento de Colaborador">Emprendimientos de Colaboradores</option>
            </select>
          </div>
        </div>
        <div class="page-emprendimientos-section1-container-left page-emprendimientos-section1-container-left-zona">
          <label for="order_by" class="page-emprendimientos-section1-container-left-label">Zona</label>
          <div class="page-emprendimientos-section1-container-left-select">
            <select name="order_by" id="order_by" class="page-emprendimientos-section1-container-left-select-input"
              #mySelect2 (change)='onZonaSelected(mySelect2.value)'>
              <option value="" selected>Todos</option>
              <option value="Norte">Norte</option>
              <option value="Centro">Centro</option>
              <option value="Sur">Sur</option>
            </select>
          </div>
        </div>
        <div class="page-emprendimientos-section1-container-right">
          <input type="search" (keyup)="onKeyUp($event)" name="search_by" id="search_by"
            class="page-emprendimientos-section1-container-right-input">
          <button type="button" class="page-emprendimientos-section1-container-right-btn">
            <img src="assets/img/emprendimientos/icon-search.png" alt="">
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="page-emprendimientos-section2">
    <div class="container">
      <div class="page-emprendimientos-section2-container">
        <div class="page-emprendimientos-section2-container-items">
          <div class="page-emprendimientos-section2-container-item"
            *ngFor="let emprendimiento of emprendimientos; let i = index" (click)="openModal(emprendimiento)" [ngClass]="{
              'page-emprendimientos-section2-container-item-cat2': emprendimiento.categoria === 'Recomendación de Colaborador'
            }">
            <div class="page-emprendimientos-section2-container-item-header">
              <img [src]="emprendimiento.imagen" class="page-emprendimientos-section2-container-item-header-img" alt="">
              <h3 class="page-emprendimientos-section2-container-item-header-title">{{ emprendimiento.titulo }}</h3>
            </div>
            <div class="page-emprendimientos-section2-container-item-body">
              <h4 class="page-emprendimientos-section2-container-item-body-title" *ngIf="emprendimiento.autor">{{
                emprendimiento.autor }}</h4>
              <div class="page-emprendimientos-section2-container-item-body-info1">
                <p *ngIf="emprendimiento.cargo">{{ emprendimiento.cargo }}</p>
                <p *ngIf="emprendimiento.plantaoficina">{{ emprendimiento.plantaoficina }}</p>
              </div>
              <div class="page-emprendimientos-section2-container-item-body-info2" *ngIf="emprendimiento.informacion">
                <p [innerHTML]="emprendimiento.informacion"></p>
                <!-- <p>98409 8319</p>
                <p>telicer@gmail.com</p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="page-emprendimientos-section2-container-paginations">
          <template *ngFor="let item of arrayPagination; let i = index">
            <a href="javascript:;" class="page-emprendimientos-section2-container-pagination"
              (click)="setPageEmbajadores(i + 1)" [ngClass]="{
                'active': i === (currentPageEmprendimientos - 1)
              }">{{ i + 1 }} </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</section>

<div id="modal-eventos" class="modal-eventos" style="display: none;">
  <div class="overlay-modal" (click)="closeModal()"></div>
  <div class="modal-content">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body modal-body-eventos modal-body-soluciones">
      <div class="item-left-body-soluciones">
        <img [src]="imagenModal" alt="" class="img-modal">
        <div class="box-item-soluciones" *ngIf="autorModal">
          <h2 class="title-modal1">Autor</h2>
          <p>{{autorModal}}</p>
        </div>
        <div class="box-item-soluciones" *ngIf="cargoModal">
          <h2 class="title-modal1">Cargo</h2>
          <p>{{cargoModal}}</p>
        </div>
        <div class="box-item-soluciones" *ngIf="plantaoficinaModal">
          <h2 class="title-modal1">Planta/Oficina</h2>
          <p>{{plantaoficinaModal}}</p>
        </div>
        <div class="box-item-soluciones" *ngIf="informacionModal">
          <h2 class="title-modal1">Información</h2>
          <p [innerHTML]="informacionModal"></p>
        </div>
      </div>
      <div class="item-right-body-soluciones">
        <div class="box-content-body-modal ">
          <div class="box-item-soluciones box-item-soluciones-title">
            <h2 class="title-modal1">Título</h2>
            <p>{{tituloModal}}</p>
          </div>
          <div class="box-item-soluciones" *ngIf="contenidoModal">
            <h2 class="title-modal1">Contenido</h2>
            <p [innerHTML]="contenidoModal"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>