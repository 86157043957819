<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="back_title_page">
        <a href="{{ back_route_link }}"><span>{{ back_title_page }}</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="back_section_route_title">
        <a href="{{ back_section_route_link }}"><span>{{ back_section_route_title }}</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Video Inducción Plantas</span>
      </div>
    </div>
  </div>
</section>
<section class="p-section" id="{{ slug }}">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11">
        <div class="text-center mb-3">
          <h1 class="title1 text-center">Video Inducción Plantas</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="container d">
    <div class="row">
      <div class="col-lg-6">
        <a (click)="showModalVideoIframe($event, 'S1K6BVYzwog')"
          class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up" data-aos="fade-up"
          data-aos-duration="700">
          <div class="card-img">
            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/03/maxresdefault.jpg" alt="" />
            <div class="diagonal-cut"></div>
            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
              class="btn-play-desing1" />
          </div>
          <div class="card-content">
            <h4 class="card-title" [innerHTML]="'Video Inducción Plantas'"></h4>
          </div>
        </a>
        <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2023/03/Video-Inductivo-Plantas.mp4"
          class="btn-download" download>Descargar Video</a>
      </div>
    </div>
  </div>
  <!-- <div class="grid-galerias grid-galerias-even">
    <a href="#" class="item-galeria" *ngFor="let item of galerias2; let i = index">
      <img src="{{ item.feature_img }}" alt="0" class="img-item-galeria">
      <div class="overlay-item-galeria"></div> 
      <div class="item-content-galeria">
        <h3 class="title-item-galeria">{{ item.post_title }}</h3>
      </div>
    </a>
  </div> -->
</section>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls
      *ngIf="modalSrcVideo != 'https://interactrapp.com/share/6132fa5888ed7'">
      Your browser does not support HTML5 video.
    </video>
    <h3 class="title-video-full" [innerHTML]="modalSrcVideo"
      *ngIf="modalSrcVideo != 'https://interactrapp.com/share/6132fa5888ed7'"></h3>
    <div class="box-video-iframe-blank" *ngIf="modalSrcVideo == 'https://interactrapp.com/share/6132fa5888ed7'">
      <h3 class="title-video-full">
        Para ver este video ingresa a la siguiente url
        <a href="{{ modalSrcVideo }}" target="_blank">{{ modalSrcVideo }}</a>
      </h3>
    </div>
  </div>
</div>

<div id="modal-video-youtube" class="modal-video" [ngClass]="modalVideoIframe == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideoIframe()"></div>
  <button class="close" (click)="closeModalVideoIframe()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <iframe width="560" height="315" [src]="urlSafe" class="iframe-video" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>