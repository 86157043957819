import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LandingService {

  constructor(private httpClient: HttpClient) { }
  getInfo(slug: number): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/page-landing/?id=${slug}`, null)
      .pipe((result) => {
        return result;
      });
  }
  getInfoNew(slug: number): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/landing-info/?id=${slug}`, null)
      .pipe((result) => {
        return result;
      });
  }
  getInfoPage(id: number): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/get-info-page/?id=${id}`, null)
      .pipe((result) => {
        return result;
      });
  }
  getInfoConexion(id: number): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/get-info-conexion/?id=${id}`, null)
      .pipe((result) => {
        return result;
      });
  }

  getInfoSection(id: number): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/info-section/?id_section=${id}`, null)
      .pipe((result) => {
        return result;
      });
  }

}
