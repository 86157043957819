<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Búsqueda</span>
      </div>
    </div>
  </div>
</section>
<div class="">
  <section class="section-custom-news">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <form #userForm="ngForm" (ngSubmit)="onSubmit(userForm.value.search)">
            <div class="box-search">
              <input type="text" name="search" placeholder="Buscar una Noticias" [ngModel]="busqueda">
              <button type="submit"><img src="assets/img/icons/icon-search.svg" alt=""></button>
            </div>
          </form>
          <div class="tabs-search">
            <button data-tab="#news-search" class="item-tab-search active">Noticias</button>
            <button data-tab="#documents-search" class="item-tab-search">Documentos</button>
            <button data-tab="#fotos-search" class="item-tab-search">Fotos</button>
            <button data-tab="#videos-search" class="item-tab-search">Videos</button>
          </div>
        </div>
        <div class="col-lg-9 mx-auto ">
          <div class="tabs-content-search">
            <div class="item-tab-content-search" id="news-search">
              <div *ngFor="let new1 of news1; let i = index"
                [ngClass]="new1.post_type_slug == 'archivos' ? 'archivos' : ''">
                <div class="modal-section-custom1 modal-section-custom1-new" *ngIf="new1.post_type_slug == 'post'">
                  <div class="row">
                    <div class="col-lg-7 modal-section-custom1-img">
                      <img src="{{ new1.img }}" class="" alt="">
                      <div class="cut-diagonal"></div>
                    </div>
                    <div class="modal-section-custom1-text col-lg-5">
                      <h3 class="title2-modal-section-custom1" [innerHTML]="new1.title"></h3>
                      <a [routerLink]="['/noticia/' + new1.slug ]"
                        *ngIf="new1.post_type_slug == 'post' || new1.post_type_slug == 'eventos_new' || new1.post_type_slug == 'noticias_inow' || new1.post_type_slug == 'noticiascomite' || new1.post_type_slug == 'prensa' || new1.post_type_slug == 'concursos' || new1.post_type_slug == 'noticiasgruposcbp' || new1.post_type_slug == 'noticiasgrupos' || new1.post_type_slug == 'embajadoresnews' || new1.post_type_slug == 'noticiasgruposinow' || new1.post_type_slug == 'noticiasgrupospote'">Ver
                        más</a>
                      <a [routerLink]="['/galeria/' + new1.id ]" [queryParams]="{title: new1.title }"
                        *ngIf="new1.post_type_slug == 'galeria' || new1.post_type_slug == 'galeriagruposcbp' || new1.post_type_slug == 'embajadoresgallery' || new1.post_type_slug == 'galeriagruposinow' || new1.post_type_slug == 'galeriagrupospote' || new1.post_type_slug == 'galerias_inow' || new1.post_type_slug == 'galeriascomite' || new1.post_type_slug == 'galeriagruposcbp'">Ver
                        más</a>
                      <a (click)="showModalVideoIframe($event, new1.video_url)"
                        *ngIf="new1.video_url && new1.post_type_slug == 'galeria_videos'">Ver video</a>
                      <a (click)="showModalVideo($event, new1.video_url)"
                        *ngIf="new1.video_url && new1.post_type_slug == 'videos_inow'">Ver
                        video</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-tab-content-search" id="documents-search" style="display: none;">
              <div class="list-search-custom1">
                <div *ngFor="let document1 of documents1; let i = index"
                  [ngClass]="document1.post_type_slug == 'archivos' ? 'archivos' : ''">
                  <a href="{{document1.link_archivo}}" class="item-document-custom-new3" target="_blank"
                    data-aos="fade-up" data-aos-duration="700" data-aos-delay="0"
                    *ngIf="document1.post_type_slug == 'archivos' || documents1">
                    <h3 class="title-document-custom-new3">{{document1.title}}</h3>
                    <div class="box-acctions-custom-new3">
                      <span class="view-document">
                        <img src="assets/img/file.svg" alt="">
                      </span>
                      <span class="download-document">
                        <img src="assets/img/download.svg" alt="">
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="item-tab-content-search" id="fotos-search" style="display: none;">
              <div class="list-search-custom1">
                <div *ngFor="let item of galerias; let i = index">
                  <div class="modal-section-custom1 modal-section-custom1-new">
                    <div class="row">
                      <div class="col-lg-7 modal-section-custom1-img">
                        <img src="{{ item.feature_img }}" class="" alt="">
                        <div class="cut-diagonal"></div>
                      </div>
                      <div class="modal-section-custom1-text col-lg-5">
                        <h3 class="title2-modal-section-custom1" [innerHTML]="item.post_title"></h3>
                        <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }">Ver
                          galería</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-tab-content-search" id="videos-search" style="display: none;">
              <div class="list-search-custom1">
                <div *ngFor="let item of galeriasVideos; let i = index">
                  <div class="modal-section-custom1 modal-section-custom1-new" *ngIf="params != 'i-now-talk'">
                    <div class="row">
                      <div class="col-lg-7 modal-section-custom1-img">
                        <img src="{{ item.img }}" class="" alt="">
                        <div class="cut-diagonal"></div>
                      </div>
                      <div class="modal-section-custom1-text col-lg-5">
                        <h3 class="title2-modal-section-custom1" [innerHTML]="item.title"></h3>
                        <a (click)="showModalVideo($event, item.video_url)">Ver
                          video</a>
                      </div>
                    </div>
                  </div>
                  <div class="modal-section-custom1 modal-section-custom1-new" *ngIf="params == 'i-now-talk'">
                    <div class="row">
                      <div class="col-lg-7 modal-section-custom1-img">
                        <img src="{{ item.img }}" class="" alt="">
                        <div class="cut-diagonal"></div>
                      </div>
                      <div class="modal-section-custom1-text col-lg-5">
                        <h3 class="title2-modal-section-custom1" [innerHTML]="item.title"></h3>
                        <div class="card-text" *ngIf="item.content">
                          <p [innerHTML]="item.content"></p>
                        </div>
                        <a (click)="showModalVideoIframe($event, item.video_url)">Ver
                          video</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
            <a (click)="nextPage()" class="btn-custom-new">
              <span>VER MÁS</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div id="modal-video-youtube" class="modal-video" [ngClass]="modalVideoIframe == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideoIframe()"></div>
  <button class="close" (click)="closeModalVideoIframe()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <iframe width="560" height="315" [src]="urlSafe" class="iframe-video" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>