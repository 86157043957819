<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Comité Paritario de Higiene y Seguridad</span>
      </div>
    </div>
  </div>
</section>
<section class="section-custom-new3 section-custom-new3-comite" *ngIf="info">
  <div class="banner-seleccion-talento">
    <!-- <div class="swiper-container" [swiper]="carouselBanner.config">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="assets/img/banner-comite.png" alt="">
        </div>
      </div>
      <div class="swiper-button-prev">
        <span class="material-icons">
          keyboard_arrow_left
        </span>
      </div>
      <div class="swiper-button-next">
        <span class="material-icons">
          keyboard_arrow_right
        </span>
      </div>
    </div> -->
    <img [src]="info.banner" alt="">
  </div>
  <div class="section-design2">
    <img src="assets/img/bg-section-design2-2.png" alt="" class="bg-top-section-design2">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <h3 class="title-section-design2 mb-5">Conócenos</h3>
        </div>
        <div class="col-lg-11 mx-auto">
          <div class="row">
            <div class="col-lg-6 col-integrantes-custom1" *ngFor="let area of info.areas; let i = index">
              <div class="integrantes-custom1-content">
                <h3 class="integrantes-custom1-content-title">{{ area.titulo }}</h3>
                <div class="integrantes-custom1-content-border"></div>
                <div class="integrantes-custom1">
                  <div class="integrante-custom1" *ngFor="let integrante of area.integrantes; let i = index">
                    <img [src]="integrante.foto" alt="" class="integrante-custom1-img">
                    <div class="integrante-custom1-content">
                      <h3 class="integrante-custom1-content-title">{{integrante.nombre}}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section-design2 section-design2-conceptos">
    <img src="assets/img/bg-section-design2-1.png" alt="" class="bg-top-section-design2">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <h3 class="title-section-design2 text-left">¿Qué hace un Comité Paritario <br>de Higiene y Seguridad?</h3>
          <div class="box-text-section-design2">
            <p [innerHTML]="info.text_que_hace"></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-design2 section-design2-funciones">
    <img src="assets/img/bg-section-design2-2.png" alt="" class="bg-top-section-design2">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <div class="row">
            <div class="col-lg-6">
              <img src="assets/img/img-comite1.png" alt="" class="img-fluid w-100">
            </div>
            <div class="col-lg-6">
              <h3 class="title-section-design2 text-left">Conoce las funciones
                del CPHS</h3>
              <div class="box-text-section-design2">
                <p [innerHTML]="info.texto_funciones"></p>
              </div>
            </div>
            <div class="col-12">
              <div class="items-funciones">
                <div class="item-funciones" *ngFor="let funcion of info.funciones; let i = index">
                  <img [src]="funcion.icono" alt="" class="item-funciones-img">
                  <h4 class="item-funciones-title">{{funcion.titulo}}</h4>
                  <p [innerHTML]="funcion.texto"></p>
                </div>
              </div>
              <a href="mailto:cphsoce@abastible.cl" class="text-conoce-funciones">
                <p>Escríbenos a <span>cphsoce@abastible.cl</span></p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-design2 section-design2-confidencial">
    <img src="assets/img/bg-section-design2-1.png" alt="" class="bg-top-section-design2">
    <div class="container">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 mx-auto">
            <div class="box-title-actas">
              <h3 class="box-title-actas-title title-section-design2"><span>Conoce nuestras</span><br>Actas de Reuniones
                <br><small>e</small>Información de Seguridad
              </h3>
            </div>
            <div class="row">
              <div class="col-lg-6"
                [ngClass]="{ 'items-actas-swiper-col-left': i % 2 == 0, 'items-actas-swiper-col-right': i % 2 != 0 }"
                *ngFor="let acta_info of info.actas_info; let i = index">
                <div class="items-actas-swiper">
                  <div class="swiper-container" [swiper]="carouselActas.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let acta of acta_info.actas; let i = index">
                        <div class="item-actas-swiper">
                          <img [src]="acta.imagen" alt="" class="item-actas-swiper-img">
                          <a [href]="acta.archivo" target="_blank" download="true" class="item-actas-swiper-document">
                            <div class="item-actas-swiper-title" *ngIf="acta.titulo != ''">
                              <h3 class="item-actas-swiper-title-text" [innerHTML]="acta.titulo"></h3>
                            </div>
                            <img src="assets/img/icon-download.png" class="item-actas-swiper-document-icon" />
                            <h4 class="item-actas-swiper-document-title">Descargar Informativo</h4>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                </div>
                <div class="box-text-section-design2">
                  <p [innerHTML]="acta_info.texto"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-design2"
    *ngIf="newsSeguridadInformacion.length > 0 || galeriaSeguridad.length > 0 || galeriaVideosSeguridad.length > 0">
    <img src="assets/img/bg-section-design2-1.png" alt="" class="bg-top-section-design2">
    <div class="tabs-search">
      <button data-tab="#news-seguridad-informacion" class="item-tab-search active"
        *ngIf="newsSeguridadInformacion.length > 0">Noticias</button>
      <button data-tab="#galeries-seguridad-informacion" class="item-tab-search "
        *ngIf="galeriaSeguridad.length > 0">Galería</button>
      <button data-tab="#videos-seguridad-informacion" class="item-tab-search "
        *ngIf="galeriaVideosSeguridad.length > 0">Videos</button>
    </div>
    <div class="tabs-content-search">
      <div class="item-tab-content-search" id="news-seguridad-informacion" *ngIf="newsSeguridadInformacion.length > 0">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas2.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide"
                  *ngFor="let newSeguridadInformacion of newsSeguridadInformacion; let i = index">
                  <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ newSeguridadInformacion.img }}" alt="">
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="newSeguridadInformacion.title"></h4>
                      <div class="card-text">
                        <p [innerHTML]="newSeguridadInformacion.text"></p>
                      </div>
                      <a [routerLink]="['/noticia/' + newSeguridadInformacion.slug ]" class="card-link">Leer
                        más</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                <a [routerLink]="['/noticias']"
                  [queryParams]="{categoryslug: 'comite-paritario-de-higiene-y-seguridad-oficina-central' }"
                  class="btn-custom-new">
                  <span>VER MÁS</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="item-tab-content-search" id="galeries-seguridad-informacion" *ngIf="galeriaSeguridad.length > 0"
        style="display: none;">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas2.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of galeriaSeguridad; let i = index">
                  <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                    class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                    data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ item.feature_img }}" alt="">
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                    </div>
                  </a>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
              <a [routerLink]="['/galeria-image-section/comite-paritario-de-higiene-y-seguridad-oficina-central' ]"
                class="btn-custom-new">
                <span>VER MÁS</span>
              </a>
            </div>
          </div>
        </section>
      </div>
      <div class="item-tab-content-search" id="videos-seguridad-informacion" *ngIf="galeriaVideosSeguridad.length > 0"
        style="display: none;">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas3.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of galeriaVideosSeguridad; let i = index">
                  <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                    (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ item.img }}" alt="">
                      <div class="diagonal-cut"></div>
                      <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                        class="btn-play-desing1">
                    </div>
                    <div class="card-content">
                      <h4 class="card-title">{{ item.title }}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
              <a [routerLink]="['/galeria-videos-section/comite-paritario-de-higiene-y-seguridad-oficina-central' ]"
                [queryParams]="1" class="btn-custom-new">
                <span>VER MÁS</span>
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>

<div class="modal-custom3-text">
  <div class="overlay-modal"></div>
  <div class="box-custom3-text">
    <button class="close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="title-custom3-text">Levantamiento del proceso</h3>
    <div class="box-text-custome-text">
      <p>Entrevistas y reuniones de validación con los dueños y responsables del proceso con el objetivo de
        elaborar el
        informe de levantamiento de la situación actual y el diagrama de flujos de las actividades realizadas.
      </p>
    </div>
  </div>
</div>