import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { AppComponent } from '../../../app.component';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { GruposService } from '@services/grupos';

@Component({
  selector: 'app-lista-grupos',
  templateUrl: './lista-grupos.component.html',
  styleUrls: ['./lista-grupos.component.scss'],
})
export class ListaGruposComponent implements OnInit {
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  selectedIndex: any[] = [];
  grupos: any;
  gruposRes: any;
  currentPage1 = 1;
  catNews = "all";
  categoriesFilter: any;
  loggedIn = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private GruposService: GruposService,
    private router: Router,
    public appComponent: AppComponent,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Grupos | Intranet Abastible');
    this.paramsID = 29622;
    this.slug = this.params['slug'];
    localStorage.removeItem('back_title_page');
    localStorage.removeItem('back_route_link');
    localStorage.removeItem('back_section_route_title');
    localStorage.removeItem('back_section_route_link');
    this.appComponent.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
      }
      this.loggedIn = loggedIn;
    });
    if (this.loggedIn) {
      this.GruposService.getInfoListaGrupos().subscribe(
        (res) => {
          this.grupos = res;
          console.log(res);
        },
        (err) => {
          console.error(err);
        }
      );
    } else {
      this.router.navigate(['/muro']);
    }


  }
}
