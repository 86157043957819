<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Noticias conexión naranja</span>
      </div>
    </div>
  </div>
</section>

<section class="page-conexion-naranja" *ngIf="info">
  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2024/05/bg-gradient.png" alt=""
    class="page-conexion-naranja-bg">
  <div class="container">
    <div class="page-conexion-naranja-container">
      <div class="page-conexion-naranja-container-banner">
        <div class="swiper-container page-conexion-naranja-container-banner-gallery" [swiper]="galleryInternPost.config"
          *ngIf="info.fields.imagenes_banner" [(index)]="galleryInternPost.index">
          <div class="swiper-wrapper">
            <div class="page-conexion-naranja-container-banner-gallery-img swiper-slide"
              *ngFor="let image of info.fields.imagenes_banner; let k = index">
              <img src="{{image.url}}" alt="{{image.description}}">
            </div>
          </div>

          <div class="swiper-button-prev">
            <span class="material-icons">
              keyboard_arrow_left
            </span>
          </div>
          <div class="swiper-button-next">
            <span class="material-icons">
              keyboard_arrow_right
            </span>
          </div>
        </div>
        <div class="page-conexion-naranja-container-banner-text" *ngIf="info.fields.texto_banner">
          <h6>{{info.fields.texto_banner}}</h6>
        </div>
      </div>
      <div class="page-conexion-naranja-container-content">
        <div class="page-conexion-naranja-container-content-items">
          <div class="page-conexion-naranja-container-content-item" *ngFor="let term of months; let i = index">
            <h4 class="page-conexion-naranja-container-content-item-title">{{ term.term }}</h4>
            <div class="swiper-container page-conexion-naranja-container-content-item-items"
              [swiper]="carouselGalerias.config" *ngIf="info.fields.imagenes_banner" [(index)]="carouselGalerias.index">
              <div class="swiper-wrapper">
                <div class="page-conexion-naranja-container-content-item-item-slide swiper-slide"
                  *ngFor="let term_int of term.terms; let i = index">
                  <a [routerLink]="['/noticias-conexion-naranja/' + term_int.slug]"
                    class="page-conexion-naranja-container-content-item-item">
                    <div class="page-conexion-naranja-container-content-item-item-img">
                      <img src="{{ term_int.image }}" alt="">
                    </div>
                    <div class="page-conexion-naranja-container-content-item-item-text">
                      <h6>
                        <span>{{ term_int.name }}</span>
                        <!-- <svg *ngIf="term_int.type == 'Video'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                          width="24" height="24" color="#ffffff" fill="none">
                          <path
                            d="M2 11C2 7.70017 2 6.05025 3.02513 5.02513C4.05025 4 5.70017 4 9 4H10C13.2998 4 14.9497 4 15.9749 5.02513C17 6.05025 17 7.70017 17 11V13C17 16.2998 17 17.9497 15.9749 18.9749C14.9497 20 13.2998 20 10 20H9C5.70017 20 4.05025 20 3.02513 18.9749C2 17.9497 2 16.2998 2 13V11Z"
                            stroke="currentColor" stroke-width="1.5" />
                          <path
                            d="M17 8.90585L17.1259 8.80196C19.2417 7.05623 20.2996 6.18336 21.1498 6.60482C22 7.02628 22 8.42355 22 11.2181V12.7819C22 15.5765 22 16.9737 21.1498 17.3952C20.2996 17.8166 19.2417 16.9438 17.1259 15.198L17 15.0941"
                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                          <circle cx="11.5" cy="9.5" r="1.5" stroke="currentColor" stroke-width="1.5" />
                        </svg>
                        <svg *ngIf="term_int.type == 'Audio'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                          width="24" height="24" color="#ffffff" fill="none">
                          <circle cx="6.5" cy="18.5" r="3.5" stroke="currentColor" stroke-width="1.5" />
                          <circle cx="18" cy="16" r="3" stroke="currentColor" stroke-width="1.5" />
                          <path
                            d="M10 18.5L10 7C10 6.07655 10 5.61483 10.2635 5.32794C10.5269 5.04106 11.0175 4.9992 11.9986 4.91549C16.022 4.57222 18.909 3.26005 20.3553 2.40978C20.6508 2.236 20.7986 2.14912 20.8993 2.20672C21 2.26432 21 2.4315 21 2.76587V16"
                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M10 10C15.8667 10 19.7778 7.66667 21 7" stroke="currentColor" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg *ngIf="term_int.type == 'Enlace externo'" xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24" width="24" height="24" color="#ffffff" fill="none">
                          <path
                            d="M10 13.229C10.1416 13.4609 10.3097 13.6804 10.5042 13.8828C11.7117 15.1395 13.5522 15.336 14.9576 14.4722C15.218 14.3121 15.4634 14.1157 15.6872 13.8828L18.9266 10.5114C20.3578 9.02184 20.3578 6.60676 18.9266 5.11718C17.4953 3.6276 15.1748 3.62761 13.7435 5.11718L13.03 5.85978"
                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                          <path
                            d="M10.9703 18.14L10.2565 18.8828C8.82526 20.3724 6.50471 20.3724 5.07345 18.8828C3.64218 17.3932 3.64218 14.9782 5.07345 13.4886L8.31287 10.1172C9.74413 8.62761 12.0647 8.6276 13.4959 10.1172C13.6904 10.3195 13.8584 10.539 14 10.7708"
                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                          <path d="M21 16H18.9211M16 21L16 18.9211" stroke="currentColor" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M3 8H5.07889M8 3L8 5.07889" stroke="currentColor" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg *ngIf="term_int.type == 'Imagen'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                          width="24" height="24" color="#ffffff" fill="none">
                          <path
                            d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
                            stroke="currentColor" stroke-width="1.5" />
                          <circle cx="16.5" cy="7.5" r="1.5" stroke="currentColor" stroke-width="1.5" />
                          <path
                            d="M16 22C15.3805 19.7749 13.9345 17.7821 11.8765 16.3342C9.65761 14.7729 6.87163 13.9466 4.01569 14.0027C3.67658 14.0019 3.33776 14.0127 3 14.0351"
                            stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                          <path
                            d="M13 18C14.7015 16.6733 16.5345 15.9928 18.3862 16.0001C19.4362 15.999 20.4812 16.2216 21.5 16.6617"
                            stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                        </svg> -->
                      </h6>
                    </div>
                  </a>
                </div>
              </div>

              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="page-section2-container-pagination-container">
          <a href="javascript:;" class="page-section2-container-pagination" (click)="setPage(i + 1)"
            *ngFor="let item of arrayPagination; let i = index" [ngClass]="{ 'active': i === (currentPage - 1) }">{{ i +
            1
            }} </a>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal-img-full" [ngClass]="modalImgShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullImgModal()"></div>
  <button class="close" (click)="closeFullImgModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-img-full">
    <!-- <img src="{{ modalImgSrc }}" alt=""> -->
  </div>
  <div class="navs-buttons">
    <button class="btn-prev-lightbox" *ngIf="has_prev_img" (click)="prevFullImgModal()">
      <img src="assets/img/icons/arrow-white.svg" alt="" />
    </button>

    <button class="btn-next-lightbox" *ngIf="has_next_img" (click)="nextFullImgModal()">
      <img src="assets/img/icons/arrow-white.svg" alt="" />
    </button>
  </div>
</div>

<div id="modal-video-youtube" class="modal-video" [ngClass]="modalVideoIframe == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideoIframe()"></div>
  <button class="close" (click)="closeModalVideoIframe()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <iframe width="560" height="315" [src]="urlSafe" class="iframe-video" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>