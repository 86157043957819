<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <a [routerLink]="['/innovacion']"><span>Innovación</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Desafíos</span>
      </div>
    </div>
  </div>
</section>
<div class="">
  <section class="section-custom-news">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-11">
          <div class="text-center mb-3">
            <h1 class="title1 text-center">Desafíos</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-9 mx-auto">
          <div class="row">
            <div class="col-lg-6 mb-5">
              <a [routerLink]="['/ideas-desafio7' ]"
                class="card-design-notice card-design-notice-nm card-design-embajadores card-design-desafio"
                data-aos="fade-up" data-aos-duration="700">
                <div class="card-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/02/banner-2022.jpg"
                    style="object-fit: contain; object-position: 25%; background: #fff;" alt="">
                  <div class="diagonal-cut"></div>
                </div>
                <div class="card-content">
                  <h4 class="card-title">Desafío Masivo 2022 i-NOW CERO / 100</h4>
                </div>
              </a>
            </div>
            <div class="col-lg-6 mb-5">
              <a [routerLink]="['/ideas-desafio6' ]"
                class="card-design-notice card-design-notice-nm card-design-embajadores card-design-desafio"
                data-aos="fade-up" data-aos-duration="700">
                <div class="card-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/inicio.jpg" alt="">
                  <div class="diagonal-cut"></div>
                </div>
                <div class="card-content">
                  <h4 class="card-title">SOMOS INNOVACIÓN, TÚ ERES INNOVACIÓN</h4>
                </div>
              </a>
            </div>
            <div class="col-lg-6 mb-5">
              <a [routerLink]="['/ideas-desafio5' ]"
                class="card-design-notice card-design-notice-nm card-design-embajadores card-design-desafio"
                data-aos="fade-up" data-aos-duration="700">
                <div class="card-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/2020.jpg" alt="">
                  <div class="diagonal-cut"></div>
                </div>
                <div class="card-content">
                  <h4 class="card-title">LEGADO CREATIVO</h4>
                </div>
              </a>
            </div>
            <div class="col-lg-6 mb-5">
              <a [routerLink]="['/ideas-desafio4' ]"
                class="card-design-notice card-design-notice-nm card-design-embajadores card-design-desafio"
                data-aos="fade-up" data-aos-duration="700">
                <div class="card-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/2019.jpg" alt="">
                  <div class="diagonal-cut"></div>
                </div>
                <div class="card-content">
                  <h4 class="card-title">EL FUTURO ERES TÚ</h4>
                </div>
              </a>
            </div>
            <div class="col-lg-6 mb-5">
              <a [routerLink]="['/ideas-desafio3' ]"
                class="card-design-notice card-design-notice-nm card-design-embajadores card-design-desafio"
                data-aos="fade-up" data-aos-duration="700">
                <div class="card-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/2018.jpg" alt="">
                  <div class="diagonal-cut"></div>
                </div>
                <div class="card-content">
                  <h4 class="card-title">Dale una Vuelta</h4>
                </div>
              </a>
            </div>
            <div class="col-lg-6 mb-5">
              <a [routerLink]="['/ideas-desafio2' ]"
                class="card-design-notice card-design-notice-nm card-design-embajadores card-design-desafio"
                data-aos="fade-up" data-aos-duration="700">
                <div class="card-img">
                  <img
                    src="https://intranet.abastible.cl/wp/wp-content/uploads/2019/02/Inow-2do-desaf%C3%ADo-cambios-aplicadosv5-fina1.jpg"
                    alt="">
                  <div class="diagonal-cut"></div>
                </div>
                <div class="card-content">
                  <h4 class="card-title">100% productividad con foco en el cliente</h4>
                </div>
              </a>
            </div>
            <div class="col-lg-6 mb-5">
              <a [routerLink]="['/ideas-desafio1' ]"
                class="card-design-notice card-design-notice-nm card-design-embajadores card-design-desafio"
                data-aos="fade-up" data-aos-duration="700">
                <div class="card-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2019/02/DSC_4972.jpg" alt="">
                  <div class="diagonal-cut"></div>
                </div>
                <div class="card-content">
                  <h4 class="card-title">¿Cómo nos convertimos en la mejor empresa para trabajar en Chile?</h4>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- <a [routerLink]="['/formulario-desafio7inow' ]" class="btn-mailto-inow"><img src="assets/img/icons/mail.svg" alt="">
  <span>Cuéntanos tu idea</span>
</a> -->