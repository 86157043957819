import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EmprendimientosService {

    constructor(private httpClient: HttpClient) { }

    getEmprendimientos(search: string, page: number, category: string, zona: string): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/emprendimientos/`, {
                search: search,
                page: page,
                category: category,
                zona: zona
            })
            .pipe((result) => {
                console.log(typeof result);
                return result;
            });
    }
}
