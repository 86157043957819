<div class="bg-white bg-white-custom pb-0">
  <section class="section-preguntas-frecuentes">
    <div class="container">
      <div class="text-center mb-3">
        <h1 class="title1 text-center">Preguntas Frecuentes</h1>
      </div>
      <div class="items-questions">
        <div class="item-question" *ngFor="let item of questions" >
          <button class="btn-item-question" [innerHTML]="item.pregunta"></button>
          <div class="item-question-content">
            <p [innerHTML]="item.pregunta"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>