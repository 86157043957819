import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { NoticiasService } from '@services/noticias';
import { InnovacionService } from '@services/innovacion';

@Component({
  selector: 'app-embajadores',
  templateUrl: './embajadores.component.html',
  styleUrls: ['./embajadores.component.scss'],
})
export class EmbajadoresComponent implements OnInit {
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  selectedIndex: any[] = [];
  news1: any;
  news1Res: any;
  currentPage1 = 1;
  catNews = "all";
  categoriesFilter: any;
  embajadores: any;
  embajadoresRes: any;
  currentPageEmbajadores = 1;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private NoticiasService: NoticiasService,
    private InnovacionService: InnovacionService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Embajadores | Intranet Abastible');
    this.paramsID = 29622;
    this.slug = this.params['slug'];
    $("body").removeClass("modal-open");
    $("body").removeClass("modal-single-content-open");

    this.InnovacionService.getEmbajadores(1).subscribe(
      (res) => {
        this.embajadores = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

  }

  nextPageEmbajadores() {
    this.currentPageEmbajadores = this.currentPageEmbajadores + 1;

    this.InnovacionService.getEmbajadores(this.currentPageEmbajadores).subscribe(
      (res) => {
        this.embajadoresRes = res;
        this.embajadores = [...this.embajadores, ...this.embajadoresRes];
        console.log([...this.embajadores, res]);
        console.log(this.embajadoresRes)
      },
      (err) => {
        console.error(err);
      }
    );
  }

  textMin(text: string) {
    var str = text;
    var res = str.substring(0, 200);//get first 5 chars
    return res + '...';;
  }

  contentFilter(content: string): string {
    return content.replace(/<img .*?>/g, '');
  }

  setSelected(id: number) {
    if (this.selectedIndex[id] == true) {
      this.selectedIndex[id] = false;
    } else {
      this.selectedIndex[id] = true;
    }
  }
} 
