import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { DocumentosService } from '@services/documentos';
import { LoadingService } from '@services/loading';

@Component({
  selector: 'app-single-grupos',
  templateUrl: './single-grupos.component.html',
  styleUrls: ['./single-grupos.component.scss'],
})
export class SingleGrupoComponent implements OnInit {
  documentos: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  url: string = "";
  urlSafe: SafeResourceUrl
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private documentosService: DocumentosService,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private title: Title,
    public sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('Single Grupos | Intranet Abastible');
    this.activatedRoute.params.subscribe((params) => {
      this.params = params['slug'];
      console.log(this.params)
    });
    if (this.params == "comunidad-de-buenas-practicas") {
      this.url = "https://intratest.abastible.cl/groups/comunidad-de-buenas-practicas"
    }
    if (this.params == "embajadores-i-now") {
      this.url = "https://intratest.abastible.cl/groups/embajadores-i-now"
    }
    if (this.params == "potencial-abastible") {
      this.url = "https://intratest.abastible.cl/groups/potencial-abastible"
    }
    if (this.params == "grupo-de-testing") {
      this.url = "https://intratest.abastible.cl/groups/grupo-de-testing"
    }
    if (this.params == "embajadores-de-servicio") {
      this.url = "https://intratest.abastible.cl/groups/embajadores-de-servicio"
    }
    if (this.params == "comite-paritario") {
      this.url = "https://intratest.abastible.cl/groups/comite-paritario"
    }
    this.url = this.url.replace("watch?v=", "v/");
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    $("body").css("overflow", "hidden");
  }
} 
