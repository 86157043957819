import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { NewsService } from '@services/news';
import { InnovacionService } from '@services/innovacion';
import { DesafioService } from '@services/desafio';
import { LoadingService } from '@services/loading';
import * as $ from 'jquery';

@Component({
  selector: 'app-ideas-desafio7',
  templateUrl: './ideas-desafio7.component.html',
  styleUrls: ['./ideas-desafio7.component.scss'],
})
export class IdeasDesafios7Component implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  addClassFixed = false;
  selectedIndex: any[] = [];
  linksScrollSwiper = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        570: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  videoinBanner: any;
  sections: any;
  documentos: any;
  noticias_manual: any;
  currentPage1 = 1;
  currentPageEmbajadores = 1;
  currentPage2 = 1;
  news1: any;
  news2: any;
  news2Res: any;
  news1Res: any;
  embajadores: any;
  embajadoresRes: any;
  modalTextShow: any;
  modalText: any;
  modalTitulo: any;
  carouselGalerias2 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselBanner = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  modalVideo = false;
  modalSrcVideo: any;
  currentPage = 1;
  modalEventos = false;
  ideas: any;
  ideas_ganadoras: any;
  ideas_quick: any;
  ideasRes: any;
  modalTitle: string;
  nombre_del_colaborador: string;
  email_del_colaborador: string;
  modalImage: string;
  planta_u_oficina: string;
  nombre_de_la_idea: string;
  descripcion: string;
  beneficios: string;
  retos: string;
  costos: string;
  ideas_problemas: any;
  hideVerMas = true;
  modalVideoIframe = false;
  modalSrcVideoIframe: string = "";
  urlSafe: SafeResourceUrl;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private landingService: LandingService,
    private NewsService: NewsService,
    private InnovacionService: InnovacionService,
    private desafioService: DesafioService,
    public sanitizer: DomSanitizer,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Somos innovación, tu eres innovación | Intranet Abastible');
    this.paramsID = 23094;
    this.slug = this.params['slug'];
    $(".btn-play-video").click(function (e) {
      e.preventDefault();
      $(this).fadeOut();
      $(this).parent().find("video").fadeIn();
      $(this).parent().find("video").get(0).play();
    });

    $("body").delegate(".item-sostenibilidad-custom1", "click", function () {
      var titleModal = $(this).find(".box-item-sostenibilidad-custom1 h3").text();
      var textModal = $(this).find(".box-item-sostenibilidad-custom1 p").html();
      $(".modal-custom3-text").fadeIn();
      $('.modal-custom3-text .title-custom3-text').text(titleModal);
      $('.modal-custom3-text .box-text-custome-text').html(textModal);
    });
    $("body").delegate(".modal-custom3-text .close", "click", function () {
      $(".modal-custom3-text").fadeOut();
    });
    $("body").delegate(".modal-custom3-text .overlay", "click", function () {
      $(".modal-custom3-text").fadeOut();
    });

    this.desafioService.getIdeasDesafio7(1).subscribe(
      (res) => {
        this.ideas = res;
        console.log(res)
      },
      (err) => {
        console.error(err);
      }
    );

  }
  nextPage() {
    this.currentPage = this.currentPage + 1;

    this.desafioService.getIdeasDesafio7(this.currentPage).subscribe(
      (res) => {
        this.ideasRes = res;
        console.log(this.ideasRes.length)
        this.ideas = [...this.ideas, ...this.ideasRes];
        if (this.ideasRes.length < 20) {
          this.hideVerMas = false;
        }
        console.log([...this.ideas, res]);
        console.log(this.ideasRes)
      },
      (err) => {
        console.error(err);
      }
    );
  }
  openModalEvento(title: string, nombre_del_colaborador: string, email_del_colaborador: string, planta_u_oficina: string, nombre_de_la_idea: string, descripcion: string, beneficios: string, retos: string, costos: string, semifinalista: string) {
    this.modalEventos = true;
    this.modalTitle = title;
    this.nombre_del_colaborador = nombre_del_colaborador;
    this.email_del_colaborador = email_del_colaborador;
    this.planta_u_oficina = planta_u_oficina;
    this.nombre_de_la_idea = nombre_de_la_idea;
    this.descripcion = descripcion;
    this.beneficios = beneficios;
    this.retos = retos;
    this.costos = costos;
    this.modalImage = 'assets/img/bg-orange.png';
    if (semifinalista != "1") {
      this.modalImage = 'assets/img/cat1-desafio6.png'
    }
    if (semifinalista == "1") {
      this.modalImage = 'assets/img/oportunidad-de-mejora2.png'
    }
  }
  closeEventosModal() {
    this.modalEventos = false;
  }

  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }
  showModalVideoIframe(event, srcVideo: string) {
    event.stopPropagation();
    let prevText = "https://www.youtube.com/embed/";
    this.modalVideoIframe = true;
    this.modalSrcVideoIframe = prevText + srcVideo;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
  }
  closeModalVideoIframe() {
    this.modalVideoIframe = false;
    this.modalSrcVideoIframe = '';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
    $("#modal-video-youtube").removeClass("active-modal");
  }
}
