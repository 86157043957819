export { LoginComponent } from './auth/login/login.component';
export { HomeComponent } from './home/home.component';
export { HomeNewComponent } from './home-new/home-new.component';
export { SinglePostComponent } from './posts/single/single.component';
export { SingleEventosComponent } from './evento/single/single.component';
export { SinglePageComponent } from './single/single.component';
export { SingleContentComponent } from './single-content/single-content.component';
export { GaleriasPageComponent } from './galerias/galerias.component';
export { GaleriaVideosPageComponent } from './galeria-videos/galeria-videos.component';
export { ContactanosPageComponent } from './contactanos/contactanos.component';
export { DocumentosPageComponent } from './documentos/documentos.component';
export { SingleDocumentosPageComponent } from './single-documentos/single-documentos.component';
export { ConozcaAbastibleComponent } from './conozca-abastible/conozca-abastible.component';
export { HacerLasCosasBienComponent } from './hacer-las-cosas-bien/hacer-las-cosas-bien.component';
export { BeneficiosAbastibleComponent } from './beneficios/beneficios.component';
export { NoticiasAbastibleComponent } from './noticias/noticias.component';
export { PuntoPrensaAbastibleComponent } from './punto-prensa/punto-prensa.component';
export { ConcursosInternosAbastibleComponent } from './concursos-internos/concursos-internos.component';
export { LinksAbastibleComponent } from './links/links.component';
export { IndicadoresAbastibleComponent } from './indicadores/indicadores.component';
export { CanalDenunciasAbastibleComponent } from './canal-denuncias/canal-denuncias.component';
export { DesarrolloOrganizacionalComponent } from './desarrollo-organizacional/desarrollo-organizacional.component';
export { GobiernoCorporativoComponent } from './gobierno-corporativo/gobierno-corporativo.component';
export { InnovacionComponent } from './innovacion/innovacion.component';
export { IdeasDesafios1Component } from './ideas-desafio1/ideas-desafio1.component';
export { IdeasDesafios2Component } from './ideas-desafio2/ideas-desafio2.component';
export { IdeasDesafios3Component } from './ideas-desafio3/ideas-desafio3.component';
export { IdeasDesafios4Component } from './ideas-desafio4/ideas-desafio4.component';
export { IdeasDesafios5Component } from './ideas-desafio5/ideas-desafio5.component';
export { ManualMarcaComponent } from './manual-marca/manual-marca.component';
export { EstandarSenaliticaomponent } from './estandar-senalitica/estandar-senalitica.component';
export { RevistaComponent } from './revista/revista.component';
export { Office365Component } from './office-365/office-365.component';
export { MasAbastibleComponent } from './mas-abastible/mas-abastible.component';
export { ComunicadosAbastibleComponent } from './comunicados/comunicados.component';
export { ConozcaAbastible2Component } from './conozca-abastible2/conozca-abastible2.component';
export { PerfilPageComponent } from './perfil/perfil.component';
export { CumpleanosPageComponent } from './cumpleanos/cumpleanos.component';
export { ListaGruposComponent } from './lista-grupos/lista-grupos.component';
export { SingleGrupoComponent } from './single-grupos/single-grupos.component';
export { SingleGaleriaComponent } from './single-galeria/single-galeria.component';
export { EmbajadoresComponent } from './embajadores/embajadores.component';
export { EventosComponent } from './eventos/eventos.component';
export { SeguridadComponent } from './seguridad/seguridad.component';
export { GaleriaVideosSectionPageComponent } from './galeria-videos-section/galeria-videos-section.component';
export { GaleriaImageSectionPageComponent } from './galeria-image-section/galeria-image-section.component';
export { DesafiosAbastibleComponent } from './desafios/desafios.component';
export { ComunidadBuenasPracticasComponent } from './groups/comunidad-buenas-practicas/comunidad-buenas-practicas.component';
export { EmbajadoresInowComponent } from './groups/embajadores-inow/embajadores-inow.component';
export { EmbajadoresServicioComponent } from './groups/embajadores-servicio/embajadores-servicio.component';
export { PotencialAbastibleComponent } from './groups/potencial-abastible/potencial-abastible.component';
export { DocumentosCBPComponent } from './groups/documentos-cbp/documentos-cbp.component';
export { GaleriaVideosSectionInowPageComponent } from './galeria-videos-section-inow/galeria-videos-section-inow.component';
export { InstructivosCoronavirusComponent } from './instructivos-coronavirus/instructivos-coronavirus.component';
export { NoticiasGruposComponent } from './groups/noticias/noticias-grupos.component';
export { ComiteParitarioComponent } from './groups/comite-paritario/comite-paritario.component';
export { SearchGruposComponent } from './search/search.component';
export { GaleriaGruposPageComponent } from './groups/galeria-grupos/galeria-grupos.component';
export { FormularioDesafio6Component } from './formulario-desafio6/formulario-desafio6.component';
export { FormularioDesafio7InowComponent } from './formulario-desafío7inow/formulario-desafío7inow.component';
export { IdeasDesafios6Component } from './ideas-desafio6/ideas-desafio6.component';
export { IdeasDesafios7Component } from './ideas-desafio7/ideas-desafio7.component';
export { SostenibilidadComponent } from './sostenibilidad/sostenibilidad.component';
export { JuegoRiesgoComponent } from './juego-riesgo/juego-riesgo.component';
export { TechoComponent } from './techo/techo.component';
export { PreguntasFrecuentesComponent } from './preguntas-frecuentes/preguntas-frecuentes.component';
export { ConcursoAbastibleComponent } from './concurso/concurso.component';
export { VideoInduccionPageComponent } from './video-induccion/video-induccion.component';
export { ComiteParitarioDeHigieneYSeguridadOficinaCentral } from './comite-paritario-de-higiene-y-seguiridad-oficina-central/comite-paritario-de-higiene-y-seguiridad-oficina-central.component';
export { PropositoAbastibleComponent } from './proposito/proposito.component';
export { TriviaCiberseguridad1AbastibleComponent } from './trivia-ciberseguridad1/trivia-ciberseguridad1.component';
export { TriviaCiberseguridad2AbastibleComponent } from './trivia-ciberseguridad2/trivia-ciberseguridad2.component';
export { TriviaCiberseguridad3AbastibleComponent } from './trivia-ciberseguridad3/trivia-ciberseguridad3.component';
export { AgilidadAbastibleComponent } from './agilidad/agilidad.component';
export { RespositorioLegalComponent } from './repositorio-legal/repositorio-legal.component';
export { FormulariosModuloMMComponent } from './formularios-modulo-mm/formularios-modulo-mm.component';
export { RedirectHomeComponent } from './redirect-home/redirect-home.component';
export { DuenosDeProcesosComponent } from './duenos-de-procesos/duenos-de-procesos.component';
export { RedesSocialesComponent } from './redes-sociales/redes-sociales.component';
export { MetodologiaGoComponent } from './metodologia-go/metodologia-go.component';
export { EmprendimientosComponent } from './emprendimientos/emprendimientos.component';
export { ReciclarEsColaborarAbastibleComponent } from './reciclar-es-colaborar/reciclar-es-colaborar.component';
export { LeyKarinAbastibleComponent } from './ley-karin/ley-karin.component';
export { NoticiasConexionNaranjaAbastibleComponent } from './noticias-conexion-naranja/noticias-conexion-naranja.component';
export { NoticiasConexionNaranjaEdicionAbastibleComponent } from './noticias-conexion-naranja/noticias-conexion-naranja-edicion/noticias-conexion-naranja-edicion.component';
export { NoticiasConexionNaranjaSingleAbastibleComponent } from './noticias-conexion-naranja/noticias-conexion-naranja-single/noticias-conexion-naranja-single.component';