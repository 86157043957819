<div class="bg-white bg-white-custom">
  <div id="animationWindow"></div>
  <section id="breadcrumbs">
    <div class="container">
      <div class="breadcrumbs breadcrumbs-sections">
        <div class="breadcrumbs-item">
          <a [routerLink]="['/muro']"><span class="material-icons">
              home
            </span></a>
          <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
        </div>
        <div class="breadcrumbs-item">
          <span>Instructivos Coronavirus</span>
        </div>
      </div>
    </div>
  </section>
  <section class="section-custom-new3">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">INSTRUCTIVOS</h3>
          <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
          <div class="content-documents-custom-new3">
            <a href="{{ instructivo.pdf }}" class="item-document-custom-new3"
              *ngFor="let instructivo of instructivosCoronavirus; let i = index" target="_blank" data-aos="fade-up"
              data-aos-duration="700" data-aos-delay="0">
              <h3 class="title-document-custom-new3">{{ instructivo.titulo }}</h3>
              <div class="box-acctions-custom-new3">
                <span class="view-document">
                  <img src="assets/img/file.svg" alt="">
                </span>
                <span class="download-document">
                  <img src="assets/img/download.svg" alt="">
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>