<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Eventos</span>
      </div>
    </div>
  </div>
</section>
<div class="">
  <section class="section-custom-news">
    <div class="container">
      <div class="text-center mb-3">
        <h1 class="title1 text-center mb-5">Eventos</h1>
      </div>
      <div class="row">
        <div class="col-lg-12 mx-auto" *ngIf="eventos_actuales?.length > 0">
          <div class="row">
            <div class="col-12">
              <h3 class="mb-3 title1">Eventos de hoy</h3>
            </div>
            <div class="col-lg-11 mx-auto" *ngFor="let evento of eventos_actuales; let i = index">
              <div class="modal-section-custom1 modal-section-custom1-new">
                <div class="row">
                  <div class="col-lg-6 modal-section-custom1-img">
                    <img src="{{ evento.image }}" class="" alt="">
                    <div class="cut-diagonal"></div>
                  </div>
                  <div class="modal-section-custom1-text col-lg-6">
                    <h3 class="title2-modal-section-custom1" [innerHTML]="evento.title"></h3>
                    <p *ngIf="evento.fields.fecha"><b>Fecha:</b> {{evento.fields.fecha}}</p>
                    <p *ngIf="evento.fields.categoria"><b>Categoría:</b> {{evento.fields.categoria}}</p>
                    <p *ngIf="evento.fields.horario"><b>Horario:</b> {{evento.fields.horario}}</p>
                    <p *ngIf="evento.fields.direccion"><b>Dirección:</b> <a href="{{evento.fields.direccion}}" target="_blank">{{evento.fields.direccion}}</a></p>
                    <p *ngIf="evento.fields.organizador_del_evento"><b>Organizador del evento:</b> {{evento.fields.organizador_del_evento}}</p>
                    <p *ngIf="evento.fields.email_organizador_del_evento"><b>Email organizador del evento:</b> {{evento.fields.email_organizador_del_evento}}</p>
                    <a [routerLink]="['/evento/' + evento.ID ]">Ver más</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 mx-auto mt-5" *ngIf="eventos_siguientes?.length > 0">
          <div class="row">
            <div class="col-12">
              <h3 class="mb-3 title1">Próximos eventos</h3>
            </div>
            <div class="col-lg-11 mx-auto" *ngFor="let evento of eventos_siguientes; let i = index">
              <div class="modal-section-custom1 modal-section-custom1-new">
                <div class="row">
                  <div class="col-lg-6 modal-section-custom1-img">
                    <img src="{{ evento.image }}" class="" alt="">
                    <div class="cut-diagonal"></div>
                  </div>
                  <div class="modal-section-custom1-text col-lg-6">
                    <h3 class="title2-modal-section-custom1" [innerHTML]="evento.title"></h3>
                    <p *ngIf="evento.fields.fecha"><b>Fecha:</b> {{evento.fields.fecha}}</p>
                    <p *ngIf="evento.fields.categoria"><b>Categoría:</b> {{evento.fields.categoria}}</p>
                    <p *ngIf="evento.fields.horario"><b>Horario:</b> {{evento.fields.horario}}</p>
                    <p *ngIf="evento.fields.direccion"><b>Dirección:</b> <a href="{{evento.fields.direccion}}" target="_blank">{{evento.fields.direccion}}</a></p>
                    <p *ngIf="evento.fields.organizador_del_evento"><b>Organizador del evento:</b> {{evento.fields.organizador_del_evento}}</p>
                    <p *ngIf="evento.fields.email_organizador_del_evento"><b>Email organizador del evento:</b> {{evento.fields.email_organizador_del_evento}}</p>
                    <a [routerLink]="['/evento/' + evento.ID ]">Ver más</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
          <a (click)="nextPageEventos()" class="btn-custom-new">
            <span>VER MÁS</span>
          </a>
        </div> -->
      </div>
    </div>
  </section>
</div>

<div id="modal-eventos" class="modal-eventos" *ngIf="modalEventos">
  <div class="overlay-modal" (click)="closeEventosModal()"></div>
  <div class="modal-content">
    <button type="button" class="close" aria-label="Close" (click)="closeEventosModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body modal-body-eventos">
      <img src="{{ modalImg }}" alt="" class="img-modal">
      <div class="box-content-body-modal">
        <h2 class="title-modal">{{ modalTitle }}</h2>
        <h6 class="subtitle-modal">{{ modalDia }} de {{ modalMes }}</h6>
        <div class="box-text-modal">
          {{ modalContent }}
        </div>
      </div>
    </div>
  </div>
</div>