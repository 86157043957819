import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificacionesService {

    constructor(private httpClient: HttpClient) { }
    getNotifications(paged: number): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));
        const user_id = user.ID;

        return this.httpClient
            .post(`${environment2.apiUrl}/notifications/?user_id=${user_id}&paged=${paged}&per_page=6`, null)
            .pipe((result) => {
                return result;
            });
    }
    getTotalNotifications(): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));
        const user_id = user.ID;

        return this.httpClient
            .post(`${environment2.apiUrl}/total-notifications/?user_id=${user_id}`, null)
            .pipe((result) => {
                return result;
            });
    }
    getNotificationsNew(paged: number): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));
        const user_id = user.ID;

        return this.httpClient
            .post(`${environment2.apiUrl}/notifications-new/?user_id=${user_id}&paged=${paged}&per_page=6`, null)
            .pipe((result) => {
                return result;
            });
    }
    UpadteNotificationsNew(paged: number, idNotification: number): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));
        const user_id = user.ID;

        return this.httpClient
            .post(`${environment2.apiUrl}/update-notifications-new/?user_id=${user_id}&paged=${paged}&per_page=6&id_notification=${idNotification}`, null)
            .pipe((result) => {
                return result;
            });
    }

    deleteNotifications(idNotification: number): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));
        const user_id = user.ID;
        return this.httpClient
            .post(`${environment2.apiUrl}/delete-notification/?notification_id=${idNotification}`, null)
            .pipe((result) => {
                return result;
            });
    }
}
