import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { NewsService } from '@services/news';
import { LoadingService } from '@services/loading';
import * as $ from 'jquery';

@Component({
  selector: 'app-manual-marca',
  templateUrl: './manual-marca.component.html',
  styleUrls: ['./manual-marca.component.scss'],
})
export class ManualMarcaComponent implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  paramsID: any;
  documentos: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private landingService: LandingService,
    private NewsService: NewsService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Manual de marca | Intranet Abastible');
    this.slug = this.params['slug'];
    this.paramsID = 24466;
    this.landingService.getInfoNew(this.paramsID).subscribe(
      (res) => {
        let that = this;
        this.landing = res;
        this.documentos = res.documentos;
        console.log("documentos", res)
      },
      (err) => {
        console.error(err);
      }
    );
  }
} 
