import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { GaleriasService } from '@services/galerias';
import { LoadingService } from '@services/loading';
import { InnovacionService } from '@services/innovacion';

@Component({
  selector: 'app-galeria-videos-section-inow',
  templateUrl: './galeria-videos-section-inow.component.html',
  styleUrls: ['./galeria-videos-section-inow.component.scss'],
})
export class GaleriaVideosSectionInowPageComponent implements OnInit {
  galerias: any;
  galerias2: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  currentPage = 1;
  paramsID: any;
  galeriasRes: any;
  urlSafe: SafeResourceUrl;
  modalVideoIframe = false;
  modalSrcVideoIframe: string = "";
  modalVideo = false;
  modalSrcVideo: any;
  valueSearch = "";
  inowTalks: any;
  haveInowTalks = 0;
  videosInow: any;
  haveVideosInow = 0;
  back_title_page: any;
  back_route_link: any;
  back_section_route_link: any;
  back_section_route_title: any;
  has_back_section = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private galeriasService: GaleriasService,
    private innovacionService: InnovacionService,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private title: Title,
    public sanitizer: DomSanitizer,
    private sanitized: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('Galería de videos | Intranet Abastible');
    console.log(location.origin);
    if (localStorage.getItem('back_title_page')) {
      this.back_title_page = localStorage.getItem('back_title_page');
      this.back_route_link = "https://intranet.abastible.cl/" + localStorage.getItem('back_route_link')

      if (localStorage.getItem('back_section_route_link')) {
        this.has_back_section = true;
        this.back_section_route_title = localStorage.getItem('back_section_route_title');
        this.back_section_route_link = this.back_route_link + localStorage.getItem('back_section_route_link');
      } else {
        this.has_back_section = false;
      }
    }
    this.activatedRoute.params.subscribe((params) => {
      this.params = params['id'];
      console.log(this.params)
    });

    this.galeriasService.galeriaVideosByCategory(this.params, 1, 8,"").subscribe(
      (res) => {
        this.galerias = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    $("body").removeClass("modal-open");
    $("body").removeClass("modal-single-content-open");
    $("body").delegate(".item-video-galeria", "click", function () {
      var dataVideo = $(this).data("video");
      $("#modal-video .box-modal-video-full video").attr("src", dataVideo);
      $("#modal-video").addClass("active-modal");
    });
  }
  nextPage() {
    this.currentPage = this.currentPage + 1;
    
    this.galeriasService.galeriaVideosByCategory(this.params, this.currentPage, 8,this.valueSearch).subscribe(
      (res) => {
        this.galeriasRes = res;
        this.galerias = [...this.galerias, ...this.galeriasRes];
        console.log([...this.galerias, res]);
        console.log(this.galeriasRes)
      },
      (err) => {
        console.error(err);
      }
    );
  }
  onSubmit(value: any) {
    this.valueSearch = value
    console.log(this.valueSearch);
    this.galeriasService.galeriaVideosByCategory(this.params, this.currentPage, 8,this.valueSearch).subscribe(
      (res) => {
        this.galerias = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
  }
  showModalVideoIframe(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideoIframe = true;
    this.modalSrcVideoIframe = srcVideo;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
  }
  closeModalVideoIframe() {
    this.modalVideoIframe = false;
    this.modalSrcVideoIframe = '';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
    $("#modal-video-youtube").removeClass("active-modal");
  }
  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }
}
