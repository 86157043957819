<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Grupos</span>
      </div>
    </div>
  </div>
</section>
<div class="">
  <section class="section-custom-news">
    <div class="container">
      <div class="text-center mb-3">
        <h1 class="title1 text-center mb-5">Grupos</h1>
      </div>
      <div class="row">
        <div class="col-lg-9 mx-auto">

          <div class="modal-section-custom1 modal-section-custom1-new" *ngFor="let grupo of grupos; let i = index">
            <div *ngIf="grupo.slug != 'grupo-de-testing'">
              <div class="row">
                <div class="col-lg-3 modal-section-custom1-img">
                  <img src="{{ grupo.img_grupo }}" class="" alt="">
                  <div class="cut-diagonal"></div>
                </div>
                <div class="modal-section-custom1-text col-lg-9">
                  <h3 class="title2-modal-section-custom1" [innerHTML]="grupo.titulo"></h3>
                  <a [routerLink]="['/group/' + grupo.slug ]">Ver más</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>