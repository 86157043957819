import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { NoticiasService } from '@services/noticias';
import { NewsService } from '@app/services/news';
import { GaleriasService } from '@app/services/galerias';

@Component({
  selector: 'app-proposito',
  templateUrl: './proposito.component.html',
  styleUrls: ['./proposito.component.scss'],
})
export class PropositoAbastibleComponent implements OnInit {
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  selectedIndex: any[] = [];
  newsSeguridadInformacion: any;
  galeriaVideosSeguridad: any;
  galeriaSeguridad: any;
  news1: any;
  news1Res: any;
  currentPage1 = 1;
  catNews = "all";
  categoriesFilter: any;
  modalVideoIframe = false;
  modalSrcVideoIframe: string = "";
  urlSafe: SafeResourceUrl;
  carouselCustom = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 20,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselCharlas2 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  modalVideo: any;
  modalSrcVideo: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private NoticiasService: NoticiasService,
    private galeriasService: GaleriasService,
    public sanitizer: DomSanitizer,
    private NewsService: NewsService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Propósito | Intranet Abastible');
    this.paramsID = 29622;
    this.slug = this.params['slug'];

    // Seguridad de Informacion
    
    this.newsSeguridadInformacion = [];
    this.NewsService.getNews("proposito", 1).subscribe(
      (res) => {
        this.newsSeguridadInformacion = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.galeriaSeguridad = [];
    this.galeriasService.galeriaByCategory("proposito", 1, "").subscribe(
      (res) => {
        if(res == null){
          this.galeriaSeguridad = [];
        }else{
          this.galeriaSeguridad = res;
        }
        console.log("testestsetsetes",res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.galeriaVideosSeguridad = [];
    this.galeriasService.galeriaVideosByCategory("proposito", 1, 8, "").subscribe(
      (res) => {
        this.galeriaVideosSeguridad = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
  } 

  showModalVideoIframe(event, srcVideo: string) {
    event.stopPropagation();
    let prevText = "https://www.youtube.com/embed/";
    this.modalVideoIframe = true;
    this.modalSrcVideoIframe = prevText + srcVideo;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
  }
  closeModalVideoIframe() {
    this.modalVideoIframe = false;
    this.modalSrcVideoIframe = '';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
    $("#modal-video-youtube").removeClass("active-modal");
  }

  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }
} 
