import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VotarService {

    constructor(private httpClient: HttpClient) { }
    getListVotarEvento(): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/list_votar_evento/`, null)
            .pipe((result) => {
                return result;
            });
    }
    votarEvento(id_votar: number): Observable<Record<string, any>> {
        var user = JSON.parse(localStorage.getItem('user'));
        var user_id = parseInt(user['ID']);
        return this.httpClient
            .post(`${environment2.apiUrl}/votar_evento?id_votar=${id_votar}&user_id=${user_id}`, null)
            .pipe((result) => {
                return result;
            });
    }
    checkVotarEvento(): Observable<Record<string, any>> {
        var user = JSON.parse(localStorage.getItem('user'));
        var user_id = parseInt(user['ID']);
        return this.httpClient
            .post(`${environment2.apiUrl}/check_votar_evento?user_id=${user_id}`, null)
            .pipe((result) => {
                return result;
            });
    }
}
