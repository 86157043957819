<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Cumpleaños</span>
      </div>
    </div>
  </div>
</section>
<div class="container">
  <div class="text-center mb-3">
    <h1 class="title1 text-center mb-5">Cumpleaños</h1>
  </div>
  <div class="row">
    <div class="col-lg-8 mx-auto">
      <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">{{ cumpleanos[0].mes }}</h3>
      <span class="line-title-custom-new mx-auto" data-aos="fade-up" data-aos-duration="700"
        data-aos-delay="100"></span>
      <div class="content-documents-custom-new3">
        <div class="item-document-custom-new3" *ngFor="let cumpleano of cumpleanos; let i = index">
          <h3 class="title-document-custom-new3 title-document-custom-new3-dia">{{cumpleano.dia}}</h3>
          <h3 class="title-document-custom-new3" [innerHTML]="renderTitleCumpleanos(cumpleano.post_title)"></h3>
        </div>
      </div>
    </div>
  </div>
</div>