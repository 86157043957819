<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs breadcrumbs-sections">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <a [routerLink]="['/grupos']">Grupos</a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Potencial Abastible</span>
      </div>
    </div>
  </div>
</section>
<div class="content-banner-group">
  <h1 class="title-group">{{nameGroup}}</h1>
  <img [src]="bannerGroup" alt="" class="" />
</div>
<section class="section-login-group" *ngIf="!loggedIn">
  <div class="container">
    <div class="content-buttom-login-group">
      <a href="https://intranet.abastible.cl/wp/login/?hash={{setHash}}" class="btn-login">Login</a>
    </div>
  </div>
</section>
<section class="section-login-group" *ngIf="!hasInGroup && loggedIn">
  <div class="container">
    <div class="content-has-group">
      <h3 class="title-int-group">No perteneces a este grupo</h3>
    </div>
  </div>
</section>
<section class="navbar-custom2" *ngIf="hasInGroup && loggedIn">
  <div class="container">
    <div class="col-12 home-menu">
      <span class="item-navbar-custom2 item-navbar-tab" data-id="#item-tab1">
        <h6 class="title-navbar-custom">Integrantes</h6>
      </span>
      <a [routerLink]="['/noticias-grupo/noticiasgrupospote']" class="item-navbar-custom2">
        <h6 class="title-navbar-custom">Noticias</h6>
      </a>
      <a [routerLink]="['/galerias/galeriagrupospote' ]" class="item-navbar-custom2">
        <h6 class=" title-navbar-custom">Galerías</h6>
      </a>
      <!-- <a [routerLink]="['/documentos-grupos/archivosemba' ]" class="item-navbar-custom2">
        <h6 class="title-navbar-custom">Archivos</h6>
      </a> -->
    </div>
  </div>
</section>
<section class="section-content-tab" *ngIf="hasInGroup && loggedIn">
  <div class="container">
    <div class="content-tab">
      <div class="item-tab-content-custom1" id="item-tab1" style="display: block;">
        <h3 class="title-int-group title-int-group-members" [ngClass]="{'active': showMembers}"
          (click)="showMembersList()">Integrantes
          ({{totalMembersGroup}})
          <img src="assets/img/icons/arrow-white.svg" alt="" class="arrow-down">
        </h3>
        <ul class="list-members-group" *ngIf="showMembers">
          <li class="member-group" *ngFor="let member of membersGroup; let i = index">
            <img [src]="member.avatar_url" alt="" class="avatar-img-group">
            <h3 class="title-member-group" [innerHTML]="member.user_email"></h3>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<div class="container" *ngIf="hasInGroup && loggedIn">
  <section class="box-noticias">
    <div class="box-card-design3" *ngFor="let activity of activities; let i = index" data-id="{{ activity.id }}">
      <div class="card-design3 activity-post" [ngClass]="{'activity-post-large':activity.notice_large === 'si'}">
        <div class="card-haeder">
          <div class="box-img-profile">
            <img [src]="activity.avatar_img" class="img-item-comments" alt="" />
          </div>
          <!-- <img src="assets/img/img-profile.png" alt="" class="img-profile" /> -->
          <h3 class="card-title-header">{{ activity.display_name }}</h3>
          <span class="heading-date">
            <img src="assets/img/icons/calendar.svg" alt="Calendario" />
            Publicado {{activity.date_recorded | date:'dd/MM/yyy'}}
          </span>
        </div>
        <div class="card-body" [ngClass]="{'card-body-no-logged': !loggedIn }">
          <a [routerLink]="['/noticia/' + activity.secondary_item_id ]" [queryParams]="{activityid: activity.id }"
            routerLinkActive="active"><img [src]="getActivityImage(activity.content)" alt="" class="card-img-design3"
              *ngIf="activityHasImage(activity.content)" /></a>
          <div class="card-text-design3" [ngClass]="{'card-text-design3-custom1': !activityHasImage(activity.content)}"
            *ngIf="!activity.gallery">
            <p [innerHTML]="contentFilter(activity.content)"></p>
          </div>
          <div class="card-text-design3 card-text-gallery" *ngIf="activity.gallery">
            <p [innerHTML]="contentFilter(activity.content)"></p>
            <ng-image-slider [images]="activity.gallery" #nav></ng-image-slider>
            <!-- <div class="swiper-container gallery-carousel-post" [swiper]="galleryPost.config"
              [(index)]="galleryPost.index">
              <div class="swiper-wrapper">
                <div class="img-galley  swiper-slide" *ngFor="let image of activity.gallery">
                  <img src="{{ image.image }}" alt="">
                </div>
              </div>

              <div class="swiper-button-prev">
                <img src="assets/img/icons/arrow-white.svg" alt="" />
              </div>
              <div class="swiper-button-next">
                <img src="assets/img/icons/arrow-white.svg" alt="" />
              </div>
            </div> -->
          </div>
          <div class="card-reactions-design3" *ngIf="loggedIn">
            <div class="like" (mouseenter)="
                showReactions('#actiivty-reactions-' + activity.id)
              " (mouseleave)="
                hideReactions($event, '#actiivty-reactions-' + activity.id)
              ">
              <img [src]="
                  activity.liked
                    ? getReactionIcon(activity.like_id)
                    : 'assets/img/icon-heart.svg'
                " alt="Me gusta" />
              <span class="counter-likes">{{ activity.likes }}</span>

              <div class="activity-reactions" id="actiivty-reactions-{{ activity.id }}">
                <div *ngFor="let reaction of reactions; let j = index" class="reactions-wrapper"
                  [hidden]="reaction.front !== 'checked'">
                  <span *ngIf="reaction.front === 'checked'" [ngClass]="{
                      'active-reaction': reaction.front === 'checked',
                      'selected-reaction': reaction.id === activity.like_id
                    }" (mouseenter)="hoverReaction($event)" (mouseleave)="unHoverReaction($event)"
                    (click)="likeActivity(activity.id, reaction.id)">
                    <img src="{{ reaction.img_url }}" alt="{{ reaction.name }}" class="img-fluid" />
                  </span>
                </div>
              </div>
            </div>
            <a href="#activity-comments-{{ activity.id }}" class="comments" (click)="showActivityComments(i)">
              <img src="assets/img/comment.svg" alt="" />
              <span class="counter-likes">{{
                getActivityCommentsCount(activity.comments)
                }}</span>
              <span *ngIf="getActivityCommentsCount(activity.comments) != 1"> comentarios</span>
              <span *ngIf="getActivityCommentsCount(activity.comments) == 1"> comentario</span>
            </a>
          </div>
        </div>
        <div class="card-footer card-footer-comments" *ngIf="loggedIn" id="activity-comments-{{ activity.id }}"
          [hidden]="!activity.showComments">
          <div class="item-comments" *ngFor="let comment of activity.comments">
            <div class="box-img-item-comments">
              <img [src]="comment.avatar_url" class="img-item-comments" alt="" />
            </div>
            <div class="box-content-item-comments">
              <div class="title-content-item-comments title-content-item-comments-destaqued">
                <h3>{{ comment.display_name }}</h3>
                <h6 class="date-item-comments">
                  {{ showCommentAntiquity(comment.date_recorded) }}
                </h6>
              </div>
              <div class="tags-item-comments">
                <!-- <a href="#">#Photoshoot</a>
                  <a href="#">#Smile</a>
                  <a href="#">#Beautiful</a>
                  <a href="#">#Fashion</a> -->
              </div>
              <div class="text-item-comments">
                <p [innerHTML]="parseCommentContent(comment.content)"></p>
              </div>
              <div class="box-link-like">
                <div class="like" (mouseenter)="showCommentReactions('#comment-reactions-' + comment.id)"
                  (mouseleave)="hideReactions($event, '#comment-reactions-' + comment.id)">
                  <img [src]="comment.liked ? getReactionIcon(comment.like_id) : 'assets/img/icon-heart.svg'"
                    alt="Me gusta" />
                  <span class="counter-likes">{{ comment.likes }}</span>

                  <div class="activity-reactions" id="comment-reactions-{{ comment.id }}">
                    <div *ngFor="let reaction of reactions; let j = index" class="reactions-wrapper"
                      [hidden]="reaction.front !== 'checked'">
                      <span *ngIf="reaction.front === 'checked'"
                        [ngClass]="{'active-reaction': reaction.front === 'checked', 'selected-reaction': reaction.id === comment.like_id}"
                        (mouseenter)="hoverReaction($event)" (mouseleave)="unHoverReaction($event)"
                        (click)="likeComment(activity.id,comment.id, reaction.id)">
                        <img src="{{ reaction.img_url }}" alt="{{ reaction.name }}" class="img-fluid" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item-comments item-add-comments">
            <div class="box-img-item-comments">
              <img src="{{avatar_logged}}" class="img-item-comments" alt="" />
            </div>
            <form class="box-content-item-comments" [formGroup]="commentForm" (ngSubmit)="
                uploadActivityComment(activity.secondary_item_id, activity.id)
              ">
              <div class="form-group content-box-input">
                <input type="file" (change)="fileProgress($event)" />
                <input type="text" (blur)="onBlur()" (keyup)=onCommentKeyup($event) (focus)="onFocus()"
                  placeholder="Agregar comentario" class="input-item-comments" formControlName="content"
                  id="message{{ activity.id }}" /> <button (click)="toggleEmojiPicker()" type="button"> 😀</button>
                <div class="users-list-tag" *ngIf="showListUsers">
                  <ul>
                    <li *ngFor="let user of users" (click)="tagUser(user)">{{ user.name }}</li>
                  </ul>
                </div>
                <emoji-mart class="emoji-mart" set="{{set}}" *ngIf="showEmojiPicker"
                  (emojiSelect)="addEmoji($event, 'message'+ activity.id)" title="Pick your emoji…"></emoji-mart>
              </div>
              <div *ngIf="fileUploadProgress">
                Upload progress: {{ fileUploadProgress }}
              </div>
              <div class="image-preview mb-3" *ngIf="previewUrl">
                <img [src]="previewUrl" height="300" />
              </div>

              <div class="mb-3" *ngIf="uploadedFilePath">
                {{uploadedFilePath}}
              </div>
              <button type="submit" class="orange-small-btn" [disabled]="!commentForm.valid">
                Enviar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class=" card-design3 activity-post" *ngIf="activities?.length < 1 && emptyActivities == false">
      <div class="card-haeder">
        <img src="assets/img/icons/user.svg" alt="Empty user" class="img-profile" />
        <h3 class="card-title-header loading-shimmer">Nombre vacío</h3>
      </div>
      <div class="card-body">
        <a href="#"></a>
        <div class="card-tags-design3"></div>
        <div class="card-text-design3">
          <p class="loading-shimmer">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio
            quas molestias expedita nostrum itaque soluta aspernatur iusto
            enim, tenetur, necessitatibus maxime ipsam, aperiam
            exercitationem alias repudiandae placeat iure voluptate labore.
          </p>
        </div>
        <div class="card-reactions-design3">
          <a href="#" class="like">
            <img src="assets/img/heart.svg" alt="" />
            <span class="counter-likes loading-shimmer">00</span>
          </a>
          <a href="#activity-comments-empty" class="comments">
            <img src="assets/img/comment.svg" alt="" />
            <span class="counter-likes loading-shimmer">00</span>
          </a>
        </div>
      </div>
    </div>
    <div class="empty-activities mt-5" *ngIf="emptyActivities">
      <h3 class="title-empty-activities">Este Grupo no Posee Actividades</h3>
    </div>



    <div class="box-charge-more" *ngIf="activities?.length < 1 && emptyActivities == false">
      <a class="btn-more" (click)="nextPage()">Ver Más</a>
    </div>
  </section>
</div>

<div class="modal-img-full" [ngClass]="modalImgShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullImgModal()"></div>
  <button class="close" (click)="closeFullImgModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-img-full">
    <img src="{{ modalImgSrc }}" alt="">
  </div>
</div>