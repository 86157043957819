<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <a [routerLink]="['/innovacion']"><span>Innovación</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Embajadores</span>
      </div>
    </div>
  </div>
</section>
<div class="">
  <section class="section-custom-news">
    <div class="container">
      <div class="text-center mb-3">
        <h1 class="title1 text-center mb-5">Embajadores</h1>
      </div>
      <div class="row">
        <div class="col-lg-3" *ngFor="let embajador of embajadores; let i = index">
          <div class="card-design-notice card-design-embajadores" data-aos="fade-up" data-aos-duration="700">
            <div class="card-img">
              <img src="{{ embajador.img }}" alt="">
              <div class="diagonal-cut"></div>
            </div>
            <div class="card-content">
              <h4 class="card-title">{{ embajador.title }}</h4>
              <div class="card-text">
                <p [innerHTML]="embajador.content"></p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
          <a (click)="nextPageEmbajadores()" class="btn-custom-new">
            <span>VER MÁS</span>
          </a>
        </div> -->
      </div>
    </div>
  </section>
</div>
<!-- <a [routerLink]="['/formulario-desafio7inow' ]" class="btn-mailto-inow"><img src="assets/img/icons/mail.svg"
    alt=""><span>Cuéntanos
    tu idea</span></a> -->