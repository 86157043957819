import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { GruposService } from '@services/grupos';
import { LoadingService } from '@services/loading';
import * as $ from 'jquery';

@Component({
  selector: 'app-documentos-cbp',
  templateUrl: './documentos-cbp.component.html',
  styleUrls: ['./documentos-cbp.component.scss'],
})
export class DocumentosCBPComponent implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  addClassFixed = false;
  selectedIndex: any[] = [];
  videoinBanner: any;
  sections: any;
  documentos: any;
  back_title_page: any;
  back_route_link: any;
  back_section_route_link: any;
  back_section_route_title: any;
  has_back_section = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private landingService: LandingService,
    private gruposService: GruposService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Archivos Grupos | Intranet Abastible');
    this.paramsID = 29657;
    this.slug = this.params['slug'];
    if (localStorage.getItem('back_title_page')) {
      this.back_title_page = localStorage.getItem('back_title_page');
      this.back_route_link = "https://intranet.abastible.cl/" + localStorage.getItem('back_route_link')

      if (localStorage.getItem('back_section_route_link')) {
        this.has_back_section = true;
        this.back_section_route_title = localStorage.getItem('back_section_route_title');
        this.back_section_route_link = "https://intranet.abastible.cl/group/" + localStorage.getItem('back_section_route_link');
      } else {
        this.has_back_section = false;
      }
    }
    this.gruposService.getArchivesGrupo(this.slug).subscribe(
      (res) => {
        this.documentos = res;
        console.log(this.documentos);
      },
      (err) => {
        console.error(err);
      }
    );

  }
}
