import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { ContentService } from '@services/content';
import { LoadingService } from '@services/loading';

@Component({
  selector: 'app-single-content',
  templateUrl: './single-content.component.html',
  styleUrls: ['./single-content.component.scss']
})
export class SingleContentComponent implements OnInit {
  params: any;
  titulo: any;
  slug: any;
  content: any;
  valores: any;
  paramsSlug: any;
  columns_info: any;
  productosServicios: any;
  tituloOld: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private contentService: ContentService,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private title: Title
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params;
      console.log(this.params);
    });
    if (this.params['slug'] == 'mision-vision-valores-historia-conozca-abastible') {
      this.paramsSlug = 'vision-mision-valores'
      this.title.setTitle('Misión - Visión - Valores - Historia | Intranet Abastible');
    }
    if (this.params['slug'] == 'productos-y-servicios-conozca-abastible') {
      this.paramsSlug = 'productos-y-servicios'
      this.title.setTitle('Productos y Servicios | Intranet Abastible');
      this.productosServicios = true;
    }

    if (this.params['slug'] == 'proceso-productivo-conozca-abastible') {
      this.paramsSlug = 'proceso-productivo'
      this.title.setTitle('Proceso Productivo | Intranet Abastible');
    }

    if (this.params['slug'] == 'conocenos-hacer-las-cosas-bien') {
      this.paramsSlug = 'conocenos-hacer-las-cosas-bien'
      this.title.setTitle('Conócenos Control Interno | Intranet Abastible');
    }
    if (this.params['slug'] == 'conocenos-seguridad') {
      this.paramsSlug = 'conocenos-0-cero'
      this.title.setTitle('Conócenos 0 seguridad | Intranet Abastible');
    }
    if (this.params['slug'] == 'concocenos-calidad-de-vida') {
      this.paramsSlug = 'conocenos-calidad-de-vida'
      this.title.setTitle('Conócenos Calidad de Vida | Intranet Abastible');
    }
    if (this.params['slug'] == 'conocenos-desarrollo-organizacional') {
      this.paramsSlug = 'conocenos-desarrollo-organizacional'
      this.title.setTitle('Conócenos Desarrollo Organizacional | Intranet Abastible');
    }
    if (this.params['slug'] == 'clima-organizacional-calidad-de-vida') {
      this.paramsSlug = 'clima-organizacional'
      this.title.setTitle('Clima Organizacional | Intranet Abastible');
    }
    this.contentService.getInfo(this.paramsSlug).subscribe(
      (res) => {
        this.content = res;
        this.valores = res['valores'];
        this.columns_info = res['columns_info'];
        console.log(this.content);
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
