<div *ngIf="participo == 3">
  <div class="page-trivia-ciberseguridad-before">
    <div class="page-trivia-ciberseguridad-before-content">
      <div class="page-trivia-ciberseguridad-before-content-section3">
      </div>
    </div>
  </div>
</div>
<div *ngIf="participo == 1">
  <div class="page-trivia-ciberseguridad-before">
    <div class="page-trivia-ciberseguridad-before-content">
      <div class="page-trivia-ciberseguridad-before-content-section1">
        <h3 class="page-trivia-ciberseguridad-before-content-section3-title">Ya has participado en la trivia</h3>
      </div>
    </div>
  </div>
</div>
<form [formGroup]="myForm" *ngIf="participo == 0">
  <div class="page-trivia-ciberseguridad-before">
    <div class="page-trivia-ciberseguridad-before-content">
      <div class="page-trivia-ciberseguridad-before-content-section1">
        <h3 class="page-trivia-ciberseguridad-before-content-section1-title">INGRESA TUS DATOS <br>
          PARA PARTICIPAR DEL DESAFÍO</h3>
      </div>
      <div class="page-trivia-ciberseguridad-before-content-section2">
        <div class="page-trivia-ciberseguridad-before-content-section2-items">
          <div class="page-trivia-ciberseguridad-before-content-section2-item">
            <label for="full_name" class="page-trivia-ciberseguridad-before-content-section2-item-label">Nombre
              completo:</label>
            <input type="text" name="full_name" id="full_name" formControlName="full_name"
              class="page-trivia-ciberseguridad-before-content-section2-item-input">
          </div>
          <!-- <div class="page-trivia-ciberseguridad-before-content-section2-item">
            <label for="region" class="page-trivia-ciberseguridad-before-content-section2-item-label">Región:</label>
            <select name="region" formControlName="region" id="region"
              class="page-trivia-ciberseguridad-before-content-section2-item-select">
              <option value="Región Metropolitana">Región Metropolitana</option>
              <option value="I Región de Tarapacá">I Región de Tarapacá</option>
              <option value="II Región de Antofagasta">II Región de Antofagasta</option>
              <option value="III Región de Atacama">III Región de Atacama</option>
              <option value="IV Región de Coquimbo">IV Región de Coquimbo</option>
              <option value="V Región de Valparaíso">V Región de Valparaíso</option>
              <option value="VI Región del Libertador General Bernardo O’Higgins">VI Región del Libertador General
                Bernardo O’Higgins</option>
              <option value="VII Región del Maule">VII Región del Maule</option>
              <option value="VIII Región del Bio-Bio">VIII Región del Bio-Bio</option>
              <option value="IX Región de la Araucanía">IX Región de la Araucanía</option>
              <option value="X Región de Los Lagos">X Región de Los Lagos</option>
              <option value="XI Región de Aysén del General Carlos Ibáñez del Campo">XI Región de Aysén del General
                Carlos
                Ibáñez del Campo</option>
              <option value="XI Región de Magallanes y de la Antártica Chilena">XI Región de Magallanes y de la
                Antártica
                Chilena</option>
              <option value="XIV Región de los Ríos">XIV Región de los Ríos</option>
              <option value="XV Región de Arica y Parinacota">XV Región de Arica y Parinacota</option>
              <option value="XIV Región del Ñuble">XIV Región del Ñuble</option>
            </select>
          </div> -->
          <div class="page-trivia-ciberseguridad-before-content-section2-item">
            <label for="gerencia"
              class="page-trivia-ciberseguridad-before-content-section2-item-label">Gerencia:</label>
            <select name="gerencia" formControlName="gerencia" id="gerencia"
              class="page-trivia-ciberseguridad-before-content-section2-item-select">
              <option value="Gerencia General">Gerencia General</option>
              <option value="Gerencia de Administración y Finanzas">Gerencia de Administración y Finanzas</option>
              <option value="Gerencia Comercial de Soluciones Energética">Gerencia Comercial de Soluciones Energética
              </option>
              <option value="Gerencia Riesgo Operacional">Gerencia Riesgo Operacional</option>
              <option value="Gerencia Control Interno">Gerencia Control Interno</option>
              <option value="Gerencia Sostenibilidad">Gerencia Sostenibilidad</option>
              <option value="Gerencia Legal y Asuntos Corporativos">Gerencia Legal y Asuntos Corporativos</option>
              <option value="Gerencia de Personas">Gerencia de Personas</option>
              <option value="Gerencia de Operaciones">Gerencia de Operaciones</option>
              <option value="Gerencia Comercial Distribuidores">Gerencia Comercial Distribuidores</option>
              <option value="Gerencia de Red de Distribuidores">Gerencia de Red de Distribuidores</option>
              <option value="Gerencia de Marketing">Gerencia de Marketing</option>
              <option value="Gerencia Planificación Comercial">Gerencia Planificación Comercial</option>
              <option value="Gerencia Comercial Cliente Hogar">Gerencia Comercial Cliente Hogar</option>
              <option value="Gerencia de Planificación y Control de Gestión">Gerencia de Planificación y Control de
                Gestión</option>
              <option value="Gerencia de Abastecimiento">Gerencia de Abastecimiento</option>
              <option value="Gerencia de Excelencia Operacional">Gerencia de Excelencia Operacional</option>
            </select>
          </div>
          <div class="page-trivia-ciberseguridad-before-content-section2-item">
            <label for="cargo" class="page-trivia-ciberseguridad-before-content-section2-item-label">Cargo:</label>
            <input type="text" formControlName="cargo" name="cargo" id="cargo"
              class="page-trivia-ciberseguridad-before-content-section2-item-input">
          </div>
          <div class="page-trivia-ciberseguridad-before-content-section2-item">
            <label for="dependencia"
              class="page-trivia-ciberseguridad-before-content-section2-item-label">Dependencia:</label>
            <input type="text" formControlName="dependencia" name="dependencia" id="dependencia"
              class="page-trivia-ciberseguridad-before-content-section2-item-input">
          </div>
        </div>
        <button type="button" class="page-trivia-ciberseguridad-before-content-section2-button">Ingresar</button>
      </div>
      <div class="page-trivia-ciberseguridad-content-section5">
        <img src="assets/img/trivia-ciberseguridad/img-footer.png" alt=""
          class="page-trivia-ciberseguridad-content-section5-img">
      </div>
    </div>
  </div>
  <div class="page-trivia-ciberseguridad">
    <div class="page-trivia-ciberseguridad-content">
      <div class="page-trivia-ciberseguridad-content-section1">
        <img src="assets/img/trivia-ciberseguridad/img-banner3.jpg" alt=""
          class="page-trivia-ciberseguridad-content-section1-img">
      </div>
      <div class="page-trivia-ciberseguridad-content-section2">
        <h2 class="page-trivia-ciberseguridad-content-section2-title">TE DESAFÍO A DEMOSTRAR TU CONOCIMIENTO</h2>
        <!-- <h6 class="page-trivia-ciberseguridad-content-section2-subtitle">
          En Abastible existen aproximadamente 2.600 intentos de fuerza bruta al mes sobre cuentas de colaboradores
        </h6> -->
        <div class="page-trivia-ciberseguridad-content-section2-text">
          <p>¿Cuál de las siguientes alternativas corresponde a la definición de ataque de fuerza bruta?</p>
        </div>
      </div>
      <div class="page-trivia-ciberseguridad-content-section3">
        <div class="page-trivia-ciberseguridad-content-section3-items">
          <div class="page-trivia-ciberseguridad-content-section3-item">
            <h4 class="page-trivia-ciberseguridad-content-section3-item-title">Alternativa A:</h4>
            <div class="page-trivia-ciberseguridad-content-section3-item-text">
              <p>Es un tipo de ataque que se aprovecha de una vulnerabilidad en la validación de los contenidos
                introducidos en un
                formulario web y que puede permitir la obtención, de forma ilegítima, de los datos almacenados en la
                base de datos del
                sitio web, entre ellos las credenciales de acceso.</p>
            </div>
          </div>
          <div class="page-trivia-ciberseguridad-content-section3-item">
            <h4 class="page-trivia-ciberseguridad-content-section3-item-title">Alternativa B:</h4>
            <div class="page-trivia-ciberseguridad-content-section3-item-text">
              <p>Es un procedimiento para averiguar un usuario o contraseña. Consiste en probar todas las combinaciones
                posibles hasta
                encontrar la correcta. Estas alternativas se buscan con ayuda de un software que acelera el proceso y,
                dado que se
                utiliza el método de prueba y error, puede tardarse mucho tiempo en encontrar la combinación, incluso
                miles de años.</p>
            </div>
          </div>
          <div class="page-trivia-ciberseguridad-content-section3-item">
            <h4 class="page-trivia-ciberseguridad-content-section3-item-title">Alternativa C:</h4>
            <div class="page-trivia-ciberseguridad-content-section3-item-text">
              <p>Es un tipo de software que tiene como objetivo dañar o infiltrarse sin el consentimiento de su
                propietario en un sistema
                de información.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="page-trivia-ciberseguridad-content-section4">
        <h3 class="page-trivia-ciberseguridad-content-section4-title">Elige la alternativa correcta</h3>
        <div class="page-trivia-ciberseguridad-content-section4-items">
          <div class="page-trivia-ciberseguridad-content-section4-item">
            <input type="radio" name="question_1" formControlName="question_1" id="question_1_1" value="Alternativa A"
              class="page-trivia-ciberseguridad-content-section4-item-input">
            <label for="question_1_1" class="page-trivia-ciberseguridad-content-section4-item-label">Alternativa
              A</label>
          </div>
          <div class="page-trivia-ciberseguridad-content-section4-item">
            <input type="radio" name="question_1" formControlName="question_1" id="question_1_2" value="Alternativa B"
              class="page-trivia-ciberseguridad-content-section4-item-input">
            <label for="question_1_2" class="page-trivia-ciberseguridad-content-section4-item-label">Alternativa
              B</label>
          </div>
          <div class="page-trivia-ciberseguridad-content-section4-item">
            <input type="radio" name="question_1" formControlName="question_1" id="question_1_3" value="Alternativa C"
              class="page-trivia-ciberseguridad-content-section4-item-input">
            <label for="question_1_3" class="page-trivia-ciberseguridad-content-section4-item-label">Alternativa
              C</label>
          </div>
        </div>
        <button class="page-trivia-ciberseguridad-content-section4-button">ENVIAR RESPUESTA</button>
      </div>
      <input type="hidden" name="time_form" id="time_form" formControlName="time_form" value="0">
      <div class="page-trivia-ciberseguridad-content-section5">
        <img src="assets/img/trivia-ciberseguridad/img-footer.png" alt=""
          class="page-trivia-ciberseguridad-content-section5-img">
      </div>
    </div>
  </div>

</form>
<div *ngIf="participo == 4">
  <div class="page-trivia-ciberseguridad-before">
    <div class="page-trivia-ciberseguridad-before-content">
      <div class="page-trivia-ciberseguridad-before-content-section1">
        <h3 class="page-trivia-ciberseguridad-before-content-section3-title">Tu respuesta ha sido enviada existosamente
        </h3>
      </div>
    </div>
  </div>
</div>