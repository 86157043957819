import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { NoticiasService } from '@services/noticias';
import { EventosService } from '@services/eventos';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss'],
})
export class EventosComponent implements OnInit {
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  selectedIndex: any[] = [];
  news1: any;
  news1Res: any;
  currentPage1 = 1;
  catNews = "all";
  categoriesFilter: any;
  embajadores: any;
  embajadoresRes: any;
  currentPageEventos = 1;
  mesActual: any;
  mesNext: any;
  nombreMesActual: string;
  mesNextRes: any;
  modalImg: any;
  modalTitle: any;
  modalDia: any;
  modalMes: any;
  modalContent: any;
  eventos_actuales: any;
  eventos_siguientes: any;
  modalEventos = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private NoticiasService: NoticiasService,
    private EventosService: EventosService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Eventos | Intranet Abastible');
    this.paramsID = 29622;
    this.slug = this.params['slug'];

    this.EventosService.eventosActuales().subscribe(
      (res) => {
        console.log(res);
        this.eventos_actuales = res;
      },
      (err) => {
        console.error(err);
      }
    );
    this.EventosService.eventosSiguientes().subscribe(
      (res) => {
        console.log(res);
        this.eventos_siguientes = res;
      },
      (err) => {
        console.error(err);
      }
    );

    this.EventosService.eventosTotal(1).subscribe(
      (res) => {
        this.mesActual = res[0].cumpleanos.listaEventos;
        this.nombreMesActual = res[0].cumpleanos.nombreMes;
        this.mesNext = res[0].cumpleanosNext;
        console.log(res);
        console.log(res[0]);
        console.log(res[0].cumpleanos);
      },
      (err) => {
        console.error(err);
      }
    );

  }

  nextPageEventos() {
    this.currentPageEventos = this.currentPageEventos + 1;

    this.EventosService.eventosTotal(this.currentPageEventos).subscribe(
      (res) => {
        this.mesNextRes = res[0].cumpleanosNext;
        this.mesNext = [...this.mesNext, ...this.mesNextRes];
        console.log([...this.mesNext, res[0].cumpleanosNext]);
        console.log(this.mesNextRes)
      },
      (err) => {
        console.error(err);
      }
    ); 
  }

  textMin(text: string) {
    var str = text;
    var res = str.substring(0, 200);//get first 5 chars
    return res + '...';;
  }

  contentFilter(content: string): string {
    return content.replace(/<img .*?>/g, '');
  }

  setSelected(id: number) {
    if (this.selectedIndex[id] == true) {
      this.selectedIndex[id] = false;
    } else {
      this.selectedIndex[id] = true;
    }
  }
  openModalEvento(image: string, title: string, content: string, dia: string, mes: string) {
    this.modalEventos = true;
    this.modalImg = image;
    this.modalTitle = title;
    this.modalContent = content;
    this.modalDia = dia;
    this.modalMes = mes;
  }
  closeEventosModal() {
    this.modalEventos = false;
  }
} 
