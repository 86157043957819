<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Manual de Marca</span>
      </div>
    </div>
  </div>
</section>
<section class="section-custom-new3">
  <div class="container">
    <div class="text-center mb-3">
      <h1 class="title1 text-center mb-5">Manual de Marca</h1>
    </div>
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <div class="content-documents-custom-new3">
          <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
            data-aos-duration="700" data-aos-delay="0" *ngFor="let document of documentos; let i = index">
            <h3 class="title-document-custom-new3" [innerHTML]="document.titulo"></h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="">
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="">
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>