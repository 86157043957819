import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { ActivitiesService } from '@services/activities';
import { LandingService } from '@services/landing';
import { NewsService } from '@services/news';
import { PreguntasFrecuentesService } from '@services/preguntas-frecuentes';
import { LoadingService } from '@services/loading';
import * as $ from 'jquery';

@Component({
  selector: 'app-preguntas-frecuentes',
  templateUrl: './preguntas-frecuentes.component.html',
  styleUrls: ['./preguntas-frecuentes.component.scss'],
})
export class PreguntasFrecuentesComponent implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  addClassFixed = false;
  selectedIndex: any[] = [];
  videoinBanner: any;
  sections: any;
  documentos: any;
  noticias_manual: any;
  currentPage1 = 1;
  currentPageEmbajadores = 1;
  currentPage2 = 1;
  news1: any;
  news2: any;
  news2Res: any;
  news1Res: any;
  embajadores: any;
  embajadoresRes: any;
  modalTextShow: any;
  modalText: any;
  modalTitulo: any;
  modalVideo = false;
  modalSrcVideo: any;
  questions: any;
  activities: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private landingService: LandingService,
    private NewsService: NewsService,
    private activitiesService: ActivitiesService,
    private preguntasFrecuentesService: PreguntasFrecuentesService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.title.setTitle('Preguntas Frecuentes | Intranet Abastible');
      this.preguntasFrecuentesService.getQuestions().subscribe(
        (res) => {
          this.questions = res.preguntas;
          $.each(this.questions, function (indexInArray, valueOfElement) {
            $(".items-questions").append(`
              <div class="item-question">
                <button class="btn-item-question">${valueOfElement.pregunta} <img src="assets/img/arrow-orange.svg" alt=""></button>
                <div class="item-question-content">
                  <p>${valueOfElement.respuesta}</p>
                </div>
              </div>
            `)
          });
          console.log(this.questions)
        },
        (err) => {
          console.error(err);
        }
      );
      $("body").delegate(".btn-item-question", "click", function (e) {
        e.preventDefault();
        if ($(this).hasClass("active")) {
          $(this).removeClass("active");
          $(this).next().slideUp()
        } else {
          $(".btn-item-question").removeClass("active");
          $(".item-question-content").slideUp("active");
          $(this).addClass("active");
          $(this).next().slideDown()
        }
      });
    });
  }
}
