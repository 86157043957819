<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <a [routerLink]="['/noticias-conexion-naranja']">
          Noticias conexión naranja
        </a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <a [routerLink]="['/noticias-conexion-naranja/' + term_info.slug]">
          {{term_info.name}}
        </a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>{{ post_info.post_title }}</span>
      </div>
    </div>
  </div>
</section>

<section class="page-conexion-naranja" *ngIf="post">
  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2024/05/Objeto-inteligente-vectorial.png" alt=""
    class="page-conexion-naranja-bg">
  <div class="container">
    <div class="page-conexion-naranja-container">
      <div class="page-conexion-naranja-edicion-container-content">
        <div class="page-conexion-naranja-container-banner-custom">
          <div class="page-conexion-naranja-container-banner-custom-img">
            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2024/05/Capa-2.png" alt="">
          </div>
        </div>
        <div class="page-conexion-naranja-edicion-container-content-single">
          <div class="page-conexion-naranja-edicion-container-content-single-date">
            <h6>{{ term_info.term_fields.mes }}</h6>
          </div>
          <div class="page-conexion-naranja-edicion-container-content-single-title">
            <h1>{{ post_info.post_title }}</h1>
          </div>
          <div class="page-conexion-naranja-edicion-container-content-single-image">
            <img src="{{ post_info.image }}" alt="">
          </div>
          <div class="page-conexion-naranja-edicion-container-content-single-content"
            [innerHTML]="post_info.post_content"></div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal-img-full" [ngClass]="modalImgShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullImgModal()"></div>
  <button class="close" (click)="closeFullImgModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-img-full">
    <!-- <img src="{{ modalImgSrc }}" alt=""> -->
  </div>
  <div class="navs-buttons">
    <button class="btn-prev-lightbox" *ngIf="has_prev_img" (click)="prevFullImgModal()">
      <img src="assets/img/icons/arrow-white.svg" alt="" />
    </button>

    <button class="btn-next-lightbox" *ngIf="has_next_img" (click)="nextFullImgModal()">
      <img src="assets/img/icons/arrow-white.svg" alt="" />
    </button>
  </div>
</div>

<div id="modal-video-youtube" class="modal-video" [ngClass]="modalVideoIframe == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideoIframe()"></div>
  <button class="close" (click)="closeModalVideoIframe()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <iframe width="560" height="315" [src]="urlSafe" class="iframe-video" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>