import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as $ from 'jquery';

import { PostsService } from '@services/posts';
import { ActivitiesService } from '@services/activities';
import { UsersService } from '@services/users';
import { GaleriasService } from '@services/galerias';

@Component({
  selector: 'app-single-galeria',
  templateUrl: './single-galeria.component.html',
  styleUrls: ['./single-galeria.component.scss'],
})
export class SingleGaleriaComponent implements OnInit {
  params;
  post;
  user;
  commentForm: FormGroup;
  modalImgShow = false;
  modalImgSrc: string;
  fileData: File = null;
  userLogged: any;
  avatar_logged: any;
  images: any;
  name_logged: any;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  hasActivityId = false;
  activityId: any;
  users: any;
  reactions: string[];
  showListUsers = false;
  modalShortText: string = null;
  title_gallery = "";
  galleryPost = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 3,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  back_title_page: any;
  back_route_link: any;
  back_section_route_link: any;
  back_section_route_title: any;
  has_back_section = false;
  has_next_img = false;
  has_prev_img = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private activitiesService: ActivitiesService,
    private postsService: PostsService,
    private title: Title,
    private galeriasService: GaleriasService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private usersService: UsersService,
  ) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }
  ngOnInit(): void {
    console.log(location.origin);
    if (localStorage.getItem('back_title_page')) {
      this.back_title_page = localStorage.getItem('back_title_page');
      this.back_route_link = "https://intranet.abastible.cl/" + localStorage.getItem('back_route_link')

      if (localStorage.getItem('back_section_route_link')) {
        this.has_back_section = true;
        this.back_section_route_title = localStorage.getItem('back_section_route_title');
        this.back_section_route_link = this.back_route_link + localStorage.getItem('back_section_route_link');
      } else {
        this.has_back_section = false;
      }
    }
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['title']) {
        this.title_gallery = queryParams['title'];
      }
    });
    this.activatedRoute.params.subscribe((params) => {
      this.getGallery(params['id']);
    });

  }



  getGallery(id): void {
    this.galeriasService.getSingleGallery(id).subscribe((result) => {
      console.log(result)
      this.images = result;
    });
  }

  fullImgModal(src: string, idGallery: number, idImgGallery: number, description: string) {
    console.log(idGallery);
    console.log(idImgGallery);
    $(".box-modal-img-full").html(" ");
    $("[data-gallery='gallery-single" + idGallery + "']").each(function () {
      var srcImage = $(this).attr("src");
      var idImg = $(this).attr("id");
      var description = $(this).attr("alt");
      console.log(srcImage);
      $(".box-modal-img-full").append(`
        <div id="${idImg}" class="gallery-single${idImg}-1" style="display:none;">
          <img src="${srcImage}"  data-gallery="gallery-single${idGallery}"  alt="">
          <p id="description-${idImg}">${description}</p>
        </div>
      `)
    });
    $(".box-modal-img-full #" + idImgGallery).addClass("active").fadeIn();
    if ($(".box-modal-img-full #" + idImgGallery).next().length) {
      this.has_next_img = true;
    } else {
      this.has_next_img = false;
    }
    if ($(".box-modal-img-full #" + idImgGallery).prev().length) {
      this.has_prev_img = true;
    } else {
      this.has_prev_img = false;
    }
    this.modalImgShow = true;
    // this.modalImgSrc = src;
  }

  prevFullImgModal() {
    var elemActive = $(".box-modal-img-full div.active");
    var elemPrev = elemActive.prev();
    elemActive.removeClass("active").fadeOut(300);
    setTimeout(() => {
      elemPrev.addClass("active").fadeIn();
    }, 300);
    this.has_next_img = true;
    if (elemPrev.prev().length) {
      this.has_prev_img = true;
    } else {
      this.has_prev_img = false;
    }
  }
  nextFullImgModal() {
    var elemActive = $(".box-modal-img-full div.active");
    var elemNext = elemActive.next()
    elemActive.removeClass("active").fadeOut(300);
    setTimeout(() => {
      elemNext.addClass("active").fadeIn();
    }, 300);
    this.has_prev_img = true;
    if (elemNext.next().length) {
      this.has_next_img = true;
    } else {
      this.has_next_img = false;
    }
  }
  closeFullImgModal() {
    this.modalImgShow = false;
  }

}
