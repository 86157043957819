<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs breadcrumbs-sections">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <a [routerLink]="['/grupos']">Grupos</a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Comité Paritario</span>
      </div>
    </div>
  </div>
</section>
<div class="content-banner-group">
  <h1 class="title-group">{{nameGroup}}</h1>
  <img [src]="bannerGroup" alt="" class="" />
</div>
<section class="section-login-group" *ngIf="!loggedIn">
  <div class="container">
    <div class="content-buttom-login-group">
      <a href="https://intranet.abastible.cl/wp/login/?hash={{setHash}}" class="btn-login">Login</a>
    </div>
  </div>
</section>
<section class="section-login-group" *ngIf="!hasInGroup && loggedIn">
  <div class="container">
    <div class="content-has-group">
      <h3 class="title-int-group">No perteneces a este grupo</h3>
    </div>
  </div>
</section>
<section class="navbar-custom2" *ngIf="hasInGroup && loggedIn">
  <div class="container">
    <div class="col-12 home-menu">
      <span class="item-navbar-custom2 active item-navbar-tab" data-id="#item-tab1">
        <h6 class="title-navbar-custom">Conócenos</h6>
      </span>
      <span class="item-navbar-custom2 item-navbar-tab" data-id="#item-tab2">
        <h6 class="title-navbar-custom">Misión</h6>
      </span>
      <span class="item-navbar-custom2 item-navbar-tab" data-id="#item-tab3">
        <h6 class="title-navbar-custom">Funciones</h6>
      </span>
      <span class="item-navbar-custom2 item-navbar-tab" data-id="#item-tab4">
        <h6 class="title-navbar-custom">Integrantes</h6>
      </span>
      <a [routerLink]="['/noticias-grupo/noticiascomite']" class="item-navbar-custom2">
        <h6 class="title-navbar-custom">Noticias</h6>
      </a>
      <a [routerLink]="['/galeria-grupos/galeriascomite' ]" class="item-navbar-custom2">
        <h6 class=" title-navbar-custom">Galerías</h6>
      </a>
      <a [routerLink]="['/documentos-grupos/archivoscomite' ]" class="item-navbar-custom2">
        <h6 class="title-navbar-custom">Archivos</h6>
      </a>
    </div>
  </div>
</section>
<section class="section-content-tab" *ngIf="hasInGroup && loggedIn">
  <div class="container">
    <div class="content-tab">
      <div class="item-tab-content-custom1" id="item-tab1" style="display: block;">
        <h3 class="title-int-group">Conócenos</h3>
        <h2 class="title-paritario title-objetivos">Objetivos de Comité Paritario</h2>
        <p>Si donde trabajas hay un Comité Paritario y te gustaría ser parte, primero conoce sus objetivos y
          responsabilidades. Además te contamos cuáles son los requisitos para participar.</p>
        <p>Los objetivos de un comité paritario son:</p>
        <div class="row items-paritario">
          <div class="col-md-6 card-paritario">
            <span class="span-paritario"></span>
            <div class="card-content">
              <h4 class="card-title">Asesorar e Instruir </h4>
              <p class="card-text">a los trabajadores para la correcta utilización de los elementos de protección
                personal.
              </p>
            </div>

          </div>
          <div class="col-md-6 card-paritario">
            <div class="card-content">
              <h4 class="card-title">Vigilar</h4>
              <p class="card-text">el cumplimiento, tanto por parte de las empresas como de los trabajadores, sobre las
                medidas de prevención, higiene y seguridad.</p>
            </div>
            <span class="span-paritario"></span>
          </div>
          <div class="col-md-6 card-paritario">
            <span class="span-paritario"></span>
            <div class="card-content">
              <h4 class="card-title">Investigar</h4>
              <p class="card-text">las causas de los accidentes del trabajo y enfermedades profesionales que se
                produzcan en
                la empresa.</p>
            </div>

          </div>
          <div class="col-md-6 card-paritario">
            <div class="card-content">
              <h4 class="card-title">Promover</h4>
              <p class="card-text">la realización de cursos destinados a la capacitación profesional de los
                trabajadores.</p>
            </div>
            <span class="span-paritario"></span>
          </div>
          <div class="col-md-6 card-paritario">
            <span class="span-paritario"></span>
            <div class="card-content">
              <h4 class="card-title">Cumplir</h4>
              <p class="card-text">con las demás funciones o misiones que encomiende el organismo administrador del
                seguro,
                como la ACHS.</p>
            </div>

          </div>
          <div class="col-md-6 card-paritario">
            <div class="card-content">
              <h4 class="card-title">Decidir</h4>
              <p class="card-text">si el accidente o la enfermedad profesional se debió a negligencia inexcusable del
                trabajador.</p>
            </div>
            <span class="span-paritario"></span>
          </div>
          <div class="col-md-6 card-paritario">
            <span class="span-paritario"></span>
            <div class="card-content">
              <h4 class="card-title">Adoptar medidas</h4>
              <p class="card-text">de higiene y seguridad que sirvan para la prevención de los riesgos profesionales.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="item-tab-content-custom1" id="item-tab2">
        <h3 class="title-int-group">Misión</h3>
        <h2 class="margin-cp title-paritario title-mision">Misión del Comité Paritario</h2>
        <p>Los Comités deben realizar principalmente nueve gestiones para asegurar su funcionamiento y velar por el
          bienestar
          de los trabajadores.</p>
        <div class="row items-paritario items-mision">
          <div class="col-md-6 card-paritario">
            <span class="span-paritario"></span>
            <div class="card-content">
              <h4 class="card-title">Autogestión</h4>
              <p class="card-text">Es importante que se establezcan normas escritas de funcionamiento, así como realizar
                diagnósticos de las situaciones de riesgos. Se deben comunicar y difundir las acciones del comité, y
                desarrollar programas de trabajos escritos, controlados y evaluados.</p>
            </div>

          </div>
          <div class="col-md-6 card-paritario">
            <div class="card-content">
              <h4 class="card-title">Estrategia de difusión</h4>
              <p class="card-text">La comunicación es una gran herramienta de gestión para los Comités Paritarios. La
                intranet, el uso de las revistas, pizarras, volantes, boletines, afiches, trípticos, u otras
                herramientas son
                fundamentales para el éxito de su ejercicio.</p>
            </div>
            <span class="span-paritario"></span>
          </div>
          <div class="col-md-6 card-paritario">
            <span class="span-paritario"></span>
            <div class="card-content">
              <h4 class="card-title">Participación</h4>
              <p class="card-text">Es importante impulsar y ejercer la participación de todas las instancias de la
                empresa en
                las actividades del Comité. También es posible generar interés a nivel externa, como a nivel municipal o
                de
                actores sociales relacionados, directa o indirectamente, con la seguridad. </p>
            </div>

          </div>
          <div class="col-md-6 card-paritario">
            <div class="card-content">
              <h4 class="card-title">Orientación de funcionarios y directivos</h4>
              <p class="card-text">Se debe informar sobre el acceso a prestaciones médicas, económicas y preventivas a
                todas
                las instancias de la empresa, así como en los procedimientos de denuncia y reclamo.</p>
            </div>
            <span class="span-paritario"></span>
          </div>
          <div class="col-md-6 card-paritario">
            <span class="span-paritario"></span>
            <div class="card-content">
              <h4 class="card-title">Coordinación Permanente</h4>
              <p class="card-text">A nivel interno, es importante que todos los Comités estén coordinados entre ellos y
                con
                las unidades de apoyo, el área de comunicaciones de la empresa, servicios generales, contratos,
                departamento
                de mantención, administración de personal, entre otras. A nivel externo, es básico que trabajen en
                conjunto
                con la ACHS, bomberos, el Consejo Nacional de Seguridad (CNS), empresas contratistas, entre otros.</p>
            </div>

          </div>
          <div class="col-md-6 card-paritario">
            <div class="card-content">
              <h4 class="card-title">Capacitación</h4>
              <p class="card-text">Se debe impulsar la capacitación permanentemente tanto de los integrantes del comité,
                como
                de los funcionarios de la empresa, que en el futuro podrían ser integrantes. También es importante la
                participación de los directivos y de la línea jerárquica.</p>
            </div>
            <span class="span-paritario"></span>
          </div>
          <div class="col-md-6 card-paritario">
            <span class="span-paritario"></span>
            <div class="card-content">
              <h4 class="card-title">Establecimiento de alianzas</h4>
              <p class="card-text">Es fundamental que se generen lazos con aquellas unidades internas y externas,
                públicas o
                privadas, que persiguen objetivos similares y que van en el Comité Paritario, una instancia de apoyo
                mutuo.
              </p>
            </div>
          </div>
          <div class="col-md-6 card-paritario">
            <div class="card-content">
              <h4 class="card-title">Fuentes de financiamiento</h4>
              <p class="card-text">A nivel interno, se puede buscar apoyo en las áreas de proyectos, finanzas y recursos
                humanos. A nivel externo es posible gestionar recursos en concursos del Consejo Nacional de Seguridad,
                Premios
                de Mutualidades, entre otros.</p>
            </div>
            <span class="span-paritario"></span>
          </div>
          <div class="col-md-6 card-paritario">
            <span class="span-paritario"></span>
            <div class="card-content">
              <h4 class="card-title">Principios Éticos</h4>
              <p class="card-text">La motivación y el actuar del Comité siempre deben estar guiados por un propósito
                único: la
                seguridad, el bienestar y la salud de los funcionarios.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="item-tab-content-custom1" id="item-tab3">
        <h3 class="title-int-group">Funciones</h3>
        <h2 class="margin-cp title-paritario title-funciones">Funciones del Comité Paritario</h2>
        <p>Es una obligación legal que se conforme un comité paritario en cualquier lugar de trabajo con más de 25
          personas.
          ¿Cuáles son sus tareas? </p>
        <div class="card-articulo-funciones">
          <img src="https://intranet.abastible.cl/wp/wp-content/themes/intranet-abastible/assets/img/auction.png" alt=""
            class="card-img" width="64" height="64">
          <p class="card-text">El Articulo 66 de la Ley N° 16.744, en relación con el Artículo 24 del D.S N° 54 del año
            1968,
            Creado por el Ministerio del trabajo y previsión social, establece que las funciones de los Comités
            Paritarios de
            higiene y seguridad son las siguientes:</p>
        </div>
        <p><b>¿Cuáles son las funciones del Comité Paritario?</b></p>
        <div class="row items-paritario items-funciones">
          <div class="col-md-6 card-paritario">
            <span class="span-paritario"></span>
            <div class="card-content">
              <h4 class="card-title">Asesorar e instruir</h4>
              <p class="card-text">a los trabajadores para la correcta utilización de los elementos de protección
                personal.
              </p>
            </div>
          </div>
          <div class="col-md-6 card-paritario">
            <div class="card-content">
              <h4 class="card-title">Investigar </h4>
              <p class="card-text">las causas de los accidentes del trabajo y enfermedades profesionales que se
                produzcan en
                la empresa.</p>
            </div>
            <span class="span-paritario"></span>
          </div>
          <div class="col-md-6 card-paritario">
            <span class="span-paritario"></span>
            <div class="card-content">
              <h4 class="card-title">Indicar </h4>
              <p class="card-text">la adopción de todas las medidas de higiene y seguridad que sirvan para la prevención
                de
                riesgos profesionales.</p>
            </div>
          </div>
          <div class="col-md-6 card-paritario">
            <div class="card-content">
              <h4 class="card-title">Promover</h4>
              <p class="card-text">la realización de cursos destinados a la capacitación profesional de los trabajadores
                en
                organismos públicos o privados autorizados para cumplir con esa finalidad. También pueden realizarse en
                la
                misma empresa bajo el control y dirección de dichos organismos.</p>
            </div>
            <span class="span-paritario"></span>
          </div>
          <div class="col-md-6 card-paritario">
            <span class="span-paritario"></span>
            <div class="card-content">
              <h4 class="card-title">Vigilar </h4>
              <p class="card-text">el cumplimiento, tanto por parte de las empresas como de los trabajadoresde las
                medidas de
                prevención, higiene y seguridad.</p>
            </div>
          </div>
          <div class="col-md-6 card-paritario">
            <div class="card-content">
              <h4 class="card-title">Decidir </h4>
              <p class="card-text">si el accidente o la enfermedad profesional se debió a negligencia inexcusable.</p>
            </div>
            <span class="span-paritario"></span>
          </div>
          <div class="col-md-6 card-paritario">
            <span class="span-paritario"></span>
            <div class="card-content">
              <h4 class="card-title">Cumplir </h4>
              <p class="card-text">las demás funciones o misiones que se encomiende el organismo administrador
                respectivo.</p>
            </div>

          </div>
        </div>
      </div>
      <div class="item-tab-content-custom1" id="item-tab4">
        <h3 class="title-int-group">Integrantes ({{totalMembersGroup}})</h3>
        <ul class="list-members-group">
          <li class="member-group" *ngFor="let member of membersGroup; let i = index">
            <img [src]="member.avatar_url" alt="" class="avatar-img-group">
            <h3 class="title-member-group" [innerHTML]="member.user_email"></h3>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<div class="container" *ngIf="hasInGroup && loggedIn">
  <section class="box-noticias">
    <div class="box-card-design3" *ngFor="let activity of activities; let i = index" data-id="{{ activity.id }}">
      <div class="card-design3 activity-post" [ngClass]="{'activity-post-large':activity.notice_large === 'si'}">
        <div class="card-haeder">
          <div class="box-img-profile">
            <img [src]="activity.avatar_img" class="img-item-comments" alt="" />
          </div>
          <!-- <img src="assets/img/img-profile.png" alt="" class="img-profile" /> -->
          <h3 class="card-title-header">{{ activity.display_name }}</h3>
          <span class="heading-date">
            <img src="assets/img/icons/calendar.svg" alt="Calendario" />
            Publicado {{activity.date_recorded | date:'dd/MM/yyy'}}
          </span>
        </div>
        <div class="card-body" [ngClass]="{'card-body-no-logged': !loggedIn }">
          <a [routerLink]="['/noticia/' + activity.secondary_item_id ]" [queryParams]="{activityid: activity.id }"
            routerLinkActive="active"><img [src]="getActivityImage(activity.content)" alt="" class="card-img-design3"
              *ngIf="activityHasImage(activity.content)" /></a>
          <div class="card-text-design3" [ngClass]="{'card-text-design3-custom1': !activityHasImage(activity.content)}"
            *ngIf="!activity.gallery">
            <p [innerHTML]="contentFilter(activity.content)"></p>
          </div>
          <div class="card-text-design3 card-text-gallery" *ngIf="activity.gallery">
            <p [innerHTML]="contentFilter(activity.content)"></p>
            <ng-image-slider [images]="activity.gallery" #nav></ng-image-slider>
            <!-- <div class="swiper-container gallery-carousel-post" [swiper]="galleryPost.config"
                [(index)]="galleryPost.index">
                <div class="swiper-wrapper">
                  <div class="img-galley  swiper-slide" *ngFor="let image of activity.gallery">
                    <img src="{{ image.image }}" alt="">
                  </div>
                </div>
  
                <div class="swiper-button-prev">
                  <img src="assets/img/icons/arrow-white.svg" alt="" />
                </div>
                <div class="swiper-button-next">
                  <img src="assets/img/icons/arrow-white.svg" alt="" />
                </div>
              </div> -->
          </div>
          <div class="card-reactions-design3" *ngIf="loggedIn">
            <div class="like" (mouseenter)="
                  showReactions('#actiivty-reactions-' + activity.id)
                " (mouseleave)="
                  hideReactions($event, '#actiivty-reactions-' + activity.id)
                ">
              <img [src]="
                    activity.liked
                      ? getReactionIcon(activity.like_id)
                      : 'assets/img/icon-heart.svg'
                  " alt="Me gusta" />
              <span class="counter-likes">{{ activity.likes }}</span>

              <div class="activity-reactions" id="actiivty-reactions-{{ activity.id }}">
                <div *ngFor="let reaction of reactions; let j = index" class="reactions-wrapper"
                  [hidden]="reaction.front !== 'checked'">
                  <span *ngIf="reaction.front === 'checked'" [ngClass]="{
                        'active-reaction': reaction.front === 'checked',
                        'selected-reaction': reaction.id === activity.like_id
                      }" (mouseenter)="hoverReaction($event)" (mouseleave)="unHoverReaction($event)"
                    (click)="likeActivity(activity.id, reaction.id)">
                    <img src="{{ reaction.img_url }}" alt="{{ reaction.name }}" class="img-fluid" />
                  </span>
                </div>
              </div>
            </div>
            <a href="#activity-comments-{{ activity.id }}" class="comments" (click)="showActivityComments(i)">
              <img src="assets/img/comment.svg" alt="" />
              <span class="counter-likes">{{
                getActivityCommentsCount(activity.comments)
                }}</span>
              <span *ngIf="getActivityCommentsCount(activity.comments) != 1"> comentarios</span>
              <span *ngIf="getActivityCommentsCount(activity.comments) == 1"> comentario</span>
            </a>
          </div>
        </div>
        <div class="card-footer card-footer-comments" *ngIf="loggedIn" id="activity-comments-{{ activity.id }}"
          [hidden]="!activity.showComments">
          <div class="item-comments" *ngFor="let comment of activity.comments">
            <div class="box-img-item-comments">
              <img [src]="comment.avatar_url" class="img-item-comments" alt="" />
            </div>
            <div class="box-content-item-comments">
              <div class="title-content-item-comments title-content-item-comments-destaqued">
                <h3>{{ comment.display_name }}</h3>
                <h6 class="date-item-comments">
                  {{ showCommentAntiquity(comment.date_recorded) }}
                </h6>
              </div>
              <div class="tags-item-comments">
                <!-- <a href="#">#Photoshoot</a>
                    <a href="#">#Smile</a>
                    <a href="#">#Beautiful</a>
                    <a href="#">#Fashion</a> -->
              </div>
              <div class="text-item-comments">
                <p [innerHTML]="parseCommentContent(comment.content)"></p>
              </div>
              <div class="box-link-like">
                <div class="like" (mouseenter)="showCommentReactions('#comment-reactions-' + comment.id)"
                  (mouseleave)="hideReactions($event, '#comment-reactions-' + comment.id)">
                  <img [src]="comment.liked ? getReactionIcon(comment.like_id) : 'assets/img/icon-heart.svg'"
                    alt="Me gusta" />
                  <span class="counter-likes">{{ comment.likes }}</span>

                  <div class="activity-reactions" id="comment-reactions-{{ comment.id }}">
                    <div *ngFor="let reaction of reactions; let j = index" class="reactions-wrapper"
                      [hidden]="reaction.front !== 'checked'">
                      <span *ngIf="reaction.front === 'checked'"
                        [ngClass]="{'active-reaction': reaction.front === 'checked', 'selected-reaction': reaction.id === comment.like_id}"
                        (mouseenter)="hoverReaction($event)" (mouseleave)="unHoverReaction($event)"
                        (click)="likeComment(activity.id,comment.id, reaction.id)">
                        <img src="{{ reaction.img_url }}" alt="{{ reaction.name }}" class="img-fluid" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item-comments item-add-comments">
            <div class="box-img-item-comments">
              <img src="{{avatar_logged}}" class="img-item-comments" alt="" />
            </div>
            <form class="box-content-item-comments" [formGroup]="commentForm" (ngSubmit)="
                  uploadActivityComment(activity.secondary_item_id, activity.id)
                ">
              <div class="form-group content-box-input">
                <input type="file" (change)="fileProgress($event)" />
                <input type="text" (blur)="onBlur()" (keyup)=onCommentKeyup($event) (focus)="onFocus()"
                  placeholder="Agregar comentario" class="input-item-comments" formControlName="content"
                  id="message{{ activity.id }}" /> <button (click)="toggleEmojiPicker()" type="button"> 😀</button>
                <div class="users-list-tag" *ngIf="showListUsers">
                  <ul>
                    <li *ngFor="let user of users" (click)="tagUser(user)">{{ user.name }}</li>
                  </ul>
                </div>
                <emoji-mart class="emoji-mart" set="{{set}}" *ngIf="showEmojiPicker"
                  (emojiSelect)="addEmoji($event, 'message'+ activity.id)" title="Pick your emoji…"></emoji-mart>
              </div>
              <div *ngIf="fileUploadProgress">
                Upload progress: {{ fileUploadProgress }}
              </div>
              <div class="image-preview mb-3" *ngIf="previewUrl">
                <img [src]="previewUrl" height="300" />
              </div>

              <div class="mb-3" *ngIf="uploadedFilePath">
                {{uploadedFilePath}}
              </div>
              <button type="submit" class="orange-small-btn" [disabled]="!commentForm.valid">
                Enviar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class=" card-design3 activity-post" *ngIf="activities?.length < 1">
      <div class="card-haeder">
        <img src="assets/img/icons/user.svg" alt="Empty user" class="img-profile" />
        <h3 class="card-title-header loading-shimmer">Nombre vacío</h3>
      </div>
      <div class="card-body">
        <a href="#"></a>
        <div class="card-tags-design3"></div>
        <div class="card-text-design3">
          <p class="loading-shimmer">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio
            quas molestias expedita nostrum itaque soluta aspernatur iusto
            enim, tenetur, necessitatibus maxime ipsam, aperiam
            exercitationem alias repudiandae placeat iure voluptate labore.
          </p>
        </div>
        <div class="card-reactions-design3">
          <a href="#" class="like">
            <img src="assets/img/heart.svg" alt="" />
            <span class="counter-likes loading-shimmer">00</span>
          </a>
          <a href="#activity-comments-empty" class="comments">
            <img src="assets/img/comment.svg" alt="" />
            <span class="counter-likes loading-shimmer">00</span>
          </a>
        </div>
      </div>
    </div>

    <div class="box-charge-more">
      <a class="btn-more" (click)="nextPage()">Ver Más</a>
    </div>
  </section>
</div>

<div class="modal-img-full" [ngClass]="modalImgShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullImgModal()"></div>
  <button class="close" (click)="closeFullImgModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-img-full">
    <img src="{{ modalImgSrc }}" alt="">
  </div>
</div>