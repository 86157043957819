<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Links</span>
      </div>
    </div>
  </div>
</section>
<section class="links-section">
  <div class="container">
    <div class="text-center mb-3">
      <h1 class="title1 text-center mb-5">Links</h1>
    </div>
    <div class="grid-descubre">
      <div class="grid-item">
        <!-- <a href="http://150.10.1.75/abast/" target="_blank" class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/bo_ventas.jpg" alt=""
            class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">BO Ventas</h3>
          </div>
        </a> -->
        <a href="http://siga.abastible.cl" target="_blank" class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/siga.jpg" alt=""
            class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">SIGA</h3>
          </div>
        </a>
      </div>
      <div class="grid-item">
        <a href="http://200.68.56.244/PDAAutogas/" target="_blank" class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/bo_glp_motors.jpg" alt=""
            class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">BO GLP Motors</h3>
          </div>
        </a>
        <a href="http://bogranel.abastible.cl:8080/BO_FTGR_Boleta/index.html" target="_blank" class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/bo_medidor.jpg" alt=""
            class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">BO Granel</h3>
          </div>
        </a>
      </div>
      <div class="grid-item">
        <a href="http://200.68.56.244/abast/reportes/principal.php" target="_blank" class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/gestion_ventas.jpg" alt=""
            class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">Gestión de Ventas PDA</h3>
          </div>
        </a>
      </div>
    </div>
    <div class="grid-descubre grid-descubre-invert">
      <div class="grid-item">
        <a href="http://10.10.1.14/controlprod/" target="_blank" class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/produccion.jpg" alt=""
            class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">BO Producción</h3>
          </div>
        </a>
      </div>
      <div class="grid-item">
        <a href="https://hcm19.sapsf.com/login?company=abastibles&loginMethod=PWD" target="_blank"
          class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/9c42e6aafac5fb1d8bef89ebb2cd920a-1.png"
            alt="" class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">Sistema Gestión de Desempeño</h3>
          </div>
        </a>
        <a href="http://shpapp01:8890" target="_blank" class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/cartas_oficios.jpg" alt=""
            class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">Portal de Cartas y Oficios</h3>
          </div>
        </a>
        <!-- <a href="http://bi.abastible.cl/qlikview/login.htm" target="_blank" class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/bussiness.jpg" alt=""
            class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">Business Intelligence</h3>
          </div>
        </a> -->
      </div>
      <div class="grid-item">
        <a href="http://vestuario/vestuario/view/principal/administrative/home.seam?scid=24118" target="_blank"
          class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/ABAS_C10277_2007_2007-1.jpg" alt=""
            class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">Web Vestuario</h3>
          </div>
        </a>
        <!-- <a href="http://SGV.abastible.cl" target="_blank" class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/gestion_vehiculos.jpg" alt=""
            class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">Sistema de Gestión de Vehículos</h3>
          </div>
        </a> -->
      </div>
    </div>
    <div class="grid-descubre grid-descubre-invert">
      <div class="grid-item">
        <a href="http://shpapp01:9110/sites/portalpersonas/Pages/default.aspx" target="_blank" class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/05/2.png" alt=""
            class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">Portal Personas</h3>
          </div>
        </a>
      </div>
      <div class="grid-item">
        <a href="http://shpapp01:9100/sites/autogas/Pages/default.aspx" target="_blank" class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/autogas.jpg" alt=""
            class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">Portal Autogas</h3>
          </div>
        </a>
        <a href="http://abagrcprdci.abastible.cl:8000/nwbc/" target="_blank" class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/unnamed.jpg" alt=""
            class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">SAP GRC</h3>
          </div>
        </a>
      </div>
      <div class="grid-item">
        <!-- <a href="http://vestuario/vestuario/view/principal/administrative/home.seam?scid=24118" target="_blank"
          class="card-descubre">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/ABAS_C10277_2007_2007-1.jpg" alt=""
            class="card-descubre-img" />
          <div class="box-text">
            <h3 class="card-descubre-title">Web Vestuario</h3>
          </div>
        </a> -->
      </div>
    </div>
  </div>
</section>