import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

// Services
import { AuthService } from '@services/auth';
import { ContactoService } from '@services/contacto';
import { LoadingService } from '@services/loading';

@Component({
  selector: 'app-contactanos',
  templateUrl: './contactanos.component.html',
  styleUrls: ['./contactanos.component.scss'],
})
export class ContactanosPageComponent implements OnInit {
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  commentForm: FormGroup;
  userLogged: any;
  avatar_logged: any;
  name_logged: any;
  state: any;
  paramsID: any;
  resultForm: any;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  form: FormGroup;
  modalSuccess = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private title: Title,
    private http: HttpClient,
    private contactoService: ContactoService
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('Contáctanos | Intranet Abastible');
    this.activatedRoute.params.subscribe((params) => {
      console.log(this.params)
    });
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      name: ['', [Validators.required, Validators.minLength(3)]],
      message: ['', [Validators.required]]
    });
  }
  submitForm() {
    if (this.form.invalid) {
      this.form.setValue({
        email: '',
        name: '',
        message: ''
      });
      return;
    }
    var formData: any = new FormData();
    formData.append("name", this.form.get('name').value);
    formData.append("email", this.form.get('email').value);
    formData.append("message", this.form.get('message').value);

    this.contactoService.sendMailContact(formData).subscribe(
      (res) => {
        console.log(res);
        this.form.setValue({
          email: '',
          name: '',
          message: ''
        });
        this.successModal();
      },
      (err) => {
        console.error(err);
      }
    );

  }
  successModal() {
    this.modalSuccess = true;
  }
  closeSuccessModal() {
    this.modalSuccess = false;
  }
} 
