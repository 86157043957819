import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InnovacionService {

    constructor(private httpClient: HttpClient) { }
    getEmbajadores(paged: number): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/embajadores/?paged=${paged}`, null)
            .pipe((result) => {
                return result;
            });
    }
    getDuenosProcesos(): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/get-duenos-de-procesos`, null)
            .pipe((result) => {
                return result;
            });
    }
    getInowTalks(): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/i-now-talks/`, null)
            .pipe((result) => {
                return result;
            });
    }
    getVideosInow(): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/videos-i-now/`, null)
            .pipe((result) => {
                return result;
            });
    }
}
