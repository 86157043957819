import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { NewsService } from '@services/news';
import { GaleriasService } from '@services/galerias';
import { LoadingService } from '@services/loading';
import * as $ from 'jquery';

@Component({
  selector: 'app-redirect-home',
  templateUrl: './redirect-home.component.html',
  styleUrls: ['./redirect-home.component.scss'],
})
export class RedirectHomeComponent implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private galeriasService: GaleriasService,
    private landingService: LandingService,
    private NewsService: NewsService,
    private title: Title,
    private sanitized: DomSanitizer,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
    });
  }
}
