import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as $ from 'jquery';
import { AppComponent } from '../../../../app.component';

import { PostsService } from '@services/posts';
import { ActivitiesService } from '@services/activities';
import { UsersService } from '@services/users';

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.scss'],
})
export class SinglePostComponent implements OnInit {
  params;
  post;
  user;
  commentForm: FormGroup;
  modalImgShow = false;
  modalImgSrc: string;
  fileData: File = null;
  userLogged: any;
  avatar_logged: any;
  name_logged: any;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  hasActivityId = false;
  activityId: any;
  users: any;
  reactions: string[];
  showListUsers = false;
  post_title = "  ";
  galleryPost = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 3,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  name = 'Angular';
  message = '';
  showEmojiPicker = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set = 'twitter';
  loggedIn = false;
  back_title_page: any;
  back_route_link: any;
  has_back_section = false;
  back_section_route_link: any;
  back_section_route_title: any;
  has_next_img = false;
  has_prev_img = false;
  items: any;
  reactions_list_comment: any;
  modalReactionComment = false;
  reactions_list: any;
  modalReaction = false;
  logged_user_id: any;
  urlIframe: any;
  activities: string[];
  modalImgSingleShow: any;
  modalVideo = false;
  modalSrcVideo: any;
  urlSafe: SafeResourceUrl;
  modalVideoIframe = false;
  modalSrcVideoIframe: string = "";
  constructor(
    private activatedRoute: ActivatedRoute,
    private activitiesService: ActivitiesService,
    private postsService: PostsService,
    private title: Title,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private usersService: UsersService,
    public sanitizer: DomSanitizer,
    private appComponent: AppComponent,
    @Inject(Location) private readonly location: Location
  ) {

    this.commentForm = this.formBuilder.group({
      content: this.formBuilder.control(null, [
        Validators.required,
        Validators.minLength(1),
      ]),
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('back_title_page')) {
      this.back_title_page = localStorage.getItem('back_title_page');
      this.back_route_link = "https://intranet.abastible.cl/" + localStorage.getItem('back_route_link')

      if (localStorage.getItem('back_section_route_link')) {
        this.has_back_section = true;
        this.back_section_route_title = localStorage.getItem('back_section_route_title');
        this.back_section_route_link = this.back_route_link + localStorage.getItem('back_section_route_link');
      } else {
        this.has_back_section = false;
      }
    }
    this.appComponent.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.userLogged = JSON.parse(localStorage.getItem('user'));
        this.avatar_logged = this.userLogged['avatar_url'];
        this.name_logged = this.userLogged['display_name'];
        this.logged_user_id = this.userLogged['ID'];
        console.log(this.name_logged);
      }
      this.loggedIn = loggedIn;
    });
    this.usersService.getUsers().subscribe(
      (res: any) => {
        console.log(res);
        this.items = res;
      },
      (err) => {
        console.error(err);
      }
    );
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['activityid']) {
        this.activityId = queryParams['activityid'];
        this.hasActivityId = true;
      }
    });
    this.activatedRoute.params.subscribe((params) => {
      if (this.hasActivityId) {
        this.getPost(params['slug']);
      } else {
        this.getPost(params['slug']);
      }
    });

  }

  getUrlSafeYoutube(youtube_id: any) {
    let prevText = "https://www.youtube.com/embed/";
    let srcVideo = prevText + youtube_id;
    return this.sanitizer.bypassSecurityTrustResourceUrl(srcVideo);
  }
  fullImgSingleModal(src: string) {
    $(".box-modal-img-full").html("");
    $(".box-modal-img-full").append(`
      <img src="${src}" alt="">
    `)
    this.modalImgSingleShow = true;
    // this.modalImgSrc = src;
  }
  closeFullImgSingleModal() {
    this.modalImgSingleShow = false;
  }
  fullImgModal(src: string, idGallery: number, idImgGallery: number) {
    console.log(idGallery);
    console.log(idImgGallery);
    $(".box-modal-img-full").html(" ");
    $("[data-gallery='gallery-single" + idGallery + "']").each(function () {
      var srcImage = $(this).attr("src");
      var idImg = $(this).attr("id");
      var description = $(this).attr("alt");
      console.log(srcImage);
      $(".box-modal-img-full").append(`
        <div id="${idImg}" class="gallery-single${idImg}-1" style="display:none;">
          <img src="${srcImage}"  data-gallery="gallery-single${idGallery}"  alt="">
          <p id="description-${idImg}">${description}</p>
        </div>
      `)
    });
    $(".box-modal-img-full #img-gallery-single" + idImgGallery).addClass("active").fadeIn();
    if ($(".box-modal-img-full #img-gallery-single" + idImgGallery).next().length) {
      this.has_next_img = true;
    } else {
      this.has_next_img = false;
    }
    if ($(".box-modal-img-full #img-gallery-single" + idImgGallery).prev().length) {
      this.has_prev_img = true;
    } else {
      this.has_prev_img = false;
    }
    this.modalImgShow = true;
    // this.modalImgSrc = src;
  }
  prevFullImgModal() {
    var elemActive = $(".box-modal-img-full div.active");
    var elemPrev = elemActive.prev();
    elemActive.removeClass("active").fadeOut(300);
    setTimeout(() => {
      elemPrev.addClass("active").fadeIn();
    }, 300);
    this.has_next_img = true;
    if (elemPrev.prev().length) {
      this.has_prev_img = true;
    } else {
      this.has_prev_img = false;
    }
  }
  nextFullImgModal() {
    var elemActive = $(".box-modal-img-full div.active");
    var elemNext = elemActive.next()
    elemActive.removeClass("active").fadeOut(300);
    setTimeout(() => {
      elemNext.addClass("active").fadeIn();
    }, 300);
    this.has_prev_img = true;
    if (elemNext.next().length) {
      this.has_next_img = true;
    } else {
      this.has_next_img = false;
    }
  }
  closeFullImgModal() {
    this.modalImgShow = false;
  }
  getPost(slug): void {
    this.postsService.getPost(slug).subscribe((result) => {
      this.post = result;
      this.post_title = result.post_title;
      this.reactions = result['reactions'];
      this.urlIframe = this.getUrlSafeYoutube(result.youtube_id);
      this.title.setTitle(result.post_title + ' | Intranet Abastible');
      setTimeout(() => {

        $(".col-content-single-page a").attr("target", "_blank");
        var mainImage = $(".body-image").attr("src");
        $(".col-content-single-page a").attr("target", "_blank");
        $(".col-content-single-page a[href='" + mainImage + "']").fadeOut();
      }, 1000);
      console.log(result);
    });
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }
  preview() {
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }
  contentFilter(content: string): string {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = content.replace(/<img .*?>/g, '');
    return tmp.textContent || tmp.innerText || "";
  }
  uploadPostComment(post_id) {
    const user = JSON.parse(localStorage.getItem('user'));
    const user_id = parseInt(user['ID']);
    if (this.previewUrl == null) {
      const content = this.commentForm.controls.content.value;
      this.postsService
        .uploadComment(
          post_id,
          user['display_name'],
          user['user_email'],
          user_id,
          content
        )
        .subscribe(
          (comments) => {
            this.post['comments'] = comments;
            this.commentForm.reset();
          },
          (err) => {
            console.error(err);
          }
        );

      return;

    } else {
      const formData = new FormData();
      var fileData64 = this.previewUrl;
      var tipeFile = this.fileData['type'];
      var fileUrlNoData64 = fileData64.replace("data:" + tipeFile + ";base64,", "");
      formData.append('file', this.previewUrl);
      formData.append('name', this.fileData['name']);
      formData.append('type', this.fileData['type']);
      formData.append('urlImg', fileUrlNoData64);
      console.log(this.fileData);
      this.http.post('https://intranet.abastible.cl/wp/wp-json/v2/upload-img/', formData)
        .subscribe(res => {
          console.log(res);
          const content = this.commentForm.value.content + `<img src="${res}" class="img-content-comment">`;
          this.postsService
            .uploadComment(
              post_id,
              user['display_name'],
              user['user_email'],
              user_id,
              content
            )
            .subscribe(
              (comments) => {
                this.post['comments'] = comments;
                this.commentForm.reset();
                this.fileData = null;
                this.previewUrl = null;
                this.fileUploadProgress = null;
                this.uploadedFilePath = null;
              },
              (err) => {
                console.error(err);
              }
            );

          return;
        })
    }



    return;
  }
  showCommentAntiquity(timestamp: string): string {
    const diff = moment().diff(moment(timestamp, 'YYYY-MM-DD HH:mm:ss'));
    const duration = moment.duration(diff);

    const diffInHours = duration.asHours();
    const diffInMinutes = duration.asMinutes();
    const diffInDays = duration.asDays();
    const diffInMonths = duration.asMonths();

    if (diffInMinutes < 3) {
      return `Hace un momento`;
    } else if (diffInHours < 1) {
      return `Hace ${parseInt(diffInMinutes.toString())}mins`;
    } else if (diffInHours > 1 && diffInDays < 1) {
      return `Hace ${parseInt(diffInHours.toString())}h`;
    } else if (diffInDays >= 1 && diffInMonths < 1) {
      return `Hace ${parseInt(diffInDays.toString())}d`;
    } else if (diffInMonths >= 1) {
      const formatedDate = moment(timestamp, 'YYYY-MM-DD HH:mm:ss').format(
        'DD/MM/YYYY'
      );

      return formatedDate;
    }
  }
  parseCommentContent(content: string): string {
    return content.replace(/\n/g, '<br />');
  }
  isPostLoaded(): boolean {
    return typeof this.post !== 'undefined';
  }
  getPostDate(): string {
    return moment(this.post.post_date, 'YYYY-MM-DD HH:mm:ss').format(
      'DD/MM/YYYY'
    );
  }
  deleteActivity(activity_id: any) {
    this.activitiesService.deleteActivity(activity_id).subscribe(
      (res: any) => {
        console.log(res);
        document.getElementById('activity' + activity_id).remove()
      },
      (err) => {
        console.error(err);
      }
    );
  }
  likeComment(comment_id, reaction_id): void {
    const user = JSON.parse(localStorage.getItem('user'));
    const user_id = parseInt(user['ID']);
    this.activitiesService.likeComment(comment_id, reaction_id).subscribe(
      (res) => {
        console.log(res)
        const currentCommentIntIndex = this.post['comments'].findIndex(
          (element) => element['id'] == comment_id
        );
        this.post['comments'][currentCommentIntIndex]['likes'] = res.reaction_count
          ? res.reaction_count
          : 0;
        this.post['comments'][currentCommentIntIndex]['liked'] = res.liked;
        this.post['comments'][currentCommentIntIndex]['like_id'] = reaction_id;
      },
      (err) => {
        console.error(err);
      }
    );

    return;
  }
  likeActivity(activity_id, reaction_id): void {
    this.activitiesService.likeActivity(activity_id, reaction_id).subscribe(
      (res) => {
        console.log(res);

        this.post['likes'] = res.reaction_count
          ? res.reaction_count
          : 0;
        this.post['liked'] = res.liked;
        this.post['like_id'] = reaction_id;
      },
      (err) => {
        console.error(err);
      }
    );

    return;
  }
  getReactionIcon(reaction_id): string {
    const reactionIndex = this.reactions.findIndex(
      (element) => element['id'] == reaction_id
    );

    return this.reactions[reactionIndex]['img_url'];
  }
  hoverReaction(event): void {
    event.stopPropagation();

    anime({
      targets: event.target,
      scale: 1.2,
      duration: 500,
    });
  }
  unHoverReaction(event): void {
    event.stopPropagation();

    anime({
      targets: event.target,
      scale: 1,
      duration: 300,
    });
  }
  showReactions(element: string): void {
    const el = document.querySelector(element) as HTMLElement;
    const reactions = el.querySelectorAll('.active-reaction');

    anime.remove(element);

    anime({
      targets: element,
      opacity: [0, 1],
      delay: 200,
      duration: 1200,
      begin: function () {
        el.style.display = 'block';
      },
    });

    anime({
      targets: reactions,
      translateY: [10, 0],
      opacity: [0, 1],
      delay: anime.stagger(100, { start: 300 }),
      duration: 700,
      easing: 'easeOutBack',
    });
  }
  showCommentReactions(element: string): void {
    const el = document.querySelector(element) as HTMLElement;
    const reactions = el.querySelectorAll('.active-reaction');

    anime.remove(element);

    anime({
      targets: element,
      opacity: [0, 1],
      delay: 200,
      duration: 1200,
      begin: function () {
        el.style.display = 'block';
      },
    });

    anime({
      targets: reactions,
      translateY: [10, 0],
      opacity: [0, 1],
      delay: anime.stagger(100, { start: 300 }),
      duration: 700,
      easing: 'easeOutBack',
    });
  }
  hideReactions(event, element: string): void {
    const isChild = this.isChildOf(
      document.getElementById(element),
      event.toElement
    );

    if (isChild) {
      return;
    }

    anime.remove(element);

    anime({
      targets: element,
      opacity: 0,
      delay: 200,
      duration: 800,
      complete: function () {
        const el = document.querySelector(element) as HTMLElement;
        const reactions = el.querySelectorAll('.active-reaction');

        el.style.display = 'none';
        reactions.forEach(function (item: HTMLElement) {
          item.style.opacity = '0';
          item.style.transform = 'translateY(10px)';
        });
      },
    });
  }
  isChildOf(parent, child) {
    let node = child.parentNode;

    while (node != null) {
      if (node == parent) {
        return true;
      }
      node = node.parentNode;
    }

    return false;
  }
  onCommentKeyup(event) {
    const value = event.target.value;
    let lastCharacter = value.charAt(value.length - 1);
    console.log(event.keyCode)
    if (event.key == '@') {
      this.usersService.getUsers().subscribe(
        (res) => {
          this.users = res;
          this.showListUsers = true;
        },
        (err) => {
          console.error(err);
        }
      );
    } else if (event.keyCode == 8 && lastCharacter === '@') {
      this.usersService.getUsers().subscribe(
        (res) => {
          this.users = res;
          this.showListUsers = true;
        },
        (err) => {
          console.error(err);
        }
      );
    } else {
      this.showListUsers = false;
    }
    return false;
  }
  tagUser(user) {
    let commentValue = this.commentForm.controls.content.value;

    this.commentForm.controls.content.setValue(`${commentValue}${user.name}`);
    this.showListUsers = false;
  }
  toggleEmojiPicker() {
    console.log(this.showEmojiPicker);
    this.showEmojiPicker = !this.showEmojiPicker;
  }
  addEmoji(event, idmessage) {
    console.log($(event).next());
    const { message } = this;
    const text = `${event.emoji.native}`;
    this.message = text;
    let commentValue = this.commentForm.controls.content.value;
    this.commentForm.controls.content.setValue(`${commentValue}${this.message}`);
    // this.showEmojiPicker = false;
  }
  onFocus() {
    console.log('focus');
    this.showEmojiPicker = false;
  }
  onBlur() {
    console.log('onblur')
  }
  showModalReactionsComments(event, comment_id: any) {
    event.stopPropagation();

    this.activitiesService.getListReactionsComment(comment_id).subscribe(
      (res: any) => {
        console.log(res);
        this.reactions_list_comment = res;
        this.modalReactionComment = true;
      },
      (err) => {
        console.error(err);
      }
    );
  }
  showModalReactions(event, activity_id: any) {
    event.stopPropagation();

    this.activitiesService.getListReactions(activity_id).subscribe(
      (res: any) => {
        console.log(res);
        this.reactions_list = res;
        this.modalReaction = true;
      },
      (err) => {
        console.error(err);
      }
    );
  }
  closeModalReactionsComment() {
    this.modalReactionComment = false;
    $("#modal-reactions-list-comment").removeClass("active-modal");
  }
  closeModalReactions() {
    this.modalReaction = false;
    $("#modal-reactions-list").removeClass("active-modal");
  }

  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }

  showModalVideoIframe(event, srcVideo: string) {
    event.stopPropagation();
    let prevText = "https://www.youtube.com/embed/";
    this.modalVideoIframe = true;
    this.modalSrcVideoIframe = prevText + srcVideo;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
  }
  closeModalVideoIframe() {
    this.modalVideoIframe = false;
    this.modalSrcVideoIframe = '';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
    $("#modal-video-youtube").removeClass("active-modal");
  }
}
