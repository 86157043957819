<div class="bg-white bg-white-custom pb-0">
  <section class="section-desafio6">
    <div class="box-header-desafio6 mb-4">
      <a [routerLink]="['/noticia/78924']"><img
          src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/02/header.jpg" class="img-fluid" alt="" /></a>
    </div>
    <section id="breadcrumbs">
      <div class="container">
        <div class="breadcrumbs breadcrumbs-sections">
          <div class="breadcrumbs-item">
            <a [routerLink]="['/muro']"><span class="material-icons">
                home
              </span></a>
            <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
          </div>
          <div class="breadcrumbs-item">
            <a [routerLink]="['/innovacion']">Innovación</a>
            <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
          </div>
          <div class="breadcrumbs-item">
            <span>Somos innovación, tu eres innovación</span>
          </div>
        </div>
      </div>
    </section>
    <div class="box-body-desafio6">
      <div class="container">
        <div class="box-videos-desafio6">
          <div class="item-video-desafio6-1 item-video-desafio6">
            <div class="box-video-desafio6">
              <img src="assets/img/video-1-desafio6.jpg" class="video-preview-desafio6" alt="" />
              <button class="btn-play-video">
                <img src="assets/img/icon-play.png" alt="" />
              </button>
              <video controls>
                <source src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/iNow-2021-v10-1.mp4" />
              </video>
            </div>
          </div>
          <div class="item-video-desafio6-2 item-video-desafio6">
            <div class="box-video-desafio6">
              <img src="assets/img/video-2-desafio6.jpg" class="video-preview-desafio6" alt="" />
              <button class="btn-play-video">
                <img src="assets/img/icon-play.png" alt="" />
              </button>
              <video controls>
                <source src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/iNow-2021-v5-HI-1.mp4" />
              </video>
            </div>
          </div>
        </div>
        <div class="box-text-desafio6">
          <p>
            Este año i-NOW tiene como objetivo continuar con el traspaso de
            capacidades y metodologías relacionadas a la innovación. Para esto,
            se pondrán a disposición 5 cápsulas de la metodología Pensamiento
            Sistemático Inventivo (SIT), desarrollada para ayudar a las
            organizaciones a lograr sus objetivos, sistematizando la
            creatividad. Éstas capsulas ya están disponibles en la plataforma
            AULA y serán una herramienta de ayuda para generar nuevas
            iniciativas.
          </p>
          <p>
            El desafío de este año permitirá que todos los colaboradores de
            Abastible puedan participar de forma independiente, postulando sus
            ideas con foco en 2 categorías.
          </p>
          <div class="items-desafio6-custom1">
            <div class="item-desafio6-custom1">
              <h4 class="title-desafio6-custom1">Categoría 1</h4>
              <p>
                <b>Generación de nuevos productos y/o servicios:</b> Buscamos el
                próximo producto o servicio que irrumpa en el mercado,
                posicionando a Abastible como una empresa líder, que se preocupa
                y trabaja por el bienestar de sus clientes.
              </p>
            </div>
            <div class="item-desafio6-custom1">
              <h4 class="title-desafio6-custom1">Categoría 2</h4>
              <p>
                <b>Soluciones innovadoras que mejoren/optimicen procesos o
                  aborden alguna problemática de la empresa:</b>
                Queremos nuevas propuestas para solucionar problemáticas de la
                empresa, o generar eficiencias mediante el rediseño de procesos
                operativos, administrativos u otros.
              </p>
            </div>
            <div class="item-desafio6-custom1-full">
              <div class="box-text-item-desafio6-custom1-full">
                <p>
                  <b>PUEDES POSTULAR TODAS LAS IDEAS QUE QUIERAS EN AMBAS
                    CATEGORÍAS</b>
                </p>
              </div>
            </div>
          </div>
          <p class="text-center">
            <b>El desafío está compuesto por 3 etapas que se desarrollarán
              durante todo el año.</b>
          </p>
          <div class="box-desafio6-custom1">
            <div class="overlay-desafio-custom1"></div>
            <div class="box-header-desafio6-custom1">
              <div class="items-header-desafio6-custom1">
                <div class="item-header-desafio6-custom1">
                  <h3 class="title-header-desafio-custom1">
                    etapa <span>1</span>
                  </h3>
                  <img src="assets/img/icon-bombillo.png" alt="" />
                </div>
                <div class="item-header-desafio6-custom1">
                  <h3 class="title-header-desafio-custom1">
                    etapa <span>2</span>
                  </h3>
                  <img src="assets/img/icon-bombillo.png" alt="" />
                </div>
                <div class="item-header-desafio6-custom1">
                  <h3 class="title-header-desafio-custom1">
                    etapa <span>3</span>
                  </h3>
                  <img src="assets/img/icon-bombillo.png" alt="" />
                </div>
              </div>
            </div>
            <div class="box-body-desafio6-custom1">
              <div class="items-body-desafio6-custom1">
                <div class="item-body-desafio6-custom1">
                  <p>
                    <b>Encuentra tu idea:</b> : En esta etapa debes postular tus
                    ideas innovadoras. Puedes inscribir la idea en una categoría
                    o en ambas.
                  </p>
                  <h6>¡Participa las veces que quieras!</h6>
                </div>
                <div class="item-body-desafio6-custom1">
                  <p>
                    <b>Ahora es una solución:</b> Si tu idea quedó entre las
                    seleccionadas, ahora tendrás que explicar cómo la
                    desarrollarías, calcular beneficios, formas de
                    implementación, tecnologías, etc.
                  </p>
                </div>
                <div class="item-body-desafio6-custom1">
                  <p>
                    <b>Última milla:</b> Esta es la recta final, en la que los
                    participantes deberán afinar detalles, y preparar la
                    presentación que harán frente al Comité de Gerentes. En esta
                    presentación, seleccionaremos a dos ganadores, uno por cada
                    categoría del Desafío i-NOW 2021.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-11 mx-auto">
              <div class="box-desafio6-custom2">
                <div class="box-img-desafio6-custom2">
                  <img src="assets/img/icon-desafio6.png" alt="" />
                </div>
                <div class="box-text-desafio6-custom2">
                  <p>Las ideas serán evaluadas según 3 criterios:</p>
                  <ul>
                    <li>
                      <b>Novedosas:</b> Esto significa que sean soluciones
                      nuevas para la compañía
                    </li>
                    <li><b>Viables:</b> Deben ser factibles de implementar</li>
                    <li>
                      <b>Valor:</b> Estas soluciones deben generar un beneficio
                      cuantificable para la empresa
                    </li>
                  </ul>
                </div>
              </div>
              <h3 class="title-desafio6-custom2">¿Cómo participar?</h3>
              <p>
                Con el objetivo de facilitar la participación por parte de los
                colaboradores, existirán dos canales de ingreso de ideas:
              </p>
              <p>
                <b>Físico:</b> En todas las plantas y oficinas se va a disponer
                de formularios impresos para ingresar las ideas que serán
                facilitados por los embajadores i-NOW. Las ideas podrán ser
                entregadas a través del embajador o enviando una foto del
                formulario vía WhatsApp al número +569 4442 4862.
              </p>
              <p>
                <b>Digital:</b> Puntos virtuales en los que los colaboradores
                podrán ingresar sus ideas. Estos son: Tótems, Sección i-NOW en
                Intranet y Correo i-NOW.
              </p>
              <div class="box-desafio6-custom3">
                <img src="assets/img/icon-bombillo.png" alt="" />
                <div class="box-text-desafio6-custom3">
                  <p>
                    RECUERDA QUE PUEDES POSTULAR <br /><b>TODAS LAS IDEAS QUE QUIERAS</b>EN AMBAS CATEGORÍAS
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="box-desafio6-custom4">
            <h3 class="title-desafio6-custom3">Ganadores</h3>
            <p>
              <b>El desafío tendrá a dos ganadores, uno por cada categoría de
                ideas.</b>
            </p>
            <div class="box-desafio6-custom4-int">
              <p>
                El ganador de cada categoría del concurso i-NOW 2021 obtendrá
                <b>un gran premio de $1.000.000 de pesos</b>
              </p>
            </div>
            <p>
              Además, en cada una de las evaluaciones (Cierre Etapa 1 y cierre
              Etapa 2) se va a premiar a los seleccionados que pasen a la
              siguiente etapa (premios menores).
            </p>
          </div>
          <div class="box-buttons-desafio6">
            <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/Bases-2021-i-NOW.pdf"
              class="btn-design1" target="_blank">Bases del Desafio</a>
            <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2021/06/formulario-intranet-1.pdf"
              class="btn-design1" target="_blank">Formulario de ideación para descargar</a>
          </div>
        </div>
        <section class="section-custom5">
          <div class="container">
            <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">
              Ideas (111)
            </h3>
            <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
            <div class="row row-ideas-desafio6">
              <div class="col-lg-3" *ngFor="let idea of ideas; let i = index">
                <div class="card-design-notice card-design-notice-desafio {{
                    idea.semifinalista
                  }}" (click)="
                    openModalEvento(
                      idea.title,
                      idea.content,
                      idea.autor,
                      idea.beneficios,
                      idea.retos,
                      idea.categoria_de_desafio_6,
                      idea.semifinalista
                    )
                  ">
                  <div class="card-img" *ngIf="
                      idea.categoria_de_desafio_6 ==
                        'Oportunidad de mejora y solución de problemas' &&
                      idea.semifinalista != 1
                    ">
                    <img [src]="'assets/img/cat1-desafio6.png'" alt="" />
                    <div class="diagonal-cut"></div>
                  </div>
                  <div class="card-img" *ngIf="
                      idea.categoria_de_desafio_6 ==
                        'Oportunidad de mejora y solución de problemas' &&
                      idea.semifinalista == 1
                    ">
                    <img [src]="'assets/img/oportunidad-de-mejora2.png'" alt="" />
                    <div class="diagonal-cut"></div>
                  </div>
                  <div class="card-img" *ngIf="
                      idea.categoria_de_desafio_6 ==
                        'Nuevos Productos o Servicios' &&
                      idea.semifinalista != 1
                    ">
                    <img [src]="'assets/img/cat2-desafio6.png'" alt="" />
                    <div class="diagonal-cut"></div>
                  </div>
                  <div class="card-img" *ngIf="
                      idea.categoria_de_desafio_6 ==
                        'Nuevos Productos o Servicios' &&
                      idea.semifinalista == 1
                    ">
                    <img [src]="'assets/img/nuevos-productos2.png'" alt="" />
                    <div class="diagonal-cut"></div>
                  </div>
                  <div class="card-content">
                    <h4 class="card-title" [innerHTML]="
                        idea.title.length > 40
                          ? (idea.title | slice: 0:40) + '..'
                          : idea.title
                      "></h4>
                    <div class="card-text">
                      <p [innerHTML]="
                          idea.content.length > 100
                            ? (idea.content | slice: 0:100) + '..'
                            : idea.content
                        ">
                        En la actualidad existe un apoyo de Abastible para
                        realizar…
                      </p>
                    </div>
                    <a class="card-link">Leer más</a>
                  </div>
                </div>
              </div>
              <div class="col-12 box-btn-news" *ngIf="hideVerMas" data-aos="fade-up" data-aos-duration="700"
                data-aos-delay="100">
                <a (click)="nextPage()" class="btn-custom-new">
                  <span>VER MÁS</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="box-footer-desafio6">
      <img src="assets/img/bottom-desafio6.jpg" class="img-fluid" alt="" />
    </div>
  </section>
</div>
<!-- <a [routerLink]="['/formulario-desafio6' ]" class="btn-mailto-inow"><img src="assets/img/icons/mail.svg" alt=""><span>Cuéntanos
    tu idea</span></a> -->
<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>

<div id="modal-eventos" class="modal-eventos" *ngIf="modalEventos">
  <div class="overlay-modal" (click)="closeEventosModal()"></div>
  <div class="modal-content">
    <button type="button" class="close" aria-label="Close" (click)="closeEventosModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body modal-body-eventos">
      <img [src]="modalImage" alt="" class="img-modal" />
      <div class="box-content-body-modal">
        <h2 class="title-modal" [innerHTML]="modalTitle"></h2>
        <h6 class="subtitle-modal">Autor: {{ modalAutor }}</h6>
        <h6 class="subtitle-modal" *ngIf="categoria_de_desafio_6">
          Categoría: {{ categoria_de_desafio_6 }}
        </h6>
        <h5 class="title-int-modal">Descripción</h5>
        <div class="box-text-modal" [innerHTML]="modalContent"></div>
        <h5 class="title-int-modal">Beneficios</h5>
        <div class="box-text-modal" [innerHTML]="beneficiosContent"></div>
        <h5 class="title-int-modal">Retos</h5>
        <div class="box-text-modal" [innerHTML]="retosContent"></div>
      </div>
    </div>
  </div>
</div>