<app-header *ngIf="!true"></app-header>
<app-header-new *ngIf="true"></app-header-new>

<div [ngClass]="{ active: sideMenuOpen }" class="box-toggle-white">
    <div (click)="toggleMenu()" class="overlay-toggle"></div>
    <button (click)="toggleMenu()" class="btn-floating btn-design-float">
        <span></span>
    </button>
    <div class="content-box">
        <ul class="list-content-box">
            <li>
                <a [routerLink]="['/conoce-abastible']" routerlinkactive="active"><img alt=""
                        src="assets/img/icons/icon-new-8.png">Conoce Abastible</a>
            </li>
            <li>
                <a [routerLink]="['/gobierno-corporativo']" routerlinkactive="active"><img alt=""
                        src="assets/img/icons/gobierno-corporativo@2x.png">Gobierno Corporativo</a>
            </li>
            <!-- <li>
                <a [routerLink]="['/control-interno']" routerlinkactive="active"><img alt=""
                        src="assets/img/icons/icon-new-9.png">Control Interno</a>
            </li> -->
            <li>
                <a [routerLink]="['/desarrollo-organizacional']" routerlinkactive="active"><img alt=""
                        src="assets/img/icons/icon-desarrollo-organizacional.svg">Desarrollo Organizacional</a>
            </li>
            <li>
                <a [routerLink]="['/seguridad']" routerlinkactive="active"><img alt=""
                        src="assets/img/icons/icon-seguridad-new.svg">Seguridad</a>
            </li>
            <li>
                <a [routerLink]="['/beneficios']" routerlinkactive="active"><img alt="" src="
                        assets/img/icons/beneficios@2x.png">Beneficios</a>
            </li>
            <li>
                <a [routerLink]="['/innovacion']"><img alt="" src="assets/img/icons/icon-inow.svg">Soluciones
                    Energéticas</a>
            </li>
            <li>
                <a [routerLink]="['/sostenibilidad']"><img alt=""
                        src="assets/img/icon-sostenibilidad.png">Sostenibilidad</a>
            </li>
        </ul>
    </div>
</div>

<div class="bg-body" [ngClass]="{ 'content-muro-new': true }">
    <div [@routerAnimation]="o.isActivated ? o.activatedRoute : ''">
        <router-outlet #o="outlet"></router-outlet>
    </div>
</div>
<app-footer></app-footer>