import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DesafioService {

    constructor(private httpClient: HttpClient) { }
    getIdeas(id_desafio: number, page: number): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/ideas-desafios/?id_desafio=${id_desafio}&page=${page}`, null)
            .pipe((result) => {
                return result;
            });
    }
    getIdeasDesafio6(page: number): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/ideas-desafio6/?&page=${page}`, null)
            .pipe((result) => {
                return result;
            });
    }
    getIdeasDesafio7(page: number): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/ideas-desafio7/?&page=${page}`, null)
            .pipe((result) => {
                return result;
            });
    }
    getIdeasGanadoras(id_desafio: number): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/ideas-desafios-ganadoras/?id_desafio=${id_desafio}`, null)
            .pipe((result) => {
                return result;
            });
    }
    getIdeasQuick(id_desafio: number): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/ideas-desafios-quick/?id_desafio=${id_desafio}`, null)
            .pipe((result) => {
                return result;
            });
    }
    getIdeasProblemas(id_desafio: number): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/ideas-desafios-problemas/?id_desafio=${id_desafio}`, null)
            .pipe((result) => {
                return result;
            });
    }
    getSolucionesDesafio4(): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/soluciones-desafio4`, null)
            .pipe((result) => {
                return result;
            });
    }
    getProcesosDesafio4(): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/procesos-desafio4`, null)
            .pipe((result) => {
                return result;
            });
    }
    getVideosDesafio4(): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/videos-desafio4`, null)
            .pipe((result) => {
                return result;
            });
    }
    sendFormDesafio6(formData: any): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/form-desafio6`, formData)
            .pipe((result) => {
                return result;
            });
    }
    sendFormDesafio7(formData: any): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/form-desafio7`, formData)
            .pipe((result) => {
                return result;
            });
    }
}
