import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { NewsService } from '@services/news';
import { InnovacionService } from '@services/innovacion';
import { DesafioService } from '@services/desafio';
import { LoadingService } from '@services/loading';
import * as $ from 'jquery';

@Component({
  selector: 'app-ideas-desafio6',
  templateUrl: './ideas-desafio6.component.html',
  styleUrls: ['./ideas-desafio6.component.scss'],
})
export class IdeasDesafios6Component implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  addClassFixed = false;
  selectedIndex: any[] = [];
  linksScrollSwiper = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        570: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  videoinBanner: any;
  sections: any;
  documentos: any;
  noticias_manual: any;
  currentPage1 = 1;
  currentPageEmbajadores = 1;
  currentPage2 = 1;
  news1: any;
  news2: any;
  news2Res: any;
  news1Res: any;
  embajadores: any;
  embajadoresRes: any;
  modalTextShow: any;
  modalText: any;
  modalTitulo: any;
  carouselGalerias2 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  modalVideo = false;
  modalSrcVideo: any;
  currentPage = 1;
  modalEventos = false;
  ideas: any;
  ideas_ganadoras: any;
  ideas_quick: any;
  ideasRes: any;
  modalTitle: string;
  modalContent: string;
  modalAutor: string;
  modalImage: string;
  beneficiosContent: string;
  retosContent: string;
  categoria_de_desafio_6: string;
  ideas_problemas: any;
  hideVerMas = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private landingService: LandingService,
    private NewsService: NewsService,
    private InnovacionService: InnovacionService,
    private desafioService: DesafioService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Somos innovación, tu eres innovación | Intranet Abastible');
    this.paramsID = 23094;
    this.slug = this.params['slug'];
    $(".btn-play-video").click(function (e) {
      e.preventDefault();
      $(this).fadeOut();
      $(this).parent().find("video").fadeIn();
      $(this).parent().find("video").get(0).play();
    });

    this.desafioService.getIdeasDesafio6(1).subscribe(
      (res) => {
        this.ideas = res;
        console.log(res)
      },
      (err) => {
        console.error(err);
      }
    );

  }
  nextPage() {
    this.currentPage = this.currentPage + 1;

    this.desafioService.getIdeasDesafio6(this.currentPage).subscribe(
      (res) => {
        this.ideasRes = res;
        console.log(this.ideasRes.length)
        this.ideas = [...this.ideas, ...this.ideasRes];
        if (this.ideasRes.length < 20) {
          this.hideVerMas = false;
        }
        console.log([...this.ideas, res]);
        console.log(this.ideasRes)
      },
      (err) => {
        console.error(err);
      }
    );
  }
  openModalEvento(title: string, content: string, autor: string, beneficiosContent: string, retosContent: string, categoria_de_desafio_6: string,semifinalista:string) {
    this.modalEventos = true;
    this.modalTitle = title;
    this.modalContent = content;
    this.modalAutor = autor;
    this.beneficiosContent = beneficiosContent;
    this.retosContent = retosContent;
    this.categoria_de_desafio_6 = categoria_de_desafio_6;
    this.modalImage = 'assets/img/bg-orange.png';
    if(categoria_de_desafio_6 == 'Oportunidad de mejora y solución de problemas' && semifinalista != "1"){
      this.modalImage = 'assets/img/cat1-desafio6.png'
    }
    if(categoria_de_desafio_6 == 'Oportunidad de mejora y solución de problemas' && semifinalista == "1"){
      this.modalImage = 'assets/img/oportunidad-de-mejora2.png'
    }
    if(categoria_de_desafio_6 == 'Nuevos Productos o Servicios' && semifinalista != "1"){
      this.modalImage = 'assets/img/cat2-desafio6.png'
    }
    if(categoria_de_desafio_6 == 'Nuevos Productos o Servicios' && semifinalista == "1"){
      this.modalImage = 'assets/img/nuevos-productos2.png'
    }
  }
  closeEventosModal() {
    this.modalEventos = false;
  }

  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }
}
