<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>MAS Abastible</span>
      </div>
    </div>
  </div>
</section>
<section class="section-custom-new3">
  <div class="container">
    <div class="text-center mb-3">
      <h1 class="title1 text-center mb-5">MAS Abastible</h1>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-3">
        <a href="https://mas.abastible.cl/" target="_blank" class="card-links">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/mas-icon-2.png" alt=""
            class="card-links-img" />
          <div class="box-text">
            <h3 class="card-links-title">Acceso web MAS</h3>
          </div>
        </a>
      </div>
      <div class="col-lg-3">
        <a href="https://hcm19.sapsf.com/login?company=abastibles&loginMethod=PWD" target="_blank" class="card-links">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/mas-icon-2.png" alt=""
            class="card-links-img" />
          <div class="box-text">
            <h3 class="card-links-title">E-learning MAS</h3>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>