<section class="page-proposito">
  <div class="page-proposito-banner">
    <a [routerLink]="['/emprendimientos']">
      <!-- <img src="assets/img/banner-proposito.jpg" alt="" class="page-proposito-banner-full"> -->
      <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/09/Banner-Emprendedores.jpg" alt=""
        class="page-proposito-banner-full">
    </a>
    <!-- <img src="assets/img/cut-proposito.png" alt="" class="page-proposito-banner-img"> -->
    <div class="page-proposito-banner-box-title">
      <h3 class="page-proposito-banner-title">PROPÓSITO</h3>
    </div>
  </div>
  <section id="breadcrumbs">
    <div class="container">
      <div class="breadcrumbs">
        <div class="breadcrumbs-item">
          <a [routerLink]="['/muro']"><span class="material-icons">
              home
            </span></a>
          <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
        </div>
        <div class="breadcrumbs-item">
          <span>Propósito</span>
        </div>
      </div>
    </div>
  </section>
  <div class="page-proposito-seccion1">
    <div class="container">
      <div class="page-proposito-seccion1-content">
        <div class="page-proposito-seccion1-content-full">
          <h3 class="page-proposito-seccion1-content-full-title">"POTENCIAR EL ESPÍRITU EMPRENDEDOR"</h3>
          <div class="page-proposito-seccion1-content-full-text">
            <p>Las Pymes generan más de <b>4 millones de puestos de trabajo</b>, <br>más de la mitad del total de
              trabajadores en Chile</p>
          </div>
          <!-- <a href="javascript:;" (click)="showModalVideoIframe($event, 'khWEm2Jgf4c')"
            class="btn-design1 page-proposito-seccion1-content-full-btn">Mira el video aquí</a> -->
        </div>
        <div class="page-proposito-seccion1-content-right">
          <h3 class="page-proposito-seccion1-content-right-title">Cada pyme es una historia y en cada una hay un
            valiente.</h3>
          <div class="page-proposito-seccion1-content-right-text">
            <p>Agobiadas por la adversidad, ser pyme no es fácil. Cada paso que dan es una amenaza constante. <br>Las
              pymes
              dan la vida por
              salir adelante y generan más de la mitad de los trabajos de Chile. ¿Qué haríamos sin ellas? <br>Por el
              bien de
              todos, es
              fundamental potenciar a las pymes para que logren salir adelante.</p>
            <p>Abastible ha realizado y continuará trabajando su propuesta de valor fundada en base a un ecosistema de
              pymes.  A través
              de cientos de ellas, <br>recibe, transporta, envasa, instala, distribuye y presta un servicio de
              excelencia
              que ha sido
              preferido y premiado por sus clientes.</p>
          </div>
        </div>
        <div class="page-proposito-seccion1-content-left">
          <div class="swiper-container" [swiper]="carouselCustom.config">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="box-content-video-proposito" (click)="showModalVideoIframe($event, 'odMiUmRbTy8')">
                  <img
                    src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/12/Screenshot-2023-12-21-073821.jpg"
                    alt="" class="box-content-video-proposito-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                    class="btn-play-desing1">
                </div>
              </div>
              <div class="swiper-slide">
                <div class="box-content-video-proposito" (click)="showModalVideoIframe($event, 'ahUJuP82QJ8')">
                  <img
                    src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/12/Screenshot-2023-12-21-073854.jpg"
                    alt="" class="box-content-video-proposito-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                    class="btn-play-desing1">
                </div>
              </div>
              <div class="swiper-slide">
                <div class="box-content-video-proposito" (click)="showModalVideoIframe($event, 'wK_Q7sY49rU')">
                  <img
                    src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/12/Screenshot-2023-12-21-073950.jpg"
                    alt="" class="box-content-video-proposito-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                    class="btn-play-desing1">
                </div>
              </div>
              <div class="swiper-slide">
                <div class="box-content-video-proposito"
                  (click)="showModalVideo($event, 'https://intranet.abastible.cl/wp/wp-content/uploads/2023/12/PROPOOSITO_PYMES__POTENCIAR-low.mp4')">
                  <img
                    src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/12/Screenshot-2023-12-21-074014.jpg"
                    alt="" class="box-content-video-proposito-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                    class="btn-play-desing1">
                </div>
              </div>
            </div>
            <div class="swiper-button-prev">
              <span class="swiper-button-arrow"></span>
            </div>
            <div class="swiper-button-next">
              <span class="swiper-button-arrow"></span>
            </div>
          </div>
          <!-- <div class="box-content-video-proposito" (click)="showModalVideoIframe($event, 'wK_Q7sY49rU')">
            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/05/Screenshot-2023-05-08-110430.png"
              alt="" class="box-content-video-proposito-img">
            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
              class="btn-play-desing1">
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="page-proposito-seccion2">
    <div class="container">
      <div class="page-proposito-seccion2-content">
        <h2 class="page-proposito-seccion2-content-title"><b>Pilares</b> que guían nuestro nuevo <b>Propósito</b></h2>
        <div class="page-proposito-seccion2-content-items">
          <a [routerLink]="['/noticia/88174']" class="page-proposito-seccion2-content-item">
            <div class="page-proposito-seccion2-content-item-img">
              <img src="assets/img/img-proposito1.png" alt="">
            </div>
            <h6 class="page-proposito-seccion2-content-item-number">1</h6>
            <div class="page-proposito-seccion2-content-item-text">
              <h3 class="page-proposito-seccion2-content-item-title">Soy proactivo para <b>crear valor</b> y cuidadoso
                para protegerlo.</h3>
            </div>
          </a>
          <a [routerLink]="['/noticia/88174']" class="page-proposito-seccion2-content-item">
            <div class="page-proposito-seccion2-content-item-text">
              <h3 class="page-proposito-seccion2-content-item-title"><b>Innovo y experimento</b>, atreviéndome a
                trabajar de manera distinta.</h3>
            </div>
            <h6 class="page-proposito-seccion2-content-item-number">2</h6>
            <div class="page-proposito-seccion2-content-item-img">
              <img src="assets/img/img-proposito2.png" alt="">
            </div>
          </a>
          <a [routerLink]="['/noticia/88174']" class="page-proposito-seccion2-content-item">
            <div class="page-proposito-seccion2-content-item-img">
              <img src="assets/img/img-proposito3.png" alt="">
            </div>
            <h6 class="page-proposito-seccion2-content-item-number">3</h6>
            <div class="page-proposito-seccion2-content-item-text">
              <h3 class="page-proposito-seccion2-content-item-title"><b>Colaboro</b> con mis colegas, proveedores,
                distribuidores, clientes y el ecosistema, <b>poniéndome en el lugar del otro</b>.</h3>
            </div>
          </a>
          <a [routerLink]="['/noticia/88174']" class="page-proposito-seccion2-content-item">
            <div class="page-proposito-seccion2-content-item-text">
              <h3 class="page-proposito-seccion2-content-item-title"><b>Persevero y soy optimista</b> incluso en los
                periodos difíciles.</h3>
            </div>
            <h6 class="page-proposito-seccion2-content-item-number">4</h6>
            <div class="page-proposito-seccion2-content-item-img">
              <img src="assets/img/img-proposito4.png" alt="">
            </div>
          </a>
          <a [routerLink]="['/noticia/88174']" class="page-proposito-seccion2-content-item">
            <div class="page-proposito-seccion2-content-item-img">
              <img src="assets/img/img-proposito5.png" alt="">
            </div>
            <h6 class="page-proposito-seccion2-content-item-number">5</h6>
            <div class="page-proposito-seccion2-content-item-text">
              <h3 class="page-proposito-seccion2-content-item-title"><b>Soy ágil</b>, actuando y adaptándome con
                rapidez.</h3>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="page-proposito-seccion5">
    <div class="container">
      <div class="page-proposito-seccion5-container">
        <div class="page-proposito-seccion5-container-left">
          <img src="assets/img/corazon1.jpg" alt="" class="page-proposito-seccion5-container-left-img">
        </div>
        <div class="page-proposito-seccion5-container-right">
          <h3 class="page-proposito-seccion5-container-right-title">ESTOS SON NUESTROS COMPROMISOS</h3>
          <div class="page-proposito-seccion5-container-right-content">
            <ul>
              <li>1 - LIDERAR CON EL EJEMPLO</li>
              <li>2 - CREAR CONSCIENCIA</li>
              <li>3 - INSPIRAR A COLABORAR</li>
            </ul>
            <p>Creemos decididamente que las pymes impulsan la energía esencial para generar movilidad social y
              construir una sociedad equitativa y cohesionada. Sin ellas no será posible el desarrollo social y
              económico, inclusivo y sostenible, que Chile necesita. Creemos firmemente que son el corazón de Chile y
              estamos comprometidos a apoyar y potenciar a esos hombres y mujeres de espíritu gigante, valientes y
              arriesgados que, con esfuerzo y sacrificio emprenden y crean</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-proposito-seccion6">
    <div class="container">
      <div class="page-proposito-seccion6-container">
        <div class="page-proposito-seccion6-container-items">
          <div class="page-proposito-seccion6-container-item page-proposito-seccion6-container-item1">
            <div class="page-proposito-seccion6-container-item-front">
              <img src="assets/img/corazon2.png" alt="" class="page-proposito-seccion6-container-item-front-img">
            </div>
            <div class="page-proposito-seccion6-container-item-back">
              <h3 class="page-proposito-seccion6-container-item-back-title">LIDERAR CON EL EJEMPLO</h3>
              <div class="page-proposito-seccion6-container-item-back-text">
                <p>El pago oportuno a las pymes, es crítico. Por eso, en Abastible asumimos el compromiso de pagarles a
                  7 días. Pero no solo basta con esto, necesitamos que todas las empresas asuman este compromiso.</p>
              </div>
            </div>
          </div>
          <div class="page-proposito-seccion6-container-item page-proposito-seccion6-container-item2">
            <div class="page-proposito-seccion6-container-item-front">
              <img src="assets/img/corazon3.png" alt="" class="page-proposito-seccion6-container-item-front-img">
            </div>
            <div class="page-proposito-seccion6-container-item-back">
              <h3 class="page-proposito-seccion6-container-item-back-title">CREAR CONSCIENCIA</h3>
              <div class="page-proposito-seccion6-container-item-back-text">
                <p>Es fundamental comprender las complejidades de las pymes. Para esto, crearemos junto a otras empresas
                  e instituciones, una corporación que investigará la realidad problemática de los emprendimientos.</p>
              </div>
            </div>
          </div>
          <div class="page-proposito-seccion6-container-item page-proposito-seccion6-container-item3">
            <div class="page-proposito-seccion6-container-item-front">
              <img src="assets/img/corazon4.png" alt="" class="page-proposito-seccion6-container-item-front-img">
            </div>
            <div class="page-proposito-seccion6-container-item-back">
              <h3 class="page-proposito-seccion6-container-item-back-title">INSPIRAR A COLABORAR</h3>
              <div class="page-proposito-seccion6-container-item-back-text">
                <p>Invitamos a unirse a todas las empresas e instituciones que, al igual que Abastible, creen en la
                  importancia social y económica que tienen el emprendimiento y las pymes para hacer de Chile un país
                  para todos.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h2 class="page-proposito-seccion3-content-title"><b>Testimonios</b></h2>
  <div class="page-proposito-seccion3">
    <img src="assets/img/bg-section3.jpg" alt="" class="page-proposito-seccion3-bg">
    <div class="container">
      <div class="page-proposito-seccion3-content">
        <div class="page-proposito-seccion3-content-swiper">
          <div class="swiper-container" [swiper]="carouselCustom.config">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="box-content-video-proposito" (click)="showModalVideoIframe($event, 'avyMPm7_HXY')">
                  <img
                    src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/05/Screenshot-2023-05-08-110650.png"
                    alt="" class="box-content-video-proposito-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                    class="btn-play-desing1">
                </div>
              </div>
              <div class="swiper-slide">
                <div class="box-content-video-proposito" (click)="showModalVideoIframe($event, '0ed0Ar1vVBg')">
                  <img
                    src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/05/Screenshot-2023-05-08-110952.png"
                    alt="" class="box-content-video-proposito-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                    class="btn-play-desing1">
                </div>
              </div>
              <div class="swiper-slide">
                <div class="box-content-video-proposito" (click)="showModalVideoIframe($event, 'vp-TBhv4ioQ')">
                  <img
                    src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/05/Screenshot-2023-05-08-111304.png"
                    alt="" class="box-content-video-proposito-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                    class="btn-play-desing1">
                </div>
              </div>
              <div class="swiper-slide">
                <div class="box-content-video-proposito" (click)="showModalVideoIframe($event, 'h6qQg_v_4g4')">
                  <img
                    src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/05/Screenshot-2023-05-08-111400.png"
                    alt="" class="box-content-video-proposito-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                    class="btn-play-desing1">
                </div>
              </div>
              <div class="swiper-slide">
                <div class="box-content-video-proposito" (click)="showModalVideoIframe($event, 'HBSxdR5W5UU')">
                  <img
                    src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/05/Screenshot-2023-05-08-111507.png"
                    alt="" class="box-content-video-proposito-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                    class="btn-play-desing1">
                </div>
              </div>
              <div class="swiper-slide">
                <div class="box-content-video-proposito" (click)="showModalVideoIframe($event, '95YrMY2JGvQ')">
                  <img
                    src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/05/Screenshot-2023-05-08-111631.png"
                    alt="" class="box-content-video-proposito-img">
                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                    class="btn-play-desing1">
                </div>
              </div>
            </div>
            <div class="swiper-button-prev">
              <span class="swiper-button-arrow"></span>
            </div>
            <div class="swiper-button-next">
              <span class="swiper-button-arrow"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-proposito-seccion4">
    <div class="container">
      <div class="page-proposito-seccion4-content">
        <h2 class="page-proposito-seccion4-content-title"><b>Acciones Concretas</b></h2>
        <div class="page-proposito-seccion4-content-tabs">
          <div class="page-proposito-seccion4-content-tabs-header-items">
            <button class="page-proposito-seccion4-content-tabs-header-item item-tab-proposito active"
              data-tab="#tab-proveedores">Proveedores</button>
            <button class="page-proposito-seccion4-content-tabs-header-item item-tab-proposito"
              data-tab="#tab-distribuidores">Distribuidores </button>
            <button class="page-proposito-seccion4-content-tabs-header-item item-tab-proposito"
              data-tab="#tab-instaladores">Instaladores </button>
          </div>
          <div class="page-proposito-seccion4-content-tabs-body-items">
            <div class="page-proposito-seccion4-content-tabs-body-item item-tab-proposito-body" id="tab-proveedores">
              <div class="page-proposito-seccion4-content-tabs-body-item-banner">
                <img src="assets/img/banner-proposito1.jpg" alt=""
                  class="page-proposito-seccion4-content-tabs-body-item-banner-img">
              </div>
              <div class="page-proposito-seccion4-content-tabs-body-item-content">
                <div class="page-proposito-seccion4-content-tabs-body-item-content-row">
                  <div class="page-proposito-seccion4-content-tabs-body-item-content-col">
                    <h3 class="page-proposito-seccion4-content-tabs-body-item-content-col-title">¿Quiénes son
                      proveedores
                      pymes de Abastible?</h3>
                    <div class="page-proposito-seccion4-content-tabs-body-item-content-col-text">
                      <p>Son todas aquellas personas y empresas que prestan servicios o entregan productos a Abastible,
                        colaborando y formando
                        parte esencial de la cadena necesaria para llegar con un servicio de excelencia a las
                        comunidades
                        y clientes, y que han
                        sido calificadas por el Servicio de Impuestos Internos como empresas de menor tamaño (micro,
                        pequeñas y medianas).</p>
                    </div>
                  </div>
                  <div class="page-proposito-seccion4-content-tabs-body-item-content-col">
                    <h3 class="page-proposito-seccion4-content-tabs-body-item-content-col-title">Pago a 7 días</h3>
                    <div class="page-proposito-seccion4-content-tabs-body-item-content-col-text">
                      <p>Porque sabemos que emprender no es una tarea fácil y el financiamiento a las pymes es&nbsp;una
                        de
                        sus principales
                        dificultades, en Abastible contamos con un procedimiento para poder<strong> pagar
                          a&nbsp;nuestros
                          proveedores pymes en
                          un plazo máximo de 7 días.</strong></p>
                      <p>Ante cualquier consulta relacionada con el procesamiento de sus documentos electrónicos y su
                        respectivo pago, por
                        favor, hacerla al correo electrónico: <strong>cuentasporpagar@abastible.cl.</strong></p>
                    </div>
                  </div>
                </div>
                <div class="page-proposito-seccion4-content-tabs-body-item-content-button">
                  <a href="https://abastible.cl/wp-content/uploads/2023/03/Pago-a-7-di%CC%81as-Procedimiento-1.pdf"
                    class="btn-design1">Conoce nuestro instructivo de pago a 7 días</a>
                </div>
              </div>
            </div>
            <div class="page-proposito-seccion4-content-tabs-body-item item-tab-proposito-body" style="display: none"
              id="tab-distribuidores">
              <div class="page-proposito-seccion4-content-tabs-body-item-banner">
                <img src="assets/img/banner-proposito2.png" alt=""
                  class="page-proposito-seccion4-content-tabs-body-item-banner-img">
              </div>
              <div class="page-proposito-seccion4-content-tabs-body-item-content-row">
                <div class="page-proposito-seccion4-content-tabs-body-item-content-col">
                  <div class="page-proposito-seccion4-content-tabs-body-item-content-col-text">
                    <p>Abastible desde sus inicios ha privilegiado efectuar la distribución de sus cilindros de gas
                      licuado a través de
                      distribuidores y es así como llega a millones de hogares y pequeños comercios en Chile con un
                      servicio de
                      excelencia.</p>
                    <p>Hoy en día, reflejo de una relación virtuosa, nos sentimos orgullosos de decir que existen más de
                      1.000
                      distribuidores a lo largo y ancho de Chile, siendo casi todos ellos pymes.</p>
                    <p>Esta relación se logra por el arduo trabajo de los distribuidores y el constante apoyo y
                      colaboración que les
                      brinda Abastible, mediante la entrega de herramientas que mejoran la gestión y eficiencia de su
                      negocio.</p>
                  </div>
                </div>
                <div class="page-proposito-seccion4-content-tabs-body-item-content-col">
                  <div class="page-proposito-seccion4-content-tabs-body-item-content-col-text">
                    <p>Una de las formas de lograr lo anterior, es a través de la negociación en forma centralizada por
                      Abastible de la
                      compra de herramientas de trabajo, que generan economías de escala permitiendo su entrega a los
                      distribuidores en
                      condiciones más ventajosas.</p>
                    <p>Además, Abastible bajo determinadas circunstancias otorga apoyos especiales a los distribuidores
                      para colaborar con
                      ellos en la prestación de un servicio de excelencia a los clientes.</p>
                  </div>
                </div>
              </div>
              <div class="page-proposito-seccion4-content-tabs-body-item-content-items">
                <div class="page-proposito-seccion4-content-tabs-body-item-content-item">
                  <div class="page-proposito-seccion4-content-tabs-body-item-content-item-image">
                    <img src="assets/img/img-acciones1.jpg" alt="">
                  </div>
                  <div class="page-proposito-seccion4-content-tabs-body-item-content-item-content">
                    <h4 class="page-proposito-seccion4-content-tabs-body-item-content-item-content-title">Herramientas y
                      recursos</h4>
                    <div class="page-proposito-seccion4-content-tabs-body-item-content-item-content-text">
                      <ul>
                        <li><strong>Acceso a camionetas y cilindros</strong> en condiciones muy favorables.</li>
                        <li><strong>Facilidades en condiciones de pago</strong> para la adquisición de cargas de gas
                          licuado.</li>
                        <li><strong>Flexibilidad:</strong> se entregan condiciones especiales tales como renegociaciones
                          de deuda,
                          postergación de pagos, entre otros, para sobrellevar la primera etapa de crecimiento de los
                          distribuidores pyme, así
                          como para enfrentar situaciones particulares o excepcionales como cierre de calles o eventos
                          naturales o
                          catástrofes.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="page-proposito-seccion4-content-tabs-body-item-content-item">
                  <div class="page-proposito-seccion4-content-tabs-body-item-content-item-content">
                    <h4 class="page-proposito-seccion4-content-tabs-body-item-content-item-content-title">Tecnología
                    </h4>
                    <div class="page-proposito-seccion4-content-tabs-body-item-content-item-content-text">
                      <ul>
                        <li><strong>Acceso a plataformas tecnológicas de clase mundial</strong> para facilitar la
                          comunicación con los
                          clientes, incrementar sus ventas y aumentar la eficiencia de sus operaciones.</li>
                        <li><strong>Acceso a dispositivos tecnológicos como celulares y tabletas</strong>, los que
                          permiten una venta más
                          personalizada y competitiva.</li>
                      </ul>
                    </div>
                  </div>
                  <div class="page-proposito-seccion4-content-tabs-body-item-content-item-image">
                    <img src="assets/img/img-acciones2.jpg" alt="">
                  </div>
                </div>
                <div class="page-proposito-seccion4-content-tabs-body-item-content-item">
                  <div class="page-proposito-seccion4-content-tabs-body-item-content-item-image">
                    <img src="assets/img/img-acciones3.jpg" alt="">
                  </div>
                  <div class="page-proposito-seccion4-content-tabs-body-item-content-item-content">
                    <h4 class="page-proposito-seccion4-content-tabs-body-item-content-item-content-title">Soporte a la
                      operación</h4>
                    <div class="page-proposito-seccion4-content-tabs-body-item-content-item-content-text">
                      <p>Abastible apoya con programas y capacitaciones para mejorar la seguridad de sus operaciones,
                        desarrollar un negocio
                        “sano” y aumentar su calidad de servicio.</p>
                      <ul>
                        <li><strong>Programa PES:</strong> desarrollo del “Programa de Excelencia en Servicio” para
                          repartidores y
                          telefonistas de los distribuidores.</li>
                        <li><strong>Capacitaciones</strong> en aspectos relacionados a gestión del negocio, cumplimiento
                          normativo, seguridad,
                          entre otros.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="page-proposito-seccion4-content-tabs-body-item item-tab-proposito-body" style="display: none"
              id="tab-instaladores">
              <div class="page-proposito-seccion4-content-tabs-body-item-banner">
                <img src="assets/img/banner-proposito3.png" alt=""
                  class="page-proposito-seccion4-content-tabs-body-item-banner-img">
              </div>
              <div class="page-proposito-seccion4-content-tabs-body-item-content-row">
                <div class="page-proposito-seccion4-content-tabs-body-item-content-col">
                  <div class="page-proposito-seccion4-content-tabs-body-item-content-col-text">
                    <p><strong>Programa de acreditación de equipos de soldadores</strong>, para garantizar cumplimiento
                      normativo y calidad
                      de la ejecución de sus proyectos.</p>
                  </div>
                </div>
                <div class="page-proposito-seccion4-content-tabs-body-item-content-col">
                  <div class="page-proposito-seccion4-content-tabs-body-item-content-col-text">
                    <p><strong>Cambio en el modelo de provisión de cañerías</strong> de cobre a los proyectos, donde
                      Abastible proporciona y
                      financia directamente las cañerías consideradas en proyectos granel, lo que ayuda al que el
                      negocio de los
                      instaladores sea más sostenible.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page-proposito-seccion4-content-button">
          <a [routerLink]="['/emprendimientos']" class="btn-design1">Conoce los emprendimiento de nuestros
            colaboradores</a>
        </div>
      </div>
    </div>
  </div>

  <div class="section-design2"
    *ngIf="newsSeguridadInformacion.length > 0 || galeriaSeguridad.length > 0 || galeriaVideosSeguridad.length > 0">
    <div class="tabs-search">
      <button data-tab="#news-seguridad-informacion" class="item-tab-search active"
        *ngIf="newsSeguridadInformacion.length > 0">Noticias</button>
      <button data-tab="#galeries-seguridad-informacion" class="item-tab-search "
        *ngIf="galeriaSeguridad.length > 0">Galería</button>
      <button data-tab="#videos-seguridad-informacion" class="item-tab-search "
        *ngIf="galeriaVideosSeguridad.length > 0">Videos</button>
    </div>
    <div class="tabs-content-search">
      <div class="item-tab-content-search" id="news-seguridad-informacion" *ngIf="newsSeguridadInformacion.length > 0">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas2.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide"
                  *ngFor="let newSeguridadInformacion of newsSeguridadInformacion; let i = index">
                  <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ newSeguridadInformacion.img }}" alt="">
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="newSeguridadInformacion.title"></h4>
                      <div class="card-text">
                        <p [innerHTML]="newSeguridadInformacion.text"></p>
                      </div>
                      <a [routerLink]="['/noticia/' + newSeguridadInformacion.slug ]" class="card-link">Leer
                        más</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'proposito' }" class="btn-custom-new">
                  <span>VER MÁS</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="item-tab-content-search" id="galeries-seguridad-informacion" *ngIf="galeriaSeguridad.length > 0"
        style="display: none;">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas2.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of galeriaSeguridad; let i = index">
                  <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                    class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                    data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ item.feature_img }}" alt="">
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                    </div>
                  </a>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
              <a [routerLink]="['/galeria-image-section/proposito' ]" class="btn-custom-new">
                <span>VER MÁS</span>
              </a>
            </div>
          </div>
        </section>
      </div>
      <div class="item-tab-content-search" id="videos-seguridad-informacion" *ngIf="galeriaVideosSeguridad.length > 0"
        style="display: none;">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas2.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of galeriaVideosSeguridad; let i = index">
                  <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                    (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ item.img }}" alt="">
                      <div class="diagonal-cut"></div>
                      <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                        class="btn-play-desing1">
                    </div>
                    <div class="card-content">
                      <h4 class="card-title">{{ item.title }}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
              <a [routerLink]="['/galeria-videos-section/proposito' ]" [queryParams]="1" class="btn-custom-new">
                <span>VER MÁS</span>
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>

<div id="modal-video-youtube" class="modal-video" [ngClass]="modalVideoIframe == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideoIframe()"></div>
  <button class="close" (click)="closeModalVideoIframe()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <iframe width="560" height="315" [src]="urlSafe" class="iframe-video" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>