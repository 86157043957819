import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private httpClient: HttpClient) { }
  getInfo(slug: string): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user.token;

    return this.httpClient
      .post(`${environment.apiUrl}/vision-mision-valores/?slug=${slug}`, null, {
        headers: new HttpHeaders({ Authorization: `Bearer ${token}` }),
      })
      .pipe((result) => {
        return result;
      });
  }
}
