import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { ActivatedRoute } from '@angular/router';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

// Services
import { AuthService } from '@services/auth';
import { DocumentosService } from '@services/documentos';
import { LoadingService } from '@services/loading';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss'],
})
export class DocumentosPageComponent implements OnInit {
  landing_descripcion_general: any;
  documentos: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  searchText;
  paramsID: any;
  showResults = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private documentosService: DocumentosService,
    private title: Title,
    private ng2SearchPipeModule: Ng2SearchPipeModule
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('Documentos | Intranet Abastible');
    this.activatedRoute.params.subscribe((params) => {
      this.params = params['slug'];
      console.log(this.params)
    });

    this.documentosService.getInfo('all').subscribe(
      (res) => {
        this.documentos = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
  }
  onKeyup(event) {
    let oldval = event.target.value;
    console.log(oldval.length);
    if (oldval.length >= 1) {
      this.showResults = true;
    } else {
      this.showResults = false;
    }
  }
} 
