<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs breadcrumbs-sections">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <!-- <div class="breadcrumbs-item">
        <a [routerLink]="['/grupos']">Grupos</a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div> -->
      <div class="breadcrumbs-item">
        <span>Juego Riesgo</span>
      </div>
    </div>
  </div>
</section>

<section>
  <iframe [src]="urlSafe" frameborder="0"></iframe>
</section>

