import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as uuid from 'uuid';

// Services
import { AuthService } from '@services/auth';
import { UsersService } from '@services/users';
import { LoadingService } from '@services/loading';
import { GaleriasService } from '@services/galerias';
import { AppComponent } from '../../../app.component';
import { NewsService } from '@services/news';
import * as $ from 'jquery';

@Component({
  selector: 'app-techo',
  templateUrl: './techo.component.html',
  styleUrls: ['./techo.component.scss'],
})
export class TechoComponent implements OnInit {
  activities: string[];
  reactions: string[];
  currentPage: number;
  loading = true;
  commentForm: FormGroup;
  userLogged: any;
  avatar_logged: any;
  name_logged: any;
  urlSafe: SafeResourceUrl;
  news: any;
  galerias: any;
  galeriasVideos: any;
  hasGaleriasVideos = false;
  carouselCharlas = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  modalVideo = false;
  modalSrcVideo: any;

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private title: Title,
    private http: HttpClient,
    private router: Router,
    private galeriasService: GaleriasService,
    public sanitizer: DomSanitizer,
    public appComponent: AppComponent,
    private NewsService: NewsService,
  ) {
  }

  ngOnInit() {
    this.title.setTitle('Techo | Intranet Abastible');
    this.NewsService.getNews("comparte-calor	", 1).subscribe(
      (res) => {
        this.news = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.galeriasService.galeriaByCategory("comparte-calor	", 1, "").subscribe(
      (res) => {
        this.galerias = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.galeriasService.galeriaVideosByCategory("techo", 1, 8, "").subscribe(
      (res) => {
        this.hasGaleriasVideos = true;
        this.galeriasVideos = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }

}
