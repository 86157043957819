import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { NoticiasService } from '@services/noticias';
import { IndicadoresService } from '@services/indicadores';

@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.scss'],
})
export class IndicadoresAbastibleComponent implements OnInit {
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  selectedIndex: any[] = [];
  news1: any;
  news1Res: any;
  currentPage1 = 1;
  catNews = "all";
  categoriesFilter: any;
  post: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private NoticiasService: NoticiasService,
    private indicadoresService: IndicadoresService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Indicadores | Intranet Abastible');
    this.indicadoresService.getInfoIndicadores().subscribe(
      (res) => {
        this.post = res
      },
      (err) => {
        console.error(err);
      }
    );
  }
} 
