import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.scss'],
})
export class SinglePageComponent implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  addClassFixed = false;
  linksScrollSwiper = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 5,
        },
        768: {
          slidesPerView: 3,
        },
        570: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private landingService: LandingService,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  get_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }
  init(): void {
    if (this.params['slug'] == 'conozca-abastible') {
      this.title.setTitle('Conoce Abastible | Intranet Abastible');
      this.paramsID = 1141;
    }
    if (this.params['slug'] == 'hacer-las-cosas-bien') {
      this.title.setTitle('Control Interno | Intranet Abastible');
      this.paramsID = 2381;
    }
    if (this.params['slug'] == 'seguridad') {
      this.title.setTitle('0 + Cero Seguridad | Intranet Abastible');
      this.paramsID = 2409;
    }
    if (this.params['slug'] == 'calidad-de-vida') {
      this.title.setTitle('Calidad de Vida | Intranet Abastible');
      this.paramsID = 2438;
    }
    if (this.params['slug'] == 'desarrollo-organizacional') {
      this.title.setTitle('Desarrollo Organizacional | Intranet Abastible');
      this.paramsID = 13448;
    }
    this.slug = this.params['slug'];

    this.landingService.getInfo(this.paramsID).subscribe(
      (res) => {
        console.log(res);
        this.landing = res['landing_array'];
        this.landing_descripcion_general = res['descripcion_general'];
        this.titulo = res['titulo'];
        this.linksScrollSwiper = {
          index: 0,
          config: {
            direction: 'horizontal',
            spaceBetween: 5,
            breakpoints: {
              // when window width is >= 320px
              1199: {
                slidesPerView: 5,
              },
              768: {
                slidesPerView: 3,
              },
              570: {
                slidesPerView: 2,
              },
              0: {
                slidesPerView: 1,
              },
            },
          },
        };
      },
      (err) => {
        console.error(err);
      }
    );

  }
  scroll(id) {
    console.log(`scrolling to ${id}`);
    let el = document.getElementById(id);
    var box = document.getElementsByClassName('box-scroll-conozca');
    for (var i = 0; i < box.length; i++) {
      box[i].classList.remove('active')
    }
    el.classList.add('active')
    el.scrollIntoView({
      behavior: 'smooth'
    });
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    // do some stuff here when the window is scrolled
    const verticalOffset = window.pageYOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop || 0;
    if (verticalOffset > 90) {
      this.addClassFixed = true;
    } else {
      this.addClassFixed = false;
    }
  }
} 
