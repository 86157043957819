import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as uuid from 'uuid';

// Services
import { AuthService } from '@services/auth';
import { ActivitiesService } from '@services/activities';
import { UsersService } from '@services/users';
import { PuntoPrensaService } from '@services/punto-prensa';
import { CumpleanosService } from '@services/cumpleanos';
import { GruposService } from '@services/grupos';
import { LoadingService } from '@services/loading';
import { AppComponent } from '../../../app.component';
import * as $ from 'jquery';

@Component({
  selector: 'app-juego-riesgo',
  templateUrl: './juego-riesgo.component.html',
  styleUrls: ['./juego-riesgo.component.scss'],
})
export class JuegoRiesgoComponent implements OnInit {
  activities: string[];
  reactions: string[];
  currentPage: number;
  loading = true;
  commentForm: FormGroup;
  userLogged: any;
  avatar_logged: any;
  name_logged: any;
  urlSafe: SafeResourceUrl;

  constructor(
    private authService: AuthService,
    private activitiesService: ActivitiesService,
    private usersService: UsersService,
    private puntoPrensaService: PuntoPrensaService,
    private cumpleanosService: CumpleanosService,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private gruposService: GruposService,
    private formBuilder: FormBuilder,
    private title: Title,
    private http: HttpClient,
    private router: Router,
    public sanitizer: DomSanitizer,
    public appComponent: AppComponent,
  ) {
  }

  ngOnInit() {
    this.title.setTitle('Juego Riesgo | Intranet Abastible');
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl("https://test2.notagency.es/juego-abastible/index.html");
    window.addEventListener("keydown", function (e) {
      if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
        e.preventDefault();
      }
    }, false);
  }

}
