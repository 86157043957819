<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Repositorio Legal</span>
      </div>
    </div>
  </div>
</section>
<section class="section-custom-new3">
  <div class="container">
    <div class="text-center mb-3">
      <h1 class="title1 text-center mb-5">Repositorio Legal</h1>
    </div>
    <div class="row">
      <div class="col-lg-8 mx-auto">

        <h3 class="title-custom-new3 text-center" data-aos="fade-up" data-aos-duration="700">Poderes Abastible</h3>
        <span class="line-title-custom-new mx-auto" data-aos="fade-up" data-aos-duration="700"
          data-aos-delay="100"></span>
        <div class="content-documents-custom-new3 mb-5">
          <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
            data-aos-duration="700" data-aos-delay="0" *ngFor="let document of documentos1; let i = index">
            <h3 class="title-document-custom-new3" [innerHTML]="document.titulo"></h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="">
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="">
              </span>
            </div>
          </a>
        </div>

        <h3 class="title-custom-new3 text-center" data-aos="fade-up" data-aos-duration="700">Documentos societarios
          Abastible</h3>
        <span class="line-title-custom-new mx-auto" data-aos="fade-up" data-aos-duration="700"
          data-aos-delay="100"></span>
        <div class="content-documents-custom-new3 mb-5">
          <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
            data-aos-duration="700" data-aos-delay="0" *ngFor="let document of documentos2; let i = index">
            <h3 class="title-document-custom-new3" [innerHTML]="document.titulo"></h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="">
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="">
              </span>
            </div>
          </a>
        </div>

        <h3 class="title-custom-new3 text-center" data-aos="fade-up" data-aos-duration="700">Webdox</h3>
        <span class="line-title-custom-new mx-auto" data-aos="fade-up" data-aos-duration="700"
          data-aos-delay="100"></span>
        <div class="content-documents-custom-new3 mb-4">
          <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
            data-aos-duration="700" data-aos-delay="0" *ngFor="let document of documentos3; let i = index">
            <h3 class="title-document-custom-new3" [innerHTML]="document.titulo"></h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="">
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="">
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>