import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GruposService {

  constructor(private httpClient: HttpClient) { }
  validateUser(group_id: number): Observable<Record<string, any>> {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(`${environment2.apiUrl}/validate-user-group/?user_id=${user_id}&group_id=${group_id}`, null)
      .pipe((result) => {
        return result;
      });
  }

  getInfoListaGrupos(): Observable<Record<string, any>> {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(`${environment2.apiUrl}/list-groups/?user_id=${user_id}`, null)
      .pipe((result) => {
        return result;
      });
  }

  getInfoMedallero(): Observable<Record<string, any>> {
    return this.httpClient
      .post(`${environment2.apiUrl}/medallero-cbp`, null)
      .pipe((result) => {
        return result;
      });
  }

  getActivityGrupos(group_id: number, paged: number) {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(`${environment2.apiUrl}/page-activities-group/?group_id=${group_id}&paged=${paged}&user_id=${user_id}`, null)
      .pipe((result) => {
        return result;
      });
  }
  checkAdminGroup(group_id: number){
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(`${environment2.apiUrl}/check_is_admin_group/?group_id=${group_id}&user_id=${user_id}`, null)
      .pipe((result) => {
        return result;
      });
  }

  removeMemberGroup(group_id: number, user_id: number){
    return this.httpClient
      .post(`${environment2.apiUrl}/remove_member_group/?group_id=${group_id}&user_id=${user_id}`, null)
      .pipe((result) => {
        return result;
      });
  }

  addActivityGroup(group_id: number, user_id: number, content: string){
    return this.httpClient
      .post(`${environment2.apiUrl}/add_activity_group/?group_id=${group_id}&user_id=${user_id}&content=${content}`, null)
      .pipe((result) => {
        return result;
      });
  }

  getInfoGrupo(group_id: number) {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(`${environment2.apiUrl}/info-group/?group_id=${group_id}&user_id=${user_id}`, null)
      .pipe((result) => {
        return result;
      });
  }
  getArchivesGrupo(post_type: string) {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(`${environment2.apiUrl}/archives_by_post_type/?post_type=${post_type}`, null)
      .pipe((result) => {
        return result;
      });
  }
  getNoticesGrupo(post_type: string, search: string, paged: number) {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(`${environment2.apiUrl}/news-by-post-type/?post_type=${post_type}&search=${search}&paged=${paged}`, null)
      .pipe((result) => {
        return result;
      });
  }
  getGalleryGrupo(post_type: string, search: string, paged: number) {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(`${environment2.apiUrl}/gallery_by_post_type/?post_type=${post_type}&search=${search}&paged=${paged}`, null)
      .pipe((result) => {
        return result;
      });
  }
}
