<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Concursos Internos</span>
      </div>
    </div>
  </div>
</section>
<div class="">
  <section class="section-custom-news">
    <div class="container">
      <div class="text-center mb-3">
        <h1 class="title1 text-center mb-5">Concursos Internos</h1>
      </div>
      <div class="row">
        <div class="col-lg-9 mx-auto">

          <div class="modal-section-custom1 modal-section-custom1-new" *ngFor="let new1 of news1; let i = index">
            <div class="modal-section-custom1-text">
              <h3 class="title2-modal-section-custom1" [innerHTML]="new1.post_title"></h3>
              <h3 class="estado-concurso" [innerHTML]="new1.estado_concurso"
                [ngClass]="{'concurso-cerrado': new1.estado_concurso === 'Concurso Cerrado', 'concurso-desierto': new1.estado_concurso === 'Concurso Desierto', 'concurso-abierto': new1.estado_concurso === 'Concurso Abierto'}">
              </h3>
              <p [innerHTML]="contentFilter(textMin(new1.post_content))"></p>
              <a [routerLink]="['/noticia/' + new1.post_name ]">Ver más</a>
            </div>
          </div>
          <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
            <a (click)="nextPage()" class="btn-custom-new">
              <span>VER MÁS</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>