import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { AppComponent } from '../../../app.component';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { NoticiasService } from '@services/noticias';
import { ContactoService } from '@services/contacto';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-canal-denuncias',
  templateUrl: './canal-denuncias.component.html',
  styleUrls: ['./canal-denuncias.component.scss'],
})
export class CanalDenunciasAbastibleComponent implements OnInit {
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  commentForm: FormGroup;
  userLogged: any;
  avatar_logged: any;
  name_logged: any;
  state: any;
  paramsID: any;
  resultForm: any;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  myForm: FormGroup;
  modalSuccess = false;
  categoriesFilter: any;
  files: any;
  valueInputFile: any;
  back_title_page: any;
  back_route_link: any;
  back_section_route_link: any;
  back_section_route_title: any;
  logged_user_email: any;
  logged_user_id: any;
  has_back_section = false;
  files_upload = [];
  arrayUrlImages = [];
  progressSendMail = false;
  loggedIn = false;
  haveSendMail = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private NoticiasService: NoticiasService,
    private formBuilder: FormBuilder,
    private title: Title,
    private contactoService: ContactoService,
    private http: HttpClient,
    private appComponent: AppComponent
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
    this.myForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      name: ['', [Validators.required, Validators.minLength(3)]],
      phone: ['', [Validators.required]],
      day_hours: ['', [Validators.required]],
      place: ['', [Validators.required]],
      date: ['', [Validators.required]],
      name_persons: ['', [Validators.required]],
      irregularity: ['', [Validators.required]],
      evidence: ['', [Validators.required]]
    });
  }
  init(): void {
    var that = this;
    this.title.setTitle('Canal de Denuncias | Intranet Abastible');
    this.paramsID = 29622;
    this.slug = this.params['slug'];
    console.log(location.origin);
    if (localStorage.getItem('back_title_page')) {
      this.back_title_page = localStorage.getItem('back_title_page');
      this.back_route_link = "https://intranet.abastible.cl/" + localStorage.getItem('back_route_link')
    }

    this.appComponent.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.userLogged = this.authService.user;
        this.avatar_logged = this.authService.user['avatar_url'];
        this.name_logged = this.authService.user['display_name'];
        this.logged_user_email = this.authService.user['user_email'];
        this.logged_user_id = this.authService.user['ID'];
        console.log(this.logged_user_email);
        console.log(this.name_logged);
      } 
      this.loggedIn = loggedIn;
    });

    $('.btn-canal-denuncias[href="#step2"]').click(function (e) {
      e.preventDefault();
      $('#step1').fadeOut()
      setTimeout(function () {
        $('#step2').fadeIn()
        $('.box-btn-siguiente').fadeIn()
        $('.box-btn-volver').removeClass('box-btn-volver-centrar').fadeIn();
      }, 300)
    });

    $('#volver').click(function (e) {
      e.preventDefault();
      var id = $('.box-steps-canal-denuncias:visible').attr('id'),
        positionStep = parseInt(id.substr(id.length - 1)),
        prevStep = positionStep - 1;
      console.log(prevStep);
      if (prevStep != 0) {
        if (prevStep == 1) {
          $('.box-steps-canal-denuncias:visible').fadeOut()
          $('.box-btn-siguiente').fadeOut()
          setTimeout(function () {
            $('.box-btn-volver').addClass('box-btn-volver-centrar').hide()
            $('#step1').fadeIn()
          }, 300)
        } else if (prevStep == 4) {
          $('.box-steps-canal-denuncias:visible').fadeOut();
          setTimeout(function () {
            $('.box-btn-volver').removeClass('box-btn-volver-centrar').hide()
            $('.box-header-canal-denuncias').fadeIn();
            $('#step1').fadeIn()
            $('.box-btn-siguiente').hide()
          }, 300)
        } else {
          $('.box-steps-canal-denuncias:visible').fadeOut()
          setTimeout(function () {
            $('#step' + prevStep).fadeIn()
          }, 300)
        }
      }
    });

    $('#siguiente').click(function (e) {
      e.preventDefault();
      var id = $('.box-steps-canal-denuncias:visible').attr('id'),
        positionStep = parseInt(id.substr(id.length - 1)),
        nextStep = positionStep + 1
      if (nextStep <= 6) {
        $('.box-btn-volver').fadeIn()
        if (nextStep == 2) {
          $('.box-steps-canal-denuncias:visible').fadeOut()
          $('.box-btn-siguiente').fadeOut()
          $('.box-btn-volver').removeClass('box-btn-volver-centrar').fadeIn()
          setTimeout(function () {
            $('#step3').fadeIn()
          }, 300)
        } else if (nextStep == 5) {
          $('.box-steps-canal-denuncias:visible').fadeOut()
          $('.box-header-canal-denuncias').fadeOut()
          $('.box-btn-siguiente').fadeOut();
          that.submitForm();
          setTimeout(function () {
            $('.box-btn-volver').addClass('box-btn-volver-centrar').fadeIn()
            // $('#form-denuncia').submit()
            $('#step5').fadeIn()
          }, 300);
        } else {
          $('.box-steps-canal-denuncias:visible').fadeOut()
          setTimeout(function () {
            $('#step' + nextStep).fadeIn()
          }, 300)
        }
      }
    });

    function readURL(input, imgSelected) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          imgSelected.attr('src', e.target.result);
        }

        reader.readAsDataURL(input.files[0]);
      }
    }

    function createArrayFile() {
      that.files_upload = [];
      $(".item-preview-img").each(function (index) {
        that.files_upload[index] = new Object();
        var file = $(this).data("file");
        var name = $(this).data("name");
        var type = $(this).data("type");
        var url = $(this).data("url");
        that.files_upload[index].file = file;
        that.files_upload[index].name = name;
        that.files_upload[index].type = type;
        that.files_upload[index].url = url;
      });
      console.log(that.files_upload);
    }
    $(".box-inputs-file-canal-deuncias").delegate('.input-add-img-canal-denuncias', "change", function (e) {
      let elem = $(this);
      let elemDataId = $(this).data("id");
      var hashId = Math.random().toString(36).slice(-6);
      var inputID = elem.attr("id");
      let file = (<HTMLInputElement>document.getElementById(inputID)).files;
      $(elemDataId).hide();
      $(".box-inputs-file-canal-deuncias").append(`
        <div class="item-input-file-canal-denuncias" id="item-input-file${hashId}">
          <label for="file_img_${hashId}" class="input-custom-canal-denuncias"><img src="assets/img/icon-camera.svg"
              alt="">Agregar Evidencia</label>
          <input type="file" accept=".pdf,.jpg,.png,.jpeg,.gif" data-id="#item-input-file${hashId}"
            class="input-add-img-canal-denuncias" name="evidence[]" id="file_img_${hashId}">
        </div>
      `);
      var image = file[0];
      console.log(image);
      var reader = new FileReader();
      reader.onload = function (file) {
        var fileUrl: any = file.target.result;
        var typeImage = image.type;
        var nameImage = image.name;
        var fileUrlNoData64 = fileUrl.replace("data:" + typeImage + ";base64,", "");
        $('.box-preview-img').append(`
          <div class="item-preview-img" data-id="${elemDataId}" data-file="${fileUrl}" data-name="${nameImage}" data-type="${typeImage}" data-url="${fileUrlNoData64}">
            <img src="${fileUrl}" data-input="${inputID}" alt="">
            <a href="#" class="btn-delete-img">x</a>
          </div>
        `)
      }
      reader.readAsDataURL(image);
      setTimeout(() => {
        createArrayFile();
      }, 500);
    });

    $("body").delegate(".btn-delete-img", "click", function (e) {
      e.preventDefault();
      let idParent = $(this).parent().data("id");
      $(idParent).remove();
      $(this).parent().remove();
      setTimeout(() => {
        createArrayFile();
      }, 500);
    });
  }






  submitForm() {
    var that = this;
    var i = 0;
    var files_length = this.files_upload.length;
    if (this.files_upload.length > 0) {
      $.each(this.files_upload, function (index: number, value) {
        const formData = new FormData();
        formData.append('file', value["file"]);
        formData.append('name', value["name"]);
        formData.append('type', value["type"]);
        formData.append('urlImg', value["url"]);
        that.http.post('https://intranet.abastible.cl/wp/wp-json/v2/upload-img-denuncias/', formData)
          .subscribe(res => {
            that.arrayUrlImages[i] = res;
            i = i + 1;
            if (i == files_length) {
              const formData = new FormData();
              formData.append("email_logged", that.logged_user_email);
              formData.append("name_logged", that.name_logged);
              formData.append("name", that.myForm.get('name').value);
              formData.append("email", that.myForm.get('email').value);
              formData.append("phone", that.myForm.get('phone').value);
              formData.append("day_hours", that.myForm.get('day_hours').value);
              formData.append("place", that.myForm.get('place').value);
              formData.append("date", that.myForm.get('date').value);
              formData.append("name_persons", that.myForm.get('name_persons').value);
              formData.append("irregularity", that.myForm.get('irregularity').value);
              $.each(that.arrayUrlImages, function (index: number, value) {
                console.log(value);
                formData.append("evidence[]", value);
              });
              that.contactoService.sendMailCanalDenuncias(formData).subscribe(
                (res) => {
                  console.log(res);
                  that.progressSendMail = true;
                  if (res) {
                    that.haveSendMail = true;
                  } else {
                    that.haveSendMail = false
                  }
                  that.myForm.setValue({
                    email: '',
                    name: '',
                    phone: '',
                    day_hours: '',
                    place: '',
                    date: '',
                    name_persons: '',
                    irregularity: '',
                    evidence: ''
                  });
                  $('.box-preview-img .btn-delete-img').trigger("click")
                },
                (err) => {
                  console.error(err);
                }
              );
              console.log();
            }
          })
      });
    } else {
      const formData = new FormData();
      formData.append("email_logged", this.logged_user_email);
      formData.append("name_logged", this.name_logged);
      formData.append("name", that.myForm.get('name').value);
      formData.append("email", that.myForm.get('email').value);
      formData.append("phone", that.myForm.get('phone').value);
      formData.append("day_hours", that.myForm.get('day_hours').value);
      formData.append("place", that.myForm.get('place').value);
      formData.append("date", that.myForm.get('date').value);
      formData.append("name_persons", that.myForm.get('name_persons').value);
      formData.append("irregularity", that.myForm.get('irregularity').value);
      formData.append("evidence[]", "");
      that.contactoService.sendMailCanalDenuncias(formData).subscribe(
        (res) => {
          console.log(res);
          that.progressSendMail = true;
          if (res) {
            that.haveSendMail = true;
          } else {
            that.haveSendMail = false
          }
          that.myForm.setValue({
            email: '',
            name: '',
            phone: '',
            day_hours: '',
            place: '',
            date: '',
            name_persons: '',
            irregularity: '',
            evidence: ''
          });
          $('.box-preview-img .btn-delete-img').trigger("click")
        },
        (err) => {
          console.error(err);
        }
      );
    }

  }
  successModal() {
    this.modalSuccess = true;
  }
  closeSuccessModal() {
    this.modalSuccess = false;
  }

}
