<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Office 365</span>
      </div>
    </div>
  </div>
</section>
<section class="section-custom-new3">
  <div class="container">
    <div class="text-center mb-3">
      <h1 class="title1 text-center mb-5">Office 365</h1>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-3">
        <a href="https://portal.office.com/" target="_blank" class="card-links">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/office-365-ventajas-pyme-1.jpg" alt=""
            class="card-links-img" />
          <div class="box-text">
            <h3 class="card-links-title">Portal Office 365</h3>
          </div>
        </a>
      </div>
      <div class="col-lg-3">
        <a href="https://abastible.sharepoint.com/sites/AbastibleOffice365" target="_blank" class="card-links">
          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/office-365-ventajas-pyme-1.jpg" alt=""
            class="card-links-img" />
          <div class="box-text">
            <h3 class="card-links-title">Portal de Ayuda Office 365</h3>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>