import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { NewsService } from '@services/news';
import { LoadingService } from '@services/loading';
import * as $ from 'jquery';

@Component({
  selector: 'app-instructivos-coronavirus',
  templateUrl: './instructivos-coronavirus.component.html',
  styleUrls: ['./instructivos-coronavirus.component.scss'],
})
export class InstructivosCoronavirusComponent implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  instructivosCoronavirus: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private NewsService: NewsService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Instructivos Coronavirus | Intranet Abastible');
    this.paramsID = 71560;
    this.slug = this.params['slug'];

    this.NewsService.getInformesCoronavirus().subscribe(
      (res) => {
        this.instructivosCoronavirus = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

  }
} 
