import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  linkContent;
  constructor() { }

  ngOnInit() {
    $('.btn-floating').click(function (e) {
      e.preventDefault();
      var parentElement = $(this).parent();
      if (parentElement.hasClass('active')) {
        parentElement.removeClass('active');
      } else {
        parentElement.addClass('active');
      }
    });
    $("body").delegate(".box-toggle-white.active .overlay-toggle", "click", function (e) {
      e.preventDefault();
      var parentElement = $(this).parent();
      parentElement.removeClass('active');
    });
    $('.content-menu-mobile .btn-design-float').click(function (e) {
      e.preventDefault();
      if ($(this).hasClass('active')) {
        $('.box-toggle-white').fadeOut();
        $(this).removeClass('active');
      } else {
        $('.box-toggle-white').fadeIn();
        $(this).addClass('active');
        $('.link-collapse-menu-mobile').removeClass('active')
        $('.content-collapse-menu-mobile').fadeOut();
      }
    });
    $('.link-collapse-menu-mobile').click(function (e) {
      e.preventDefault();
      var linkContent = $(this).attr('href');
      if (!$('.content-menu-mobile .btn-design-float').hasClass('active')) {
        if (!$(this).hasClass('active')) {
          $('.link-collapse-menu-mobile').removeClass('active')
          $(this).addClass('active');
          $('.content-collapse-menu-mobile').fadeOut();
          setTimeout(function () {
            $(linkContent).fadeIn();
          }, 300)
        } else {
          $(this).removeClass('active');
          $('.content-collapse-menu-mobile').fadeOut();
        }
      }
    });
  }
}
