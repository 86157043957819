import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { NoticiasService } from '@services/noticias';
import { NewsService } from '@services/news';
import { GaleriasService } from '@services/galerias';

@Component({
  selector: 'app-ley-karin',
  templateUrl: './ley-karin.component.html',
  styleUrls: ['./ley-karin.component.scss'],
})
export class LeyKarinAbastibleComponent implements OnInit {
  params: any;
  titulo: any;
  titlePage: any;
  routeLink: any;
  slug: any;
  state: any;
  paramsID: any;
  selectedIndex: any[] = [];
  news1: any = null;
  news1Res: any;
  currentPage1 = 1;
  catNews = "all";
  categoriesFilter: any;
  post: any;
  modalVideoIframe = false;
  modalSrcVideoIframe: string = "";
  urlSafe: SafeResourceUrl;
  has_next_img = false;
  has_prev_img = false;
  modalImgShow = false;
  modalVideo = false;
  modalSrcVideo: any;
  galeriaCompliance: any = [];
  galerias1: any = [];
  galleryPost = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 3,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselGalerias = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselGalerias2 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselGalerias3 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private NoticiasService: NoticiasService,
    private galeriasService: GaleriasService,
    private NewsService: NewsService,
    public sanitizer: DomSanitizer,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Ley Karin | Intranet Abastible');
    this.titlePage = "Ley Karin";
    this.routeLink = "ley-karin";
    localStorage.setItem('back_title_page', this.titlePage);
    localStorage.setItem('back_route_link', this.routeLink);
    localStorage.removeItem('back_section_route_title');
    localStorage.removeItem('back_section_route_link');

    this.NewsService.getNews("ley-karin", 1).subscribe(
      (res) => {
        this.news1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.galeriasService.galeriaByCategory("ley-karin", 1, "").subscribe(
      (res) => {
        this.galerias1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.galeriasService.galeriaVideosByCategory("ley-karin", 1, 8, "").subscribe(
      (res) => {
        this.galeriaCompliance = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  showModalVideoIframe(event, srcVideo: string) {
    event.stopPropagation();
    let prevText = "https://www.youtube.com/embed/";
    this.modalVideoIframe = true;
    this.modalSrcVideoIframe = prevText + srcVideo;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
  }
  closeModalVideoIframe() {
    this.modalVideoIframe = false;
    this.modalSrcVideoIframe = '';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
    $("#modal-video-youtube").removeClass("active-modal");
  }

  fullImgModal(src: string, idGallery: number, idImgGallery: number, activityId: number) {
    console.log(idGallery);
    console.log(idImgGallery);
    $(".box-modal-img-full").html(" ");
    $(".gallery-single" + activityId).each(function () {
      var srcImage = $(this).attr("src");
      var idImg = $(this).attr("id");
      var description = $(this).attr("alt");
      console.log(srcImage);
      $(".box-modal-img-full").append(`
        <div id="${idImg}" class="gallery-single${idImg}-1" style="display:none;">
          <img src="${srcImage}"  data-gallery="gallery-single${idGallery}"  alt="">
        </div>
      `)
    });
    $(".box-modal-img-full #img-gallery-single" + idImgGallery).addClass("active").fadeIn();
    if ($(".box-modal-img-full #img-gallery-single" + idImgGallery).next().length) {
      this.has_next_img = true;
    } else {
      this.has_next_img = false;
    }
    if ($(".box-modal-img-full #img-gallery-single" + idImgGallery).prev().length) {
      this.has_prev_img = true;
    } else {
      this.has_prev_img = false;
    }
    this.modalImgShow = true;
    // this.modalImgSrc = src;
  }

  prevFullImgModal() {
    var elemActive = $(".box-modal-img-full div.active");
    var elemPrev = elemActive.prev();
    elemActive.removeClass("active").fadeOut(300);
    setTimeout(() => {
      elemPrev.addClass("active").fadeIn();
    }, 300);
    this.has_next_img = true;
    if (elemPrev.prev().length) {
      this.has_prev_img = true;
    } else {
      this.has_prev_img = false;
    }
  }
  nextFullImgModal() {
    var elemActive = $(".box-modal-img-full div.active");
    var elemNext = elemActive.next()
    elemActive.removeClass("active").fadeOut(300);
    setTimeout(() => {
      elemNext.addClass("active").fadeIn();
    }, 300);
    this.has_prev_img = true;
    if (elemNext.next().length) {
      this.has_next_img = true;
    } else {
      this.has_next_img = false;
    }
  }
  closeFullImgModal() {
    this.modalImgShow = false;
  }
  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }
} 
