import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as $ from 'jquery';
import { AppComponent } from '../../../../app.component';

import { PostsService } from '@services/posts';
import { ActivitiesService } from '@services/activities';
import { EventosService } from '@services/eventos';
import { UsersService } from '@services/users';

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.scss'],
})
export class SingleEventosComponent implements OnInit {
  params;
  post;
  user;
  commentForm: FormGroup;
  modalImgShow = false;
  modalImgSrc: string;
  fileData: File = null;
  userLogged: any;
  avatar_logged: any;
  name_logged: any;
  evento = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  hasActivityId = false;
  activityId: any;
  users: any;
  reactions: string[];
  showListUsers = false;
  post_title = "  ";
  galleryPost = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 3,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  name = 'Angular';
  message = '';
  showEmojiPicker = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set = 'twitter';
  loggedIn = false;
  back_title_page: any;
  back_route_link: any;
  has_back_section = false;
  back_section_route_link: any;
  back_section_route_title: any;
  has_next_img = false;
  has_prev_img = false;
  items: any;
  reactions_list_comment: any;
  modalReactionComment = false;
  reactions_list: any;
  modalReaction = false;
  logged_user_id: any;
  activities: string[];
  modalImgSingleShow = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private activitiesService: ActivitiesService,
    private postsService: PostsService,
    private eventosService: EventosService,
    private title: Title,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private appComponent: AppComponent,
    @Inject(Location) private readonly location: Location
  ) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('back_title_page')) {
      this.back_title_page = localStorage.getItem('back_title_page');
      this.back_route_link = "https://intranet.abastible.cl/" + localStorage.getItem('back_route_link')

      if (localStorage.getItem('back_section_route_link')) {
        this.has_back_section = true;
        this.back_section_route_title = localStorage.getItem('back_section_route_title');
        this.back_section_route_link = this.back_route_link + localStorage.getItem('back_section_route_link');
      } else {
        this.has_back_section = false;
      }
    }
    this.appComponent.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.userLogged = JSON.parse(localStorage.getItem('user'));
        this.avatar_logged = this.userLogged['avatar_url'];
        this.name_logged = this.userLogged['display_name'];
        this.logged_user_id = this.userLogged['ID'];
        console.log(this.name_logged);
      }
      this.loggedIn = loggedIn;
    });
    this.activatedRoute.params.subscribe((params) => {
      console.log(params['id'])
      this.getEvento(params['id']);
    });
  }

  getEvento(id): void {
    this.eventosService.eventosSingle(id).subscribe((result) => {
      console.log(result);
      this.evento = result;
      this.title.setTitle(this.evento.title + ' | Intranet Abastible');
      setTimeout(() => {

        $(".col-content-single-page a").attr("target", "_blank");
        var mainImage = $(".body-image").attr("src");
        $(".col-content-single-page a").attr("target", "_blank");
        $(".col-content-single-page a[href='" + mainImage + "']").fadeOut();
        $(".col-content-single-page img[src='" + mainImage + "']").fadeOut();
      }, 1000);
    });
  }

  fullImgSingleModal(src: string) {
    $(".box-modal-img-full").html("");
    $(".box-modal-img-full").append(`
      <img src="${src}" alt="">
    `)
    this.modalImgSingleShow = true;
    // this.modalImgSrc = src;
  }

  closeFullImgSingleModal() {
    this.modalImgSingleShow = false;
  }

  fullImgModal(src: string, idGallery: number, idImgGallery: number) {
    console.log(idGallery);
    console.log(idImgGallery);
    $(".box-modal-img-full").html(" ");
    $("[data-gallery='gallery-single" + idGallery + "']").each(function () {
      var srcImage = $(this).attr("src");
      var idImg = $(this).attr("id");
      console.log(srcImage);
      $(".box-modal-img-full").append(`
        <img src="${srcImage}" id="${idImg}" data-gallery="gallery-single${idGallery}" style="display:none" alt="">
      `)
    });
    $(".box-modal-img-full #img-gallery-single" + idImgGallery).addClass("active").fadeIn();
    if ($(".box-modal-img-full #img-gallery-single" + idImgGallery).next().length) {
      this.has_next_img = true;
    } else {
      this.has_next_img = false;
    }
    if ($(".box-modal-img-full #img-gallery-single" + idImgGallery).prev().length) {
      this.has_prev_img = true;
    } else {
      this.has_prev_img = false;
    }
    this.modalImgShow = true;
    // this.modalImgSrc = src;
  }

  prevFullImgModal() {
    var elemActive = $(".box-modal-img-full img.active");
    var elemPrev = elemActive.prev();
    elemActive.removeClass("active").fadeOut(300);
    setTimeout(() => {
      elemPrev.addClass("active").fadeIn();
    }, 300);
    this.has_next_img = true;
    if (elemPrev.prev().length) {
      this.has_prev_img = true;
    } else {
      this.has_prev_img = false;
    }
  }

  nextFullImgModal() {
    var elemActive = $(".box-modal-img-full img.active");
    var elemNext = elemActive.next()
    elemActive.removeClass("active").fadeOut(300);
    setTimeout(() => {
      elemNext.addClass("active").fadeIn();
    }, 300);
    this.has_prev_img = true;
    if (elemNext.next().length) {
      this.has_next_img = true;
    } else {
      this.has_next_img = false;
    }
  }
  closeFullImgModal() {
    this.modalImgShow = false;
  }

  isPostLoaded(): boolean {
    return typeof this.evento !== 'undefined';
  }

  getPostDate(): string {
    return moment(this.evento.fields.fecha, 'YYYY-MM-DD HH:mm:ss').format(
      'DD/MM/YYYY'
    );
  }
}
