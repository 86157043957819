<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="back_title_page">
        <a href="{{back_route_link}}"><span>{{
            back_title_page }}</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="back_section_route_title">
        <a href="{{back_section_route_link}}"><span>{{
            back_section_route_title }}</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Galería de imágenes</span>
      </div>
    </div>
  </div>
</section>

<section class="p-section" id="{{ slug }}">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11">
        <form #userForm="ngForm" (ngSubmit)="onSubmit(userForm.value.search)">
          <div class="box-search">
            <input type="text" name="search" placeholder="Buscar una Galería" ngModel>
            <button type="submit"><img src="assets/img/icons/icon-search.svg" alt=""></button>
          </div>
        </form>
        <div class="box-title-section">
          <h1 class="title1 text-center">Galería de imágenes</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-lg-6" *ngFor="let item of galerias; let i = index">
        <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
          class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up" data-aos="fade-up"
          data-aos-duration="700">
          <div class="card-img">
            <img src="{{ item.feature_img }}" alt="">
            <div class="diagonal-cut"></div>
          </div>
          <div class="card-content">
            <h4 class="card-title" [innerHTML]="item.post_title"></h4>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="box-charge-more" *ngIf="galerias?.length > 5">
    <a class="btn-more" (click)="nextPage()">Cargar Más</a>
  </div>
</section>