import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { PerfilService } from '@services/perfil';
import { CumpleanosService } from '@services/cumpleanos';

@Component({
  selector: 'app-cumpleanos',
  templateUrl: './cumpleanos.component.html',
  styleUrls: ['./cumpleanos.component.scss'],
})
export class CumpleanosPageComponent implements OnInit {
  notification;
  userLogged: any;
  avatar_logged: any;
  name_logged: any;
  profileSidebar: false;
  commentCounter = 0;
  likesCounter = 0;
  cumpleanos: any;
  cumpleanosRes: any;
  currentPage1 = 1;
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private landingService: LandingService,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private CumpleanosService: CumpleanosService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('Cumpleaños | Intranet Abastible');
    this.CumpleanosService.getInfoMonthCumpleanos().subscribe(
      (res) => {
        console.log(res);
        this.cumpleanos = res
      },
      (err) => {
        console.error(err);
      }
    )
  }
  renderTitleCumpleanos(content: string): string {
    if(content.includes(",")){
      let porciones = content.split(',');
      let reorganize = porciones[1] + " " + porciones[0];
      return reorganize;
    }else{
      return content;
    }
  }
  init(): void {
  }
} 
