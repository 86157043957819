<!-- <section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Redes Sociales</span>
      </div>
    </div>
  </div>
</section> -->

<section class="page-metodologia-go">
  <div class="page-metodologia-go-section1">
    <img src="assets/img/banner-metodologia.jpg" alt="" class="page-metodologia-go-section1-img">
  </div>

  <div class="page-metodologia-go-section2">
    <div class="container">
      <img src="assets/img/cut-go2.png" alt="" class="page-metodologia-go-section2-img">
      <div class="page-metodologia-go-section2-container">
        <h1 class="page-metodologia-go-section2-container-title">
          <span>¿Qué es la Metodología GO?</span>
          <img src="assets/img/icon-go.png" alt="" class="page-metodologia-go-section2-container-title-img">
        </h1>
        <div class="text">
          <p>Es un sistema de gestión diseñado para implementar un modelo de excelencia, mejora
            continua, innovación y desarrollo de cultura, que combina diferentes herramientas para impulsar el
            crecimiento y la competitividad de Abastible. Se basa en la colaboración, eficiencia y participación de
            todos los miembros de la empresa, y busca convertirla en una empresa de clase mundial a través de la
            implementación de 7 prácticas interconectadas que son usadas en las actividades diarias.</p>
          <p>La Metodología GO se sustenta en <b style="color: #f16600;">4 pilares</b> que orientan cada una de las
            prácticas, dándole sentido a su implementación.</p>
        </div>
      </div>
    </div>

  </div>

  <div class="page-metodologia-go-section3">
    <div class="container">
      <div class="page-metodologia-go-section3-container">
        <div class="page-metodologia-go-section3-container-items">
          <div class="page-metodologia-go-section3-container-item">
            <div class="page-metodologia-go-section3-container-item-header">
              <h3 class="page-metodologia-go-section3-container-item-header-title">1</h3>
            </div>
            <div class="page-metodologia-go-section3-container-item-body">
              <h3 class="page-metodologia-go-section3-container-item-body-title">Aprendizaje continuo</h3>
              <div class="page-metodologia-go-section3-container-item-body-text">
                <p>Buscamos fomentar un espíritu crítico en nuestro equipo, que nos permita identificar nuestras
                  debilidades y nos empuje
                  constantemente a aprender de nuestros errores.</p>
              </div>
            </div>
          </div>
          <div class="page-metodologia-go-section3-container-item">
            <div class="page-metodologia-go-section3-container-item-header">
              <h3 class="page-metodologia-go-section3-container-item-header-title">2</h3>
            </div>
            <div class="page-metodologia-go-section3-container-item-body">
              <h3 class="page-metodologia-go-section3-container-item-body-title">Excelencia operacional</h3>
              <div class="page-metodologia-go-section3-container-item-body-text">
                <p>Aspiramos a convertirnos en una empresa de clase mundial que destaque por su excelencia en todos sus
                  procesos, con un
                  marcado compromiso por la mejora continua en ellos.</p>
              </div>
            </div>
          </div>
          <div class="page-metodologia-go-section3-container-item">
            <div class="page-metodologia-go-section3-container-item-header">
              <h3 class="page-metodologia-go-section3-container-item-header-title">3</h3>
            </div>
            <div class="page-metodologia-go-section3-container-item-body">
              <h3 class="page-metodologia-go-section3-container-item-body-title">Trabajo colaborativo</h3>
              <div class="page-metodologia-go-section3-container-item-body-text">
                <p>Para mejorar continuamente, necesitamos trabajar en conjunto con
                  todas la áreas de
                  la compañía, complementando visiones y apoyándonos entre todos para lograr metas comunes.</p>
              </div>
            </div>
          </div>
          <div class="page-metodologia-go-section3-container-item">
            <div class="page-metodologia-go-section3-container-item-header">
              <h3 class="page-metodologia-go-section3-container-item-header-title">4</h3>
            </div>
            <div class="page-metodologia-go-section3-container-item-body">
              <h3 class="page-metodologia-go-section3-container-item-body-title">Empodera- <br>miento y
                autonomía</h3>
              <div class="page-metodologia-go-section3-container-item-body-text">
                <p>Buscamos operadores con mirada crítica de nuestros procesos, que sean partícipes de los cambios que
                  impulsamos día a
                  día.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-metodologia-go-section4">
    <div class="container">
      <img src="assets/img/cut-go1.png" alt="" class="page-metodologia-go-section4-img">
      <div class="page-metodologia-go-section4-container">
        <h2 class="page-metodologia-go-section4-container-title">Las Prácticas</h2>
        <div class="page-metodologia-go-section4-container-items">
          <div class="page-metodologia-go-section4-container-item open-content-modal">
            <img src="assets/img/icon-go1.png" alt="" class="page-metodologia-go-section4-container-item-img">
            <h3 class="page-metodologia-go-section4-container-item-title">Reuniones autónomas</h3>
            <div class="page-metodologia-go-section4-container-item-text">
              <p>Las reuniones autónomas tienen como objetivo principal la organización de los equipos de trabajo para
                fomentar la
                comunicación, coordinación y colaboración, tomar decisiones, resolver problemas, compartir conocimientos
                y promover la mejora continua en el desempeño del equipo, las áreas y la organización. <br>
                Esta práctica puede usarse para: <br>
                La coordinación de los equipos de trabajo operativos.<br>
                La gestión y administración de las dependencias<br>
                La gestión y coordinación de distintas áreas<br>
                La gestión de proyectos, entre otros</p>
            </div>
          </div>
          <div class="page-metodologia-go-section4-container-item open-content-modal">
            <img src="assets/img/icon-go2.png" alt="" class="page-metodologia-go-section4-container-item-img">
            <h3 class="page-metodologia-go-section4-container-item-title">Tarjetas</h3>
            <div class="page-metodologia-go-section4-container-item-text">
              <p>El objetivo principal de la práctica de tarjetas GO, es establecer un sistema eficiente para reportar
                anomalías en las condiciones de trabajo, ya sea relacionadas con seguridad, operación o el
                mantenimiento. Esta práctica busca integrar y empoderar a todos los miembros de las distintas áreas bajo
                un objetivo común, fomentando una cultura preventiva en cada dependencia. <br>
                Las tarjetas permiten una comunicación clara y visual de las condiciones y problemas identificados,
                facilitando la toma de acciones correctivas y la mejora continua en la organización</p>
            </div>
          </div>
          <div class="page-metodologia-go-section4-container-item open-content-modal">
            <img src="assets/img/icon-go3.png" alt="" class="page-metodologia-go-section4-container-item-img">
            <h3 class="page-metodologia-go-section4-container-item-title">5S</h3>
            <div class="page-metodologia-go-section4-container-item-text">
              <p>Aplicar la práctica de 5S permite crear un entorno de trabajo organizado, limpio y eficiente, donde se
                minimicen los desperdicios, se promueva la seguridad y se fomente la disciplina y la mejora continua.
                <br>Esto contribuye a aumentar la productividad, reducir costos y crear un ambiente laboral más
                agradable y motivador.
              </p>
            </div>
          </div>
          <div class="page-metodologia-go-section4-container-item open-content-modal">
            <img src="assets/img/icon-go4.png" alt="" class="page-metodologia-go-section4-container-item-img">
            <h3 class="page-metodologia-go-section4-container-item-title">Kaizen</h3>
            <div class="page-metodologia-go-section4-container-item-text">
              <p>Kaizen es una práctica que busca mejorar continuamente la organización en aspectos clave como la
                seguridad, eficiencia, costos y calidad. Esto se logra mediante la participación activa de todos
                los miembros, quienes generan ideas y aplican soluciones innovadoras. Además, se enfoca en el desarrollo
                del talento y el crecimiento personal brindando oportunidades de aprendizaje y promoviendo una cultura
                de mejora continua. En definitiva, Kaizen impulsa la transformación y la excelencia en todos los
                aspectos de la organización.</p>
            </div>
          </div>
          <div class="page-metodologia-go-section4-container-item open-content-modal">
            <img src="assets/img/icon-go5.png" alt="" class="page-metodologia-go-section4-container-item-img">
            <h3 class="page-metodologia-go-section4-container-item-title">Mantenimiento
              autónomo</h3>
            <div class="page-metodologia-go-section4-container-item-text">
              <p>El mantenimiento autónomo es una práctica que se puede aplicar a todos los niveles de la organización,
                con el objetivo de mejorar la disponibilidad de los procesos y equipos, prevenir fallas y defectos
                fomentar la participación activa y el compromiso de todos los miembros, desarrollar habilidades técnicas
                y mejorar la seguridad en el entorno de trabajo. Esta práctica permite transferir la responsabilidad del
                mantenimiento básico y preventivo a los operadores o usuarios finales, involucrando a todos los
                colaboradores en el cuidado y el buen funcionamiento de los activos de Abastible.</p>
            </div>
          </div>
          <div class="page-metodologia-go-section4-container-item open-content-modal">
            <img src="assets/img/icon-go6.png" alt="" class="page-metodologia-go-section4-container-item-img">
            <h3 class="page-metodologia-go-section4-container-item-title">Mantenimiento
              profesional</h3>
            <div class="page-metodologia-go-section4-container-item-text">
              <p>La práctica del mantenimiento profesional se dedica a garantizar la confiabilidad y eficiencia de los
                equipos, asegurando su correcto funcionamiento a lo
                largo del tiempo.</p>
            </div>
          </div>
          <div class="page-metodologia-go-section4-container-item open-content-modal">
            <img src="assets/img/icon-go7.png" alt="" class="page-metodologia-go-section4-container-item-img">
            <h3 class="page-metodologia-go-section4-container-item-title">Mentalidad de costo</h3>
            <div class="page-metodologia-go-section4-container-item-text">
              <p>Mentalidad de costo es una práctica que busca identificar y reducir las pérdidas que afectan los
                recursos
                disponibles de la compañía. Se enfoca en medir y cuantificar las pérdidas y desperdicios de los
                procesos, estableciendo
                planes de mejora y priorizando los recursos de manera eficiente. El objetivo es crear una cultura de
                optimización del
                presupuesto. Con esta práctica, se puede guiar la ejecución de proyectos y enfocarse en las áreas con
                mayores costos.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-metodologia-go-section5">
    <div class="container">
      <div class="page-metodologia-go-section5-container">
        <h2 class="page-metodologia-go-section5-container-title">Estrategia y estructura</h2>
        <div class="page-metodologia-go-section5-container-text">
          <p>La Metodología GO comenzó como piloto en 2019, inicialmente en el área de Producción
            de la Gerencia de Operaciones. Hoy en día ha permeado y es parte de la cultura de trabajo de las
            dependencias, sus
            colaboradores y áreas que interactúan diariamente en las operaciones y seguimos trabajando para que forme
            parte de la
            cultura de Abastible. La estrategia de implementación se ha basado en:</p>
          <p><b>Comunicación - Capacitación y formación - Apoyo y liderazgo - Seguimiento y evaluación - Cultura de
              aprendizaje y mejora</b></p>
          <p>Esto soportado por un equipo de agentes de cambio, encargados de fomentar la cultura de mejora continua a
            través de la
            Metodología GO.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="page-metodologia-go-section6">
    <div class="container">
      <div class="page-metodologia-go-section6-container">
        <div class="page-metodologia-go-section6-container-buttons">
          <a href="#" class="page-metodologia-go-section6-container-button open-content-modal">
            <h3>Comité GO</h3>
            <p>El Comité GO está conformado por un grupo de personas encargadas de impulsar y supervisar la
              implementación de prácticas y herramientas destinadas a optimizar el rendimiento y la eficiencia. <br>
              Este comité se enfoca en promover la cultura de mejora continua dentro de la empresa, estableciendo
              objetivos, evaluando los procesos existentes y proponiendo acciones para su
              perfeccionamiento.</p>
          </a>
          <a href="#" class="page-metodologia-go-section6-container-button open-content-modal">
            <h3>Tutores</h3>
            <p>Los tutores son colaboradores que guían y apoyan a los equipos y dependencias en la aplicación de
              herramientas y
              prácticas para optimizar su desempeño y generar cultura de mejora continua. <br>Ayuda a transmitir
              conocimientos, enseña el uso de herramientas específicas y brinda orientación personalizada para
              adaptar la metodología al contexto y necesidades del grupo. El tutor promueve la aplicación práctica de la
              Metodología
              GO, guiando en proyectos de mejora y proporcionando retroalimentación. <br>
              Su objetivo es lograr mayor eficiencia y calidad en las actividades y procesos.</p>
          </a>
          <a href="#" class="page-metodologia-go-section6-container-button open-content-modal">
            <h3>Facilitadores</h3>
            <p>Los facilitadores son colaboradores encargados de promover y respaldar el aprendizaje y desarrollo de sus
              equipos de
              trabajo mediante la difusión de la metodología y la transmisión de conocimientos. Su objetivo principal es
              fomentar la
              colaboración y la eficiencia dentro de los equipos, alineándolos hacia una cultura de trabajo colaborativo
              y
              disciplinado. Además, el facilitador busca establecer estándares y procesos que permitan una mayor
              estandarización y
              mejora continua en las actividades y proyectos del equipo.</p>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="page-metodologia-go-section6-1">
    <div class="container" style="position: relative;">
      <ng-img-magnifier
        [thumbImage]='"https://intranet.abastible.cl/wp/wp-content/uploads/2023/07/Rubik-Regional-2.png"'
        [fullImage]='"https://intranet.abastible.cl/wp/wp-content/uploads/2023/07/Rubik-Regional-2.png"' [top]='3'
        [right]='3' [lensWidth]='20' [lensHeight]='10' [resultWidth]='50' [resultHeight]='50' [imgWidth]='1100'
        [imgHeight]='324'>
      </ng-img-magnifier>
    </div>
  </div>
  <section class="section-custom-new3 page-metodologia-go-section8">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">DOCUMENTOS</h3>
          <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
          <div class="content-documents-custom-new3">
            <a href="{{ documento.link }}" class="item-document-custom-new3"
              *ngFor="let documento of documentos; let i = index" target="_blank" data-aos="fade-up"
              data-aos-duration="700" data-aos-delay="0">
              <h3 class="title-document-custom-new3">{{ documento.titulo }}</h3>
              <div class="box-acctions-custom-new3">
                <span class="view-document">
                  <img src="assets/img/file.svg" alt="">
                </span>
                <span class="download-document">
                  <img src="assets/img/download.svg" alt="">
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="section-design2 page-metodologia-go-section7"
    *ngIf="newsSeguridadInformacion?.length > 0 || galeriaSeguridad?.length > 0 || galeriaVideosSeguridad?.length > 0">
    <div class="tabs-search">
      <button data-tab="#news-seguridad-informacion" class="item-tab-search active"
        *ngIf="newsSeguridadInformacion?.length > 0">Noticias</button>
      <button data-tab="#galeries-seguridad-informacion" class="item-tab-search "
        *ngIf="galeriaSeguridad?.length > 0">Galería</button>
      <button data-tab="#videos-seguridad-informacion" class="item-tab-search "
        *ngIf="galeriaVideosSeguridad?.length > 0">Videos</button>
    </div>
    <div class="tabs-content-search">
      <div class="item-tab-content-search" id="news-seguridad-informacion" *ngIf="newsSeguridadInformacion?.length > 0">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas2.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide"
                  *ngFor="let newSeguridadInformacion of newsSeguridadInformacion; let i = index">
                  <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ newSeguridadInformacion.img }}" alt="">
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="newSeguridadInformacion.title"></h4>
                      <div class="card-text">
                        <p [innerHTML]="newSeguridadInformacion.text"></p>
                      </div>
                      <a [routerLink]="['/noticia/' + newSeguridadInformacion.slug ]" class="card-link">Leer
                        más</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'metodologia-go' }"
                  class="btn-custom-new">
                  <span>VER MÁS</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="item-tab-content-search" id="galeries-seguridad-informacion" *ngIf="galeriaSeguridad?.length > 0"
        style="display: none;">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas2.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of galeriaSeguridad; let i = index">
                  <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                    class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                    data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ item.feature_img }}" alt="">
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                    </div>
                  </a>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
              <a [routerLink]="['/galeria-image-section/metodologia-go' ]" class="btn-custom-new">
                <span>VER MÁS</span>
              </a>
            </div>
          </div>
        </section>
      </div>
      <div class="item-tab-content-search" id="videos-seguridad-informacion" *ngIf="galeriaVideosSeguridad?.length > 0"
        style="display: none;">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas3.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of galeriaVideosSeguridad; let i = index">
                  <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                    (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ item.img }}" alt="">
                      <div class="diagonal-cut"></div>
                      <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                        class="btn-play-desing1">
                    </div>
                    <div class="card-content">
                      <h4 class="card-title">{{ item.title }}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
              <a [routerLink]="['/galeria-videos-section/metodologia-go' ]" [queryParams]="1" class="btn-custom-new">
                <span>VER MÁS</span>
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

</section>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>

<div class="modal-custom3-text">
  <div class="overlay-modal"></div>
  <div class="box-custom3-text">
    <button class="close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="title-custom3-text">Levantamiento del proceso</h3>
    <div class="box-text-custome-text">
      <p>Entrevistas y reuniones de validación con los dueños y responsables del proceso con el objetivo de
        elaborar el
        informe de levantamiento de la situación actual y el diagrama de flujos de las actividades realizadas.
      </p>
    </div>
  </div>
</div>