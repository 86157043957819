import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    constructor(private httpClient: HttpClient) { }
    getResults(paged: number, per_page: number, search: string): Observable<Record<string, any>> {

        return this.httpClient
            .post(
                `${environment2.apiUrl}/search-global`,
                {
                    paged: paged,
                    per_page: per_page,
                    search: search
                }
            )
            .pipe((result) => {
                return result;
            });
    }
    getResults2(paged: number, per_page: number, search: string): Observable<Record<string, any>> {

        return this.httpClient
            .post(
                `${environment2.apiUrl}/search-global2`,
                {
                    paged: paged,
                    per_page: per_page,
                    search: search
                }
            )
            .pipe((result) => {
                return result;
            });
    }
    getResults3(paged: number, per_page: number, search: string): Observable<Record<string, any>> {

        return this.httpClient
            .post(
                `${environment2.apiUrl}/search-global3`,
                {
                    paged: paged,
                    per_page: per_page,
                    search: search
                }
            )
            .pipe((result) => {
                return result;
            });
    }
    getResults4(paged: number, per_page: number, search: string): Observable<Record<string, any>> {

        return this.httpClient
            .post(
                `${environment2.apiUrl}/search-global4`,
                {
                    paged: paged,
                    per_page: per_page,
                    search: search,
                    slug: "all"
                }
            )
            .pipe((result) => {
                return result;
            });
    }
}
