import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { DocumentosService } from '@services/documentos';
import { NewsService } from '@services/news';
import { LoadingService } from '@services/loading';
import * as $ from 'jquery';

@Component({
  selector: 'app-repositorio-legal',
  templateUrl: './repositorio-legal.component.html',
  styleUrls: ['./repositorio-legal.component.scss'],
})
export class RespositorioLegalComponent implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  documentos1: any;
  documentos2: any;
  documentos3: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private documentosService: DocumentosService,
    private NewsService: NewsService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    let that = this;
    this.title.setTitle('Repositorio Legal | Intranet Abastible');
    this.slug = this.params['slug'];
    this.documentosService.getDocsByCategory(179).subscribe(
      (res) => {
        that.documentos1 = res;
      },
      (err) => {
        console.error(err);
      }
    );
    this.documentosService.getDocsByCategory(180).subscribe(
      (res) => {
        that.documentos2 = res;
      },
      (err) => {
        console.error(err);
      }
    );
    this.documentosService.getDocsByCategory(181).subscribe(
      (res) => {
        that.documentos3 = res;
      },
      (err) => {
        console.error(err);
      }
    );
  }
} 
