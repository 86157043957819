import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { AppComponent } from '../../../app.component';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { NoticiasService } from '@services/noticias';
import { ContactoService } from '@services/contacto';
import { HttpClient } from '@angular/common/http';
import { DesafioService } from '@services/desafio';

@Component({
  selector: 'app-formulario-desafío7inow',
  templateUrl: './formulario-desafío7inow.component.html',
  styleUrls: ['./formulario-desafío7inow.component.scss'],
})
export class FormularioDesafio7InowComponent implements OnInit {
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  commentForm: FormGroup;
  userLogged: any;
  avatar_logged: any;
  name_logged: any;
  state: any;
  paramsID: any;
  resultForm: any;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  myForm: FormGroup;
  modalSuccess = false;
  categoriesFilter: any;
  files: any;
  valueInputFile: any;
  back_title_page: any;
  back_route_link: any;
  back_section_route_link: any;
  back_section_route_title: any;
  has_back_section = false;
  files_upload = [];
  arrayUrlImages = [];
  progressSendMail = false;
  haveSendMail = true;
  loggedIn = false;
  logged_user_id: any;
  logged_user_email: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private NoticiasService: NoticiasService,
    private formBuilder: FormBuilder,
    private title: Title,
    private contactoService: ContactoService,
    private desafioService: DesafioService,
    private http: HttpClient,
    private appComponent: AppComponent
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
    this.myForm = this.formBuilder.group({
      name_idea: ['', [Validators.required, Validators.minLength(3)]],
      descripcion: ['', [Validators.required, Validators.minLength(3)]],
      beneficios: ['', [Validators.required, Validators.minLength(3)]],
      retos: ['', [Validators.required, Validators.minLength(3)]],
      costos: ['', [Validators.required, Validators.minLength(3)]],
      planta_oficina: ['', [Validators.required, Validators.minLength(3)]],
      name_colaborador: ['', [Validators.required, Validators.minLength(3)]],
    });
  }
  init(): void {
    var that = this;
    this.title.setTitle('Formulario VI Desafío para intranet | Intranet Abastible');
    this.paramsID = 29622;
    this.slug = this.params['slug'];
    console.log(location.origin);
    if (localStorage.getItem('back_title_page')) {
      this.back_title_page = localStorage.getItem('back_title_page');
      this.back_route_link = "https://intranet.abastible.cl/" + localStorage.getItem('back_route_link')
    }
    this.appComponent.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.userLogged = this.authService.user;
        this.avatar_logged = this.authService.user['avatar_url'];
        this.name_logged = this.authService.user['display_name'];
        this.logged_user_email = this.authService.user['user_email'];
        this.logged_user_id = this.authService.user['ID'];
      }
      this.loggedIn = loggedIn;
    });
    $('.btn-canal-denuncias[href="#step2"]').click(function (e) {
      e.preventDefault();
      $('#step1').fadeOut()
      setTimeout(function () {
        $('#step2').fadeIn()
        $('.box-btn-siguiente').fadeIn()
        $('.box-btn-volver').removeClass('box-btn-volver-centrar').fadeIn();
      }, 300)
    });

    $('#volver').click(function (e) {
      e.preventDefault();
      var id = $('.box-steps-canal-denuncias:visible').attr('id'),
        positionStep = parseInt(id.substr(id.length - 1)),
        prevStep = positionStep - 1;
      console.log(prevStep);
      if (prevStep != 0) {
        if (prevStep == 1) {
          $('.box-steps-canal-denuncias:visible').fadeOut()
          $('.box-btn-siguiente').fadeOut()
          setTimeout(function () {
            $('.box-btn-volver').addClass('box-btn-volver-centrar').hide()
            $('#step1').fadeIn()
          }, 300)
        } else if (prevStep == 6) {
          $('.box-steps-canal-denuncias:visible').fadeOut();
          setTimeout(function () {
            $('.box-btn-volver').removeClass('box-btn-volver-centrar').hide()
            $('.box-header-canal-denuncias').fadeIn();
            $('#step1').fadeIn()
            $('.box-btn-siguiente').hide()
          }, 300)
        } else {
          $('.box-steps-canal-denuncias:visible').fadeOut()
          setTimeout(function () {
            $('#step' + prevStep).fadeIn()
          }, 300)
        }
      }
    });

    $('#siguiente').click(function (e) {
      e.preventDefault();
      var id = $('.box-steps-canal-denuncias:visible').attr('id'),
        positionStep = parseInt(id.substr(id.length - 1)),
        nextStep = positionStep + 1
      if (nextStep <= 7) {
        $('.box-btn-volver').fadeIn()
        if (nextStep == 2) {
          $('.box-steps-canal-denuncias:visible').fadeOut()
          $('.box-btn-siguiente').fadeOut()
          $('.box-btn-volver').removeClass('box-btn-volver-centrar').fadeIn()
          setTimeout(function () {
            $('#step3').fadeIn()
          }, 300)
        } else if (nextStep == 7) {
          $('.box-steps-canal-denuncias:visible').fadeOut()
          $('.box-header-canal-denuncias').fadeOut()
          $('.box-btn-siguiente').fadeOut();
          that.submitForm();
          setTimeout(function () {
            $('.box-btn-volver').addClass('box-btn-volver-centrar').fadeIn()
            // $('#form-denuncia').submit()
            $('#step7').fadeIn()
          }, 300);
        } else {
          $('.box-steps-canal-denuncias:visible').fadeOut()
          setTimeout(function () {
            $('#step' + nextStep).fadeIn()
          }, 300)
        }
      }
    });
  }






  submitForm() {
    var that = this;
    var i = 0;
    const formData = new FormData();
    formData.append("name_idea", that.myForm.get('name_idea').value);
    formData.append("descripcion", that.myForm.get('descripcion').value);
    formData.append("beneficios", that.myForm.get('beneficios').value);
    formData.append("retos", that.myForm.get('retos').value);
    formData.append("costos", that.myForm.get('costos').value);
    formData.append("email", that.logged_user_email);
    formData.append("name", that.name_logged);
    formData.append("name_colaborador", that.myForm.get('name_colaborador').value);
    formData.append("planta_oficina", that.myForm.get('planta_oficina').value);
    console.log(that.logged_user_email)
    console.log(that.name_logged)
    $.each(that.arrayUrlImages, function (index: number, value) {
      console.log(value);
    });
    that.desafioService.sendFormDesafio7(formData).subscribe(
      (res) => {
        console.log(res);
        that.progressSendMail = true;
        if (res) {
          that.haveSendMail = true;
        } else {
          that.haveSendMail = false
        }
        that.myForm.setValue({
          name_idea: '',
          descripcion: '',
          beneficios: '',
          retos: '',
          costos: '',
          planta_oficina: '',
          name_colaborador: ''
        });
      },
      (err) => {
        console.error(err);
      }
    );
  }
  successModal() {
    this.modalSuccess = true;
  }
  closeSuccessModal() {
    this.modalSuccess = false;
  }

}
