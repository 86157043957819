import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { NewsService } from '@services/news';
import { LoadingService } from '@services/loading';
import * as $ from 'jquery';
import { GaleriasService } from '@app/services/galerias';
import { DocumentosService } from '@app/services/documentos';
import { EmprendimientosService } from '@app/services/emprendimientos';

@Component({
  selector: 'app-emprendimientos',
  templateUrl: './emprendimientos.component.html',
  styleUrls: ['./emprendimientos.component.scss'],
})
export class EmprendimientosComponent implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  arrayPagination: any;
  modalMoreInfo: boolean;
  categorieFilter: string;
  zonaFilter: string;
  totalPost: number;
  searchFilter: string;
  emprendimientos: any;
  emprendimientosRes: any;
  currentPageEmprendimientos = 1;
  imagenModal: any;
  tituloModal: any;
  autorModal: any;
  cargoModal: any;
  plantaoficinaModal: any;
  informacionModal: any;
  contenidoModal: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private landingService: LandingService,
    private NewsService: NewsService,
    private documentosService: DocumentosService,
    private EmprendimientosService: EmprendimientosService,
    private title: Title,
    private galeriasService: GaleriasService,
    private sanitized: DomSanitizer,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Emprendimientos | Intranet Abastible');
    this.slug = this.params['slug'];
    let that = this;
    that.modalMoreInfo = true;
    that.searchFilter = '';
    that.categorieFilter = '';
    that.zonaFilter = '';
    this.getPostsFunction(that.searchFilter, that.currentPageEmprendimientos, that.categorieFilter, that.zonaFilter)
  }

  getPostsFunction(searchFilter, currentPageEmprendimientos, categorieFilter, zonaFilter) {
    let that = this;
    this.EmprendimientosService.getEmprendimientos(searchFilter, currentPageEmprendimientos, categorieFilter, zonaFilter).subscribe(
      (res) => {
        this.emprendimientos = res['posts'];
        this.totalPost = res['total_pages_posts'];
        this.arrayPagination = new Array(this.totalPost);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  onOptionsSelected(value: string) {
    let that = this;
    this.currentPageEmprendimientos = 1;
    this.categorieFilter = value;

    this.getPostsFunction(that.searchFilter, that.currentPageEmprendimientos, that.categorieFilter, that.zonaFilter);
  }

  onZonaSelected(value: string) {
    let that = this;
    this.currentPageEmprendimientos = 1;
    this.zonaFilter = value;

    this.getPostsFunction(that.searchFilter, that.currentPageEmprendimientos, that.categorieFilter, that.zonaFilter)
  }

  onKeyUp(x) { // appending the updated value to the variable
    let that = this;
    let value = x.target.value;
    this.currentPageEmprendimientos = 1;
    this.searchFilter = value;

    this.getPostsFunction(that.searchFilter, that.currentPageEmprendimientos, that.categorieFilter, that.zonaFilter)
  }

  setPageEmbajadores(number) {
    let that = this;
    this.currentPageEmprendimientos = number;

    this.getPostsFunction(that.searchFilter, that.currentPageEmprendimientos, that.categorieFilter, that.zonaFilter)
  }

  openModal(item) {
    this.modalMoreInfo = true;
    this.tituloModal = item.titulo;
    this.imagenModal = item.imagen;
    this.autorModal = item.autor;
    this.cargoModal = item.cargo;
    this.plantaoficinaModal = item.plantaoficina;
    this.informacionModal = item.informacion;
    this.contenidoModal = item.contenido;
    $("#modal-eventos").fadeIn();
  }

  closeModal() {
    $("#modal-eventos").fadeOut();
    this.modalMoreInfo = false;
  }
} 
