<div class="bg-white bg-white-custom pb-0">
  <section class="section-legado-creativo">
    <div class="box-header-legado-creativo">
      <img src="assets/img/desafio5/bg-header-legado.jpg" class="img-fluid" alt="">
    </div>
    <section id="breadcrumbs">
      <div class="container">
        <div class="breadcrumbs breadcrumbs-sections">
          <div class="breadcrumbs-item">
            <a [routerLink]="['/muro']"><span class="material-icons">
                home
              </span></a>
            <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
          </div>
          <div class="breadcrumbs-item">
            <a [routerLink]="['/innovacion']">Innovación</a>
            <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
          </div>
          <div class="breadcrumbs-item">
            <span>Legado Creativo</span>
          </div>
        </div>
      </div>
    </section>
    <div class="box-body-legado-creativo">
      <div class="section-title-legado-creativo">
        <div class="container">
          <h3 class="title-legado-creativo"><span>Legado creativo</span></h3>
        </div>
      </div>
      <div class="container">
        <div class="box-text-legado-creativo">
          <p>La importancia de la creatividad está más que probada en estos últimos tiempos; es cuestión de ver la
            velocidad de cómo
            han ido cambiando la sociedad y las tecnologías para darnos cuenta de que hay que saber adaptarse al cambio.
            Y
            es por
            esto que decimos que <b>“Creatividad es igual a supervivencia”</b>.</p>
          <p>Ser creativo no se trata de ser sólo bueno para dibujar o inventar cosas nuevas. La creatividad es la
            capacidad
            inherente de todo ser humano para resolver problemas nuevos, o de enfrentarse a problemas viejos de nuevas
            maneras.
            Existe un tipo de creatividad cotidiana, esa que usamos a diario la llamada “pequeña creatividad” que trata
            de
            esto, de
            las cosas que tenemos a mano para afrontar los pequeños desafíos del día a día.</p>
          <p>En realidad, ¿Qué hay más cotidiano que los problemas?</p>
          <p>Cuanto más creativos seamos, más flexibles, ingeniosos y más soluciones se nos ocurrirán, mientras más
            entrenemos el
            músculo de la creatividad mejor equipados estaremos para superar con éxito cualquier obstáculo que nos
            depare
            la vida,
            ya sea improvisar una receta de cocina o resolver un problema familiar o profesional. Y ya lo dicen varias
            investigaciones <b>“Las personas creativas, son más felices”.</b></p>
        </div>
      </div>
      <div class="section-title-legado-creativo">
        <div class="container">
          <h3 class="title-legado-creativo"><span>¿Cuál es el objetivo?</span></h3>
        </div>
      </div>
      <div class="box-text-legado-creativo">
        <div class="container">
          <p>Capacitar en tiempos de COVID 19, no es ni será fácil para el presente ni los próximos años. Por esto la
            capacitación
            será online, atractiva y activa. <br>La participación de todos será clave, por lo que se propone utilizar
            los
            medios más a mano que poseen nuestros
            colaboradores y en conjunto a las DDAs (Dinámicas didácticas Activas). <b>APRENDER HACIENDO</b>.</p>
          <ul>
            <li><span>1</span>Aprender haciendo con herramientas de iniciación para entrenar la CREATIVIDAD a diario.
            </li>
            <li><span>2</span>Demostrar que la CREATIVIDAD es una habilidad fundamental para la Vida.</li>
            <li><span>3</span>Mostrar que la CREATIVIDAD constituye uno de los pilares de la adaptabilidad y la
              supervivencia en
              contextos complejos.
              Aportando valor en cada una de las etapas de la vida de las personas en esta NUEVA NORMALIDAD.</li>
            <li><span>4</span>Co-Crear un “Manifiesto para Crecer” (Made in ABASTIBLE) de 50 puntos entre todos los
              colaboradores de
              ABASTIBLE.</li>
            <li><span>5</span>Desarrollar aquellos aspectos relevantes relativos a la agregación de Valor CREATIVO en
              cualquier
              proceso
              personal,
              social y profesional.</li>
            <li><span>6</span>Producir luego, de todo el proceso, una Bitácora, Documento y/o Libro como regalo para
              todos los
              colaboradores
              ABASTIBLE).</li>
          </ul>
          <h3 class="title-legado-creativo2">¿A quién va dirigido?</h3>
          <p>Legado Creativo es una capacitación activa dirigida a todos los colaboradores que deseen participar,
            rehabilitar y
            potenciar su Creatividad. <br>La idea es que participen todos aquellos que deseen aumentar su velocidad de
            ideación y ven en la creatividad una
            herramienta práctica de Liderazgo.</p>
        </div>
      </div>
      <div class="section-title-legado-creativo">
        <div class="container">
          <h3 class="title-legado-creativo"><span>Videos de campaña</span></h3>
        </div>
      </div>
      <div class="box-content-videos-campana">
        <div class="container">
          <div class="swiper-container" [swiper]="carouselGalerias2.config">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                  (click)="showModalVideo($event, 'https://intranet.abastible.cl/wp/wp-content/uploads/2020/09/MICROVIDEO-4-1.mp4')"
                  data-aos-duration="700">
                  <div class="card-img">
                    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2020/09/legado-creativo.png" alt="">
                    <div class="diagonal-cut"></div>
                    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                      class="btn-play-desing1">
                  </div>
                  <div class="card-content">
                    <h4 class="card-title">i-NOW -Conviértelo en algo personal</h4>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                  (click)="showModalVideo($event, 'https://intranet.abastible.cl/wp/wp-content/uploads/2020/09/MICROVIDEO-3-1.mp4')"
                  data-aos-duration="700">
                  <div class="card-img">
                    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2020/09/VISUAL%C3%8DCELO.png" alt="">
                    <div class="diagonal-cut"></div>
                    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                      class="btn-play-desing1">
                  </div>
                  <div class="card-content">
                    <h4 class="card-title">i-NOW - VISUALÍCELO</h4>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                  (click)="showModalVideo($event, 'https://intranet.abastible.cl/wp/wp-content/uploads/2020/09/MICROVIDEO-1-1.mp4')"
                  data-aos-duration="700">
                  <div class="card-img">
                    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2020/09/1microvideo.png" alt="">
                    <div class="diagonal-cut"></div>
                    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                      class="btn-play-desing1">
                  </div>
                  <div class="card-content">
                    <h4 class="card-title">i-NOW - COMO TENER IDEAS GENIALES</h4>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                  (click)="showModalVideo($event, 'https://intranet.abastible.cl/wp/wp-content/uploads/2020/09/ABAST2020_TECHO02.mp4')"
                  data-aos-duration="700">
                  <div class="card-img">
                    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2020/09/Sin-t%C3%ADtulo-1.png" alt="">
                    <div class="diagonal-cut"></div>
                    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                      class="btn-play-desing1">
                  </div>
                  <div class="card-content">
                    <h4 class="card-title">Aporte en Valparaíso - Abastible, Seremi y Techo</h4>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                  (click)="showModalVideo($event, 'https://intranet.abastible.cl/wp/wp-content/uploads/2020/08/iNow-2020-v8.mp4')"
                  data-aos-duration="700">
                  <div class="card-img">
                    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2020/08/i-NOW.png" alt="">
                    <div class="diagonal-cut"></div>
                    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                      class="btn-play-desing1">
                  </div>
                  <div class="card-content">
                    <h4 class="card-title">i-NOW 2020 . LEGADO CREATIVO</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev">
              <span class="material-icons">
                keyboard_arrow_left
              </span>
            </div>
            <div class="swiper-button-next">
              <span class="material-icons">
                keyboard_arrow_right
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/yeVQnGOWjzk" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen class="mb-5"></iframe>
      </div>
    </div>

    <div class="box-footer-legado-creativo">
      <img src="assets/img/desafio5/bg-footer-legado.jpg" class="img-fluid" alt="">
    </div>
    <!-- <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2020/09/1440x2570.jpg" class="img-desktop" alt="">
    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2020/09/375x1134.jpg" class="img-mobile" alt=""> -->

  </section>
</div>
<!-- <a [routerLink]="['/formulario-desafio6' ]" class="btn-mailto-inow"><img src="assets/img/icons/mail.svg" alt=""><span>Cuéntanos
    tu idea</span></a> -->
<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>