<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Documentos</span>
      </div>
    </div>
  </div>
</section>

<section class="p-section" id="{{ slug }}">
  <div class="container">
    <div class="row">
      <div class="col-lg-9">
        <div class="box-conozca-abastible">
          <div class="row box-conozca-abastible-row">
            <div class="col-lg-6 m-auto">
              <h1 class="title1">Documentos</h1>
            </div>
            <div class="col-lg-6 my-auto">
              <div class="box-search">
                <input type="text" placeholder="Buscar un Documento" (keyup)="onKeyup($event)" name="search"
                  [(ngModel)]="searchText" autocomplete="off">
                <img src="assets/img/icons/icon-search.svg" alt="">
              </div>
            </div>
          </div>
          <div class="row box-result-documentos" *ngIf="showResults">
            <div class="col-sm-6 col-lg-4 col-documentos-single"
              *ngFor="let item of documentos | filter:searchText; let i = index">
              <a href="{{item.link_archivo}}" target="_blank" class="item-documentos-single" *ngIf="item.link_archivo">
                <img src="assets/img/icons/icon-doc.svg" *ngIf="item.ext == '.doc'" alt="">
                <img src="assets/img/icons/icon-pdf.svg"
                  *ngIf="item.ext == '.pdf' || item.ext == '.png' || item.ext == '.zip'" alt="">
                <img src="assets/img/icons/icon-pps.svg" *ngIf="item.ext == '.pps'" alt="">
                <img src="assets/img/icons/icon-ppt.svg" *ngIf="item.ext == '.ppt'" alt="">
                <img src="assets/img/icons/icon-pptx.svg" *ngIf="item.ext == 'pptx'" alt="">
                <div class="item-documentos-single-content">
                  <p>{{item.titulo}}</p>
                </div>
              </a>
            </div>
          </div>
          <div class="row box-documentos" *ngIf="!showResults">
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/a']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">A</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/b']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">B</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/c']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">C</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/d']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">D</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/e']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">E</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/f']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">F</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/g']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">G</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/h']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">H</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/i']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">I</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/j']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">J</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/k']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">K</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/l']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">L</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/m']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">M</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/n']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">N</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/o']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">O</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/p']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">P</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/q']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">Q</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/r']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">R</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/s']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">S</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/t']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">T</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/u']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">U</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/v']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">V</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/w']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">W</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/x']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">X</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/y']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">Y</span>
              </a>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-documentos">
              <a [routerLink]="['/single-documentos/z']" class="item-documentos">
                <img src="assets/img/icons/icon-documents.svg" alt="">
                <span class="letra-documentos">Z</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 box-info-user">
        <app-sidebar></app-sidebar>
      </div>
    </div>
  </div>
</section>