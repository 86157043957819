<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="back_title_page">
        <a href="{{back_route_link}}"><span>{{
            back_title_page }}</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Canal de Denuncias</span>
      </div>
    </div>
  </div>
</section>
<section id="canal-denuncias">
  <div class="container">
    <div class="box-canal-denuncias">
      <div class="box-header-canal-denuncias">
        <h2 class="title1">Canal de Denuncias</h2>
        <p class="text1-canal-denuncias text-center">Recuerda que la denuncia es anónima</p>
      </div>
      <form [formGroup]="myForm" (ngSubmit)="submitForm()" id="form-denuncia" enctype="multipart/form-data">
        <div class="box-steps-canal-denuncias box-step1-canal-denuncias" id="step1">
          <h4 class="title1-canal-denuncias">¿Quieres comenzar?</h4>
          <div class="box-white-canal-denuncias">
            <a href="#step2" class="btn-canal-denuncias">Sí</a>
            <a href="#" class="btn-canal-denuncias">No</a>
          </div>
        </div>
        <div class="box-steps-canal-denuncias box-step2-canal-denuncias" id="step2">
          <h4 class="title1-canal-denuncias">Datos Personales</h4>
          <div class="box-white-canal-denuncias">
            <input type="text" class="input-custom-canal-denuncias" placeholder="Nombre (Opcional)" id="first_name"
              formControlName="name">
            <input type="email" class="input-custom-canal-denuncias" placeholder="Email de contacto"
              formControlName="email" id="email">
            <input type="tel" class="input-custom-canal-denuncias" placeholder="Teléfono" formControlName="phone"
              id="phone">
            <input type="text" class="input-custom-canal-denuncias" placeholder="Día y Hora para Comunicarnos"
              formControlName="day_hours" id="day_hours">
          </div>
        </div>
        <div class="box-steps-canal-denuncias box-step3-canal-denuncias" id="step3">
          <h4 class="title1-canal-denuncias">Datos de la Irregularidad</h4>
          <div class="box-white-canal-denuncias">
            <input type="text" class="input-custom-canal-denuncias" placeholder="Lugar donde ocurrió"
              formControlName="place" id="place">
            <input type="text" class="input-custom-canal-denuncias" placeholder="Fecha" formControlName="date"
              id="date">
            <input type="text" class="input-custom-canal-denuncias" placeholder="Nombre de las personas"
              formControlName="name_persons" id="name_persons">
            <textarea name="" id="" class="input-custom-canal-denuncias" placeholder="Describe la irregularidad"
              formControlName="irregularity" id="irregularity" cols="30" rows="5"></textarea>
          </div>
        </div>
        <div class="box-steps-canal-denuncias box-step4-canal-denuncias" id="step4">
          <h4 class="title1-canal-denuncias">Fotos / Evidencias</h4>
          <div class="box-white-canal-denuncias">
            <div class="box-inputs-file-canal-deuncias">
              <div class="item-input-file-canal-denuncias" id="item-input-file1">
                <label for="file_img_1" class="input-custom-canal-denuncias"><img src="assets/img/icon-camera.svg"
                    alt="">Agregar Evidencia</label>
                <input type="file" accept=".pdf,.jpg,.png,.jpeg,.gif" data-id="#item-input-file1"
                  class="input-add-img-canal-denuncias" name="evidence[]" id="file_img_1">
              </div>
            </div>
            <div class="box-preview-img">
            </div>
          </div>
        </div>

        <div class="box-steps-canal-denuncias box-step5-canal-denuncias" id="step5">
          <div class="overlay-step-5" *ngIf="!progressSendMail">
            <span></span><span></span>
          </div>
          <div class="text-center mb-3">
            <h2 class="title1" *ngIf="haveSendMail">¡Gracias!</h2>
            <h2 class="title1" *ngIf="!haveSendMail">Ha ocurrido un error</h2>
          </div>
          <p class="text2-canal-denuncias" *ngIf="haveSendMail">Ya estamos evaluando el caso. <br><br>Está atento a el
            correo y teléfono si
            lo necesitamos te vamos a contactar</p>
          <p class="text2-canal-denuncias" *ngIf="!haveSendMail">Ha fallado el envio del correo</p>
        </div>

        <div class="box-buttons-canal-denuncias">
          <div class="row">
            <div class="col-6 box-btn-volver box-btn-volver-centrar" style="display: none;">
              <a href="#" id="volver" class="btn-design2">Volver</a>
            </div>
            <div class="col-6 box-btn-siguiente">
              <button type="submit" id="siguiente" class="btn-design2">Siguiente</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>

<div id="modal-success" class="modal-success" *ngIf="modalSuccess">
  <div class="overlay-modal" (click)="closeSuccessModal()"></div>
  <div class="modal-content">
    <button type="button" class="close" aria-label="Close" (click)="closeSuccessModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
      <h2 class="title-success">Su mensaje ha sido enviado exitosamente</h2>
    </div>
  </div>
</div>