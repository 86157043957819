<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="back_title_page">
        <a href="{{back_route_link}}"><span>{{
            back_title_page }}</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="back_section_route_title">
        <a href="{{back_section_route_link}}"><span>{{
            back_section_route_title }}</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Noticias</span>
      </div>
    </div>
  </div>
</section>
<div class="">
  <section class="section-custom-news">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-11">
          <form #userForm="ngForm" (ngSubmit)="onSubmit(userForm.value.search)">
            <div class="box-search">
              <input type="text" name="search" placeholder="Buscar una Noticias" ngModel>
              <button type="submit"><img src="assets/img/icons/icon-search.svg" alt=""></button>
            </div>
          </form>
          <div class="text-center mb-3">
            <h1 class="title1 text-center">Noticias</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-9">

          <div class="modal-section-custom1 modal-section-custom1-new" *ngFor="let new1 of news1; let i = index">
            <div class="row">
              <div class="col-lg-7 modal-section-custom1-img">
                <img src="{{ new1.img }}" class="" alt="">
                <div class="cut-diagonal"></div>
              </div>
              <div class="modal-section-custom1-text col-lg-5">
                <h3 class="title2-modal-section-custom1" [innerHTML]="new1.title"></h3>
                <p [innerHTML]="contentFilter(new1.text)"></p>
                <a [routerLink]="['/noticia/' + new1.slug ]">Ver más</a>
              </div>
            </div>
          </div>
          <div class="col-12 box-btn-news" *ngIf="news1.length > 5" data-aos="fade-up" data-aos-duration="700"
            data-aos-delay="100">
            <a (click)="nextPage()" class="btn-custom-new">
              <span>VER MÁS</span>
            </a>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="box-categories">
            <h3 class="title-aside"> Categorías</h3>
            <ng-container *ngFor="let categoryFilter of categoriesFilter; let i = index">
              <a (click)="setCategoryList($event, categoryFilter.slug)"
                [ngClass]="{'no-show-cat': categoryFilter.parent != 0}"
                class="link-cat link-cat-{{categoryFilter.slug}}">{{categoryFilter.name}}</a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>