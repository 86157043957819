import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { NoticiasService } from '@services/noticias';
import { NewsService } from '@services/news';
import { GaleriasService } from '@services/galerias';

import { PostsService } from '@services/posts';

@Component({
  selector: 'app-noticias-conexion-naranja-edicion',
  templateUrl: './noticias-conexion-naranja-edicion.component.html',
  styleUrls: ['./noticias-conexion-naranja-edicion.component.scss'],
})
export class NoticiasConexionNaranjaEdicionAbastibleComponent implements OnInit {
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  selectedIndex: any[] = [];
  news: any = null;
  newsRes: any;
  currentPage = 1;
  catNews = "all";
  categoriesFilter: any;
  post: any;
  modalVideoIframe = false;
  modalSrcVideoIframe: string = "";
  modalAudioIframe = false;
  modalSrcAudioIframe: string = "";
  urlSafe: SafeResourceUrl;
  has_next_img = false;
  has_prev_img = false;
  modalImgShow = false;
  modalVideo = false;
  modalSrcVideo: any;
  modalAudio = false;
  modalSrcAudio: any;
  modalSrcAudioPreview: any;
  galeriaCompliance: any;
  galerias1: any = null;
  posts: any = null;
  term_slug: any = null;
  edicion: any = null;
  post_title: any = null;
  term_info: any = null;
  info: any = null;
  totalPost: any = null;
  arrayPagination: any;
  galleryInternPost = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  galleryPost = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 3,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselGalerias = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 25,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselGalerias2 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselGalerias3 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  constructor(
    private activatedRoute: ActivatedRoute,
    private postsService: PostsService,
    private authService: AuthService,
    private NoticiasService: NoticiasService,
    private galeriasService: GaleriasService,
    private NewsService: NewsService,
    private landingService: LandingService,
    public sanitizer: DomSanitizer,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    let that = this;

    that.title.setTitle('Reciclar es Colaborar | Intranet Abastible');

    that.activatedRoute.params.subscribe((params) => {
      that.edicion = params['edicion'];
      that.getPost(params['edicion'], that.currentPage);
    });
  }

  getPost(slug: string, currentPage: number): void {
    this.postsService.getPostsCN(slug, currentPage).subscribe((result) => {
      this.post = result;
      this.post_title = this.post.term.name;
      this.term_slug = this.post.term.slug;
      this.title.setTitle(this.post_title + ' | Intranet Abastible');
      this.term_info = this.post.term;
      this.posts = this.post.posts;
      this.totalPost = this.post.total_pages_posts;
      this.arrayPagination = new Array(this.post.total_pages_posts);
      console.log("this.post.total_pages_posts", this.arrayPagination);
    });
  }


  setPage(number) {
    let that = this;
    that.currentPage = number;
    that.getPost(that.edicion, that.currentPage);
  }

  showModalVideoIframe(event, srcVideo: string) {
    event.stopPropagation();
    let prevText = "https://www.youtube.com/embed/";
    this.modalVideoIframe = true;
    this.modalSrcVideoIframe = prevText + srcVideo;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
  }
  closeModalVideoIframe() {
    this.modalVideoIframe = false;
    this.modalSrcVideoIframe = '';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
    $("#modal-video-youtube").removeClass("active-modal");
  }

  showModalAudioIframe(event, srcAudio: string) {
    event.stopPropagation();
    let prevText = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/";
    let nextText = "&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&visual=true";
    this.modalAudioIframe = true;
    this.modalSrcAudioIframe = prevText + srcAudio + nextText;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcAudioIframe);
  }
  closeModalAudioIframe() {
    this.modalAudioIframe = false;
    this.modalSrcAudioIframe = '';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcAudioIframe);
    $("#modal-audio-soundcloud").removeClass("active-modal");
  }

  fullImgModal(src: string, idGallery: number, idImgGallery: number, activityId: number) {
    console.log(idGallery);
    console.log(idImgGallery);
    $(".box-modal-img-full").html(" ");
    $(".gallery-single" + activityId).each(function () {
      var srcImage = $(this).attr("src");
      var idImg = $(this).attr("id");
      var description = $(this).attr("alt");
      console.log(srcImage);
      $(".box-modal-img-full").append(`
        <div id="${idImg}" class="gallery-single${idImg}-1" style="display:none;">
          <img src="${srcImage}"  data-gallery="gallery-single${idGallery}"  alt="">
        </div>
      `)
    });
    $(".box-modal-img-full #img-gallery-single" + idImgGallery).addClass("active").fadeIn();
    if ($(".box-modal-img-full #img-gallery-single" + idImgGallery).next().length) {
      this.has_next_img = true;
    } else {
      this.has_next_img = false;
    }
    if ($(".box-modal-img-full #img-gallery-single" + idImgGallery).prev().length) {
      this.has_prev_img = true;
    } else {
      this.has_prev_img = false;
    }
    this.modalImgShow = true;
    // this.modalImgSrc = src;
  }

  prevFullImgModal() {
    var elemActive = $(".box-modal-img-full div.active");
    var elemPrev = elemActive.prev();
    elemActive.removeClass("active").fadeOut(300);
    setTimeout(() => {
      elemPrev.addClass("active").fadeIn();
    }, 300);
    this.has_next_img = true;
    if (elemPrev.prev().length) {
      this.has_prev_img = true;
    } else {
      this.has_prev_img = false;
    }
  }
  nextFullImgModal() {
    var elemActive = $(".box-modal-img-full div.active");
    var elemNext = elemActive.next()
    elemActive.removeClass("active").fadeOut(300);
    setTimeout(() => {
      elemNext.addClass("active").fadeIn();
    }, 300);
    this.has_prev_img = true;
    if (elemNext.next().length) {
      this.has_next_img = true;
    } else {
      this.has_next_img = false;
    }
  }
  closeFullImgModal() {
    this.modalImgShow = false;
  }
  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }
  showModalAudio(event, srcAudio: string, srcPreview: string) {
    event.stopPropagation();
    this.modalAudio = true;
    this.modalSrcAudio = srcAudio;
    this.modalSrcAudioPreview = srcPreview;
  }
  closeModalAudio() {
    this.modalAudio = false;
    this.modalSrcAudio = '';
    $("#modal-video").removeClass("active-modal");
  }
} 
