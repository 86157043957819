import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { DesafioService } from '@services/desafio';
import { LoadingService } from '@services/loading';
import * as $ from 'jquery';

@Component({
  selector: 'app-ideas-desafio4',
  templateUrl: './ideas-desafio4.component.html',
  styleUrls: ['./ideas-desafio4.component.scss'],
})
export class IdeasDesafios4Component implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  carouselCharlas = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 15,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselProcesos = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 15,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 3,
        },
        570: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  paramsID: any;
  addClassFixed = false;
  selectedIndex: any[] = [];
  currentPage = 1;
  modalTextShow: any;
  modalText: any;
  modalTitulo: any;
  modalSoluciones = false;
  videosDesafio4: any;
  videosDesafio4_ganadoras: any;
  videosDesafio4_quick: any;
  videosDesafio4Res: any;
  modalTitle: string;
  modalContent: string;
  modalAutor: string;
  modalImage: string;
  videosDesafio4_problemas: any;
  solucionesDesafio4: any;
  procesosDesafio4: any;
  solucionesDesafio4Res: any;
  urlSafe: SafeResourceUrl;
  urlSafe2: SafeResourceUrl;
  urlSafe3: SafeResourceUrl;
  modalVideoIframe = false;
  modalSrcVideoIframe: string = "";
  modalProcesos = false;
  modalProcesosImagenPortada: string;
  modalProcesosImagen: string;
  modalProcesosPdf: string;
  modalProcesosTitle: string;
  modalSolucionesTitle: string;
  modalSolucionesTexto: string;
  modalSolucionesAutor: string;
  modalSolucionesImagen: string;
  modalSolucionesIntegrantes: string;
  modalSolucionesProceso: string;
  modalSolucionesDolores: string;
  modalSolucionesTecnologias: string;
  modalSolucionesDocumentos: any;
  modalSolucionesVideo: string;
  modalSolucionesVideoYoutube1: string;
  modalSolucionesVideoYoutube2: string;
  planta_oficina: string;
  modalImgSingleShow: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private desafioService: DesafioService,
    private title: Title,
    public sanitizer: DomSanitizer,
    private sanitized: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Desafio 4 | Intranet Abastible');
    this.desafioService.getVideosDesafio4().subscribe(
      (res) => {
        this.videosDesafio4 = res;
        console.log(res)
      },
      (err) => {
        console.error(err);
      }
    );
    this.desafioService.getSolucionesDesafio4().subscribe(
      (res) => {
        this.solucionesDesafio4 = res;
        console.log(res)
      },
      (err) => {
        console.error(err);
      }
    );
    this.desafioService.getProcesosDesafio4().subscribe(
      (res) => {
        this.procesosDesafio4 = res;
        console.log(res)
      },
      (err) => {
        console.error(err);
      }
    );
  }

  showModalVideoIframe(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideoIframe = true;
    this.modalSrcVideoIframe = srcVideo;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
  }
  closeModalVideoIframe() {
    this.modalVideoIframe = false;
    this.modalSrcVideoIframe = '';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
    $("#modal-video-youtube").removeClass("active-modal");
  }

  openModalProceso(title: string, imagen_portada: string, imagen: string, pdf: string) {
    this.modalProcesos = true;
    this.modalProcesosImagenPortada = imagen_portada;
    this.modalProcesosImagen = imagen;
    this.modalProcesosPdf = pdf;
    this.modalProcesosTitle = title;
  }

  closeProcesosModal() {
    this.modalProcesos = false;
  }

  openModalSolucion(titulo: string, texto: string, autor: string, imagen: string, integrantes: string, proceso: string, dolores: string, tecnologias: string, documentos: any, video: string, video_youtube: string, video_youtube2: string, planta_oficina: string) {
    this.modalSoluciones = true;
    this.modalSolucionesTitle = titulo;
    this.modalSolucionesTexto = texto;
    this.modalSolucionesAutor = autor;
    this.modalSolucionesImagen = imagen;
    this.modalSolucionesIntegrantes = integrantes;
    this.modalSolucionesProceso = proceso;
    this.modalSolucionesDolores = dolores;
    this.modalSolucionesTecnologias = tecnologias;
    this.modalSolucionesDocumentos = documentos;
    this.modalSolucionesVideo = video;
    this.planta_oficina = planta_oficina;
    this.modalSolucionesVideoYoutube1 = video_youtube;
    this.urlSafe2 = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSolucionesVideoYoutube1);
    this.modalSolucionesVideoYoutube2 = video_youtube2;
    this.urlSafe3 = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSolucionesVideoYoutube2);
  }

  closeSolucionesModal() {
    this.modalSoluciones = false;
    this.modalSolucionesVideoYoutube1 = "";
    this.urlSafe2 = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSolucionesVideoYoutube1);
    this.modalSolucionesVideoYoutube2 = "";
    this.urlSafe3 = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSolucionesVideoYoutube2);
  }

  fullImgSingleModal(src: string) {
    $(".box-modal-img-full").html("");
    $(".box-modal-img-full").append(`
      <img src="${src}" id="img-modal-full" alt="">
    `)

    $("body").delegate(".box-modal-img-full", "mousemove", function (e) {
      const ejex = e.clientX - e.target.offsetLeft - 250
      const ejey = e.clientY - e.target.offsetTop
      console.log(ejex)
      console.log(ejey)
      $("#img-modal-full").css({
        'transformOrigin': `${ejex}px ${ejey}px`,
        'transform': 'scale(2.6)'
      })
    });

    $("body").delegate(".box-modal-img-full", "mouseleave", function (e) {
      $("#img-modal-full").css({
        'transform': 'scale(1)'
      })
    });

    this.modalImgSingleShow = true;
    // this.modalImgSrc = src;
  }

  closeFullImgSingleModal() {
    this.modalImgSingleShow = false;
  }
}
