<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Contacto</span>
      </div>
    </div>
  </div>
</section>
<section class="p-section" id="">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11">
        <div class="text-center mb-3">
          <h1 class="title1 title1-contactanos text-center">Contáctanos</h1>
        </div>
        <form [formGroup]="form" (ngSubmit)="submitForm()">
          <div class="content-form">
            <div class="box-form">
              <div class="form-group">
                <label for="first_name">Nombre</label>
                <input type="text" id="first_name" formControlName="name">
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <input type="email" formControlName="email" id="email">
              </div>
              <div class="form-group">
                <label for="message">Mensaje</label>
                <textarea formControlName="message" id="message" cols="30" rows="4"></textarea>
              </div>
              <div class="box-btn-submit">
                <button type="submit" class="btn-submit">Enviar</button>
              </div>
            </div>
            <h5 class="text-form">Para más información o aclarar dudas, comunicate a través de <a
                href="mailto:comunicaciones.abastible@abastible.cl">comunicaciones.abastible@abastible.cl</a></h5>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<div id="modal-success" class="modal-success" *ngIf="modalSuccess">
  <div class="overlay-modal"></div>
  <div class="modal-content">
    <button type="button" class="close" aria-label="Close" (click)="closeSuccessModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
      <h2 class="title-success">Su mensaje ha sido enviado exitosamente</h2>
    </div>
  </div>
</div>