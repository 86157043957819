import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventosService {
  constructor(private httpClient: HttpClient) { }

  eventosTotal(mesNext: number): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/eventos/?mes_next=${mesNext}`, null)
      .pipe((result) => {
        return result;
      });
  }
  eventosActuales(): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/eventos-actual`, null)
      .pipe((result) => {
        return result;
      });
  }
  eventosSiguientes(): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/eventos-new`, null)
      .pipe((result) => {
        return result;
      });
  }
  eventosSingle(id: number): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/single_evento/?id_evento=${id}`, null)
      .pipe((result) => {
        return result;
      });
  }
}
