import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PerfilService {
  constructor(private httpClient: HttpClient) { }

  infoPerfil(
    user_id: number,
  ): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(
        `${environment2.apiUrl}/perfil`,
        {
          user_id: user_id
        }
      )
      .pipe((result) => {
        return result;
      });
  }
  editarPerfil(formData: any): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/edit-profile/`, formData)
      .pipe((result) => {
        return result;
      });
  }
}
