import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Swiper

import { SwiperComponent, SwiperDirective } from 'ngx-swiper-wrapper';


// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { NewsService } from '@services/news';
import { LoadingService } from '@services/loading';
import { GaleriasService } from '@services/galerias';
import * as $ from 'jquery';

@Component({
  selector: 'app-seguridad',
  templateUrl: './seguridad.component.html',
  styleUrls: ['./seguridad.component.scss'],
})
export class SeguridadComponent implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  addClassFixed = false;
  selectedIndex: any[] = [];
  carouselBanner = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 1,
      autoHeight: true,
      init: true,
      slidesPerView: 1,
    },
  };
  carouselBanner2: any;
  videoinBanner: any;
  sections: any;
  documentos: any;
  noticias_manual: any;
  currentPage = 1;
  currentPage1 = 1;
  currentPage2 = 1;
  news1: any;
  news2: any;
  news2Res: any;
  news1Res: any;
  galerias: any;
  galeriasRes: any;
  modalTextShow: any;
  modalText: any;
  modalTitulo: any;
  categoryid: any;
  hasCategoryid: any;
  modalVideo = false;
  modalSrcVideo: any;
  newContentModal: any;
  modalBanners: any;
  carouselGalerias = {
    index: 0,
    config: {
      direction: 'horizontal',
      observer: true,
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  galeriasIntegridadOperacional: any;
  titlePage: any;
  galeria1: any;
  news3: any;
  news: any;
  galeriasServicioNacionalDeEmergencias: any;
  galeriaServicioNacionalDeEmergencias: any;
  galeriasMedioAmbiente: any;
  galeriaMedioAmbiente: any;
  routeLink: any;
  galeriaIntegridadOpeacional: any;
  modalDocuments: [];
  news4: any;
  galeriasGestionCalidad: any;
  galeriaGestionCalidad: any;
  news5: any;
  galeriasManejoManualDeCarga: any;
  galeriaManejoManualDeCarga: any;
  @ViewChild('carouselNoticias') carouselNoticias: any;
  @ViewChild('carouselNoticias2') carouselNoticias2: any;
  @ViewChild('carouselNoticias3') carouselNoticias3: any;
  @ViewChild('carouselVideos') carouselVideos: any;
  @ViewChild('carouselVideos2') carouselVideos2: any;
  @ViewChild('carouselGalerias1') carouselGalerias1: any;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?:
    SwiperDirective;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private landingService: LandingService,
    private NewsService: NewsService,
    private title: Title,
    private galeriasService: GaleriasService,
    private sanitized: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
    $("body").delegate(".content-ejes-estrategicos", "mousemove", function (e) {
      console.log($('img.icon-mano-dark', this).offset().left);
      console.log(e.pageX - $(this).offset().left);
      var xOld = e.pageX - $(this).offset().left + 50;
      if (xOld > $('img.icon-mano-dark', this).offset().left) {
        var x = e.pageX - $(this).offset().left + 50;
        $('.content-ejes-estrategicos-gray', this).css({ width: x });
        $('img.icon-mano-dark', this).css({ left: x });
      }
    });
    $("body").delegate(".pin-map", "click", function () {
      var dataPin = $(this).attr("href");
      $(".pin-map, .box-text-modal-map").removeClass("active")
      $(this).addClass("active");
      $("." + dataPin).addClass("active");
    });
  }

  ngAfterViewInit(): void {

    //Swiper instance will be displayed in console
  }

  init(): void {
    this.title.setTitle('Seguridad | Intranet Abastible');
    this.paramsID = 2409;
    this.slug = this.params['slug'];
    this.titlePage = "Seguridad";
    this.routeLink = "seguridad";
    localStorage.setItem('back_title_page', this.titlePage);
    localStorage.setItem('back_route_link', this.routeLink);
    localStorage.removeItem('back_section_route_title');
    localStorage.removeItem('back_section_route_link');

    this.landingService.getInfoNew(this.paramsID).subscribe(
      (res) => {
        let that = this;
        this.landing = res;
        this.sections = res.sections;
        this.documentos = res.documentos;
        this.noticias_manual = res.noticias_manual;
        console.log(this.sections)
        this.openModalHash();

        setTimeout(function () {
          that.carouselNoticias.nativeElement.swiper.slideTo(0);
          that.carouselVideos.nativeElement.swiper.slideTo(0);
        }, 700);
      },
      (err) => {
        console.error(err);
      }
    );

    /* Seguridad */

    this.NewsService.getNews("seguridad", 1).subscribe(
      (res) => {
        this.news = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaVideosByCategory("seguridad", 1, 8, "").subscribe(
      (res) => {
        this.galerias = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaByCategory("seguridad", 1, "").subscribe(
      (res) => {
        this.galeria1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    /* Medio Ambiente */

    this.NewsService.getNews("medio-ambiente", 1).subscribe(
      (res) => {
        this.news1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaByCategory("medio-ambiente", 1, "").subscribe(
      (res) => {
        this.galeriasMedioAmbiente = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaVideosByCategory("medio-ambiente", 1, 8, "").subscribe(
      (res) => {
        this.galeriaMedioAmbiente = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    /* Integridad Operacional */

    this.NewsService.getNews("integridad-operacional", 1).subscribe(
      (res) => {
        this.news2 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaByCategory("integridad-operacional", 1, "").subscribe(
      (res) => {
        this.galeriasIntegridadOperacional = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaVideosByCategory("integridad-operacional", 1, 8, "").subscribe(
      (res) => {
        this.galeriaIntegridadOpeacional = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    /* Servicio nacional de emergencias */

    this.NewsService.getNews("servicio-nacional-de-emergencias", 1).subscribe(
      (res) => {
        this.news3 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaByCategory("servicio-nacional-de-emergencias", 1, "").subscribe(
      (res) => {
        this.galeriasServicioNacionalDeEmergencias = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaVideosByCategory("servicio-nacional-de-emergencias", 1, 8, "").subscribe(
      (res) => {
        this.galeriaServicioNacionalDeEmergencias = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    /* Gestión de Calidad */

    this.NewsService.getNews("gestion-de-calidad", 1).subscribe(
      (res) => {
        this.news4 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaByCategory("gestion-de-calidad", 1, "").subscribe(
      (res) => {
        this.galeriasGestionCalidad = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaVideosByCategory("gestion-de-calidad", 1, 8, "").subscribe(
      (res) => {
        this.galeriaGestionCalidad = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    /* Manejo Manual de Carga */

    this.NewsService.getNews("manejo-manual-de-carga", 1).subscribe(
      (res) => {
        this.news5 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaByCategory("manejo-manual-de-carga", 1, "").subscribe(
      (res) => {
        this.galeriasManejoManualDeCarga = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaVideosByCategory("manejo-manual-de-carga", 1, 8, "").subscribe(
      (res) => {
        this.galeriaManejoManualDeCarga = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    $("body").delegate(".item-video-galeria", "click", function () {
      var dataVideo = $(this).data("video");
      $("#modal-video .box-modal-video-full video").attr("src", dataVideo);
      $("#modal-video").addClass("active-modal");
    });

  }
  openModalHash() {
    setTimeout(() => {
      this.activatedRoute.fragment.subscribe((fragment: string) => {
        localStorage.setItem('back_section_route_link', '#' + fragment);
        $("#" + fragment + " .btn-custom-new").trigger("click");
        console.log("My hash fragment is here => ", fragment)
        console.log($("#" + fragment + " .btn-custom-new").length);
      })
    }, 5);
  }
  nextPageGaleriaVideos() {
    this.currentPage = this.currentPage + 1;

    this.galeriasService.galeriaVideosByCategory("seguridad", this.currentPage, 8, "").subscribe(
      (res) => {
        this.galeriasRes = res;
        this.galerias = [...this.galerias, ...this.galeriasRes];
        console.log([...this.galerias, res]);
        console.log(this.galeriasRes)
      },
      (err) => {
        console.error(err);
      }
    );
  }
  nextPage() {
    this.currentPage1 = this.currentPage1 + 1;

    this.NewsService.getNews("noticias-de-seguridad", this.currentPage1).subscribe(
      (res) => {
        this.news1Res = res;
        this.news1 = [...this.news1, ...this.news1Res];
        console.log([...this.news1, res]);
        console.log(this.news1Res)
      },
      (err) => {
        console.error(err);
      }
    );
  }
  nextPage2() {
    this.currentPage2 = this.currentPage2 + 1;

    this.NewsService.getNews("manual-libre-competencia", this.currentPage2).subscribe(
      (res) => {
        this.news2Res = res;
        this.news2 = [...this.news2, ...this.news2Res];
        console.log([...this.news2, res]);
        console.log(this.news2Res)
      },
      (err) => {
        console.error(err);
      }
    );
  }

  scroll(id) {
    console.log(`scrolling to ${id}`);
    let el = document.getElementById(id);
    this.modalTextShow = false;
    var body = document.body;
    body.classList.remove("modal-open");
    body.classList.remove("modal-single-content-open");
    el.scrollIntoView({
      behavior: 'smooth'
    });
  }

  textMin(text: string) {
    var str = text;
    var res = str.substring(0, 200);//get first 5 chars
    return res + '...';;
  }

  contentFilter(content: string): string {
    return content.replace(/<img .*?>/g, '');
  }

  setSelected(id: number) {
    if (this.selectedIndex[id] == true) {
      this.selectedIndex[id] = false;
    } else {
      this.selectedIndex[id] = true;
    }
  }
  fullTextModal(titulo: string, text: string, banners: any, section: string, documentos: any) {
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "#" + section;
    var newurlSet = window.location.protocol + "//" + window.location.host + window.location.pathname + "-" + section;
    const date = new Date();
    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }
    let day = date.getDate();
    let month = n((date.getMonth() + 1));
    let year = date.getFullYear();
    let currentDate = `${day}/${month}/${year}`;
    
    this.authService.analitics(currentDate, newurlSet).subscribe(
      (data) => {
        console.log('data ', data);
      },
      (err) => {
        console.log('err ', err);
      }
    );
    window.history.pushState({ path: newurl }, '', newurl);
    localStorage.setItem('back_section_route_link', '#' + section);
    localStorage.setItem('back_section_route_title', titulo);
    this.modalTextShow = true;
    this.modalTitulo = titulo;
    this.modalText = text;
    this.modalBanners = banners;
    this.modalDocuments = documentos;
    console.log(this.modalText);
    this.newContentModal = this.sanitized.bypassSecurityTrustHtml(this.modalText);
    var body = document.body;
    this.carouselBanner2 = {
      index: 0,
      config: {
        direction: 'horizontal',
        spaceBetween: 5,
        init: true,
        slidesPerView: 1,
      },
    };
    body.classList.add("modal-open");
    body.classList.add("modal-single-content-open");
  }
  closeFullTextModal() {
    this.modalTextShow = false;
    var body = document.body;
    body.classList.remove("modal-open");
    body.classList.remove("modal-single-content-open");
  }

  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }
}
