import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TooltipPosition } from '@angular/material/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { NewsService } from '@services/news';
import { LoadingService } from '@services/loading';
import { GaleriasService } from '@services/galerias';
import * as $ from 'jquery';
import { UsersService } from '@app/services/users';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-hacer-las-cosas-bien',
  templateUrl: './hacer-las-cosas-bien.component.html',
  styleUrls: ['./hacer-las-cosas-bien.component.scss'],
})
export class HacerLasCosasBienComponent implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  addClassFixed = false;
  selectedIndex: any[] = [];
  linksScrollSwiper = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      slidesPerView: 1,
    },
  };
  carouselBanner = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 1,
      autoHeight: true,
      init: true,
      slidesPerView: 1,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
    },
  };
  carouselBanner2: any;
  videoinBanner: any;
  sections: any;
  documentos: any;
  noticias_manual: any;
  currentPage = 1;
  currentPage1 = 1;
  currentPage2 = 1;
  news1: any;
  news2: any;
  news2Res: any;
  news1Res: any;
  galerias: any;
  galeriasRes: any;
  modalTextShow: any;
  modalText: any;
  modalTitulo: any;
  categoryid: any;
  hasCategoryid: any;
  modalVideo = false;
  modalSrcVideo: any;
  newContentModal: any;
  modalBanners: any;
  carouselCharlas = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselCharlas2 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselCharlas3 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselCharlas4 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselCharlas5 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselCharlas6 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselCharlas7 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselCharlas8 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselCharlas9 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  galeriaVideosSeguridad: any;
  newsSeguridadInformacion: any;
  newsProcesos: any;
  galeriaVideosProcesos: any;
  newsRiesgos: any;
  galeriaVideosRiesgos: any;
  galeriasRiesgos: any;
  galeriasProcesos: any;
  titlePage: any;
  routeLink: any;
  newsAuditoriaInterna: any;
  galeriaVideosAuditoriaInterna: any;
  galeriasAuditoriaInterna: any;
  newsDuenosProcesos: any;
  galeriaVideosDuenosProcesos: any;
  galeriasDuenosProcesos: any;
  galeria1: any;
  galeriaVideos1: any;
  galeriaSeguridad: any;
  modalDocuments: [];
  modalVideoIframe = false;
  modalSrcVideoIframe: string = "";
  urlSafe: SafeResourceUrl;
  carouselMembers = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 0,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 6,
        },
        768: {
          slidesPerView: 5,
        },
        570: {
          slidesPerView: 3,
        },
        0: {
          slidesPerView: 2,
        },
      },
    },
  };
  addForm: FormGroup;
  users: any;
  file: File = null;
  infoProcesos: any;
  userLogged: any;
  @ViewChild('carouselNoticias') carouselNoticias: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private landingService: LandingService,
    private NewsService: NewsService,
    private title: Title,
    private galeriasService: GaleriasService,
    private sanitized: DomSanitizer,
    public sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private UsersService: UsersService,
    private route: ActivatedRoute,
    private http: HttpClient,
  ) {

    this.addForm = this.formBuilder.group({
      user_add: this.formBuilder.control(null, [
        Validators.required,
        Validators.minLength(1),
      ]),
      cargo_user: this.formBuilder.control(null, [
        Validators.required,
        Validators.minLength(1),
      ]),
      photo_user: this.formBuilder.control(null, [
        Validators.required
      ]),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
    this.userLogged = JSON.parse(localStorage.getItem('user'));
    console.log(this.userLogged.role_process);
    $("body").delegate(".item-tabs-auditoria", "click", function (e) {
      e.preventDefault();
      var target = $(this).data("target");
      if (!$(this).hasClass("active")) {
        $(".item-tabs-auditoria").removeClass("active");
        $(this).addClass("active");
        $(".items-tabs-auditoria-body .item-tabs-auditoria").slideUp(300);
        setTimeout(() => {
          $(target).slideDown(300)
        }, 300);
      }
    });
    $("body").delegate(".btn-play-video", "click", function (e) {
      e.preventDefault();
      $(this).fadeOut();
      $(this).parent().find(".video-preview-desafio6").fadeOut();
      $(this).parent().find("video").fadeIn();
      $(this).parent().find("video").get(0).play();
    });
    $("body").delegate(".content-ejes-estrategicos", "mousemove", function (e) {
      console.log($('img.icon-mano-dark', this).offset().left);
      console.log(e.pageX - $(this).offset().left);
      var xOld = e.pageX - $(this).offset().left + 50;
      if (xOld > $('img.icon-mano-dark', this).offset().left) {
        var x = e.pageX - $(this).offset().left + 50;
        $('.content-ejes-estrategicos-gray', this).css({ width: x });
        $('img.icon-mano-dark', this).css({ left: x });
      }
    });
    $("body").delegate(".pin-map", "click", function () {
      var dataPin = $(this).attr("href");
      $(".pin-map, .box-text-modal-map").removeClass("active")
      $(this).addClass("active");
      $("." + dataPin).addClass("active");
    });
    $("body").delegate(".item-tab-proceso", "click", function () {
      var titleModal = $(this).find(".box-content-item-tab-proceso h6").text();
      var textModal = $(this).find(".box-text-item-tab-proceso").html();
      $(".modal-custom3-text").fadeIn();
      $('.modal-custom3-text .title-custom3-text').text(titleModal);
      $('.modal-custom3-text .box-text-custome-text').html(textModal);
    });
    $("body").delegate(".modal-custom3-text .close", "click", function () {
      $(".modal-custom3-text").fadeOut();
    });
    $("body").delegate(".modal-custom3-text .overlay", "click", function () {
      $(".modal-custom3-text").fadeOut();
    });
    $("body").delegate(".item-pmo", "click", function (e) {
      e.preventDefault()
      var titleModal = $(this).find(".title-item-pmo").text();
      var textModal = $(this).find(".box-text-item-pmo").html();
      $(".modal-custom3-text").fadeIn();
      $('.modal-custom3-text .title-custom3-text').text(titleModal);
      $('.modal-custom3-text .box-text-custome-text').html(textModal);
    });
    $("body").delegate(".item-open-modal", "click", function (e) {
      e.preventDefault()
      var titleModal = $(this).attr("data-title");
      var textModal = $(this).attr("data-text");
      $(".modal-custom3-text").fadeIn();
      $('.modal-custom3-text .title-custom3-text').text(titleModal);
      $('.modal-custom3-text .box-text-custome-text').html(textModal);
    });
    $("body").delegate(".modal-custom3-text .close", "click", function () {
      $(".modal-custom3-text").fadeOut();
    });
    $("body").delegate(".modal-custom3-text .overlay", "click", function () {
      $(".modal-custom3-text").fadeOut();
    });
    $("body").delegate(".img-click-full", "click", function (e) {
      e.preventDefault();
      var srcImage = $(this).attr("src");
      $('#show-full-img').fadeIn();
      $('#show-full-img .box-modal-img-full').html(" ");
      $('#show-full-img .box-modal-img-full').append(`
        <img src="${srcImage}" class="img-fluid">
      `)
    });
    $("body").delegate("#show-full-img .close", "click", function (e) {
      e.preventDefault();
      $('#show-full-img').fadeOut();
    });
    $("body").delegate("#show-full-img .overlay-modal", "click", function (e) {
      e.preventDefault();
      $('#show-full-img').fadeOut();
    });

  }
  init(): void {
    this.title.setTitle('Control Interno | Intranet Abastible');
    this.paramsID = 2381;
    this.slug = this.params['slug'];
    this.titlePage = "Control Interno";
    this.routeLink = "control-interno";
    localStorage.setItem('back_title_page', this.titlePage);
    localStorage.setItem('back_route_link', this.routeLink);
    localStorage.removeItem('back_section_route_title');
    localStorage.removeItem('back_section_route_link');

    this.UsersService.getUsers().subscribe(
      (res) => {
        this.users = res;
        console.log(this.users);
      },
      (err) => {
        console.error(err);
      }
    );

    $("body").delegate(".page-duenos-procesos-section2-content-btn2", "click", function (e) {
      e.preventDefault();
      $(".modal-add-custom1").fadeIn(300)
    });
    $("body").delegate(".modal-add-custom1-overlay", "click", function (e) {
      e.preventDefault();
      $(".modal-add-custom1").fadeOut(300)
    });

    this.landingService.getInfoNew(this.paramsID).subscribe(
      (res) => {
        let that = this;
        this.landing = res;
        this.sections = res.sections;
        this.documentos = res.documentos;
        this.noticias_manual = res.noticias_manual;
        console.log(this.sections)
        this.openModalHash()
        setTimeout(function () {
          that.carouselNoticias.nativeElement.swiper.slideTo(0);
        }, 700);
      },
      (err) => {
        console.error(err);
      }
    );
    this.NewsService.getNews("hacer-las-cosas-bien", 1).subscribe(
      (res) => {
        this.news1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.landingService.getInfoSection(90211).subscribe(
      (res) => {
        this.infoProcesos = res;
        console.log("infoProcesos", res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaByCategory("hacer-las-cosas-bien", 1, "").subscribe(
      (res) => {
        this.galeria1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriaVideos1 = [];
    this.galeriasService.galeriaVideosByCategory("hacer-las-cosas-bien", 1, 8, "").subscribe(
      (res) => {
        this.galeriaVideos1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.NewsService.getNews("manual-libre-competencia", 1).subscribe(
      (res) => {
        this.news2 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    // Seguridad de Informacion

    this.NewsService.getNews("seguridad-de-la-informacion", 1).subscribe(
      (res) => {
        this.newsSeguridadInformacion = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.galeriasService.galeriaByCategory("seguridad-de-la-informacion", 1, "").subscribe(
      (res) => {
        this.galeriaSeguridad = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.galeriasService.galeriaVideosByCategory("seguridad-de-la-informacion", 1, 8, "").subscribe(
      (res) => {
        this.galeriaVideosSeguridad = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    // Gestion de Riesgo

    this.NewsService.getNews("riesgo", 1).subscribe(
      (res) => {
        this.newsRiesgos = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.galeriasService.galeriaVideosByCategory("riesgo", 1, 8, "").subscribe(
      (res) => {
        this.galeriaVideosRiesgos = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaByCategory("riesgo", 1, "").subscribe(
      (res) => {
        this.galeriasRiesgos = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    // Gestion de Procesos

    this.NewsService.getNews("gestion-de-procesos", 1).subscribe(
      (res) => {
        this.newsProcesos = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.galeriasService.galeriaVideosByCategory("procesos", 1, 8, "").subscribe(
      (res) => {
        this.galeriaVideosProcesos = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasService.galeriaByCategory("procesos", 1, "").subscribe(
      (res) => {
        this.galeriasProcesos = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    // Auditoria interna

    this.newsAuditoriaInterna = [];
    this.NewsService.getNews("auditoria-interna", 1).subscribe(
      (res) => {
        this.newsAuditoriaInterna = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriaVideosAuditoriaInterna = [];
    this.galeriasService.galeriaVideosByCategory("auditoria-interna", 1, 8, "").subscribe(
      (res) => {
        this.galeriaVideosAuditoriaInterna = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasAuditoriaInterna = [];
    this.galeriasService.galeriaByCategory("auditoria-interna", 1, "").subscribe(
      (res) => {
        this.galeriasAuditoriaInterna = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    // Dueños de procesos

    this.newsDuenosProcesos = [];
    this.NewsService.getNews("duenos-de-procesos", 1).subscribe(
      (res) => {
        this.newsDuenosProcesos = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriaVideosDuenosProcesos = [];
    this.galeriasService.galeriaVideosByCategory("duenos-de-procesos", 1, 8, "").subscribe(
      (res) => {
        this.galeriaVideosDuenosProcesos = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.galeriasDuenosProcesos = [];
    this.galeriasService.galeriaByCategory("duenos-de-procesos", 1, "").subscribe(
      (res) => {
        this.galeriasDuenosProcesos = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

  }
  // nextPageGaleriaVideos() {
  //   this.currentPage = this.currentPage + 1;

  //   this.galeriasService.galeriaVideosSeguridad(3, this.currentPage, "").subscribe(
  //     (res) => {
  //       this.galeriasRes = res;
  //       this.galerias = [...this.galerias, ...this.galeriasRes];
  //       console.log([...this.galerias, res]);
  //       console.log(this.galeriasRes)
  //     },
  //     (err) => {
  //       console.error(err);
  //     }
  //   );
  // }
  // nextPage() {
  //   this.currentPage1 = this.currentPage1 + 1;

  //   this.NewsService.getNews("hacer-las-cosas-bien", this.currentPage1).subscribe(
  //     (res) => {
  //       this.news1Res = res;
  //       this.news1 = [...this.news1, ...this.news1Res];
  //       console.log([...this.news1, res]);
  //       console.log(this.news1Res)
  //     },
  //     (err) => {
  //       console.error(err);
  //     }
  //   );
  // }
  // nextPage2() {
  //   this.currentPage2 = this.currentPage2 + 1;

  //   this.NewsService.getNews("manual-libre-competencia", this.currentPage2).subscribe(
  //     (res) => {
  //       this.news2Res = res;
  //       this.news2 = [...this.news2, ...this.news2Res];
  //       console.log([...this.news2, res]);
  //       console.log(this.news2Res)
  //     },
  //     (err) => {
  //       console.error(err);
  //     }
  //   );
  // }

  openModalHash() {
    setTimeout(() => {
      this.route.fragment.subscribe((fragment: string) => {
        localStorage.setItem('back_section_route_link', '#' + fragment);
        $("#" + fragment + " .btn-custom-new").trigger("click");
        console.log("My hash fragment is here => ", fragment)
        console.log($("#" + fragment + " .btn-custom-new").length);
      })
    }, 5);
  }

  scroll(id) {
    console.log(`scrolling to ${id}`);
    let el = document.getElementById(id);
    this.modalTextShow = false;
    var body = document.body;
    body.classList.remove("modal-open");
    body.classList.remove("modal-single-content-open");
    el.scrollIntoView({
      behavior: 'smooth'
    });
  }

  textMin(text: string) {
    var str = text;
    var res = str.substring(0, 200);//get first 5 chars
    return res + '...';;
  }

  contentFilter(content: string): string {
    return content.replace(/<img .*?>/g, '');
  }

  setSelected(id: number) {
    if (this.selectedIndex[id] == true) {
      this.selectedIndex[id] = false;
    } else {
      this.selectedIndex[id] = true;
    }
  }
  fullTextModal(titulo: string, text: string, banners: any, section: string, documentos: any) {
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "#" + section;
    var newurlSet = window.location.protocol + "//" + window.location.host + window.location.pathname + "-" + section;
    const date = new Date();
    function n(n) {
      return n > 9 ? "" + n : "0" + n;
    }
    let day = date.getDate();
    let month = n((date.getMonth() + 1));
    let year = date.getFullYear();
    let currentDate = `${day}/${month}/${year}`;

    this.authService.analitics(currentDate, newurlSet).subscribe(
      (data) => {
        console.log('data ', data);
      },
      (err) => {
        console.log('err ', err);
      }
    );
    window.history.pushState({ path: newurl }, '', newurl);
    localStorage.setItem('back_section_route_link', '#' + section);
    localStorage.setItem('back_section_route_title', titulo);
    this.modalTextShow = true;
    this.modalTitulo = titulo;
    this.modalText = text;
    this.modalBanners = banners
    console.log("modalBanners", this.modalBanners)
    this.modalDocuments = documentos;
    console.log(this.modalDocuments);
    this.newContentModal = this.sanitized.bypassSecurityTrustHtml(this.modalText);
    var body = document.body;
    this.carouselBanner2 = {
      index: 0,
      config: {
        direction: 'horizontal',
        spaceBetween: 5,
        autoHeight: true,
        init: true,
        slidesPerView: 1,
      },
    };
    body.classList.add("modal-open");
    body.classList.add("modal-single-content-open");

    // Run the scrNav when scroll
    $(".box-modal-text-full").on('scroll', function () {
      scrNav();
    });

    // scrNav function 
    // Change active dot according to the active section in the window
    function scrNav() {
      if ($('.col-procesos-title').length) {
        var sTop = $(window).scrollTop();
        $('.col-procesos-title').each(function () {
          var id = $(this).attr('id'),
            offset = $(this).offset().top - 300,
            height = $(this).height();
          if (sTop >= offset && sTop < offset + height) {
            $('.col-procesos-title').removeClass("active")
            $(this).addClass("active")
          }
        });
      }
    }
    scrNav();
  }
  closeFullTextModal() {
    this.modalTextShow = false;
    var body = document.body;
    body.classList.remove("modal-open");
    body.classList.remove("modal-single-content-open");
  }

  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }

  showModalVideoIframe(event, srcVideo: string) {
    event.stopPropagation();
    let prevText = "https://www.youtube.com/embed/";
    this.modalVideoIframe = true;
    this.modalSrcVideoIframe = prevText + srcVideo;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
  }
  closeModalVideoIframe() {
    this.modalVideoIframe = false;
    this.modalSrcVideoIframe = '';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
    $("#modal-video-youtube").removeClass("active-modal");
  }

  addUserProcess() {
    var that = this;
    const formData = new FormData();
    formData.append("user_add", this.addForm.controls.user_add.value);
    formData.append("cargo_user", this.addForm.controls.cargo_user.value);
    formData.append("async-upload", this.file);

    if (this.addForm.controls.user_add.value && this.addForm.controls.cargo_user.value && this.file) {
      that.http.post('https://intranet.abastible.cl/wp/wp-json/v2/add_user_process', formData)
        .subscribe(res => {
          console.log(res);
          location.reload()
        })
    }
    return;
  }

  removeMemberProcess(index) {
    var that = this;
    const formData = new FormData();
    formData.append("index", index);
    that.http.post('https://intranet.abastible.cl/wp/wp-json/v2/remove_user_process', formData)
      .subscribe(res => {
        console.log(res);
        location.reload()
      })
    return;
  }

  onFilechange(event: any) {
    console.log(event.target.files[0])
    this.file = event.target.files[0]
  }
}
