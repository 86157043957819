<section class="navbar-custom2">
  <div class="container">
    <div class="col-12 home-menu">
      <a [routerLink]="['/conoce-abastible']" class="item-navbar-custom2 active item-video-de-induccion-abastible"
        data-position="0" title="Conoce abastible">
        <!-- <img alt="" src="assets/img/icons/icon-new-8.png"> -->
        <h6 class="title-navbar-custom">Conoce<br />Abastible</h6>
      </a>
      <a [routerLink]="['/gobierno-corporativo']" class="item-navbar-custom2 item-directorio-administracion"
        data-position="1" title="Gobierno corporativo">
        <!-- <img alt="" src="assets/img/icons/gobierno-corporativo@2x.png"> -->
        <h6 class="title-navbar-custom">Gobierno<br />corporativo</h6>
      </a>
      <!-- <a [routerLink]="['/control-interno']" class="item-navbar-custom2 item-directorio-administracion"
        data-position="1" title="Control Interno">
        <h6 class="title-navbar-custom">Hacer Las <br />Cosas Bien</h6>
      </a> -->
      <a [routerLink]="['/desarrollo-organizacional']" class="item-navbar-custom2 item-proceso-productivo"
        data-position="5" title="Desarrollo organizacional">
        <!-- <img alt="" src="assets/img/icons/icon-desarrollo-organizacional.svg"> -->
        <h6 class="title-navbar-custom">Desarrollo <br />Organizacional</h6>
      </a>
      <a [routerLink]="['/seguridad']" class="item-navbar-custom2 item-vision-mision-valores" data-position="3"
        title="Seguridad">
        <!-- <img alt="" src="assets/img/icons/icon-seguridad.svg"> -->
        <h6 class="title-navbar-custom">Seguridad</h6>
      </a>
      <a [routerLink]="['/beneficios']" class="item-navbar-custom2 item-productos-servicios" data-position="4"
        title="Beneficios">
        <!-- <img alt="" src="assets/img/icons/beneficios@2x.png"> -->
        <h6 class="title-navbar-custom">Beneficios</h6>
      </a>
      <a [routerLink]="['/soluciones-energeticas']" class="item-navbar-custom2 item-presentacion-empresa"
        data-position="2" title="Soluciones Energéticas">
        <!-- <img alt="" src="assets/img/icons/icon-inow.svg"> -->
        <h6 class="title-navbar-custom">Soluciones <br>Energéticas</h6>
      </a>
      <a [routerLink]="['/sostenibilidad']" class="item-navbar-custom2 item-presentacion-empresa" data-position="2"
        title="Innovación">
        <!-- <img alt="" src="assets/img/icons/icon-inow.svg"> -->
        <h6 class="title-navbar-custom">Sostenibilidad</h6>
      </a>
    </div>
  </div>
</section>
<div class="container container-parent">
  <div class="row">
    <div class="col-lg-9">
      <section class="box-descubre">
        <h2 class="title-design2">Descubre</h2>
        <div class="grid-descubre">
          <div class="grid-item">
            <a href="{{ descubreArray1.enlace }}" target="_blank" class="card-descubre" *ngIf="descubreArray1">
              <img [src]="descubreArray1.img_intranet" alt="" class="card-descubre-img" />
              <img [src]="descubreArray1.img" alt="" class="card-descubre-img card-descubre-img-mobile" />
              <div class="box-text">
                <h3 class="card-descubre-title" [innerHTML]="descubreArray1.title"></h3>
                <h4 class="card-descubre-subtitle" [innerHTML]="descubreArray1.texto"></h4>
              </div>
            </a>
          </div>
          <div class="grid-item">
            <a href="{{ descubreArray2.enlace }}" target="_blank" class="card-descubre" *ngIf="descubreArray2">
              <img [src]="descubreArray2.img_intranet" alt="" class="card-descubre-img" />
              <img [src]="descubreArray2.img" alt="" class="card-descubre-img card-descubre-img-mobile" />
              <div class="box-text">
                <h3 class="card-descubre-title" [innerHTML]="descubreArray2.title"></h3>
                <h4 class="card-descubre-subtitle" [innerHTML]="descubreArray2.texto"></h4>
              </div>
            </a>
            <a href="{{ descubreArray3.enlace }}" target="_blank" class="card-descubre" *ngIf="descubreArray3">
              <img [src]="descubreArray3.img_intranet" alt="" class="card-descubre-img" />
              <img [src]="descubreArray3.img" alt="" class="card-descubre-img card-descubre-img-mobile" />
              <div class="box-text">
                <h3 class="card-descubre-title" [innerHTML]="descubreArray3.title"></h3>
                <h4 class="card-descubre-subtitle" [innerHTML]="descubreArray3.texto"></h4>
              </div>
            </a>
          </div>
          <div class="grid-item">
            <a href="{{ descubreArray4.enlace }}" target="_blank" class="card-descubre" *ngIf="descubreArray4">
              <img [src]="descubreArray4.img_intranet" alt="" class="card-descubre-img" />
              <img [src]="descubreArray4.img" alt="" class="card-descubre-img card-descubre-img-mobile" />
              <div class="box-text">
                <h3 class="card-descubre-title" [innerHTML]="descubreArray4.title"></h3>
                <h4 class="card-descubre-subtitle" [innerHTML]="descubreArray4.texto"></h4>
              </div>
            </a>
          </div>
        </div>
      </section>

      <!-- <div class="box-concurso-app">
        <img
          src="assets/img/bg-concurso-app.jpg"
          alt=""
          class="box-concurso-app-header"
        />
        <h3 class="title-box-concurso-app">
          ¡Mira las imágenes del concurso de la App Conexión Naranja! <br />¿Y
          tú qué esperas para participar?
        </h3>
        <div
          class="swiper-container gallery-carousel-post"
          [swiper]="galleryPost2.config"
          [(index)]="galleryPost2.index"
        >
          <div class="swiper-wrapper">
            <div
              class="img-galley swiper-slide"
              *ngFor="let image of fotosConcursosApp; let j = index"
            >
              <img
                src="{{ image.feature_img }}"
                alt="gallery-single10000"
                id="img-gallery-single{{ j }}"
                (click)="fullImgModal(image.feature_img, 10000, j)"
              />
            </div>
          </div>

          <div class="swiper-button-prev">
            <span class="material-icons">
              keyboard_arrow_left
            </span>
          </div>
          <div class="swiper-button-next">
            <span class="material-icons">
              keyboard_arrow_right
            </span>
          </div>
        </div>
      </div> -->

      <!-- <div class="box-votar"> 
        <img src="assets/img/bg-box-votar.jpg" class="bg-box-votar" alt="">
        <img src="assets/img/phone-votar.png" class="cohete-box-votar" alt="">
        <div class="box-votar-header">
          <img src="assets/img/titulo-vota.png" alt="" class="img-votar-header">
        </div>
        <div class="box-votar-body">
          <form action="" [formGroup]="votarForm" (ngSubmit)="submitVotarForm()">
            <div class="items-votar" [ngClass]="{'show_porcentaje': !showVotacion}">
              <div class="item-votar" *ngFor="let item of arrayVotar; let i = index">
                <input type="radio" name="votar_section" formControlName="votar_section" value="{{ item.ID }}" id="votar_section{{ i }}">
                <label for="votar_section{{ i }}"><span class="name-votacion">{{ item.nombre }}</span><span class="porcentaje-votacion">{{ item.porcentaje }}%</span></label>
              </div>
            </div>
            <div class="box-submit-votar" *ngIf="showVotacion">
              <button type="submit">Enviar</button>
            </div>
          </form>
        </div>
        <div class="box-votar-footer">
          <div class="box-text-votar-footer">
            <p>Selecciona el nombre que más te guste</p>
          </div>
        </div>
      </div> -->

      <!-- <div class="box-video-interactivo">
        <img src="assets/img/fondo-web.jpg" class="bg-video-interactivo" alt="">
        <div class="box-int-video-interactivo">
          <iframe class="_vs_ictr_player" [src]="urlSafe" width=720 height=405 frameborder="0" allow="autoplay *" scrolling="no" ></iframe><script src="https://vsplayer.global.ssl.fastly.net/player-wrapper-v4.js"></script>
        </div>
      </div> -->

      <!-- <a [routerLink]="['/ideas-desafio7']" class="link-to-form-desafio7-muro">
        <img src="assets/img/banner-muro-desafio7.jpg" alt="">
      </a> -->

      <section class="box-noticias">
        <div class="box-card-design3" *ngFor="let activity of activities; let i = index" data-id="{{ activity.id }}">
          <template *ngIf="imgBannerLength > 0">
            <div class="box-card-design3-no-img" *ngIf="i % 4 == 0 && i != 0">
              <a href="#" onclick="event.preventDefault();" target="_blank" class="card-design3 card-design3-no-img"
                *ngIf="get_enlace_banner(i)[0] == '' && get_enlace_banner(i)[1]">
                <div class="card-body">
                  <img src="{{ get_index_banner(i) }}" alt="" style="cursor: pointer;" class="card-img-design3" />
                </div>
              </a>
              <a href="{{ get_enlace_banner(i)[0] }}" target="_blank" class="card-design3 card-design3-no-img"
                *ngIf="get_enlace_banner(i)[0] != ''">
                <div class="card-body">
                  <img src="{{ get_index_banner(i) }}" alt="" style="cursor: pointer;" class="card-img-design3" />
                </div>
              </a>
            </div>
          </template>
          <div class="card-design3 activity-post" id="activity{{ activity.id }}" [ngClass]="{
              'activity-post-large': activity.notice_large === 'si'
            }" *ngIf="!activity.newsletter && !activity.videos_post_related">
            <div class="card-haeder">
              <div [innerHTML]="activity.avatar_img" class="box-img-profile"></div>
              <!-- <img src="assets/img/img-profile.png" alt="" class="img-profile" /> -->
              <h3 class="card-title-header">{{ activity.display_name }}</h3>
              <!-- <img src="assets/img/logo-conexion.png" alt="" class="card-title-header-img"> -->
              <a [routerLink]="['/noticia/' + activity.slug]" class="card-title-activity-header"
                [innerHTML]="contentFilter(activity.title)"></a>
              <span class="heading-date">
                <img src="assets/img/icons/calendar.svg" alt="Calendario" />
                Publicado {{ activity.date_recorded | date: 'dd/MM/yyy' }}
              </span>
              <button class="btn-delete-activity" (click)="deleteActivity(activity.id)"
                *ngIf="loggedIn && userLogged.ID == 1">
                Eliminar Actividad
              </button>
            </div>
            <div class="card-body" [ngClass]="{ 'card-body-no-logged': !loggedIn }">
              <a [routerLink]="['/noticia/' + activity.slug]" routerLinkActive="active" *ngIf="
                  activity.img &&
                  !activity.external_link &&
                  !activity.video &&
                  !activity.youtube_id
                ">
                <img [src]="activity.img" alt="" class="card-img-design3" />
              </a>
              <!-- <video
                controls
                poster="{{ getActivityImage(activity.content) }}"
                *ngIf="
                  !activity.video &&
                  !activity.external_link
                "
              >
                <source
                  [src]="
                    getActivityLinkVideo(activity.content, activity.content_new)
                  "
                />
              </video> -->
              <a href="{{ activity.external_link }}" target="_blank" target="_blank"
                *ngIf="activity.external_link && !activity.youtube_id">
                <img [src]="getActivityImage(activity.content)" alt="" class="card-img-design3" />
              </a>
              <video controls poster="{{ getActivityImage(activity.content) }}"
                *ngIf="activity.video && !activity.youtube_id">
                <source [src]="activity.video" />
              </video>
              <iframe width="560" height="315" [src]="getUrlSafeYoutube(activity.youtube_id)"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen *ngIf="activity.youtube_id"></iframe>
              <div class="card-tags-design3">
                <!-- <h5>#Photoshop</h5>
                  <h5>#Smile</h5>
                  <h5>#Beautiful</h5>
                  <h5>#Fashion</h5> -->
              </div>
              <div class="card-text-design3" *ngIf="!activity.gallery">
                <div class="content-documents-custom-new3" *ngIf="activity.archivo_adjunto">
                  <a href="{{ documento.archivo }}" *ngFor="
                      let documento of activity.archivo_adjunto;
                      let i = index
                    " class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
                    data-aos-delay="0">
                    <h3 class="title-document-custom-new3">
                      {{ documento.nombre }}
                    </h3>
                    <div class="box-acctions-custom-new3">
                      <span class="view-document">
                        <img src="assets/img/file.svg" alt="" />
                      </span>
                      <span class="download-document">
                        <img src="assets/img/download.svg" alt="" />
                      </span>
                    </div>
                  </a>
                </div>
                <div class="box-text-design3">
                  <p [innerHTML]="contentFilter(activity.content)"></p>
                  <a [routerLink]="['/noticia/' + activity.slug]" routerLinkActive="active"
                    *ngIf="contentFilter(activity.content).length > 80">Ver más</a>
                </div>
              </div>
              <div class="card-text-design3 card-text-gallery" *ngIf="activity.gallery">
                <div class="content-documents-custom-new3" *ngIf="activity.archivo_adjunto">
                  <a href="{{ documento.archivo }}" *ngFor="
                      let documento of activity.archivo_adjunto;
                      let i = index
                    " class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
                    data-aos-delay="0">
                    <h3 class="title-document-custom-new3">
                      {{ documento.nombre }}
                    </h3>
                    <div class="box-acctions-custom-new3">
                      <span class="view-document">
                        <img src="assets/img/file.svg" alt="" />
                      </span>
                      <span class="download-document">
                        <img src="assets/img/download.svg" alt="" />
                      </span>
                    </div>
                  </a>
                </div>
                <div class="box-text-design3">
                  <p [innerHTML]="contentFilter(activity.content)"></p>
                  <a [routerLink]="['/noticia/' + activity.slug]" routerLinkActive="active"
                    *ngIf="contentFilter(activity.content).length > 80">Ver más</a>
                </div>
                <!-- <ng-image-slider [images]="activity.gallery" #nav></ng-image-slider> -->
                <div class="swiper-container gallery-carousel-post" [swiper]="galleryPost.config"
                  [(index)]="galleryPost.index">
                  <div class="swiper-wrapper">
                    <div class="img-galley swiper-slide" *ngFor="let image of activity.gallery; let j = index">
                      <img src="{{ image.image }}" alt="{{ image.description }}" class="gallery-single{{ activity.id }}"
                        id="img-gallery-single{{ j }}" (click)="fullImgModal(image.image, i, j, activity.id)" />
                    </div>
                  </div>

                  <div class="swiper-button-prev">
                    <span class="material-icons">
                      keyboard_arrow_left
                    </span>
                  </div>
                  <div class="swiper-button-next">
                    <span class="material-icons">
                      keyboard_arrow_right
                    </span>
                  </div>
                </div>
              </div>
              <div class="card-reactions-design3" *ngIf="loggedIn">
                <div class="like" (mouseenter)="
                    showReactions('#actiivty-reactions-' + activity.id)
                  " (mouseleave)="
                    hideReactions($event, '#actiivty-reactions-' + activity.id)
                  ">
                  <img [src]="
                      activity.liked
                        ? getReactionIcon(activity.like_id)
                        : 'assets/img/icon-heart.svg'
                    " alt="Me gusta" />
                  <span class="counter-likes" (click)="showModalReactions($event, activity.id)">{{ activity.likes
                    }}</span>

                  <div class="activity-reactions" id="actiivty-reactions-{{ activity.id }}">
                    <div *ngFor="let reaction of reactions; let j = index" class="reactions-wrapper"
                      [hidden]="reaction.front !== 'checked'">
                      <span *ngIf="reaction.front === 'checked'" [ngClass]="{
                          'active-reaction': reaction.front === 'checked',
                          'selected-reaction': reaction.id === activity.like_id
                        }" (mouseenter)="hoverReaction($event)" (mouseleave)="unHoverReaction($event)"
                        (click)="likeActivity(activity.id, reaction.id)">
                        <img src="{{ reaction.img_url }}" alt="{{ reaction.name }}" class="img-fluid" />
                      </span>
                    </div>
                  </div>
                </div>
                <a href="#activity-comments-{{ activity.id }}" class="comments" (click)="showActivityComments(i)">
                  <img src="assets/img/comment.svg" alt="" />
                  <span class="counter-likes">{{
                    getActivityCommentsCount(activity.comments)
                    }}</span>
                  <span *ngIf="getActivityCommentsCount(activity.comments) != 1">
                    comentarios</span>
                  <span *ngIf="getActivityCommentsCount(activity.comments) == 1">
                    comentario</span>
                </a>
              </div>
            </div>
            <div class="card-footer card-footer-comments" id="activity-comments-{{ activity.id }}">
              <div class="box-comments-footer-activity">
                <ng-container *ngFor="let comment of activity.comments">
                  <div class="item-comments" id="activity{{ comment.comment_id }}">
                    <div class="box-img-item-comments">
                      <img [src]="comment.avatar_url" class="img-item-comments" alt="" />
                    </div>
                    <div class="box-content-item-comments" id="activity-comment-{{ comment.comment_id }}">
                      <div class="title-content-item-comments title-content-item-comments-destaqued">
                        <h3>{{ comment.display_name }}</h3>
                        <h6 class="date-item-comments">
                          {{ showCommentAntiquity(comment.date_recorded) }}
                        </h6>
                      </div>
                      <div class="tags-item-comments">
                        <!-- <a href="#">#Photoshoot</a>
                          <a href="#">#Smile</a>
                          <a href="#">#Beautiful</a>
                          <a href="#">#Fashion</a> -->
                      </div>
                      <div class="text-item-comments">
                        <p [innerHTML]="parseCommentContent(comment.content)"></p>
                      </div>
                      <span class="delete-comment" *ngIf="
                          comment.user_id == logged_user_id ||
                          logged_user_id == 1
                        " (click)="deleteActivity(comment.id)">Eliminar Comentario</span>
                      <div class="box-link-like" *ngIf="loggedIn">
                        <div class="like" (mouseenter)="
                            showCommentReactions(
                              '#comment-reactions-' + comment.id
                            )
                          " (mouseleave)="
                            hideReactions(
                              $event,
                              '#comment-reactions-' + comment.id
                            )
                          ">
                          <img [src]="
                              comment.liked
                                ? getReactionIcon(comment.like_id)
                                : 'assets/img/icon-heart.svg'
                            " alt="Me gusta" />
                          <span class="counter-likes" (click)="
                              showModalReactionsComments($event, comment.id)
                            ">{{ comment.likes }}</span>

                          <div class="activity-reactions" id="comment-reactions-{{ comment.id }}">
                            <div *ngFor="let reaction of reactions; let j = index" class="reactions-wrapper"
                              [hidden]="reaction.front !== 'checked'">
                              <span *ngIf="reaction.front === 'checked'" [ngClass]="{
                                  'active-reaction':
                                    reaction.front === 'checked',
                                  'selected-reaction':
                                    reaction.id === comment.like_id
                                }" (mouseenter)="hoverReaction($event)" (mouseleave)="unHoverReaction($event)" (click)="
                                  likeComment(
                                    activity.id,
                                    comment.id,
                                    reaction.id
                                  )
                                ">
                                <img src="{{ reaction.img_url }}" alt="{{ reaction.name }}" class="img-fluid" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="item-comments item-add-comments" *ngIf="loggedIn">
                <div class="box-img-item-comments">
                  <div class="img-item-comments" [innerHTML]="avatar_logged"></div>
                </div>
                <form class="box-content-item-comments" [formGroup]="commentForm" (ngSubmit)="
                    uploadActivityComment(
                      activity.secondary_item_id,
                      activity.id
                    )
                  ">
                  <div class="form-group content-box-input">
                    <input type="file" (change)="fileProgress($event)" />
                    <input type="text" (blur)="onBlur()" [mentionConfig]="{ items: items, labelKey: 'nicename' }"
                      [mentionListTemplate]="mentionListTemplate" (focus)="onFocus()" placeholder="Agregar comentario"
                      class="input-item-comments" formControlName="content" id="message{{ activity.id }}" />

                    <ng-template #mentionListTemplate let-item="item">
                      <span>{{ item.email }}</span>
                    </ng-template>
                    <button (click)="toggleEmojiPicker()" type="button">
                      😀
                    </button>
                    <div class="users-list-tag" *ngIf="showListUsers">
                      <ul>
                        <li *ngFor="let user of users" (click)="tagUser(user)">
                          {{ user.name }}
                        </li>
                      </ul>
                    </div>
                    <emoji-mart class="emoji-mart" set="{{ set }}" *ngIf="showEmojiPicker"
                      (emojiSelect)="addEmoji($event, 'message' + activity.id)" title="Pick your emoji…"></emoji-mart>
                  </div>
                  <div *ngIf="fileUploadProgress">
                    Upload progress: {{ fileUploadProgress }}
                  </div>
                  <div class="image-preview mb-3" *ngIf="previewUrl">
                    <img [src]="previewUrl" height="300" />
                  </div>

                  <div class="mb-3" *ngIf="uploadedFilePath">
                    {{ uploadedFilePath }}
                  </div>
                  <button type="submit" class="orange-small-btn" [disabled]="!commentForm.valid">
                    Enviar
                  </button>
                </form>
              </div>
            </div>
          </div>
          <!-- <div class="card-design3 activity-post newsletter-post" *ngIf="activity.newsletter">
            <img src="assets/img/img-header-newsletter-post.jpg" alt="" class="img-header-newsletter-post" />
            <ul class="list-images-newsletter-post">
              <li *ngFor="let newsletter of activity.imagenes; let i = index">
                <a [href]="newsletter.link" class="link-newsletter-post">
                  <img [src]="newsletter.imagen" alt="" class="img-fluid img-newsletter-post" />
                </a>
              </li>
            </ul>
            <img src="assets/img/img-footer-newsletter-post.jpg" alt="" class="img-footer-newsletter-post" />
          </div> -->
          <div class="card-design3 activity-post newsletter-post-new" *ngIf="activity.newsletter">
            <div class="card-haeder">
              <div [innerHTML]="activity.avatar_img" class="box-img-profile"></div>
              <!-- <img src="assets/img/img-profile.png" alt="" class="img-profile" /> -->
              <h3 class="card-title-header">{{ activity.display_name }}</h3>
              <!-- <img src="assets/img/logo-conexion.png" alt="" class="card-title-header-img"> -->
              <a [routerLink]="['/noticia/' + activity.slug]" class="card-title-activity-header"
                [innerHTML]="contentFilter(activity.title)"></a>
              <span class="heading-date">
                <img src="assets/img/icons/calendar.svg" alt="Calendario" />
                Publicado {{ activity.date_recorded | date: 'dd/MM/yyy' }}
              </span>
              <button class="btn-delete-activity" (click)="deleteActivity(activity.id)"
                *ngIf="loggedIn && userLogged.ID == 1">
                Eliminar Actividad
              </button>
            </div>
            <div class="newsletter-post-new-int newsletter-post-new-int-generales" *ngIf="activity.noticias_generales">
              <img src="assets/img/banner-top-newsletter-new.png" alt="" class="img-header-newsletter-post" />
              <ul class="list-images-newsletter-post-new">
                <li *ngFor="let newsletter of activity.noticias_generales; let i = index">
                  <a [href]="newsletter.link" target="_blank" class="link-newsletter-post-new">
                    <img [src]="newsletter.img" alt="" class="img-fluid img-newsletter-post" />
                    <h3 class="title-newsletter-post" [innerHTML]="contentFilter(newsletter.title)"></h3>
                  </a>
                </li>
              </ul>
            </div>
            <div class="newsletter-post-new-int newsletter-post-new-int-proposito" *ngIf="activity.noticias_proposito">
              <img src="assets/img/banner-top-newsletter-new2.png" alt="" class="img-header-newsletter-post" />
              <ul class="list-images-newsletter-post-new">
                <li *ngFor="let newsletter of activity.noticias_proposito; let i = index">
                  <a [href]="newsletter.link" target="_blank" class="link-newsletter-post-new">
                    <img [src]="newsletter.img" alt="" class="img-fluid img-newsletter-post" />
                    <h3 class="title-newsletter-post" [innerHTML]="contentFilter(newsletter.title)"></h3>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-design3 activity-post vp-related" *ngIf="activity.videos_post_related">
            <div class="card-haeder">
              <div [innerHTML]="activity.avatar_img" class="box-img-profile"></div>
              <!-- <img src="assets/img/img-profile.png" alt="" class="img-profile" /> -->
              <h3 class="card-title-header">{{ activity.display_name }}</h3>
              <!-- <img src="assets/img/logo-conexion.png" alt="" class="card-title-header-img"> -->
              <a [routerLink]="['/noticia/' + activity.slug]" class="card-title-activity-header"
                [innerHTML]="contentFilter(activity.title)"></a>
              <span class="heading-date">
                <img src="assets/img/icons/calendar.svg" alt="Calendario" />
                Publicado {{ activity.date_recorded | date: 'dd/MM/yyy' }}
              </span>
              <button class="btn-delete-activity" (click)="deleteActivity(activity.id)"
                *ngIf="loggedIn && userLogged.ID == 1">
                Eliminar Actividad
              </button>
            </div>
            <div class="vp-related-int vp-related-int-generales" *ngIf="activity.vp_related">
              <img src="assets/img/header-related.jpg" alt="" class="img-header-vp-related-post" />
              <ul class="list-images-vp-related">
                <li *ngFor="let vp_related of activity.vp_related; let i = index">
                  <a [routerLink]="['/noticia/' + vp_related.object.post.post_name]" target="_blank"
                    class="link-vp-related-post-new" *ngIf="!vp_related.tipo">
                    <img [src]="vp_related.object.thumnail" alt="" class="img-fluid img-vp-related-post" />
                    <h3 class="title-vp-related-post" [innerHTML]="contentFilter(vp_related.object.post.post_title)">
                    </h3>
                    <span class="btn-vp-related-post">Click Aquí</span>
                  </a>
                  <template *ngIf="vp_related.tipo">
                    <a class="link-vp-related-post-new"
                      (click)="showModalVideoIframe($event, vp_related.object.youtube)"
                      *ngIf="vp_related.object.youtube != '' && (vp_related.object.video == null || vp_related.object.video == '')">
                      <img [src]="vp_related.object.thumnail" alt="" class="img-fluid img-vp-related-post" />
                      <h3 class="title-vp-related-post" [innerHTML]="contentFilter(vp_related.object.post.post_title)">
                      </h3>
                      <span class="btn-vp-related-post">Click Aquí</span>
                    </a>
                    <a (click)="showModalVideo($event, vp_related.object.video)" class="link-vp-related-post-new"
                      *ngIf="vp_related.object.video != '' &&  (vp_related.object.youtube == null || vp_related.object.youtube == '')">
                      <img [src]=" vp_related.object.thumnail" alt="" class="img-fluid img-vp-related-post" />
                      <h3 class="title-vp-related-post" [innerHTML]="contentFilter(vp_related.object.post.post_title)">
                      </h3>
                      <span class="btn-vp-related-post">Click Aquí</span>
                    </a>
                  </template>
                </li>
              </ul>
              <img src="assets/img/footer-related.jpg" alt="" class="img-footer-vp-related-post" />
            </div>
          </div>
        </div>

        <div class="card-design3 activity-post" *ngIf="activities?.length < 1">
          <div class="card-haeder">
            <img src="assets/img/icons/user.svg" alt="Empty user" class="img-profile" />
            <h3 class="card-title-header loading-shimmer">Nombre vacío</h3>
          </div>
          <div class="card-body">
            <a href="#"></a>
            <div class="card-tags-design3"></div>
            <div class="card-text-design3">
              <p class="loading-shimmer">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio
                quas molestias expedita nostrum itaque soluta aspernatur iusto
                enim, tenetur, necessitatibus maxime ipsam, aperiam
                exercitationem alias repudiandae placeat iure voluptate labore.
              </p>
            </div>
            <div class="card-reactions-design3">
              <a href="#" class="like">
                <img src="assets/img/heart.svg" alt="" />
                <span class="counter-likes loading-shimmer">00</span>
              </a>
              <a href="#activity-comments-empty" class="comments">
                <img src="assets/img/comment.svg" alt="" />
                <span class="counter-likes loading-shimmer">00</span>
              </a>
            </div>
          </div>
        </div>

        <div class="box-charge-more">
          <a class="btn-more" (click)="nextPage()">Ver Más</a>
        </div>
      </section>
      <div class="box-legal-home">
        <p>De acuerdo a nuestro Reglamento Interno recodamos que está prohibido publicar o compartir información que
          pudieran
          afectar la honra, prestigio o imagen de otros.</p>
      </div>
    </div>
    <div class="col-lg-3 box-info-user">
      <app-sidebar></app-sidebar>
    </div>
  </div>
</div>

<div class="modal-img-full" [ngClass]="modalImgShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullImgModal()"></div>
  <button class="close" (click)="closeFullImgModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-img-full">
    <!-- <img src="{{ modalImgSrc }}" alt=""> -->
  </div>
  <div class="navs-buttons">
    <button class="btn-prev-lightbox" *ngIf="has_prev_img" (click)="prevFullImgModal()">
      <img src="assets/img/icons/arrow-white.svg" alt="" />
    </button>

    <button class="btn-next-lightbox" *ngIf="has_next_img" (click)="nextFullImgModal()">
      <img src="assets/img/icons/arrow-white.svg" alt="" />
    </button>
  </div>
</div>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>

<div id="modal-video-youtube" class="modal-video" [ngClass]="modalVideoIframe == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideoIframe()"></div>
  <button class="close" (click)="closeModalVideoIframe()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <iframe width="560" height="315" [src]="urlSafe" class="iframe-video" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>

<div class="modal-reactions-list" id="modal-reactions-list" [ngClass]="modalReaction == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalReactions()"></div>
  <div class="box-reactions-list-full">
    <button class="close" (click)="closeModalReactions()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="title-box-reactions">
      Personas que reaccionaron a la actividad
    </h3>
    <ul class="items-reactions-list">
      <li *ngFor="let item of reactions_list; let j = index" class="item-reactions-list">
        <img [src]="getReactionIcon(item.smiley_id)" alt="Me gusta" />
        <h3 class="title-item-reactions-list" [innerHTML]="item.display_name"></h3>
      </li>
    </ul>
  </div>
</div>

<div class="modal-reactions-list" id="modal-reactions-list-comment"
  [ngClass]="modalReactionComment == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalReactionsComment()"></div>
  <div class="box-reactions-list-full">
    <button class="close" (click)="closeModalReactionsComment()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="title-box-reactions">Personas que reaccionaron al comentario</h3>
    <ul class="items-reactions-list">
      <li *ngFor="let item of reactions_list_comment; let j = index" class="item-reactions-list">
        <img [src]="getReactionIcon(item.smiley_id)" alt="Me gusta" />
        <h3 class="title-item-reactions-list" [innerHTML]="item.display_name"></h3>
      </li>
    </ul>
  </div>
</div>