<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a href="/muro"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="back_title_page">
        <a href="{{back_route_link}}"><span>{{
            back_title_page }}</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="back_section_route_title">
        <a href="{{back_section_route_link}}"><span>{{
            back_section_route_title }}</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="evento.title">
        <span>{{ evento.title }}</span>
      </div>
    </div>
  </div>
</section>
<div class="container">
  <section class="single-post">
    <div class="row justify-content-center" *ngIf="isPostLoaded()">
      <div class="col-md-10 col-12">
        <div class="post-heading">
          <h6 class="heading-subtitle">NOTICIAS</h6>
          <h1 class="heading-title" *ngIf="evento.title">
            {{ evento.title }}
          </h1>
          <span class="heading-date">
            <img src="assets/img/icons/calendar.svg" alt="Calendario" />
            Publicado {{ evento.fields.fecha }}
          </span>
        </div>
      </div>

      <div class="col-12">
        <img src="{{ evento.image }}" class="body-image" (click)=fullImgSingleModal(evento.image) alt="" />
        <div class="body-dots">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>

      <div class="col-md-10 col-12 col-content-single-page" [innerHTML]="evento.content"></div>
      <div class="col-md-8 col-12 col-content-single-page-extra">
        <p *ngIf="evento.fields.fecha"><b>Fecha:</b> {{evento.fields.fecha}}</p>
        <p *ngIf="evento.fields.categoria"><b>Categoría:</b> {{evento.fields.categoria}}</p>
        <p *ngIf="evento.fields.direccion"><b>Dirección:</b> <a href="{{evento.fields.direccion}}">{{evento.fields.direccion}}</a></p>
        <p *ngIf="evento.fields.horario"><b>Horario:</b> {{evento.fields.horario}}</p>
        <p *ngIf="evento.fields.organizador_del_evento"><b>Organizador del evento:</b> {{evento.fields.organizador_del_evento}}</p>
        <p *ngIf="evento.fields.email_organizador_del_evento"><b>Email organizador del evento:</b> {{evento.fields.email_organizador_del_evento}}</p>
      </div>

      <div class="post-divider"></div>
    </div>

    <div class="row justify-content-center" *ngIf="!isPostLoaded()">
      <div class="col-md-10 col-12">
        <div class="post-heading">
          <h6 class="heading-subtitle">NOTICIAS</h6>
          <h1 class="heading-title loading-shimmer">
            Título de prueba
          </h1>
          <span class="heading-date">
            <img src="assets/img/icons/calendar.svg" alt="Calendario" />
            <span class="loading-shimmer">Publicado 22/04/2020</span>
          </span>
        </div>
      </div>

      <div class="col-12">
        <div class="empty-img loading-shimmer"></div>
        <div class="body-dots">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>

      <div class="col-md-10 col-12">
        <p class="loading-shimmer">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor ex
          voluptatibus hic voluptate quibusdam minima aliquid voluptatem, ipsum
          magni aspernatur excepturi mollitia laborum voluptas labore suscipit
          autem vel natus corrupti.
        </p>
        <p class="loading-shimmer">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nobis
          necessitatibus quam neque repellendus ipsa dolorem quaerat veniam
          excepturi enim temporibus nulla, corporis, culpa architecto at velit
          omnis. Autem, id.
        </p>
      </div>
    </div>
  </section>
</div>

<div class="modal-img-full" [ngClass]="modalImgSingleShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullImgSingleModal()"></div>
  <button class="close" (click)="closeFullImgSingleModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-img-full">
  </div>
</div>

<div class="modal-img-full" [ngClass]="modalImgShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullImgModal()"></div>
  <button class="close" (click)="closeFullImgModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-img-full">
    <!-- <img src="{{ modalImgSrc }}" alt=""> -->
  </div>
  <div class="navs-buttons">
    <button class="btn-prev-lightbox" *ngIf="has_prev_img" (click)="prevFullImgModal()"><img
        src="assets/img/icons/arrow-white.svg" alt=""></button>
    <button class="btn-next-lightbox" *ngIf="has_next_img" (click)="nextFullImgModal()"><img
        src="assets/img/icons/arrow-white.svg" alt=""></button>
  </div>
</div>