import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { DesafioService } from '@services/desafio';
import { LoadingService } from '@services/loading';
import * as $ from 'jquery';

@Component({
  selector: 'app-ideas-desafio3',
  templateUrl: './ideas-desafio3.component.html',
  styleUrls: ['./ideas-desafio3.component.scss'],
})
export class IdeasDesafios3Component implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  addClassFixed = false;
  selectedIndex: any[] = [];
  currentPage = 1;
  modalTextShow: any;
  modalText: any;
  modalTitulo: any;
  modalEventos = false;
  ideas: any;
  ideas_ganadoras: any;
  ideas_quick: any;
  ideasRes: any;
  modalTitle: string;
  modalContent: string;
  modalAutor: string;
  modalImage: string;
  ideas_problemas: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private desafioService: DesafioService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('i-NOW | Intranet Abastible');
    this.paramsID = 23092;
    this.slug = this.params['slug'];

    this.desafioService.getIdeas(this.paramsID, 1).subscribe(
      (res) => {
        this.ideas = res;
        console.log(res)
      },
      (err) => {
        console.error(err);
      }
    );
    this.desafioService.getIdeasGanadoras(this.paramsID).subscribe(
      (res) => {
        this.ideas_ganadoras = res;
        console.log(res)
      },
      (err) => {
        console.error(err);
      }
    );
    this.desafioService.getIdeasProblemas(this.paramsID).subscribe(
      (res) => {
        this.ideas_problemas = res;
        console.log(res)
      },
      (err) => {
        console.error(err);
      }
    );

  }
  nextPage() {
    this.currentPage = this.currentPage + 1;

    this.desafioService.getIdeas(this.paramsID, this.currentPage).subscribe(
      (res) => {
        this.ideasRes = res;
        this.ideas = [...this.ideas, ...this.ideasRes];
        console.log([...this.ideas, res]);
        console.log(this.ideasRes)
      },
      (err) => {
        console.error(err);
      }
    );
  }
  openModalEvento(title: string, content: string, autor: string, image: string) {
    this.modalEventos = true;
    this.modalTitle = title;
    this.modalContent = content;
    this.modalAutor = autor;
    this.modalImage = image;
  }
  closeEventosModal() {
    this.modalEventos = false;
  }
} 
