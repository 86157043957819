import { Component } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as $ from 'jquery';
import { AsyncSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth';
import * as uuid from 'uuid';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  hash: string;
  title = 'angular-intranet-abastible';
  loggedIn$ = new AsyncSubject<boolean>();
  setHash = uuid.v4();

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) {
    if (localStorage.getItem('seccion_logged') == "1") {
    } else {
      this.logout()
    }
    
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const date = new Date();
        function n(n){
          return n > 9 ? "" + n: "0" + n;
        }
        let day = date.getDate();
        let month = n((date.getMonth() + 1));
        let year = date.getFullYear();
        let currentDate = `${day}/${month}/${year}`;
        console.log(currentDate); // "17-6-2022"      
        console.log("test route End", this.router.url);

        this.authService.analitics(currentDate, ('https://intranet.abastible.cl' + this.router.url)).subscribe(
          (data) => {
            console.log('data ', data);
          },
          (err) => {
            console.log('err ', err);
          }
        );
      }
      if (event instanceof NavigationStart) {
        console.log("test route", this.router.url);
        if (this.router.url != "/") {
          setInterval(function () {
            window.dispatchEvent(new Event('resize'));
          }, 1000);
          if (!this.getUserLocalstorage()) {
            if (!localStorage.getItem('hash')) {
              let url_string = window.location.href;
              var url = new URL(url_string);
              var hashValue = url.searchParams.get("hash");
              if (hashValue) {
                this.hash = hashValue;
                localStorage.setItem('hash', this.hash);
                this.authService.login2(this.hash).subscribe(
                  (data) => {
                    console.log('user logged ', data);
                    if (data['ID']) {
                      this.loggedIn$.next(true);
                      this.loggedIn$.complete();
                    } else {
                      localStorage.removeItem("user");
                      localStorage.removeItem("hash");
                      this.loggedIn$.next(false);
                      window.location.href = `https://intranet.abastible.cl/wp/login?hash=${this.setHash}`;
                    }
                  },
                  (err) => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("hash");
                    this.loggedIn$.next(false);
                    // window.location.href = "https://intranet.abastible.cl/login/?hash=" + this.setHash;
                  }
                );
              } else {
                console.log("1_6")
                window.location.href = `https://intranet.abastible.cl/wp/login?hash=${this.setHash}`;
              }
            } else {
              this.hash = localStorage.getItem('hash');
              this.authService.login2(this.hash).subscribe(
                (data) => {
                  console.log('user logged ', data);
                  if (data['ID']) {
                    this.loggedIn$.next(true);
                    this.loggedIn$.complete();
                  } else {
                    localStorage.removeItem("user");
                    localStorage.removeItem("hash");
                    this.loggedIn$.next(false);
                    window.location.href = `https://intranet.abastible.cl/wp/login?hash=${this.setHash}`;
                  }
                },
                (err) => {
                  localStorage.removeItem("user");
                  localStorage.removeItem("hash");
                  this.loggedIn$.next(false);
                  // window.location.href = "https://intranet.abastible.cl/login/?hash=" + this.setHash;
                }
              );
            }
          } else {
            localStorage.removeItem("hash");
            this.loggedIn$.next(true);
            this.loggedIn$.complete();
          }
          console.log("getUserLocalstorage", this.getUserLocalstorage())
        }
        function findGetParameter(parameterName) {
          var result = null,
            tmp = [];
          var items = location.search.substr(1).split("&");
          for (var index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
          }
          return result;
        }

        $("body").removeClass("modal-open");
        $("body").removeClass("modal-single-content-open");
        setTimeout(() => {
          if ($(".swiper-button-prev").length) {
            $(".swiper-button-prev").trigger("click")
            $(".swiper-button-prev").trigger("click")
            $(".swiper-button-prev").trigger("click")
            $(".swiper-button-prev").trigger("click")
            $(".swiper-button-prev").trigger("click")
            $(".swiper-button-prev").trigger("click")
          }
        }, 3000);
      }
    });
    if (!this.getUserLocalstorage()) {
      if (!localStorage.getItem('hash')) {
        let url_string = window.location.href;
        var url = new URL(url_string);
        var hashValue = url.searchParams.get("hash");
        if (hashValue) {
          this.authService.validate_sesion(this.hash).subscribe(
            (data) => {
              console.log(data);
              if (!data['user_hash']) {
                window.location.href = `https://intranet.abastible.cl/wp/login?hash=${this.setHash}`;
              } else {
                this.authService.login2(data['user_hash']).subscribe(
                  (data) => {
                    console.log('user logged ', data);
                    if (data['ID']) {
                      this.loggedIn$.next(true);
                      this.loggedIn$.complete();
                    } else {
                      localStorage.removeItem("user");
                      localStorage.removeItem("hash");
                      this.loggedIn$.next(false);
                      window.location.href = `https://intranet.abastible.cl/wp/login?hash=${this.setHash}`;
                    }
                  },
                  (err) => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("hash");
                    this.loggedIn$.next(false);
                    // window.location.href = "https://intranet.abastible.cl/login/?hash=" + this.setHash;
                  }
                );
              }
            },
            (err) => {
              localStorage.removeItem("user");
              localStorage.removeItem("hash");
              window.location.href = `https://intranet.abastible.cl/wp/login?hash=${this.setHash}`;
            }
          );
        } else {
          window.location.href = `https://intranet.abastible.cl/wp/login?hash=${this.setHash}`;
        }
      } else {
        this.hash = localStorage.getItem('hash');
        this.authService.login2(this.hash).subscribe(
          (data) => {
            console.log('user logged ', data);
            if (data['ID']) {
              this.loggedIn$.next(true);
              this.loggedIn$.complete();
            } else {
              localStorage.removeItem("user");
              localStorage.removeItem("hash");
              this.loggedIn$.next(false);
              window.location.href = `https://intranet.abastible.cl/wp/login?hash=${this.setHash}`;
            }
          },
          (err) => {
            localStorage.removeItem("user");
            localStorage.removeItem("hash");
            this.loggedIn$.next(false);
            // window.location.href = "https://intranet.abastible.cl/login/?hash=" + this.setHash;
          }
        );
      }
    } else {

      this.loggedIn$.next(true);
      this.loggedIn$.complete();
    }
    console.log("getUserLocalstorage", this.getUserLocalstorage())
  }

  ngOnInit() {
    window.onload = function () {
      $(".overlay-step-5").fadeOut()
    };
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1000);
    $("body").on("click", ".item-tab-search", function (e) {
      e.preventDefault();
      var dataContent = $(this).data("tab");
      if (!$(this).hasClass("active")) {
        $(this).parent().parent().find(".item-tab-search").removeClass("active");
        $(this).addClass("active");
        $(this).parent().parent().find(".item-tab-content-search").fadeOut(300)
        setTimeout(() => {
          $(dataContent).fadeIn(300)
        }, 300);
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 400);
      }
    });
    $("body").on("click", ".item-tab-proposito", function (e) {
      e.preventDefault();
      var dataContent = $(this).data("tab");
      if (!$(this).hasClass("active")) {
        $(this).parent().find(".item-tab-proposito").removeClass("active");
        $(this).addClass("active");
        $(this).parent().parent().find(".item-tab-proposito-body").fadeOut(300)
        console.log("dataContent",dataContent)
        setTimeout(() => {
          $(dataContent).fadeIn(300)
        }, 300);
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 400);
      }
    });
    function findGetParameter(parameterName) {
      var result = null,
        tmp = [];
      var items = location.search.substr(1).split("&");
      for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      }
      return result;
    }



    $("body").delegate(".btn-custom-new", "click", function () {
      $('.box-modal-text-full').get(0).scrollTo(0, 0);
    });
  }

  //bk

  getUserLocalstorage() {
    if (localStorage.getItem('user')) {
      var userLocal = JSON.parse(localStorage.getItem('user'));
      if (userLocal['ID']) {
        localStorage.setItem('hash', userLocal['hash'])
        this.authService.checkIsLogged();
        return userLocal;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getIpCliente(): void {
    this.http.get('https://intranet.abastible.cl/wp/wp-json/v2/user-ip')
      .subscribe((res: any) => {
        const validIps = ["190.151.36.130", "186.67.58.194", "190.151.114.150", "200.68.45.44"];

        console.log(!validIps.includes(res), !this.authService.checkIsLogged());

        if (!validIps.includes(res) && !this.authService.checkIsLogged()) {
          window.location.href = `https://intranet.abastible.cl/wp/login?hash=${this.setHash}`;
        }
      });
  }

  logout() {
    this.authService.logOut().subscribe(
      (res: any) => {
        localStorage.removeItem("user");
        localStorage.removeItem("hash");
        localStorage.setItem('seccion_logged', '1')
        window.location.href = res;
      },
      (err) => {
        console.error(err);
      }
    ),
      (err) => {
        console.error(err);
      }
  }
}
