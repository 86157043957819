<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <a [routerLink]="['/noticias-conexion-naranja']">
          Noticias conexión naranja
        </a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>{{term_info.name}}</span>
      </div>
    </div>
  </div>
</section>

<section class="page-conexion-naranja" *ngIf="post">
  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2024/05/Objeto-inteligente-vectorial.png" alt=""
    class="page-conexion-naranja-bg">
  <div class="container">
    <div class="page-conexion-naranja-container">
      <div class="page-conexion-naranja-container-banner-custom">
        <div class="page-conexion-naranja-container-banner-custom-img">
          <img src="{{term_info.term_fields.imagen_single}}" alt="">
        </div>
        <div class="page-conexion-naranja-container-banner-custom-text">
          <h6>Te invitamos a revisar las principales novedades de este mes.</h6>
        </div>
      </div>
      <div class="page-conexion-naranja-edicion-container-content">
        <div class="page-conexion-naranja-edicion-container-content-destaqued">
          <template style="display: block;" *ngIf="term_info.term_fields.tipo_de_item_destacado == 'Audio'">
            <div
              class="page-conexion-naranja-edicion-container-content-destaqued page-conexion-naranja-edicion-container-content-destaqued-audio"
              *ngIf="term_info.term_fields.tipo_audio_destacado"
              (click)="showModalAudio($event, term_info.term_fields.audio_destacado, term_info.term_fields.preview_audio_destacado)">
              <div class="page-conexion-naranja-edicion-container-content-destaqued-image">
                <img src="{{term_info.term_fields.preview_audio_destacado}}" alt="">
                <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                  class="btn-play-desing1" />
              </div>
            </div>
            <div
              class="page-conexion-naranja-edicion-container-content-destaqued page-conexion-naranja-edicion-container-content-destaqued-audio"
              *ngIf="!term_info.term_fields.tipo_audio_destacado"
              (click)="showModalAudioIframe($event, term_info.term_fields.id_soundcloud_destacado)">
              <div class="page-conexion-naranja-edicion-container-content-destaqued-image">
                <img src="{{term_info.term_fields.preview_audio_destacado}}" alt="">
                <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                  class="btn-play-desing1" />
              </div>
            </div>
          </template>
          <template style="display: block;" *ngIf="term_info.term_fields.tipo_de_item_destacado == 'Video'">
            <div
              class="page-conexion-naranja-edicion-container-content-destaqued page-conexion-naranja-edicion-container-content-destaqued-video"
              *ngIf="term_info.term_fields.tipo_video_destacado"
              (click)="showModalVideo($event, term_info.term_fields.video_destacado)">
              <div class="page-conexion-naranja-edicion-container-content-destaqued-image">
                <img src="{{term_info.term_fields.preview_video_destacado}}" alt="">
                <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                  class="btn-play-desing1" />
              </div>
            </div>
            <div
              class="page-conexion-naranja-edicion-container-content-destaqued page-conexion-naranja-edicion-container-content-destaqued-video"
              *ngIf="!term_info.term_fields.tipo_video_destacado"
              (click)="showModalVideoIframe($event, term_info.term_fields.id_youtube_destacado)">
              <div class="page-conexion-naranja-edicion-container-content-destaqued-image">
                <img src="{{term_info.term_fields.preview_video_destacado}}" alt="">
                <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                  class="btn-play-desing1" />
              </div>
            </div>
          </template>
          <template style="display: block;" *ngIf="term_info.term_fields.tipo_de_item_destacado == 'Enlace externo'">
            <a href="{{term_info.term_fields.enlace_external_link}}" target="_blank"
              class="page-conexion-naranja-edicion-container-content-destaqued page-conexion-naranja-edicion-container-content-destaqued-video">
              <div class="page-conexion-naranja-edicion-container-content-destaqued-image">
                <img src="{{term_info.term_fields.preview_external_link}}" alt="">
              </div>
            </a>
          </template>
          <template style="display: block;" *ngIf="term_info.term_fields.tipo_de_item_destacado == 'Newsletter'">
            <div class="page-conexion-naranja-edicion-container-content-destaqued-newsletter">
              <!-- <div class="page-conexion-naranja-edicion-container-content-destaqued-newsletter-header"
                (click)="showModalAudioIframe($event, '1833091212%3Fsecret_token%3Ds-7hMmH8A4aVZ')">
                <img src="assets/img/header-newsletter.jpg" alt=""
                  class="page-conexion-naranja-edicion-container-content-destaqued-newsletter-header-img">
              </div> -->
              <div class="page-conexion-naranja-edicion-container-content-destaqued-newsletter-body">
                <div class="page-conexion-naranja-edicion-container-content-destaqued-newsletter-body-items">
                  <div class="page-conexion-naranja-edicion-container-content-destaqued-newsletter-body-item"
                    *ngFor="let term_post_int of term_info.term_fields.noticias_newsletter; let i = index">
                    <div class="page-conexion-naranja-edicion-container-content-destaqued-newsletter-body-item-image">
                      <img [src]="term_post_int.imagen" alt=""
                        class="page-conexion-naranja-edicion-container-content-destaqued-newsletter-body-item-image-img">
                    </div>
                    <h3 class="page-conexion-naranja-edicion-container-content-destaqued-newsletter-body-item-title"
                      [innerHTML]="term_post_int.titulo"></h3>
                    <a [href]="term_post_int.enlace" target="_blank"
                      class="page-conexion-naranja-edicion-container-content-destaqued-newsletter-body-item-link">Ver
                      más</a>
                  </div>
                </div>
              </div>
              <div class="page-conexion-naranja-edicion-container-content-destaqued-newsletter-footer">
                <img src="assets/img/footer-newsletter.jpg" alt=""
                  class="page-conexion-naranja-edicion-container-content-destaqued-newsletter-footer-img">
              </div>
            </div>
          </template>
          <div
            class="page-conexion-naranja-edicion-container-content-destaqued page-conexion-naranja-edicion-container-content-destaqued-imagen"
            *ngIf="term_info.term_fields.tipo_de_item_destacado == 'Imagen'">
            <div class="page-conexion-naranja-edicion-container-content-destaqued-image">
              <img src="{{term_info.term_fields.imagen_destacado}}" alt="">
            </div>
          </div>
        </div>
        <div class="page-conexion-naranja-edicion-container-content-items" *ngIf="posts.length > 0">
          <template *ngFor="let post_int of posts; let i = index" style="display: block;">
            <a [routerLink]="['/noticias-conexion-naranja/' + term_slug + '/' + post_int.info.post_name]" class="
              page-conexion-naranja-edicion-container-content-item" *ngIf="!post_int.fields.enlace_externo">
              <img src="{{post_int.fields.imagen_de_listado}}" alt="">
            </a>
            <a href="{{post_int.fields.enlace_externo}}" target="_blank" *ngIf="post_int.fields.enlace_externo" class="
              page-conexion-naranja-edicion-container-content-item">
              <img src="{{post_int.fields.imagen_de_listado}}" alt="">
            </a>
          </template>
        </div>
        <div class="page-section2-container-pagination-container" *ngIf="posts.length > 0">
          <a href="javascript:;" class="page-section2-container-pagination" (click)="setPage(i + 1)"
            *ngFor="let item of arrayPagination; let i = index" [ngClass]="{ 'active': i === (currentPage - 1) }">{{ i +
            1
            }} </a>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal-img-full" [ngClass]="modalImgShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullImgModal()"></div>
  <button class="close" (click)="closeFullImgModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-img-full">
    <!-- <img src="{{ modalImgSrc }}" alt=""> -->
  </div>
  <div class="navs-buttons">
    <button class="btn-prev-lightbox" *ngIf="has_prev_img" (click)="prevFullImgModal()">
      <img src="assets/img/icons/arrow-white.svg" alt="" />
    </button>

    <button class="btn-next-lightbox" *ngIf="has_next_img" (click)="nextFullImgModal()">
      <img src="assets/img/icons/arrow-white.svg" alt="" />
    </button>
  </div>
</div>

<div id="modal-audio-soundcloud" class="modal-audio" [ngClass]="modalAudioIframe == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalAudioIframe()"></div>
  <button class="close" (click)="closeModalAudioIframe()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-audio-full">
    <iframe width="560" height="315" [src]="urlSafe" class="iframe-audio" frameborder="0" allowfullscreen></iframe>
  </div>
</div>

<div id="modal-video-youtube" class="modal-video" [ngClass]="modalVideoIframe == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideoIframe()"></div>
  <button class="close" (click)="closeModalVideoIframe()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <iframe width="560" height="315" [src]="urlSafe" class="iframe-video" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>

<div id="modal-audio" class="modal-audio" [ngClass]="modalAudio == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalAudio()"></div>
  <button class="close" (click)="closeModalAudio()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-audio-full">
    <img [src]="modalSrcAudioPreview" />
    <audio controls [src]="modalSrcAudio">
      Su navegador no soporta el elemento <code>audio</code>.
    </audio>
  </div>
</div>