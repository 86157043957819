<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Estándar señalética</span>
      </div>
    </div>
  </div>
</section>
<section class="section-custom-new3">
  <div class="container">
    <div class="text-center mb-3">
      <h1 class="title1 text-center">Catálogo señaléticas</h1>
    </div>
    <div class="row mb-5">
      <div class="col-lg-8 mx-auto">
        <div class="content-documents-custom-new3">
          <a href="https://abastible.sharepoint.com/:b:/s/GestinPlantasAbastible/EdKnaFTYBN1Bo6u4vGQOHW4Bhb_bTEeEsd5HxVTlyXw1ZQ?e=EoC9ju"
            class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
            data-aos-delay="0">
            <h3 class="title-document-custom-new3">Catalogo de señaléticas y recomendaciones.</h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="" />
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="" />
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="text-center mb-3 pt-5">
      <h1 class="title1 text-center">Descargables</h1>
    </div>
    <div class="row mb-5">
      <div class="col-lg-8 mx-auto">
        <div class="content-documents-custom-new3">
          <a href="https://abastible.sharepoint.com/:f:/s/GestinPlantasAbastible/EtYzhGVoZLJDvFOWBv4tNA0BlE8tdoA8rg6faoeye-pVvA?e=bbxcGf"
            class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
            data-aos-delay="0">
            <h3 class="title-document-custom-new3">Seguridad</h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="" />
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="" />
              </span>
            </div>
          </a>
          <a href="https://abastible.sharepoint.com/:f:/s/GestinPlantasAbastible/EhfP83MTUONEkpFVTt2AT4kBLu38Kx0NAobhydihxnwB5A?e=jI8V2W"
            class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
            data-aos-delay="0">
            <h3 class="title-document-custom-new3">Calidad</h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="" />
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="" />
              </span>
            </div>
          </a>
          <a href="https://abastible.sharepoint.com/:f:/s/GestinPlantasAbastible/Ej4IYotX6QdLq6rqsceE_YUBd6MUX5QXis0jCLBmy_GWLA?e=qas48S"
            class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
            data-aos-delay="0">
            <h3 class="title-document-custom-new3">5S</h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="" />
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="" />
              </span>
            </div>
          </a>
          <a href="https://abastible.sharepoint.com/:f:/s/GestinPlantasAbastible/Ep-KakiQVBVCumNHC3DRGxYB9CLT6PdYW7SvY4A6cPEEgw?e=XDs8q6"
            class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
            data-aos-delay="0">
            <h3 class="title-document-custom-new3">SGA</h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="" />
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="" />
              </span>
            </div>
          </a>
          <a href="https://abastible.sharepoint.com/:x:/s/GestinPlantasAbastible/EY8pFEa2foFEv380te9yRVABcLSA0GxajvhaGDmWM7djRA?e=HVm03r"
            class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
            data-aos-delay="0">
            <h3 class="title-document-custom-new3">Codificación</h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="" />
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="" />
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>