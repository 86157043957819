import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { NewsService } from '@services/news';
import { ConcursoService } from '@services/concurso';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '@app/app.component';

@Component({
  selector: 'app-concurso',
  templateUrl: './concurso.component.html',
  styleUrls: ['./concurso.component.scss'],
})
export class ConcursoAbastibleComponent implements OnInit {
  landing: any;
  titlePage: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  routeLink: any;
  myForm: FormGroup;
  paramsID: any;
  logged_user_id: any;
  file: File = null;
  hideVerMas = true;
  photos: any;
  photosRes: any;
  currentPage = 1;
  hasSubmit = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private concursoService: ConcursoService,
    private title: Title,
    private route: ActivatedRoute,
    private NewsService: NewsService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private appComponent: AppComponent
  ) {
  }

  ngOnInit(): void {
    this.init();
    this.myForm = this.formBuilder.group({
      "async-upload": ['', [Validators.required]],
    });
  }
  init(): void {
    this.title.setTitle('Concurso de verano | Intranet Abastible');
    this.paramsID = 71567;
    this.titlePage = "Concurso de verano";
    this.routeLink = "Concurso de verano";
    localStorage.setItem('back_title_page', this.titlePage);
    localStorage.setItem('back_route_link', this.routeLink);
    localStorage.removeItem('back_section_route_title');
    localStorage.removeItem('back_section_route_link');


    this.appComponent.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.logged_user_id = this.authService.user['ID'];
      }
    });

    this.concursoService.getPhotos(1).subscribe(
      (res) => {
        this.photos = res;
        console.log(res)
      },
      (err) => {
        console.error(err);
      }
    );
  }

  likePhoto(e:Event, user_id, photo_id, index){
    e.stopPropagation();
    var that = this;
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("photo_id", photo_id);
    that.http.post('https://intranet.abastible.cl/wp/wp-json/v2/like-photo', formData)
      .subscribe(res => {
        if(res['liked']){
          that.photos[index]['liked'] = "1";
        }else{
          that.photos[index]['liked'] = "0";
        }
        that.photos[index]['total_likes'] = res['total_likes'];
        console.log(res);
      })
  }

  nextPage() {
    this.currentPage = this.currentPage + 1;

    this.concursoService.getPhotos(this.currentPage).subscribe(
      (res) => {
        this.photosRes = res;
        console.log(this.photosRes.length)
        this.photos = [...this.photos, ...this.photosRes];
        if (this.photosRes.length < 20) {
          this.hideVerMas = false;
        }
        console.log([...this.photos, res]);
        console.log(this.photosRes)
      },
      (err) => {
        console.error(err);
      }
    );
  }

  onFilechange(event: any) {
    console.log(event.target.files[0])
    this.file = event.target.files[0]
  }

  submitForm() {
    if (this.file && !this.hasSubmit) {
      this.hasSubmit = true;
      var that = this;
      const formData = new FormData();
      formData.append("user_id", that.logged_user_id);
      formData.append("async-upload", this.file);
      that.http.post('https://intranet.abastible.cl/wp/wp-json/v2/upload-image-summer', formData)
        .subscribe(res => {
          console.log(res);
          location.reload()
        })
    } else {
      alert("Por favor sube una foto primero")
    }


  }
}
