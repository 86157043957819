<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="back_title_page">
        <a href="{{ back_route_link }}"><span>{{ back_title_page }}</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="back_section_route_title">
        <a href="{{ back_section_route_link }}"><span>{{ back_section_route_title }}</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>{{
          post_title.length > 40
          ? (post_title | slice: 0:40) + '..'
          : post_title
          }}</span>
      </div>
    </div>
  </div>
</section>
<div class="container">
  <section class="single-post">
    <div class="row justify-content-center" *ngIf="isPostLoaded()">
      <div class="col-md-10 col-12">
        <div class="post-heading">
          <h6 class="heading-subtitle">NOTICIAS</h6>
          <h1 class="heading-title">
            {{ post_title }}
          </h1>
          <span class="heading-date">
            <img src="assets/img/icons/calendar.svg" alt="Calendario" />
            Publicado {{ getPostDate() }}
          </span>
        </div>
      </div>

      <div class="col-12">
        <a href="{{ post.external_link }}" target="_blank"
          *ngIf="post.external_link &&  (!post.newsletter && !post.vp_related)">
          <img src="{{ post.thumbnail }}" class="body-image" alt="" />
        </a>
        <img src="{{ post.thumbnail }}" class="body-image" (click)="fullImgSingleModal(post.thumbnail)" alt=""
          *ngIf="!post.external_link && (!post.newsletter && !post.vp_related)" />
        <div class="row" *ngIf="post.newsletter">
          <div class="col-lg-10 mx-auto">
            <div class="newsletter-post-new-int newsletter-post-new-int-generales" *ngIf="post.noticias_generales">
              <img src="assets/img/banner-top-newsletter-new.png" alt="" class="img-header-newsletter-post" />
              <ul class="list-images-newsletter-post-new">
                <li *ngFor="let newsletter of post.noticias_generales; let i = index">
                  <a [href]="newsletter.link" target="_blank" class="link-newsletter-post-new">
                    <img [src]="newsletter.img" alt="" class="img-fluid img-newsletter-post" />
                    <h3 class="title-newsletter-post" [innerHTML]="contentFilter(newsletter.title)"></h3>
                  </a>
                </li>
              </ul>
            </div>
            <div class="newsletter-post-new-int newsletter-post-new-int-proposito" *ngIf="post.noticias_proposito">
              <img src="assets/img/banner-top-newsletter-new2.png" alt="" class="img-header-newsletter-post" />
              <ul class="list-images-newsletter-post-new">
                <li *ngFor="let newsletter of post.noticias_proposito; let i = index">
                  <a [href]="newsletter.link" target="_blank" class="link-newsletter-post-new">
                    <img [src]="newsletter.img" alt="" class="img-fluid img-newsletter-post" />
                    <h3 class="title-newsletter-post" [innerHTML]="contentFilter(newsletter.title)"></h3>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="post.vp_related">
          <div class="col-lg-10 mx-auto">
            <div class="vp-related-int vp-related-int-generales">
              <img src="assets/img/header-related.jpg" alt="" class="img-header-vp-related-post" />
              <ul class="list-images-vp-related">
                <li *ngFor="let vp_related of post.vp_related; let i = index">
                  <a [routerLink]="['/noticia/' + vp_related.object.post.post_name]" target="_blank"
                    class="link-vp-related-post-new" *ngIf="!vp_related.tipo">
                    <img [src]="vp_related.object.thumnail" alt="" class="img-fluid img-vp-related-post" />
                    <h3 class="title-vp-related-post" [innerHTML]="contentFilter(vp_related.object.post.post_title)">
                    </h3>
                    <span class="btn-vp-related-post">Click Aquí</span>
                  </a>
                  <template *ngIf="vp_related.tipo">
                    <a class="link-vp-related-post-new"
                      (click)="showModalVideoIframe($event, vp_related.object.youtube)"
                      *ngIf="vp_related.object.youtube != '' && (vp_related.object.video == null || vp_related.object.video == '')">
                      <img [src]="vp_related.object.thumnail" alt="" class="img-fluid img-vp-related-post" />
                      <h3 class="title-vp-related-post" [innerHTML]="contentFilter(vp_related.object.post.post_title)">
                      </h3>
                      <span class="btn-vp-related-post">Click Aquí</span>
                    </a>
                    <a (click)="showModalVideo($event, vp_related.object.video)" class="link-vp-related-post-new"
                      *ngIf="vp_related.object.video != '' &&  (vp_related.object.youtube == null || vp_related.object.youtube == '')">
                      <img [src]=" vp_related.object.thumnail" alt="" class="img-fluid img-vp-related-post" />
                      <h3 class="title-vp-related-post" [innerHTML]="contentFilter(vp_related.object.post.post_title)">
                      </h3>
                      <span class="btn-vp-related-post">Click Aquí</span>
                    </a>
                  </template>
                </li>
              </ul>
              <img src="assets/img/footer-related.jpg" alt="" class="img-footer-vp-related-post" />
            </div>
          </div>
        </div>
        <div class="body-dots">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>

      <div class="col-md-10 col-12 col-content-single-page" [innerHTML]="post.post_content" *ngIf="!post.external_link">
      </div>

      <div class="content-video-post col-md-10 col-12 mx-auto" *ngIf="post.video">
        <video controls src="{{ post.video }}" class="img-fluid d-block mx-auto"></video>
      </div>

      <div class="content-video-post content-iframe col-md-10 col-12 mx-auto" *ngIf="post.youtube_id">
        <iframe width="560" height="315" [src]="urlIframe" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>

      <div class="col-md-10 col-12 content-gallery">
        <div class="swiper-container gallery-carousel-post" [swiper]="galleryPost.config" [(index)]="galleryPost.index">
          <div class="swiper-wrapper">
            <div class="img-galley swiper-slide" *ngFor="let image of post.gallery; let i = index">
              <img src="{{ image.url }}" data-gallery="gallery-single1" id="img-gallery-single{{ i }}"
                alt="{{ image.description }}" (click)="fullImgModal(image, 1, i)" />
            </div>
          </div>

          <div class="swiper-button-prev">
            <span class="material-icons">
              keyboard_arrow_left
            </span>
          </div>
          <div class="swiper-button-next">
            <span class="material-icons">
              keyboard_arrow_right
            </span>
          </div>
        </div>
      </div>

      <div class="col-lg-10 mb-5">
        <div class="content-documents-custom-new3" *ngIf="post.archivo_adjunto">
          <a href="{{ documento.archivo }}" *ngFor="
                let documento of post.archivo_adjunto;
                let i = index
              " class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
            data-aos-delay="0">
            <h3 class="title-document-custom-new3">
              {{ documento.nombre }}
            </h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="" />
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="" />
              </span>
            </div>
          </a>
        </div>
      </div>

      <div class="col-12" *ngIf="post.activity_id">
        <div class="box-likes-single-post">
          <div class="like" (mouseenter)="
              showReactions('#actiivty-reactions-' + post.activity_id)
            " (mouseleave)="
              hideReactions($event, '#actiivty-reactions-' + post.activity_id)
            ">
            <img [src]="
                post.liked
                  ? getReactionIcon(post.like_id)
                  : 'assets/img/icon-heart.svg'
              " alt="Me gusta" />
            <span class="counter-likes" (click)="showModalReactions($event, post.activity_id)">{{ post.likes }}</span>

            <div class="activity-reactions" id="actiivty-reactions-{{ post.activity_id }}">
              <div *ngFor="let reaction of reactions; let j = index" class="reactions-wrapper"
                [hidden]="reaction.front !== 'checked'">
                <span *ngIf="reaction.front === 'checked'" [ngClass]="{
                    'active-reaction': reaction.front === 'checked',
                    'selected-reaction': reaction.id === post.like_id
                  }" (mouseenter)="hoverReaction($event)" (mouseleave)="unHoverReaction($event)"
                  (click)="likeActivity(post.activity_id, reaction.id)">
                  <img src="{{ reaction.img_url }}" alt="{{ reaction.name }}" class="img-fluid" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="post-divider"></div>

      <div class="card-footer-comments" id="post-comments-{{ post.id }}" *ngIf="loggedIn && post.activity_id">
        <ng-container *ngFor="let comment of post.comments">
          <div class="item-comments" id="activity{{ comment.comment_id }}">
            <div class="box-img-item-comments">
              <img [src]="comment.avatar_url" class="img-item-comments" alt="" />
            </div>
            <div class="box-content-item-comments">
              <div class="title-content-item-comments title-content-item-comments-destaqued">
                <h3>{{ comment.display_name }}</h3>
                <h6 class="date-item-comments">
                  {{ showCommentAntiquity(comment.date_recorded) }}
                </h6>
              </div>
              <div class="tags-item-comments">
                <!-- <a href="#">#Photoshoot</a>
                                <a href="#">#Smile</a>
                                <a href="#">#Beautiful</a>
                                <a href="#">#Fashion</a> -->
              </div>
              <div class="text-item-comments">
                <p [innerHTML]="parseCommentContent(comment.content)"></p>
              </div>
              <span class="delete-comment" *ngIf="comment.user_id == logged_user_id || logged_user_id == 1"
                (click)="deleteActivity(comment.id)">Eliminar Comentario</span>
              <div class="box-link-like">
                <div class="like" (mouseenter)="
                    showCommentReactions('#comment-reactions-' + comment.id)
                  " (mouseleave)="
                    hideReactions($event, '#comment-reactions-' + comment.id)
                  ">
                  <img [src]="
                      comment.liked
                        ? getReactionIcon(comment.like_id)
                        : 'assets/img/icon-heart.svg'
                    " alt="Me gusta" />
                  <span class="counter-likes" (click)="showModalReactionsComments($event, comment.id)">{{ comment.likes
                    }}</span>

                  <div class="activity-reactions" id="comment-reactions-{{ comment.id }}">
                    <div *ngFor="let reaction of reactions; let j = index" class="reactions-wrapper"
                      [hidden]="reaction.front !== 'checked'">
                      <span *ngIf="reaction.front === 'checked'" [ngClass]="{
                          'active-reaction': reaction.front === 'checked',
                          'selected-reaction': reaction.id === comment.like_id
                        }" (mouseenter)="hoverReaction($event)" (mouseleave)="unHoverReaction($event)"
                        (click)="likeComment(comment.id, reaction.id)">
                        <img src="{{ reaction.img_url }}" alt="{{ reaction.name }}" class="img-fluid" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="item-comments item-add-comments">
          <div class="box-img-item-comments">
            <div class="img-item-comments" [innerHTML]="avatar_logged"></div>
          </div>
          <form class="box-content-item-comments" [formGroup]="commentForm" (ngSubmit)="uploadPostComment(post.ID)">
            <div class="form-group content-box-input">
              <input type="file" (change)="fileProgress($event)" />
              <input type="text" (blur)="onBlur()" (focus)="onFocus()" placeholder="Agregar comentario"
                class="input-item-comments" [mentionConfig]="{ items: items, labelKey: 'nicename' }"
                [mentionListTemplate]="mentionListTemplate" formControlName="content" id="message1" [mention]="items" />
              <ng-template #mentionListTemplate let-item="item">
                <span>{{ item.email }}</span>
              </ng-template>
              <button (click)="toggleEmojiPicker()" type="button">😀</button>
              <div class="users-list-tag" *ngIf="showListUsers">
                <ul>
                  <li *ngFor="let user of users" (click)="tagUser(user)">
                    {{ user.name }}
                  </li>
                </ul>
              </div>
              <emoji-mart class="emoji-mart" set="{{ set }}" *ngIf="showEmojiPicker"
                (emojiSelect)="addEmoji($event, 'message1')" title="Pick your emoji…"></emoji-mart>
            </div>
            <div *ngIf="fileUploadProgress">
              Upload progress: {{ fileUploadProgress }}
            </div>
            <div class="image-preview mb-3" *ngIf="previewUrl">
              <img [src]="previewUrl" height="300" />
            </div>

            <div class="mb-3" *ngIf="uploadedFilePath">
              {{ uploadedFilePath }}
            </div>
            <button type="submit" class="orange-small-btn" [disabled]="!commentForm.valid">
              Enviar
            </button>
          </form>
        </div>
      </div>
    </div>

    <div class="row justify-content-center" *ngIf="!isPostLoaded()">
      <div class="col-md-10 col-12">
        <div class="post-heading">
          <h6 class="heading-subtitle">NOTICIAS</h6>
          <h1 class="heading-title loading-shimmer">
            Título de prueba
          </h1>
          <span class="heading-date">
            <img src="assets/img/icons/calendar.svg" alt="Calendario" />
            <span class="loading-shimmer">Publicado 22/04/2020</span>
          </span>
        </div>
      </div>

      <div class="col-12">
        <div class="empty-img loading-shimmer"></div>
        <div class="body-dots">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>

      <div class="col-md-10 col-12">
        <p class="loading-shimmer">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor ex
          voluptatibus hic voluptate quibusdam minima aliquid voluptatem, ipsum
          magni aspernatur excepturi mollitia laborum voluptas labore suscipit
          autem vel natus corrupti.
        </p>
        <p class="loading-shimmer">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nobis
          necessitatibus quam neque repellendus ipsa dolorem quaerat veniam
          excepturi enim temporibus nulla, corporis, culpa architecto at velit
          omnis. Autem, id.
        </p>
      </div>
    </div>
  </section>
</div>

<div class="modal-img-full" [ngClass]="modalImgSingleShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullImgSingleModal()"></div>
  <button class="close" (click)="closeFullImgSingleModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-img-full"></div>
</div>

<div class="modal-img-full" [ngClass]="modalImgShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullImgModal()"></div>
  <button class="close" (click)="closeFullImgModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-img-full">
    <!-- <img src="{{ modalImgSrc }}" alt=""> -->
  </div>
  <div class="navs-buttons">
    <button class="btn-prev-lightbox" *ngIf="has_prev_img" (click)="prevFullImgModal()">
      <img src="assets/img/icons/arrow-white.svg" alt="" />
    </button>

    <button class="btn-next-lightbox" *ngIf="has_next_img" (click)="nextFullImgModal()">
      <img src="assets/img/icons/arrow-white.svg" alt="" />
    </button>
  </div>
</div>

<div class="modal-reactions-list" id="modal-reactions-list" [ngClass]="modalReaction == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalReactions()"></div>
  <div class="box-reactions-list-full">
    <button class="close" (click)="closeModalReactions()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="title-box-reactions">
      Personas que reaccionaron a la actividad
    </h3>
    <ul class="items-reactions-list">
      <li *ngFor="let item of reactions_list; let j = index" class="item-reactions-list">
        <img [src]="getReactionIcon(item.smiley_id)" alt="Me gusta" />
        <h3 class="title-item-reactions-list" [innerHTML]="item.user_email"></h3>
      </li>
    </ul>
  </div>
</div>

<div class="modal-reactions-list" id="modal-reactions-list-comment"
  [ngClass]="modalReactionComment == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalReactionsComment()"></div>
  <div class="box-reactions-list-full">
    <button class="close" (click)="closeModalReactionsComment()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="title-box-reactions">Personas que reaccionaron al comentario</h3>
    <ul class="items-reactions-list">
      <li *ngFor="let item of reactions_list_comment; let j = index" class="item-reactions-list">
        <img [src]="getReactionIcon(item.smiley_id)" alt="Me gusta" />
        <h3 class="title-item-reactions-list" [innerHTML]="item.user_email"></h3>
      </li>
    </ul>
  </div>
</div>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>

<div id="modal-video-youtube" class="modal-video" [ngClass]="modalVideoIframe == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideoIframe()"></div>
  <button class="close" (click)="closeModalVideoIframe()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <iframe width="560" height="315" [src]="urlSafe" class="iframe-video" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>