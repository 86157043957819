import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { NoticiasService } from '@services/noticias';
import { NewsService } from '@app/services/news';
import { GaleriasService } from '@app/services/galerias';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-trivia-ciberseguridad2',
  templateUrl: './trivia-ciberseguridad2.component.html',
  styleUrls: ['./trivia-ciberseguridad2.component.scss'],
})
export class TriviaCiberseguridad2AbastibleComponent implements OnInit {
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  selectedIndex: any[] = [];
  newsSeguridadInformacion: any;
  galeriaVideosSeguridad: any;
  galeriaSeguridad: any;
  news1: any;
  news1Res: any;
  currentPage1 = 1;
  catNews = "all";
  categoriesFilter: any;
  modalVideoIframe = false;
  modalSrcVideoIframe: string = "";
  urlSafe: SafeResourceUrl;
  carouselCustom = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 20,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselCharlas2 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  modalVideo: any;
  modalSrcVideo: any;
  hasSubmit: Boolean = false;
  myForm: FormGroup;
  timeInterval: any;
  participo: Number = 3;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private NoticiasService: NoticiasService,
    private galeriasService: GaleriasService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public sanitizer: DomSanitizer,
    private NewsService: NewsService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
    this.myForm = this.formBuilder.group({
      full_name: ['', [Validators.required]],
      // region: ['', [Validators.required]],
      gerencia: ['', [Validators.required]],
      cargo: ['', [Validators.required]],
      dependencia: ['', [Validators.required]],
      question_1: ['', [Validators.required]],
      time_form: ['', [Validators.required]],
    });
  }
  init(): void {
    this.title.setTitle('Trivia Ciberseguridad 2 | Intranet Abastible');
    this.paramsID = 29622;
    this.slug = this.params['slug'];
    let that = this;

    that.validateUserTrivia();

    $("body").delegate(".page-trivia-ciberseguridad-before-content-section2-button", "click", function (e) {
      e.preventDefault();
      let validate = true;
      $('[name="full_name"]').removeClass('input-error');
      if ($('[name="full_name"]').val() == null || $('[name="full_name"]').val() == '') {
        validate = false;
        $('[name="full_name"]').addClass('input-error');
      }
      // $('[name="region"]').removeClass('input-error');
      // if ($('[name="region"]').val() == null || $('[name="region"]').val() == '') {
      //   validate = false;
      //   $('[name="region"]').addClass('input-error');
      // }
      $('[name="gerencia"]').removeClass('input-error');
      if ($('[name="gerencia"]').val() == null || $('[name="gerencia"]').val() == '') {
        validate = false;
        $('[name="gerencia"]').addClass('input-error');
      }
      $('[name="cargo"]').removeClass('input-error');
      if ($('[name="cargo"]').val() == null || $('[name="cargo"]').val() == '') {
        validate = false;
        $('[name="cargo"]').addClass('input-error');
      }
      $('[name="dependencia"]').removeClass('input-error');
      if ($('[name="dependencia"]').val() == null || $('[name="dependencia"]').val() == '') {
        validate = false;
        $('[name="dependencia"]').addClass('input-error');
      }

      if (validate) {
        $(".page-trivia-ciberseguridad-before").fadeOut(300);
        setTimeout(() => {
          $(".page-trivia-ciberseguridad").fadeIn(300);
          that.functionInterval();
        }, 300);
      }

    });
    $("body").delegate(".page-trivia-ciberseguridad-content-section4-button", "click", function (e) {
      e.preventDefault();
      if ($("[name='question_1']:checked").length > 0) {
        that.functionInterval(true)
        that.myForm.setValue({
          full_name: $("#full_name").val(),
          // region: $("#region").val(),
          gerencia: $("#gerencia").val(),
          cargo: $("#cargo").val(),
          dependencia: $("#dependencia").val(),
          question_1: $("[name='question_1']:checked").val(),
          time_form: $("#time_form").val()
        });
        that.submitForm();
      }
    });

  }
  submitForm() {
    if (!this.hasSubmit) {
      this.hasSubmit = true;
      var that = this;
      const formData = new FormData();
      formData.append("full_name", that.myForm.get('full_name').value);
      // formData.append("region", that.myForm.get('region').value);
      formData.append("gerencia", that.myForm.get('gerencia').value);
      formData.append("cargo", that.myForm.get('cargo').value);
      formData.append("dependencia", that.myForm.get('dependencia').value);
      formData.append("question_1", that.myForm.get('question_1').value);
      formData.append("time_form", that.myForm.get('time_form').value);
      formData.append("user_id", that.authService.user['ID']);
      formData.append("trivia_number", "2");
      that.http.post('https://intranet.abastible.cl/wp/wp-json/v2/trivia_ciber', formData)
        .subscribe(res => {
          console.log(res);
          that.participo = 4;
        })
    }
  }
  validateUserTrivia() {
    var that = this;
    const formData = new FormData();
    formData.append("user_id", that.authService.user['ID']);
    formData.append("trivia_number", "2");
    that.http.post('https://intranet.abastible.cl/wp/wp-json/v2/validate_trivia_ciber', formData)
      .subscribe(res => {
        if (res) {
          that.participo = 1;
        } else {
          that.participo = 0
        }
      })
  }
  functionInterval(clear: boolean = false) {
    let timeIntervalVar = 0;
    let that = this;
    if (clear) {
      clearInterval(this.timeInterval);
    } else {
      that.timeInterval = setInterval(() => {
        timeIntervalVar = timeIntervalVar + 1;
        $("#time_form").val(timeIntervalVar)
      }, 1000);
    }
  }

} 
