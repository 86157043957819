import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth';
import { GaleriasService } from '@services/galerias';
import { LoadingService } from '@services/loading';

@Component({
  selector: 'app-galeria-image-section',
  templateUrl: './galeria-image-section.component.html',
  styleUrls: ['./galeria-image-section.component.scss'],
})
export class GaleriaImageSectionPageComponent implements OnInit {
  galerias: any;
  galerias2: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  currentPage = 1;
  paramsID: any;
  galeriasRes: any;
  valueSearch = "";
  back_title_page: any;
  back_route_link: any;
  back_section_route_link: any;
  back_section_route_title: any;
  has_back_section = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private galeriasService: GaleriasService,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('Galeria | Intranet Abastible');
    console.log(location.origin);
    if (localStorage.getItem('back_title_page')) {
      this.back_title_page = localStorage.getItem('back_title_page');
      this.back_route_link = "https://intranet.abastible.cl/" + localStorage.getItem('back_route_link')

      if (localStorage.getItem('back_section_route_link')) {
        this.has_back_section = true;
        this.back_section_route_title = localStorage.getItem('back_section_route_title');
        this.back_section_route_link = this.back_route_link + localStorage.getItem('back_section_route_link');
      } else {
        this.has_back_section = false;
      }
    }
    this.activatedRoute.params.subscribe((params) => {
      this.params = params['slug'];
      console.log(this.params)
    });

    this.galeriasService.galeriaByCategory(this.params, 1, this.valueSearch).subscribe(
      (res) => {
        this.galerias = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
  }
  nextPage() {
    this.currentPage = this.currentPage + 1;

    this.galeriasService.galeriaByCategory(this.params, this.currentPage, this.valueSearch).subscribe(
      (res) => {
        this.galeriasRes = res;
        this.galerias = [...this.galerias, ...this.galeriasRes];
        console.log([...this.galerias, res]);
        console.log(this.galeriasRes)
      },
      (err) => {
        console.error(err);
      }
    );
  }
  onSubmit(value: any) {
    this.valueSearch = value
    this.galeriasService.galeriaByCategory(this.params, 1, this.valueSearch).subscribe(
      (res) => {
        this.galerias = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
