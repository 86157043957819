import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NewsService {

    constructor(private httpClient: HttpClient) { }
    getNews(slug: string, paged: number): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/news-by-slug/?slug=${slug}&paged=${paged}&per_page=6`, null)
            .pipe((result) => {
                return result;
            });
    }
    getNewsCoronavirus(): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/news-by-slug/?slug=coronavirus&paged=1&per_page=1`, null)
            .pipe((result) => {
                return result;
            });
    }
    getInformesCoronavirus(): Observable<Record<string, any>> {
        return this.httpClient
            .post(`${environment2.apiUrl}/informes_coronavirus/`, null)
            .pipe((result) => {
                return result;
            });
    }
    getBeneficios(slug: string): Observable<Record<string, any>> {
        return this.httpClient
            .post(`${environment2.apiUrl}/beneficios/?slug=${slug}`, null)
            .pipe((result) => {
                return result;
            });
    }
    getAllPostProgram(slug: string): Observable<Record<string, any>> {
        return this.httpClient
            .post(`${environment2.apiUrl}/get-all-post-program/?slug=${slug}`, null)
            .pipe((result) => {
                return result;
            });
    }
}
