import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { NoticiasService } from '@services/noticias';

@Component({
  selector: 'app-punto-prensa',
  templateUrl: './punto-prensa.component.html',
  styleUrls: ['./punto-prensa.component.scss'],
})
export class PuntoPrensaAbastibleComponent implements OnInit {
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  selectedIndex: any[] = [];
  news1: any;
  news1Res: any;
  currentPage1 = 1;
  catNews = "all";
  categoriesFilter: any;
  valueSearch = "";
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private NoticiasService: NoticiasService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Punto de Prensa | Intranet Abastible');
    this.paramsID = 29622;
    this.slug = this.params['slug'];
    this.NoticiasService.getInfoPuntoPrensa(1, this.valueSearch).subscribe(
      (res) => {
        this.news1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  nextPage() {
    this.currentPage1 = this.currentPage1 + 1;

    this.NoticiasService.getInfoPuntoPrensa(this.currentPage1, this.valueSearch).subscribe(
      (res) => {
        this.news1Res = res;
        this.news1 = [...this.news1, ...this.news1Res];
        console.log([...this.news1, res]);
        console.log(this.news1Res)
      },
      (err) => {
        console.error(err);
      }
    );
  }

  textMin(text: string) {
    var str = text;
    var res = str.substring(0, 150);//get first 5 chars
    return res + '...';;
  }
  titleMin(text: string) {
    var str = text;
    var res = str.substring(0, 50);//get first 5 chars
    return res + '...';;
  }

  contentFilter(content: string): string {
    return content.replace(/<img .*?>/g, '');
  }

  setSelected(id: number) {
    if (this.selectedIndex[id] == true) {
      this.selectedIndex[id] = false;
    } else {
      this.selectedIndex[id] = true;
    }
  }
  onSubmit(value: any) {
    this.valueSearch = value
    this.NoticiasService.getInfoPuntoPrensa(1, this.valueSearch).subscribe(
      (res) => {
        this.news1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
  }
} 
