import { Component, OnInit } from '@angular/core';
import {
  animate,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: 'app-intern-layout',
  templateUrl: './intern.component.html',
  animations: [
    trigger('routerAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: 0,
            display: 'flex',
            flex: '1',
            transform: 'translateY(-30px)',
            flexDirection: 'column',
          }),
        ]),
        query(':enter', [
          animate(
            '1000ms ease',
            style({ opacity: 1, transform: 'translateY(0)' })
          ),
        ]),

        query(
          ':leave',
          [
            animate(
              '1000ms ease',
              style({ opacity: 0, transform: 'translateY(-30px)' })
            ),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InternLayout implements OnInit {
  sideMenuOpen = false;
  muroNew = false;

  constructor(private router: Router) {
    router.events.subscribe((val) => {
      this.sideMenuOpen = false;
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        console.log(this.router.url);
        if (this.router.url == '/muro-new') {
          this.muroNew = true;
        } else {
          this.muroNew = false;
        }
      }
    });
  }

  ngOnInit() { }

  toggleMenu(): void {
    this.sideMenuOpen
      ? (this.sideMenuOpen = false)
      : (this.sideMenuOpen = true);
  }
}
