import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import * as uuid from 'uuid';

// Services
import { AuthService } from '@services/auth';
import { NotificacionesService } from '@services/notificaciones';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.scss'],
})
export class HeaderNewComponent implements OnInit {
  notification;
  userLogged: any;
  avatar_logged: any;
  name_logged: any;
  profileSidebar: false;
  commentCounter = 0;
  likesCounter = 0;
  totalNotification: any;
  notifications: any;
  notificationsRes: any;
  description: any;
  loggedIn = false;
  busqueda: any;
  setHash = uuid.v4();
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private NotificacionesService: NotificacionesService,
    private httpClient: HttpClient,
    private appComponent: AppComponent,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        console.log("test route header");
        if (this.router.url != "/") {
          this.appComponent.loggedIn$.subscribe((loggedIn) => {
            if (loggedIn) {
              setInterval(() => {
                this.NotificacionesService.getNotificationsNew(1).subscribe(
                  (res) => {
                    console.log(res);
                    this.notifications = res;
                    this.notifications.forEach(element =>
                      this.showToaster(element.description.replace(/<[^>]*>/g, ''), element.post_id + '?activityid=' + element.activity_id_post, element.id),
                    )
                  },
                  (err) => {
                    console.error(err);
                  }
                ),
                  (err) => {
                    console.error(err);
                  }
              }, 6000);
              this.NotificacionesService.getTotalNotifications().subscribe(
                (res) => {
                  console.log(res);
                },
                (err) => {
                  console.error(err);
                }
              ),
                (err) => {
                  console.error(err);
                }
            }

            this.loggedIn = loggedIn;
          });
        }
      }
    });
  }

  ngOnInit() {
    let vm = this;
    this.appComponent.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.NotificacionesService.getTotalNotifications().subscribe(
          (res: any) => {
            if (res > 99) {
              this.totalNotification = "+99"
            } else {
              this.totalNotification = res
            }
            console.log(res);
          },
          (err) => {
            console.error(err);
          }
        ),
          (err) => {
            console.error(err);
          }
      }

      this.loggedIn = loggedIn;
    });
  }

  onSubmit(value: any) {
    this.busqueda = value;
    this.router.navigate(['/busqueda/' + this.busqueda]);
  }

  logout() {
    this.authService.logOut().subscribe(
      (res: any) => {
        localStorage.removeItem("user");
        localStorage.removeItem("hash");
        localStorage.setItem('seccion_logged', '1')
        window.location.href = res;
      },
      (err) => {
        console.error(err);
      }
    ),
      (err) => {
        console.error(err);
      }
  }

  showToaster(description: any, idNew: any, idNotification: any) {
    this.NotificacionesService.UpadteNotificationsNew(1, idNotification).subscribe(
      (res) => {
        console.log(res)
      },
      (err) => {
        console.error(err);
      }
    ),
      (err) => {
        console.error(err);
      }
    this.toastr.info('Revisa <a href=\"/noticia/' + idNew + '\" class=\"toastr-link\">AQUI</a>',
      description, {
      enableHtml: true,
      positionClass: 'toast-bottom-right',
      closeButton: false,
      timeOut: 5000
    })
  }
}
