<section class="p-section" id="title-header-section">
  <div class="container">
    <div class="text-center mb-3">
      <h1 class="title1" [innerHTML]="content.titulo"></h1>
    </div>
    <ng-container *ngIf="content.descripcion" class="description-section">
      <div class="descripcion-section" [innerHTML]="content.descripcion"></div>
    </ng-container>
  </div>
</section>

<ng-container *ngIf="content.columns_info">
  <section class="p-section" id="columns-info-img">
    <div class="container">
      <div class="row row-columns-info" *ngFor="let item of columns_info; let i = index">
        <div class="col-lg-6 column-info-images">
          <img src="{{ item.img }}" class="img-fluid img-valores" alt="">
        </div>
        <div *ngIf="productosServicios" [ngClass]="{'col-lg-12': !item.img}" class="col-lg-6 column-info-information">
          <h3 class="title-colums-info-img"></h3>
          <div class="text-columns-info-img">
            <p [innerHTML]="item.texto"></p>
          </div>
        </div>
        <div *ngIf="!productosServicios" class="col-lg-6 column-info-information">
          <h3 class="title-colums-info-img"></h3>
          <div class="text-columns-info-img">
            <p [innerHTML]="item.texto"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<ng-container *ngIf="valores">
  <section class="p-section" id="valores">
    <div class="container">
      <div class="text-center mb-3">
        <h1 class="title1">{{ content.valores_titulo }}</h1>
      </div>
      <div class="row">
        <!-- <div class="col-lg-5">
        <img src="assets/img/img-valores.png" class="img-fluid img-valores" alt="">
      </div> -->
        <div class="col-12">
          <p>{{ content.valores_descripcion }}</p>
        </div>
      </div>
      <div class="row row-valores">
        <div class="col-valores" *ngFor="let valor of valores; let i = index">
          <div class="card-design1">
            <img src="{{ valor.icon }}" alt="">
            <h3 class="card-title">{{ valor.titulo }}</h3>
            <p>{{ valor.texto }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<ng-container *ngIf="content.mision">
  <section class="p-section" id="mision-vision">
    <div class="container">
      <div class="row">
        <ng-container *ngIf="content.mision">
          <div class="col-12" id="mision">
            <div class="text-center mb-3">
              <h1 class="title1">Misión</h1>
            </div>
            <p>{{ content.mision }}</p>
          </div>
        </ng-container>
        <ng-container *ngIf="content.vision">
          <div class="col-12 text-align-vision" id="vision">
            <div class="text-center mb-3">
              <h1 class="title1">Visión</h1>
            </div>
            <p>{{ content.vision }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
</ng-container>

<ng-container *ngIf="content.imagen_clickma">
  <div class="container text-center">
    <a href="{{content.link_clickma}}" target="_blank" class="link-clickma">
      <img src="{{ content.imagen_clickma }}" alt="" class="img-fluid">
    </a>
  </div>
</ng-container>