<style>
  
</style>
<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="back_title_page">
        <a href="{{back_route_link}}"><span>{{
            back_title_page }}</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item" *ngIf="back_section_route_title">
        <a href="{{back_section_route_link}}"><span>{{
            back_section_route_title }}</span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>{{title_gallery}}</span>
      </div>
    </div>
  </div>
</section>
<div class="container">
  <section class="single-gallery">
    <div class="row">
      <div class="col-lg-4 col-img-gallery" *ngFor="let image of images; let i = index">
        <img src="{{ image.url }}" data-gallery="gallery-single1" id="img-gallery-single{{ i }}" alt="{{ image.description }}"
          (click)=fullImgModal(image.url,1,i,image.description)>
        <p>{{ image.description }}</p>
      </div>
    </div>
  </section>
</div>
<div class="modal-img-full" [ngClass]="modalImgShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullImgModal()"></div>
  <button class="close" (click)="closeFullImgModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-img-full">
    <!-- <img src="{{ modalImgSrc }}" alt=""> -->
  </div>
  <div class="short-description-modal">
    <p>{{modalShortText}}</p>
  </div>
  <div class="navs-buttons">
    <button class="btn-prev-lightbox" *ngIf="has_prev_img" (click)="prevFullImgModal()"><img
        src="assets/img/icons/arrow-white.svg" alt=""></button>
    <button class="btn-next-lightbox" *ngIf="has_next_img" (click)="nextFullImgModal()"><img
        src="assets/img/icons/arrow-white.svg" alt=""></button>
  </div>
</div>