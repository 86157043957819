import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { AppComponent } from '../../app.component';

// Services
import { AuthService } from '@services/auth';
import { PuntoPrensaService } from '@services/punto-prensa';
import { CumpleanosService } from '@services/cumpleanos';
import { LoadingService } from '@services/loading';
import { NewsService } from '@services/news';
import { GruposService } from '@services/grupos';
import { pipe } from 'rxjs';
import { EventosService } from '@app/services/eventos';
import * as moment from 'moment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  currentPage: number;
  loading = true;
  puntoPrensa: any;
  cumpleanos: any;
  userLogged: any;
  avatar_logged: any;
  name_logged: any;
  historySwiper = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 3,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  loggedIn = false;
  newsCoronavirus: any;
  grupos: any;
  eventos_actuales: any;
  eventos_siguientes: any;
  constructor(
    private authService: AuthService,
    private puntoPrensaService: PuntoPrensaService,
    private cumpleanosService: CumpleanosService,
    private gruposService: GruposService,
    private eventosService: EventosService,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private title: Title,
    public appComponent: AppComponent,
    private newsService: NewsService,
  ) { }

  ngOnInit() {
    // if (!localStorage.getItem('cumple')) {
    //   $("body").addClass("modal-open"); 
    //   $(".modal-birthday").fadeIn(300).css("display", "flex")
    // }
    $(".overlay-modal-birthday, .close-modal-birthday, .bg-papelillos").click(function (e) {
      e.preventDefault();
      $(".modal-birthday").fadeOut(300);
      localStorage.setItem('cumple', "Si");
      $("body").removeClass("modal-open");
    })

    this.appComponent.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.userLogged = this.authService.user;
        this.avatar_logged = this.authService.user['avatar_url'];
        this.name_logged = this.authService.user['display_name'];
      }

      this.loggedIn = loggedIn;
    });

    this.puntoPrensaService.getInfoPuntoPrensa().subscribe(
      (res) => {
        this.puntoPrensa = res;
        console.log(this.puntoPrensa);
      },
      (err) => {
        console.error(err);
      }
    );
    this.cumpleanosService.getInfoCumpleanos().subscribe(
      (res) => {
        this.cumpleanos = res;
        console.log(this.cumpleanos);
      },
      (err) => {
        console.error(err);
      }
    );
    this.newsService.getNewsCoronavirus().subscribe(
      (res) => {
        this.newsCoronavirus = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.gruposService.getInfoListaGrupos().subscribe(
      (res) => {
        this.grupos = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.eventosService.eventosActuales().subscribe(
      (res) => {
        console.log(res);
        this.eventos_actuales = res;
      },
      (err) => {
        console.error(err);
      }
    );
    this.eventosService.eventosSiguientes().subscribe(
      (res) => {
        console.log("proximos eventos",res);
        this.eventos_siguientes = res.slice(0,2);
      },
      (err) => {
        console.error(err);
      }
    );
  }
  getPostDate(fecha:string): string {
    return moment(fecha, 'YYYY-MM-DD HH:mm:ss').format(
      'DD/MM/YYYY'
    );
  }
  renderTitleCumpleanos(content: string): string {
    if(content.includes(",")){
      let porciones = content.split(',');
      let reorganize = porciones[1] + " " + porciones[0];
      return reorganize;
    }else{
      return content;
    }
  }
  contentFilter(content: string): string {
    return content.replace(/<img .*?>/g, '');
  }

}
