<section class="navbar-custom2">
  <div class="container">
    <div id="carousel-navbar">
      <div (click)="scroll(section.slug)" class="item-navbar-custom2 item-directorio-administracion"
        *ngFor="let section of sections; let i = index">
        <h6 class="title-navbar-custom">{{ section.titulo }}</h6>
      </div>
    </div>
  </div>
</section>
<div class="bg-white bg-white-custom">
  <section class="section-banner-custom-new" id="section0" style="background-image: url(assets/img/img-test2.jpg);">
    <div class="overlay-banner-custom-new"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h2 class="title-banner-custom-new" data-aos="fade-right" data-aos-duration="700">Desarrollo Organizacional
          </h2>
          <span class="line-title-custom-new-banner" data-aos="fade-right" data-aos-duration="700"
            data-aos-delay="400"></span>
          <!-- <div class="box-text-banner-custon-new" data-aos="fade-right" data-aos-duration="700">
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
          </div> -->
        </div>
      </div>
    </div>
  </section>
  <div id="animationWindow"></div>
  <section id="breadcrumbs">
    <div class="container">
      <div class="breadcrumbs breadcrumbs-sections">
        <div class="breadcrumbs-item">
          <a [routerLink]="['/muro']"><span class="material-icons">
              home
            </span></a>
          <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
        </div>
        <div class="breadcrumbs-item">
          <span>Desarrollo Organizacional</span>
        </div>
      </div>
    </div>
  </section>
  <div *ngFor="let section of sections; let i = index" class="sections-custom-new">
    <section class="section-custom-new" id="{{section.slug}}">
      <div class="container">
        <div class="row">
          <div class="" [ngClass]="{'col-lg-3': section.video, 'col-lg-4': !section.video }">
            <div class="box-title-custom-new">
              <h3 class="title-custom-new" data-aos="fade-up" data-aos-duration="700">{{ section.titulo }}</h3>
              <span class="line-title-custom-new" data-aos="fade-right" data-aos-duration="700"
                data-aos-delay="400"></span>
            </div>
          </div>
          <div class="" [ngClass]="{'col-lg-6': section.video, 'col-lg-8': !section.video }">
            <div class="box-content-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="200">
              <!-- <div class="text-destaqued-custom-new">
                <h5>{{ section.subtitulo }}</h5>
              </div> -->
              <div class="text-custom-new">
                <p [innerHTML]="textMin(section.descripcion_corta)"></p>
              </div>
              <div class="links-wrapper" *ngIf="section.titulo == 'Centro de Excelencia de Talento'">
                <span
                  (click)="fullTextModal(section.titulo , section.texto, section.banners, section.slug,section.documentos)"
                  class="btn-custom-new">
                  <span>VER MÁS</span>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                    style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                      </g>
                    </g>
                  </svg>
                </span>
                <a href="https://trabajaenabastible.cl/  " target="_blank" class="btn-custom-new btn-custom-new-custom">
                  <span>Trabaja en Abastible</span>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                    style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                      </g>
                    </g>
                  </svg>
                </a>
              </div>
              <span
                (click)="fullTextModal(section.titulo , section.texto, section.banners, section.slug,section.documentos)"
                class="btn-custom-new" *ngIf="section.titulo != 'Centro de Excelencia de Talento'">
                <span>VER MÁS</span>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                  style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                  <g>
                    <g>
                      <path
                        d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                    </g>
                  </g>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="box-img-section-custom-new" *ngIf="section.video">
        <div class="diagonal-cut"></div>
        <video autoplay muted loop *ngIf="section.titulo != 'Gestión de Desempeño'">
          <source src="{{ section.video }}">
        </video>
        <img src="{{ section.video }}" *ngIf="section.titulo == 'Gestión de Desempeño'"
          [ngClass]="{'custom-position1': section.titulo == 'Gestión de Desempeño', '': !section.video }" alt="">
      </div>
    </section>

    <section class="section-image-divider" *ngIf="section.imagen_separacion"
      [ngStyle]="{ 'background-image': 'url(' + section.imagen_separacion + ')'}">
    </section>
  </div>

  <div>
    <div class="tabs-search">
      <button data-tab="#news-general" class="item-tab-search active">Noticias</button>
      <button data-tab="#galeries-general" class="item-tab-search" *ngIf="galeria1">Galería</button>
      <button data-tab="#videos-general" class="item-tab-search" *ngIf="galeriaVideos1.length > 0">Videos</button>
    </div>
    <div class="tabs-content-search">
      <div class="item-tab-content-search" id="news-general">
        <section class="section-custom5">
          <div class="container">
            <!-- <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">NOTICIAS</h3>
            <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span> -->
            <div class="swiper-container" #carouselNoticias [swiper]="carouselCharlas.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let new1 of news1; let i = index">
                  <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ new1.img }}" alt="">
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="new1.title"></h4>
                      <div class="card-text">
                        <p [innerHTML]="new1.text"></p>
                      </div>
                      <a [routerLink]="['/noticia/' + new1.slug ]" class="card-link">Leer más</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'centro-de-aprendizaje' }"
                  class="btn-custom-new">
                  <span>VER MÁS</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="item-tab-content-search" id="galeries-general" *ngIf="galeria1" style="display: none;">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of galeria1; let i = index">
                  <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                    class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                    data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ item.feature_img }}" alt="">
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                    </div>
                  </a>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
              <a [routerLink]="['/galeria-image-section/desarrollo-organizacional' ]" class="btn-custom-new">
                <span>VER MÁS</span>
              </a>
            </div>
          </div>
        </section>
      </div>
      <div class="item-tab-content-search" id="videos-general" *ngIf="galeriaVideos1.length > 0" style="display: none;">
        <section class="section-custom5">
          <div class="container">
            <div class="swiper-container" [swiper]="carouselCharlas.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of galeriaVideos1; let i = index">
                  <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                    (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ item.img }}" alt="">
                      <div class="diagonal-cut"></div>
                      <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                        class="btn-play-desing1">
                    </div>
                    <div class="card-content">
                      <h4 class="card-title">{{ item.title }}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
              <a [routerLink]="['/galeria-videos-section/desarrollo-organizacional' ]" [queryParams]="1"
                class="btn-custom-new">
                <span>VER MÁS</span>
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

  <section class="section-custom-new3">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">DOCUMENTOS</h3>
          <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
          <div class="content-documents-custom-new3">
            <a href="{{ documento.link }}" class="item-document-custom-new3"
              *ngFor="let documento of documentos; let i = index" target="_blank" data-aos="fade-up"
              data-aos-duration="700" data-aos-delay="0">
              <h3 class="title-document-custom-new3">{{ documento.titulo }}</h3>
              <div class="box-acctions-custom-new3">
                <span class="view-document">
                  <img src="assets/img/file.svg" alt="">
                </span>
                <span class="download-document">
                  <img src="assets/img/download.svg" alt="">
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>

<div class="modal-img-full modal-single-content" [ngClass]="modalTextShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullTextModal()"></div>
  <div class="box-modal-text-full">
    <div class="modal-header-single-content">
      <button class="btn-back-single-content" (click)="closeFullTextModal()">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"
          fill="#ff7000">
          <g>
            <g>
              <path
                d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
            </g>
          </g>
        </svg>
      </button>
      <h3 class="title-header-modal">Desarrollo Organizacional / <span [innerHTML]="modalTitulo"></span></h3>
      <!-- <button class="close" (click)="closeFullTextModal()">
        <img src="assets/img/btn-close.svg" alt="">
      </button> -->
    </div>
    <div class="modal-text">
      <div class="modal-content-text">
        <div [innerHTML]="newContentModal"
          *ngIf="modalTitulo != 'Centro de Excelencia de Talento' && modalTitulo != 'Gestión de Desempeño'"></div>

        <!-- Gestión de Desempeño -->

        <div *ngIf="modalTitulo == 'Gestión de Desempeño'">
          <div class="page-gestion-desempeno">
            <div class="page-gestion-desempeno-banner">
              <img src="assets/img/desempeno/banner-desempeno.jpg" alt="" class="page-gestion-desempeno-banner-img">
            </div>
            <div class="page-gestion-desempeno-section1">
              <div class="container">
                <div class="row">
                  <div class="col-lg-11 mx-auto">
                    <div class="page-gestion-desempeno-section1-title">
                      <img src="assets/img/desempeno/title-desempeno1.png" alt=""
                        class="page-gestion-desempeno-section1-title-img">
                    </div>
                    <div class="page-gestion-desempeno-section1-text">
                      <p>La Gestión de Desempeño en Abastible se considera un proceso central para el negocio que
                        facilita
                        el
                        éxito de la organización, al lograr que los objetivos de la empresa estén claramente
                        representados
                        en
                        los objetivos de área, y que a su vez estos, estén y formen parte vital de los objetivos de cada
                        persona, de manera que se asegure el cumplimiento de la estrategia y el crecimiento sostenido de
                        la
                        organización.</p>
                    </div>
                    <div class="page-gestion-desempeno-section1-video">
                      <video
                        src="https://intranet.abastible.cl/wp/wp-content/uploads/2019/05/GESTI%C3%93N-DEL-DESEMPE%C3%91O-V2-1.mp4"
                        controls></video>
                    </div>
                  </div>
                </div>
              </div>
              <img src="assets/img/bg-section-design2-1.png" class="page-gestion-desempeno-section1-separate" alt="">
            </div>
            <div class="page-gestion-desempeno-section2">
              <div class="container">
                <div class="page-gestion-desempeno-section2-content">
                  <div class="row">
                    <div class="col-lg-11 mx-auto">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="page-gestion-desempeno-section2-content-left">
                            <div class="page-gestion-desempeno-section2-content-left-title">
                              <img src="assets/img/desempeno/title-desempeno2.png" alt=""
                                class="page-gestion-desempeno-section2-content-left-title-img">
                            </div>
                            <div class="page-gestion-desempeno-section2-content-left-image">
                              <img src="assets/img/desempeno/image-proposito1.jpg" alt=""
                                class="page-gestion-desempeno-section2-content-left-image-img">
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="page-gestion-desempeno-section2-content-right">
                            <div class="page-gestion-desempeno-section2-content-right-text">
                              <p>Uno de los elementos más importantes del sistema de gestión del desempeño consiste en
                                establecer
                                una comunicación abierta y constante entre el colaborador y su líder, que permita
                                monitorear
                                a
                                lo
                                largo del año los avances que el colaborador ha alcanzado en relación con los objetivos
                                propuestos, así como brindar retroalimentación relacionada a las conductas que se han
                                percibido
                                o
                                no en relación con las competencias organizacionales.</p>
                              <p>De esta manera, las conversaciones deben integrar temas de desempeño y desarrollo,
                                permitiendo
                                que los espacios recojan todos los aspectos que pueden llegar a impactar en la ejecución
                                de
                                cada
                                colaborador en el día a día, permitiendo que se visualice a largo plazo en la
                                organización y
                                conozca las oportunidades de crecimiento y desarrollo posibles.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img src="assets/img/bg-section-design2-2.png" class="page-gestion-desempeno-section1-separate" alt="">
            </div>
            <div class="page-gestion-desempeno-section3">
              <div class="container">
                <div class="row">
                  <div class="col-lg-10 mx-auto">
                    <div class="page-gestion-desempeno-section3-container">
                      <div class="page-gestion-desempeno-section3-container-header">
                        <img src="assets/img/desempeno/title-desempeno3.png" alt=""
                          class="page-gestion-desempeno-section3-container-header-img">
                        <div class="page-gestion-desempeno-section3-container-header-text">
                          <p>Están alineadas a nuestro Propósito <b>“Potenciar el Espíritu Emprendedor”</b></p>
                        </div>
                      </div>
                      <div class="page-gestion-desempeno-section3-container-body">
                        <div class="page-gestion-desempeno-section3-container-body-items">
                          <div class="page-gestion-desempeno-section3-container-body-item active">
                            <button class="page-gestion-desempeno-section3-container-body-item-btn">Creo valor y lo
                              protejo</button>
                            <div class="page-gestion-desempeno-section3-container-body-item-content"
                              style="display: block;">
                              <div class="page-gestion-desempeno-section3-container-body-item-content-text">
                                <p>Soy proactivo para crear valor y cuidadoso para protegerlo.</p>
                              </div>
                            </div>
                          </div>
                          <div class="page-gestion-desempeno-section3-container-body-item">
                            <button class="page-gestion-desempeno-section3-container-body-item-btn">Innovo y
                              experimento</button>
                            <div class="page-gestion-desempeno-section3-container-body-item-content">
                              <div class="page-gestion-desempeno-section3-container-body-item-content-text">
                                <p>Innovo y experimento, atreviéndome a trabajar de manera distinta.</p>
                              </div>
                            </div>
                          </div>
                          <div class="page-gestion-desempeno-section3-container-body-item">
                            <button class="page-gestion-desempeno-section3-container-body-item-btn">Colaboro</button>
                            <div class="page-gestion-desempeno-section3-container-body-item-content">
                              <div class="page-gestion-desempeno-section3-container-body-item-content-text">
                                <p>Colaboro con mis colegas, proveedores, distribuidores, clientes y el ecosistema,
                                  poniéndome en el lugar del otro.</p>
                              </div>
                            </div>
                          </div>
                          <div class="page-gestion-desempeno-section3-container-body-item">
                            <button class="page-gestion-desempeno-section3-container-body-item-btn">Persevero y soy
                              optimista</button>
                            <div class="page-gestion-desempeno-section3-container-body-item-content">
                              <div class="page-gestion-desempeno-section3-container-body-item-content-text">
                                <p>Persevero y soy optimista incluso en los períodos difíciles.</p>
                              </div>
                            </div>
                          </div>
                          <div class="page-gestion-desempeno-section3-container-body-item">
                            <button class="page-gestion-desempeno-section3-container-body-item-btn">Soy Ágil</button>
                            <div class="page-gestion-desempeno-section3-container-body-item-content">
                              <div class="page-gestion-desempeno-section3-container-body-item-content-text">
                                <p>Soy ágil, actuando y adaptándome con rapidez.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img src="assets/img/bg-section-design2-1.png" class="page-gestion-desempeno-section1-separate" alt="">
            </div>
          </div>
          <!-- Documentos -->
          <div *ngIf="modalDocuments.length > 0" class="pt-5 pb-3">
            <div class="tabs-search">
              <button data-tab="#documentos-gestion-desempeno" class="item-tab-search active">Documentos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="documentos-gestion-desempeno">
                <div class="col-lg-8 mx-auto mb-5">
                  <div class="content-documents-custom-new3">
                    <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
                      data-aos-duration="700" data-aos-delay="0" *ngFor="let document of modalDocuments; let i = index">
                      <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs-search">
            <button data-tab="#news-gestion-riesgo" class="item-tab-search active">Noticias</button>
            <button data-tab="#galeries-gestion-riesgo" class="item-tab-search"
              *ngIf="galerias4 != null">Galería</button>
            <button data-tab="#videos-gestion-riesgo" class="item-tab-search" *ngIf="videos1.length > 0">Videos</button>
          </div>
          <div class="tabs-content-search">
            <div class="item-tab-content-search" id="news-gestion-riesgo">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let new1 of news2; let i = index">
                        <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                          data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ new1.img }}" alt="">
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title">{{ new1.title }}</h4>
                            <div class="card-text">
                              <p [innerHTML]="new1.text"></p>
                            </div>
                            <a [routerLink]="['/noticia/' + new1.slug ]" class="card-link">Leer más</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'gestion-de-desempeno' }"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="galeries-gestion-riesgo" *ngIf="galerias4 != null"
              style="display: none;">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" [swiper]="carouselGalerias3.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let item of galerias4; let i = index">
                        <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                          class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                          data-aos="fade-up" data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ item.feature_img }}" alt="">
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-image-section/gestion-de-desempeno' ]" class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="videos-gestion-riesgo" *ngIf="videos1.length > 0"
              style="display: none;">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" [swiper]="carouselGalerias3.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let item of videos1; let i = index">
                        <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                          (click)="showModalVideo($event, item.video_url)" data-aos-duration="700"
                          *ngIf="item.youtube == ''">
                          <div class="card-img">
                            <img src="{{ item.img }}" alt="">
                            <div class="diagonal-cut"></div>
                            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                              class="btn-play-desing1">
                          </div>
                          <div class="card-content">
                            <h4 class="card-title">{{ item.title }}</h4>
                          </div>
                        </div>
                        <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                          (click)="showModalVideoIframe($event, item.video_url)" *ngIf="item.youtube != ''"
                          data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ item.img }}" alt="">
                            <div class="diagonal-cut"></div>
                            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                              class="btn-play-desing1">
                          </div>
                          <div class="card-content">
                            <h4 class="card-title">{{ item.title }}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-videos-section/gestion-de-desempeno' ]" [queryParams]="1"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <!-- Centro de Excelencia de Talento -->

        <div *ngIf="modalTitulo == 'Centro de Excelencia de Talento'">
          <div class="banner-seleccion-talento" *ngIf="modalBanners.length > 1">
            <div class="swiper-container" [swiper]="carouselBanner.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let modalBanner of modalBanners; let i = index">
                  <img src="{{ modalBanner }}" alt="">
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
          </div>
          <div class="banner-seleccion-talento" *ngIf="modalBanners.length == 1">
            <img src="{{ modalBanners[0] }}" alt="">
          </div>
          <div class="section-design2-equipo">
            <div class="container">
              <h3 class="title-section-design2">Equipo</h3>
            </div>
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-4 col-6 box-equipo-custom1 box-equipo-custom1-1 box-equipo-custom1-1-1"
                      data-aos="fade-up" data-aos-duration="700">
                      <div class="overlay-equipo-custom1"></div>
                      <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2024/03/Foto-Paola-Villablanca.jpg"
                        alt="" class="img-box-equipo-custom1">
                      <div class="box-content-equipo-custom1">
                        <h3 class="title-equipo-custom1" data-aos="fade-up" data-aos-duration="700">Paola Villablanca
                        </h3>
                        <span class="line-title-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
                          data-aos-delay="100"></span>
                        <div class="box-text-equipo-custom1">
                          <p>Subgerenta de Talento y Selección</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-6 box-equipo-custom1 box-equipo-custom1-1 box-equipo-custom1-1-1"
                      data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <div class="overlay-equipo-custom1"></div>
                      <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/02/Foto.jpg" alt=""
                        class="img-box-equipo-custom1">
                      <div class="box-content-equipo-custom1">
                        <h3 class="title-equipo-custom1" data-aos="fade-up" data-aos-duration="700">Karin Álvarez</h3>
                        <span class="line-title-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
                          data-aos-delay="100"></span>
                        <div class="box-text-equipo-custom1">
                          <p>Analista de Selección</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section-design2">
            <img src="assets/img/bg-section-design2-1.png" alt="" class="bg-top-section-design2">
            <div class="container">
              <h3 class="title-section-design2">Selección</h3>
              <div class="box-text-section-design2">
                <p>En el área de Selección nos preocupamos de atraer talento, buscando a las personas idóneas para los
                  cargos vacantes.Trabajamos con distintos partners que nos apoyan en el proceso de reclutamiento y
                  entrevistas de los futuros colaboradores de Abastible.</p>
              </div>
              <div class="links-section-design2">
                <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2021/02/Reclutamiento-Seleccion-y-Contratacion-.pdf"
                  download target="_blank" class="link-design-design2">
                  <img src="assets/img/link-design-design2-1.png" alt="">
                  <span>Procedimiento Selección
                    y Contratación</span>
                </a>
                <a href="https://hcm19.sapsf.com/login?company=abastibles&loginMethod=PWD" target="_blank"
                  class="link-design-design2 link-design-design2-2">
                  <img src="assets/img/link-design-design2-2.png" alt="">
                  <span>En este link puedes revisar información con respecto a los principales indicadores de gestión de
                    los procesos de Selección.</span>
                </a>
                <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2021/02/Paso-a-Paso-Solicitud-Ingreso-nuevo-o-Cambio-de-Cargo-VF-1.pptx"
                  target="_blank" download class="link-design-design2">
                  <img src="assets/img/link-design-design2-3.png" alt="">
                  <span>Paso a Paso Solicitud de Nuevas Vacantes y Cambio de Cargos por Conectados</span>
                </a>
                <a href="https://hcm19.sapsf.com/login?company=abastibles&loginMethod=PWD" target="_blank"
                  class="link-design-design2 link-design-design2-2">
                  <img src="assets/img/link-design-design2-4.png" alt="">
                  <span>Revisa acá el total de vacantes que se buscan por periodo de tiempo y gerencia.</span>
                </a>
              </div>
            </div>
          </div>

          <div class="section-design2 section-design2-talento">
            <img src="assets/img/bg-section-design2-2.png" alt="" class="bg-top-section-design2">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-5">
                  <img src="assets/img/img-section-design2-1.png" alt="" class="w-100">
                </div>
                <div class="col-lg-7 pl-5">
                  <h3 class="title-section-design2">Talento</h3>
                  <div class="box-text-section-design2">
                    <p>En el área de talento nos enfocamos en la gestión del talento que existe tanto dentro como fuera
                      de la compañía.
                      Buscamos desarrollar a nuestros propios talentos, fomentando la formación y el crecimiento de cada
                      uno de nuestros
                      colaboradores. <br><br> Te invitamos a conocer nuestros siguientes programas:</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section-design2 section-design2-programa">
            <img src="assets/img/bg-section-design2-4.png" alt="" class="bg-top-section-design2">
            <div class="container">
              <h3 class="title-section-design2">Programas</h3>
              <div class="items-tabs-programs">
                <div class="col-lg-4 col-sm-6 col-12">
                  <a href="#tab-programa-potencial" class="item-tab-program active">
                    <h3 class="item-tab-program-title">Potencial</h3>
                  </a>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <a href="#tab-programa-impulso" class="item-tab-program">
                    <h3 class="item-tab-program-title">Impulso</h3>
                  </a>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <a href="#tab-programa-trainee" class="item-tab-program">
                    <h3 class="item-tab-program-title">Trainee</h3>
                  </a>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <a href="#tab-programa-practicas" class="item-tab-program">
                    <h3 class="item-tab-program-title">Prácticas</h3>
                  </a>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <a href="#tab-programa-induccion" class="item-tab-program">
                    <h3 class="item-tab-program-title">Inducción</h3>
                  </a>
                </div>
              </div>
              <div class="items-tabs-programs-content">
                <div class="item-tab-programs-content" id="tab-programa-potencial" style="display: block;">
                  <div class="item-tab-programs-content-text">
                    <p [innerHTML]="sections[2].fields.potencial.texto"></p>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="swiper-container" [swiper]="carouselCharlas2.config">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide" *ngFor="let item of posts_program_potencial; let i = index">
                            <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                              data-aos-duration="700"
                              *ngIf="item.post_type != 'galeria' && item.post_type != 'galeria_videos'">
                              <div class="card-img">
                                <img src="{{ item.img }}" alt="">
                                <div class="diagonal-cut"></div>
                              </div>
                              <div class="card-content">
                                <h4 class="card-title" [innerHTML]="item.title"></h4>
                                <div class="card-text">
                                  <p [innerHTML]="item.text"></p>
                                </div>
                                <a [routerLink]="['/noticia/' + item.slug ]" class="card-link">Leer más</a>
                              </div>
                            </div>
                            <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                              class="card-design-notice card-design-notice-nm card-design-embajadores"
                              style="cursor: pointer;" *ngIf="item.post_type == 'galeria'">
                              <div class="card-img">
                                <img src="{{ item.feature_img }}" alt="">
                                <div class="diagonal-cut"></div>
                              </div>
                              <div class="card-content">
                                <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                              </div>
                            </a>
                            <div *ngIf="item.post_type == 'galeria_videos'">
                              <a (click)="showModalVideoIframe($event, item.video_url)" style="cursor: pointer;"
                                class="card-design-notice card-design-notice-nm card-design-embajadores"
                                data-aos="fade-up" data-aos="fade-up" data-aos-duration="700"
                                *ngIf="item.youtube != ''">
                                <div class="card-img">
                                  <img src="{{ item.img }}" alt="" />
                                  <div class="diagonal-cut"></div>
                                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png"
                                    alt="" class="btn-play-desing1" />
                                </div>
                                <div class="card-content">
                                  <h4 class="card-title" [innerHTML]="item.title"></h4>
                                </div>
                              </a>
                              <a (click)="showModalVideo($event, item.video_url)"
                                class="card-design-notice card-design-notice-nm card-design-embajadores"
                                data-aos="fade-up" data-aos="fade-up" data-aos-duration="700"
                                *ngIf="item.youtube == ''">
                                <div class="card-img">
                                  <img src="{{ item.img }}" alt="" />
                                  <div class="diagonal-cut"></div>
                                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png"
                                    alt="" class="btn-play-desing1" />
                                </div>
                                <div class="card-content">
                                  <h4 class="card-title" [innerHTML]="item.title"></h4>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="swiper-button-prev">
                          <span class="material-icons">
                            keyboard_arrow_left
                          </span>
                        </div>
                        <div class="swiper-button-next">
                          <span class="material-icons">
                            keyboard_arrow_right
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content-documents-custom-new3 pt-0">
                        <a href="{{ documento.link }}" class="item-document-custom-new3"
                          *ngFor="let documento of docs_program_potencial; let i = index" target="_blank"
                          data-aos="fade-up" data-aos-duration="700" data-aos-delay="0">
                          <h3 class="title-document-custom-new3">{{ documento.titulo }}</h3>
                          <div class="box-acctions-custom-new3">
                            <span class="view-document">
                              <img src="assets/img/file.svg" alt="">
                            </span>
                            <span class="download-document">
                              <img src="assets/img/download.svg" alt="">
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item-tab-programs-content" id="tab-programa-impulso">
                  <div class="item-tab-programs-content-text">
                    <p [innerHTML]="sections[2].fields.impulso.texto"></p>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="swiper-container" [swiper]="carouselCharlas2.config">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide" *ngFor="let item of posts_program_impulso; let i = index">
                            <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                              data-aos-duration="700"
                              *ngIf="item.post_type != 'galeria' && item.post_type != 'galeria_videos'">
                              <div class="card-img">
                                <img src="{{ item.img }}" alt="">
                                <div class="diagonal-cut"></div>
                              </div>
                              <div class="card-content">
                                <h4 class="card-title" [innerHTML]="item.title"></h4>
                                <div class="card-text">
                                  <p [innerHTML]="item.text"></p>
                                </div>
                                <a [routerLink]="['/noticia/' + item.slug ]" class="card-link">Leer más</a>
                              </div>
                            </div>
                            <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                              class="card-design-notice card-design-notice-nm card-design-embajadores"
                              style="cursor: pointer;" *ngIf="item.post_type == 'galeria'">
                              <div class="card-img">
                                <img src="{{ item.feature_img }}" alt="">
                                <div class="diagonal-cut"></div>
                              </div>
                              <div class="card-content">
                                <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                              </div>
                            </a>
                            <div *ngIf="item.post_type == 'galeria_videos'">
                              <a (click)="showModalVideoIframe($event, item.video_url)" style="cursor: pointer;"
                                class="card-design-notice card-design-notice-nm card-design-embajadores"
                                data-aos="fade-up" data-aos="fade-up" data-aos-duration="700"
                                *ngIf="item.youtube != ''">
                                <div class="card-img">
                                  <img src="{{ item.img }}" alt="" />
                                  <div class="diagonal-cut"></div>
                                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png"
                                    alt="" class="btn-play-desing1" />
                                </div>
                                <div class="card-content">
                                  <h4 class="card-title" [innerHTML]="item.title"></h4>
                                </div>
                              </a>
                              <a (click)="showModalVideo($event, item.video_url)"
                                class="card-design-notice card-design-notice-nm card-design-embajadores"
                                data-aos="fade-up" data-aos="fade-up" data-aos-duration="700"
                                *ngIf="item.youtube == ''">
                                <div class="card-img">
                                  <img src="{{ item.img }}" alt="" />
                                  <div class="diagonal-cut"></div>
                                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png"
                                    alt="" class="btn-play-desing1" />
                                </div>
                                <div class="card-content">
                                  <h4 class="card-title" [innerHTML]="item.title"></h4>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="swiper-button-prev">
                          <span class="material-icons">
                            keyboard_arrow_left
                          </span>
                        </div>
                        <div class="swiper-button-next">
                          <span class="material-icons">
                            keyboard_arrow_right
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content-documents-custom-new3 pt-0">
                        <a href="{{ documento.link }}" class="item-document-custom-new3"
                          *ngFor="let documento of docs_program_impulso; let i = index" target="_blank"
                          data-aos="fade-up" data-aos-duration="700" data-aos-delay="0">
                          <h3 class="title-document-custom-new3">{{ documento.titulo }}</h3>
                          <div class="box-acctions-custom-new3">
                            <span class="view-document">
                              <img src="assets/img/file.svg" alt="">
                            </span>
                            <span class="download-document">
                              <img src="assets/img/download.svg" alt="">
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item-tab-programs-content" id="tab-programa-trainee">
                  <div class="item-tab-programs-content-text">
                    <p [innerHTML]="sections[2].fields.trainee.texto"></p>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="swiper-container" [swiper]="carouselCharlas2.config">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide" *ngFor="let item of posts_program_trainee; let i = index">
                            <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                              data-aos-duration="700"
                              *ngIf="item.post_type != 'galeria' && item.post_type != 'galeria_videos'">
                              <div class="card-img">
                                <img src="{{ item.img }}" alt="">
                                <div class="diagonal-cut"></div>
                              </div>
                              <div class="card-content">
                                <h4 class="card-title" [innerHTML]="item.title"></h4>
                                <div class="card-text">
                                  <p [innerHTML]="item.text"></p>
                                </div>
                                <a [routerLink]="['/noticia/' + item.slug ]" class="card-link">Leer más</a>
                              </div>
                            </div>
                            <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                              class="card-design-notice card-design-notice-nm card-design-embajadores"
                              style="cursor: pointer;" *ngIf="item.post_type == 'galeria'">
                              <div class="card-img">
                                <img src="{{ item.feature_img }}" alt="">
                                <div class="diagonal-cut"></div>
                              </div>
                              <div class="card-content">
                                <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                              </div>
                            </a>
                            <div *ngIf="item.post_type == 'galeria_videos'">
                              <a (click)="showModalVideoIframe($event, item.video_url)" style="cursor: pointer;"
                                class="card-design-notice card-design-notice-nm card-design-embajadores"
                                data-aos="fade-up" data-aos="fade-up" data-aos-duration="700"
                                *ngIf="item.youtube != ''">
                                <div class="card-img">
                                  <img src="{{ item.img }}" alt="" />
                                  <div class="diagonal-cut"></div>
                                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png"
                                    alt="" class="btn-play-desing1" />
                                </div>
                                <div class="card-content">
                                  <h4 class="card-title" [innerHTML]="item.title"></h4>
                                </div>
                              </a>
                              <a (click)="showModalVideo($event, item.video_url)"
                                class="card-design-notice card-design-notice-nm card-design-embajadores"
                                data-aos="fade-up" data-aos="fade-up" data-aos-duration="700"
                                *ngIf="item.youtube == ''">
                                <div class="card-img">
                                  <img src="{{ item.img }}" alt="" />
                                  <div class="diagonal-cut"></div>
                                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png"
                                    alt="" class="btn-play-desing1" />
                                </div>
                                <div class="card-content">
                                  <h4 class="card-title" [innerHTML]="item.title"></h4>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="swiper-button-prev">
                          <span class="material-icons">
                            keyboard_arrow_left
                          </span>
                        </div>
                        <div class="swiper-button-next">
                          <span class="material-icons">
                            keyboard_arrow_right
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content-documents-custom-new3 pt-0">
                        <a href="{{ documento.link }}" class="item-document-custom-new3"
                          *ngFor="let documento of docs_program_trainee; let i = index" target="_blank"
                          data-aos="fade-up" data-aos-duration="700" data-aos-delay="0">
                          <h3 class="title-document-custom-new3">{{ documento.titulo }}</h3>
                          <div class="box-acctions-custom-new3">
                            <span class="view-document">
                              <img src="assets/img/file.svg" alt="">
                            </span>
                            <span class="download-document">
                              <img src="assets/img/download.svg" alt="">
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item-tab-programs-content" id="tab-programa-practicas">
                  <div class="item-tab-programs-content-text">
                    <p [innerHTML]="sections[2].fields.practicas.texto"></p>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="swiper-container" [swiper]="carouselCharlas2.config">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide" *ngFor="let item of posts_program_practicas; let i = index">
                            <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                              data-aos-duration="700"
                              *ngIf="item.post_type != 'galeria' && item.post_type != 'galeria_videos'">
                              <div class="card-img">
                                <img src="{{ item.img }}" alt="">
                                <div class="diagonal-cut"></div>
                              </div>
                              <div class="card-content">
                                <h4 class="card-title" [innerHTML]="item.title"></h4>
                                <div class="card-text">
                                  <p [innerHTML]="item.text"></p>
                                </div>
                                <a [routerLink]="['/noticia/' + item.slug ]" class="card-link">Leer más</a>
                              </div>
                            </div>
                            <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                              class="card-design-notice card-design-notice-nm card-design-embajadores"
                              style="cursor: pointer;" *ngIf="item.post_type == 'galeria'">
                              <div class="card-img">
                                <img src="{{ item.feature_img }}" alt="">
                                <div class="diagonal-cut"></div>
                              </div>
                              <div class="card-content">
                                <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                              </div>
                            </a>
                            <div *ngIf="item.post_type == 'galeria_videos'">
                              <a (click)="showModalVideoIframe($event, item.video_url)" style="cursor: pointer;"
                                class="card-design-notice card-design-notice-nm card-design-embajadores"
                                data-aos="fade-up" data-aos="fade-up" data-aos-duration="700"
                                *ngIf="item.youtube != ''">
                                <div class="card-img">
                                  <img src="{{ item.img }}" alt="" />
                                  <div class="diagonal-cut"></div>
                                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png"
                                    alt="" class="btn-play-desing1" />
                                </div>
                                <div class="card-content">
                                  <h4 class="card-title" [innerHTML]="item.title"></h4>
                                </div>
                              </a>
                              <a (click)="showModalVideo($event, item.video_url)"
                                class="card-design-notice card-design-notice-nm card-design-embajadores"
                                data-aos="fade-up" data-aos="fade-up" data-aos-duration="700"
                                *ngIf="item.youtube == ''">
                                <div class="card-img">
                                  <img src="{{ item.img }}" alt="" />
                                  <div class="diagonal-cut"></div>
                                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png"
                                    alt="" class="btn-play-desing1" />
                                </div>
                                <div class="card-content">
                                  <h4 class="card-title" [innerHTML]="item.title"></h4>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="swiper-button-prev">
                          <span class="material-icons">
                            keyboard_arrow_left
                          </span>
                        </div>
                        <div class="swiper-button-next">
                          <span class="material-icons">
                            keyboard_arrow_right
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content-documents-custom-new3 pt-0">
                        <a href="{{ documento.link }}" class="item-document-custom-new3"
                          *ngFor="let documento of docs_program_practicas; let i = index" target="_blank"
                          data-aos="fade-up" data-aos-duration="700" data-aos-delay="0">
                          <h3 class="title-document-custom-new3">{{ documento.titulo }}</h3>
                          <div class="box-acctions-custom-new3">
                            <span class="view-document">
                              <img src="assets/img/file.svg" alt="">
                            </span>
                            <span class="download-document">
                              <img src="assets/img/download.svg" alt="">
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item-tab-programs-content" id="tab-programa-induccion">
                  <div class="item-tab-programs-content-text">
                    <p [innerHTML]="sections[2].fields.induccion.texto"></p>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="swiper-container" [swiper]="carouselCharlas2.config">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide" *ngFor="let item of posts_program_induccion; let i = index">
                            <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                              data-aos-duration="700"
                              *ngIf="item.post_type != 'galeria' && item.post_type != 'galeria_videos'">
                              <div class="card-img">
                                <img src="{{ item.img }}" alt="">
                                <div class="diagonal-cut"></div>
                              </div>
                              <div class="card-content">
                                <h4 class="card-title" [innerHTML]="item.title"></h4>
                                <div class="card-text">
                                  <p [innerHTML]="item.text"></p>
                                </div>
                                <a [routerLink]="['/noticia/' + item.slug ]" class="card-link">Leer más</a>
                              </div>
                            </div>
                            <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                              class="card-design-notice card-design-notice-nm card-design-embajadores"
                              style="cursor: pointer;" *ngIf="item.post_type == 'galeria'">
                              <div class="card-img">
                                <img src="{{ item.feature_img }}" alt="">
                                <div class="diagonal-cut"></div>
                              </div>
                              <div class="card-content">
                                <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                              </div>
                            </a>
                            <div *ngIf="item.post_type == 'galeria_videos'">
                              <a (click)="showModalVideoIframe($event, item.video_url)" style="cursor: pointer;"
                                class="card-design-notice card-design-notice-nm card-design-embajadores"
                                data-aos="fade-up" data-aos="fade-up" data-aos-duration="700"
                                *ngIf="item.youtube != ''">
                                <div class="card-img">
                                  <img src="{{ item.img }}" alt="" />
                                  <div class="diagonal-cut"></div>
                                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png"
                                    alt="" class="btn-play-desing1" />
                                </div>
                                <div class="card-content">
                                  <h4 class="card-title" [innerHTML]="item.title"></h4>
                                </div>
                              </a>
                              <a (click)="showModalVideo($event, item.video_url)"
                                class="card-design-notice card-design-notice-nm card-design-embajadores"
                                data-aos="fade-up" data-aos="fade-up" data-aos-duration="700"
                                *ngIf="item.youtube == ''">
                                <div class="card-img">
                                  <img src="{{ item.img }}" alt="" />
                                  <div class="diagonal-cut"></div>
                                  <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png"
                                    alt="" class="btn-play-desing1" />
                                </div>
                                <div class="card-content">
                                  <h4 class="card-title" [innerHTML]="item.title"></h4>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="swiper-button-prev">
                          <span class="material-icons">
                            keyboard_arrow_left
                          </span>
                        </div>
                        <div class="swiper-button-next">
                          <span class="material-icons">
                            keyboard_arrow_right
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content-documents-custom-new3 pt-0">
                        <a href="{{ documento.link }}" class="item-document-custom-new3"
                          *ngFor="let documento of docs_program_induccion; let i = index" target="_blank"
                          data-aos="fade-up" data-aos-duration="700" data-aos-delay="0">
                          <h3 class="title-document-custom-new3">{{ documento.titulo }}</h3>
                          <div class="box-acctions-custom-new3">
                            <span class="view-document">
                              <img src="assets/img/file.svg" alt="">
                            </span>
                            <span class="download-document">
                              <img src="assets/img/download.svg" alt="">
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section-design2 section-design2-diversidad">
            <img src="assets/img/bg-section-design2-3.jpg" alt="" class="bg-top-section-design2">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-5">
                  <img src="assets/img/img-section-design2-2.png" alt="" class="w-100">
                </div>
                <div class="col-lg-7 pl-5">
                  <h3 class="title-section-design2">Diversidad <br><span>e</span> Inclusión</h3>
                  <div class="box-text-section-design2">
                    <p>Entendiendo la inclusión como un conjunto de acciones dirigidas a fomentar la integración de
                      personas diversas a
                      nuestros equipos de trabajo. En Abastible hemos desarrollado una Política de Inclusión y
                      Diversidad Laboral.</p>
                  </div>
                  <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/POL%C3%8DTICA-DE-INCLUSION-Y-DIVERSIDAD.pdf"
                    target="_blank" download class="link-design-design2 link-design-design2-3">
                    <img src="assets/img/link-design-design2-5.png" alt="">
                    <span>Ver Política</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="section-design2 section-design2-reconocimientos">
            <img src="assets/img/bg-section-design2-2.png" alt="" class="bg-top-section-design2">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-7 pr-5">
                  <h3 class="title-section-design2">Reconocimientos</h3>
                  <div class="box-text-section-design2">
                    <p>Buscamos potenciar y acrecentar de manera continua la reputación de nuestra compañía, a través
                      de
                      una comunicación
                      corporativa alineada con nuestra cultura y plan estratégico, con el fin de aportar a la
                      sostenibilidad del negocio; al
                      desarrollo económico, social; y la calidad de vida de las comunidades donde operamos. Es así
                      como
                      a lo largo de los
                      años, hemos sido reconocidos con diversos premios que nos enorgullecen como compañía.</p>
                  </div>
                </div>
                <div class="col-lg-5">
                  <img src="assets/img/img-section-design2-3.png" alt="" class="w-100">
                </div>
                <div class="col-lg-11 mx-auto mt-5">
                  <div class="box-swiper-reconocimientos">
                    <div class="swiper-container" [swiper]="carouselreconocimiento.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide"
                          *ngFor="let item of sections[2].fields.reconocimientos; let i = index">
                          <a href="{{item.enlace}}" class="link-swiper-reconocimientos" target="_blank">
                            <img src="{{item.logo}}" alt="">
                            <h6 [innerHTML]="item.titulo"></h6>
                          </a>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section-design2 section-design2-reconocimientos">
            <img src="assets/img/bg-section-design2-2.png" alt="" class="bg-top-section-design2">
            <!-- Documentos -->
            <div *ngIf="modalDocuments.length > 0" class="pt-5 pb-3">
              <div class="tabs-search">
                <button data-tab="#documentos-seleccion-talento" class="item-tab-search active">Documentos</button>
              </div>
              <div class="tabs-content-search">
                <div class="item-tab-content-search" id="documentos-seleccion-talento">
                  <div class="col-lg-8 mx-auto mb-5">
                    <div class="content-documents-custom-new3">
                      <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
                        data-aos-duration="700" data-aos-delay="0"
                        *ngFor="let document of modalDocuments; let i = index">
                        <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                        <div class="box-acctions-custom-new3">
                          <span class="view-document">
                            <img src="assets/img/file.svg" alt="">
                          </span>
                          <span class="download-document">
                            <img src="assets/img/download.svg" alt="">
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tabs-search">
              <!-- <button data-tab="#news-seleccion-talento" class="item-tab-search active"
                *ngIf="galerias1 != null">Centro de Excelencia de Talento</button> -->
              <button data-tab="#news2-seleccion-talento" class="item-tab-search active"
                *ngIf="news3 != null">Noticias</button>
              <button data-tab="#news-seleccion-talento" class="item-tab-search "
                *ngIf="galerias1 != null">Galería</button>
              <button data-tab="#videos2-seleccion-talento" class="item-tab-search "
                *ngIf="videos2.length > 0">Videos</button>
              <button data-tab="#videos-seleccion-talento" class="item-tab-search ">Vida laboral en Abastible</button>
            </div>
            <div class="tabs-content-search">

              <div class="item-tab-content-search" id="news2-seleccion-talento" *ngIf="news3 != null">
                <section class="section-custom5">
                  <div class="container" id="charlas">
                    <div class="swiper-container" [swiper]="carouselGalerias3.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of news3; let i = index">
                          <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                            data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title" [innerHTML]="item.title"></h4>
                              <div class="card-text">
                                <p [innerHTML]="item.text"></p>
                              </div>
                              <a [routerLink]="['/noticia/' + item.slug ]" class="card-link">Leer más</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'seleccion-y-talento' }"
                        class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="news-seleccion-talento" style="display: none;"
                *ngIf="galerias1 != null">
                <section class="section-custom5">
                  <div class="container">
                    <div class="col-lg-10 mx-auto text-center">
                      <p>Para los nuevos ingresos de Santiago se coordinará 1 vez
                        al mes una visita a planta,
                        donde podrán conocer los procesos presencialmente. El área de Selección coordinará
                        la visita a la planta y los
                        traslados correspondientes.</p>
                      <!-- Activar esta galeria luego, aun no se sabe que va ahi -->
                    </div>
                    <div class="swiper-container pt-5" [swiper]="carouselGalerias2.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galerias1; let i = index">
                          <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                            class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                            data-aos="fade-up" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.feature_img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"
                      *ngIf="galerias2 != null">
                      <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'seleccion-y-talento' }"
                        class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="galeries-seleccion-talento" style="display: none;"
                *ngIf="galerias2 != null">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselGalerias.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of galerias2; let i = index">
                          <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                            class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                            data-aos="fade-up" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.feature_img }}" alt="">
                              <div class="diagonal-cut"></div>
                            </div>
                            <div class="card-content">
                              <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700"
                      data-aos-delay="100">
                      <a [routerLink]="['/galeria-image-section/seleccion-y-talento' ]" class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <div class="item-tab-content-search" id="videos2-seleccion-talento" style="display: none;">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselGalerias.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of videos2; let i = index">
                          <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                            (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                              <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                                class="btn-play-desing1">
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ item.title }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/galeria-videos-section/seleccion-y-talento' ]" [queryParams]="1"
                        class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>

              <div class="item-tab-content-search" id="videos-seleccion-talento" style="display: none;">
                <section class="section-custom5">
                  <div class="container">
                    <div class="swiper-container" [swiper]="carouselGalerias.config">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of vidaLaboral; let i = index">
                          <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                            (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                            <div class="card-img">
                              <img src="{{ item.img }}" alt="">
                              <div class="diagonal-cut"></div>
                              <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                                class="btn-play-desing1">
                            </div>
                            <div class="card-content">
                              <h4 class="card-title">{{ item.title }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-button-prev">
                        <span class="material-icons">
                          keyboard_arrow_left
                        </span>
                      </div>
                      <div class="swiper-button-next">
                        <span class="material-icons">
                          keyboard_arrow_right
                        </span>
                      </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                      <a [routerLink]="['/galeria-videos-section/13' ]" [queryParams]="1" class="btn-custom-new">
                        <span>VER MÁS</span>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <!-- Formación y Liderazgo -->

        <div *ngIf="modalTitulo == 'Formación y Liderazgo'">
          <!-- Documentos -->
          <div *ngIf="modalDocuments.length > 0" class="pt-5 pb-3">
            <div class="tabs-search">
              <button data-tab="#documentos-formacion-liderazgo" class="item-tab-search active">Documentos</button>
            </div>
            <div class="tabs-content-search">
              <div class="item-tab-content-search" id="documentos-formacion-liderazgo">
                <div class="col-lg-8 mx-auto mb-5">
                  <div class="content-documents-custom-new3">
                    <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
                      data-aos-duration="700" data-aos-delay="0" *ngFor="let document of modalDocuments; let i = index">
                      <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                      <div class="box-acctions-custom-new3">
                        <span class="view-document">
                          <img src="assets/img/file.svg" alt="">
                        </span>
                        <span class="download-document">
                          <img src="assets/img/download.svg" alt="">
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs-search">
            <button data-tab="#news-formacion-liderazgo" class="item-tab-search active"
              *ngIf="news4 != null">Noticias</button>
            <button data-tab="#galeries-formacion-liderazgo" class="item-tab-search "
              *ngIf="galerias3 != null">Galería</button>
            <button data-tab="#videos-formacion-liderazgo" class="item-tab-search ">Videos</button>
          </div>
          <div class="tabs-content-search">
            <div class="item-tab-content-search" id="news-formacion-liderazgo" *ngIf="news4 != null">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" [swiper]="carouselGalerias3.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let new1 of news4; let i = index">
                        <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                          data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ new1.img }}" alt="">
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title">{{ new1.title }}</h4>
                            <div class="card-text">
                              <p [innerHTML]="new1.text"></p>
                            </div>
                            <a [routerLink]="['/noticia/' + new1.slug ]" class="card-link">Leer más</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'formacion-y-liderazgo' }"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="galeries-formacion-liderazgo" *ngIf="galerias3 != null"
              style="display: none;">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" [swiper]="carouselGalerias3.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let item of galerias3; let i = index">
                        <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                          class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                          data-aos="fade-up" data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ item.feature_img }}" alt="">
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-image-section/aula' ]" class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="videos-formacion-liderazgo" style="display: none;">
              <section class="section-custom5">
                <div class="container">
                  <div class="swiper-container" [swiper]="carouselGalerias3.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let item of videosFormacionLiderazgo; let i = index">
                        <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                          (click)="showModalVideo($event, item.video_url)" data-aos-duration="700"
                          *ngIf="item.youtube == ''">
                          <div class="card-img">
                            <img src="{{ item.img }}" alt="">
                            <div class="diagonal-cut"></div>
                            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                              class="btn-play-desing1">
                          </div>
                          <div class="card-content">
                            <h4 class="card-title">{{ item.title }}</h4>
                          </div>
                        </div>
                        <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                          (click)="showModalVideoIframe($event, item.video_url)" *ngIf="item.youtube != ''"
                          data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ item.img }}" alt="">
                            <div class="diagonal-cut"></div>
                            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                              class="btn-play-desing1">
                          </div>
                          <div class="card-content">
                            <h4 class="card-title">{{ item.title }}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-videos-section/formacion-y-liderazgo' ]" [queryParams]="1"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <footer>
          <div class="menu-mobile">
            <div class="content-menu-mobile">
              <a href="#menu-mobile1" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#menu-mobile2" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#" class="btn-design-float"><span></span></a>
              <a href="#menu-mobile3" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#menu-mobile4" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
            </div>
          </div>
          <div class="container box-footer">
            <div class="box-list-footer">
              <div class="list-footer">
                <h3 class="title-list-footer">Conoce Abastible</h3>
                <ul>
                  <li><a [routerLink]="['/conoce-abastible']"
                      fragment="presentacion-de-la-empresa-historia">Presentación de la
                      empresa/historia</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="mision-vision-valores">Misión -Visión -
                      Valores</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="directorio-y-administracion">Directorio
                      y Administración</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="productos-y-servicios">Productos y Servicios</a>
                  </li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="proceso-productivo">Proceso Productivo</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="soluciones-energeticas">Soluciones
                      Energéticas</a></li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Control Interno</h3>
                <ul>
                  <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1"
                      target="_blank">Canal de
                      Denuncias</a></li>
                  <li><a [routerLink]="['/control-interno']" fragment="auditoria-interna">Auditoría interna</a>
                  </li>
                  <li><a [routerLink]="['/control-interno']" fragment="seguridad-de-la-informacion">Seguridad de la
                      información</a></li>
                  <li><a [routerLink]="['/control-interno']" fragment="gestion-de-riesgo">Gestión de Riesgo</a>
                  </li>
                  <li><a [routerLink]="['/control-interno']" fragment="gestion-de-procesos">Gestión de Procesos</a>
                  </li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Seguridad</h3>
                <ul>
                  <li><a [routerLink]="['/seguridad']" fragment="integridad-operacional">Integridad Operacional</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="gestion-de-calidad">Gestión de Calidad</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="servicio-nacional-de-emergencias">Servicio Nacional de
                      Emergencias</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="manejo-manual-de-carga">Manejo Manual de Carga</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="medio-ambiente">Medio Ambiente</a></li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Beneficios</h3>
                <ul>
                  <li><a [routerLink]="['/beneficios']" fragment="beneficios-para-colaboradores">Beneficios para
                      colaboradores</a></li>
                  <li><a [routerLink]="['/beneficios']" fragment="clima-organizacional">Clima organizacional</a></li>
                </ul>
              </div>
              <!--<div class="list-footer">
                    <h3 class="title-list-footer">Gobierno Corporativo</h3>
                    <ul>
                      <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1" target="_blank">Canal de Denuncias</a></li>
                      <li><a [routerLink]="['/gobierno-corporativo']" fragment="section2">Compliance</a></li>
                  </ul>
                </div> -->
              <div class="list-footer">
                <h3 class="title-list-footer">Soluciones
                  Energéticas</h3>
                <ul>
                  <li><a [routerLink]="['/soluciones-energeticas']" fragment="soluciones-energeticas">Soluciones
                      Energéticas</a></li>
                </ul>
              </div>
              <!-- <div class="list-footer">
                <h3 class="title-list-footer">Innovación</h3>
                <ul>
                  <li><a [routerLink]="['/innovacion']" fragment="i-now">I-NOW</a></li>
                  <li><a [routerLink]="['/innovacion']" fragment="laboratorio-de-innovacion">Laboratorio de
                      Innovación</a></li>
                  <li><a [routerLink]="['/innovacion']" fragment="soluciones-energeticas">Soluciones Energéticas</a>
                  </li>
                </ul>
              </div> -->
              <div class="list-footer list-footer-rd">
                <h3 class="title-list-footer">Social</h3>
                <ul>
                  <li><a href="https://www.facebook.com/AbastibleCL" target="_blank"><img
                        src="assets/img/icons/icon-facebook.svg" alt="">AbastibleCL</a></li>
                  <li><a href="https://www.instagram.com/laenergiadevivir/" target="_blank"><img
                        src="assets/img/icons/icon-instagram.svg" alt="">/laenergiadevivir</a></li>
                  <li><a href="https://www.linkedin.com/company/abastible-s.a./" target="_blank"><img
                        src="assets/img/icons/icon-linkedin.svg" alt="">/company/abastible-s.a.</a></li>
                  <li><a href="https://www.youtube.com/user/AbastibleChileSA" target="_blank"><img
                        src="assets/img/icons/icon-youtube.svg" alt="">/AbastibleChileSA</a></li>
                  <li><a href="https://www.abastible.cl/" target="_blank"><img src="assets/img/icons/logo-abastible.svg"
                        alt="">abastible.cl</a></li>
                </ul>
              </div>
            </div>
            <a href="index.html" class="logo-footer"><img src="assets/img/logo.png" alt="" /></a>
          </div>
        </footer>
      </div>
    </div>
  </div>
</div>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls
      *ngIf="modalSrcVideo != 'https://interactrapp.com/share/6132fa5888ed7'">
      Your browser does not support HTML5 video.
    </video>
    <h3 class="title-video-full" [innerHTML]="modalSrcVideo"
      *ngIf="modalSrcVideo != 'https://interactrapp.com/share/6132fa5888ed7'"></h3>
    <div class="box-video-iframe-blank" *ngIf="modalSrcVideo == 'https://interactrapp.com/share/6132fa5888ed7'">
      <h3 class="title-video-full">
        Para ver este video ingresa a la siguiente url
        <a href="{{ modalSrcVideo }}" target="_blank">{{ modalSrcVideo }}</a>
      </h3>
    </div>
  </div>
</div>

<div id="modal-video-youtube" class="modal-video" [ngClass]="modalVideoIframe == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideoIframe()"></div>
  <button class="close" (click)="closeModalVideoIframe()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <iframe width="560" height="315" [src]="urlSafe" class="iframe-video" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>