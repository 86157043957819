<footer>
  <div class="menu-mobile">
    <div class="content-menu-mobile">
      <a href="#menu-mobile1" class="link-collapse-menu-mobile"><img src="assets/img/icons/icon-prensa-menu.svg"
          alt="" /></a>
      <a href="#menu-mobile2" class="link-collapse-menu-mobile"><img src="assets/img/icons/icon-herramientas-menu.svg"
          alt="" /></a>
      <a href="#" class="btn-design-float"><span></span></a>
      <a href="#menu-mobile3" class="link-collapse-menu-mobile"><img src="assets/img/icons/icon-cumple-menu.svg"
          alt="" /></a>
      <a class="link-collapse-menu-mobile" style="width: 20px;"></a>
      <!-- <a href="#menu-mobile4" class="link-collapse-menu-mobile"><img src="assets/img/icons/icon-virus-menu.svg"
          alt="" /></a> -->
    </div>
  </div>
  <div class="container box-footer">
    <div class="box-list-footer">
      <div class="list-footer">
        <h3 class="title-list-footer">Conoce Abastible</h3>
        <ul>
          <li><a [routerLink]="['/conoce-abastible']" fragment="presentacion-de-la-empresa-historia">Presentación de la
              empresa/historia</a></li>
          <li><a [routerLink]="['/conoce-abastible']" fragment="mision-vision-valores">Misión -Visión - Valores</a></li>
          <li><a [routerLink]="['/conoce-abastible']" fragment="directorio-y-administracion">Directorio
              y Administración</a></li>
          <li><a [routerLink]="['/conoce-abastible']" fragment="productos-y-servicios">Productos y Servicios</a></li>
          <li><a [routerLink]="['/conoce-abastible']" fragment="proceso-productivo">Proceso Productivo</a></li>
          <li><a [routerLink]="['/conoce-abastible']" fragment="soluciones-energeticas">Soluciones Energéticas</a></li>
        </ul>
      </div>
      <div class="list-footer">
        <h3 class="title-list-footer">Control Interno</h3>
        <ul>
          <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1" target="_blank">Canal de
              Denuncias</a></li>
          <li><a [routerLink]="['/control-interno']" fragment="auditoria-interna">Auditoría interna</a></li>
          <li><a [routerLink]="['/control-interno']" fragment="seguridad-de-la-informacion">Seguridad de la
              información</a></li>
          <li><a [routerLink]="['/control-interno']" fragment="gestion-de-riesgo">Gestión de Riesgo</a></li>
          <li><a [routerLink]="['/control-interno']" fragment="gestion-de-procesos">Gestión de Procesos</a></li>
        </ul>
      </div>
      <div class="list-footer">
        <h3 class="title-list-footer">Seguridad</h3>
        <ul>
          <li><a [routerLink]="['/seguridad']" fragment="integridad-operacional">Integridad Operacional</a></li>
          <li><a [routerLink]="['/seguridad']" fragment="gestion-de-calidad">Gestión de Calidad</a></li>
          <li><a [routerLink]="['/seguridad']" fragment="servicio-nacional-de-emergencias">Servicio Nacional de
              Emergencias</a></li>
          <li><a [routerLink]="['/seguridad']" fragment="manejo-manual-de-carga">Manejo Manual de Carga</a></li>
          <li><a [routerLink]="['/seguridad']" fragment="medio-ambiente">Medio Ambiente</a></li>
        </ul>
      </div>
      <div class="list-footer">
        <h3 class="title-list-footer">Beneficios</h3>
        <ul>
          <li><a [routerLink]="['/beneficios']" fragment="beneficios-para-colaboradores">Beneficios para
              colaboradores</a></li>
          <li><a [routerLink]="['/beneficios']" fragment="clima-organizacional">Clima organizacional</a></li>
        </ul>
      </div>
      <!--<div class="list-footer">
        <h3 class="title-list-footer">Gobierno Corporativo</h3>
        <ul>
          <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1" target="_blank">Canal de Denuncias</a></li>
          <li><a [routerLink]="['/gobierno-corporativo']" fragment="section2">Compliance</a></li>
      </ul>
    </div> -->
      <div class="list-footer">
        <h3 class="title-list-footer">Soluciones
          Energéticas</h3>
        <ul>
          <li><a [routerLink]="['/soluciones-energeticas']" fragment="soluciones-energeticas">Soluciones
              Energéticas</a></li>
        </ul>
      </div>
      <!-- <div class="list-footer">
        <h3 class="title-list-footer">Innovación</h3>
        <ul>
          <li><a [routerLink]="['/innovacion']" fragment="i-now">I-NOW</a></li>
          <li><a [routerLink]="['/innovacion']" fragment="laboratorio-de-innovacion">Laboratorio de Innovación</a></li>
          <li><a [routerLink]="['/innovacion']" fragment="soluciones-energeticas">Soluciones Energéticas</a></li>
        </ul>
      </div> -->
      <div class="list-footer list-footer-rd">
        <h3 class="title-list-footer">Social</h3>
        <ul>
          <li><a href="https://www.facebook.com/AbastibleCL" target="_blank"><img
                src="assets/img/icons/icon-facebook.svg" alt="">AbastibleCL</a></li>
          <li><a href="https://www.instagram.com/laenergiadevivir/" target="_blank"><img
                src="assets/img/icons/icon-instagram.svg" alt="">/laenergiadevivir</a></li>
          <li><a href="https://www.linkedin.com/company/abastible-s.a./" target="_blank"><img
                src="assets/img/icons/icon-linkedin.svg" alt="">/company/abastible-s.a.</a></li>
          <li><a href="https://www.youtube.com/user/AbastibleChileSA" target="_blank"><img
                src="assets/img/icons/icon-youtube.svg" alt="">/AbastibleChileSA</a></li>
          <li><a href="https://www.abastible.cl/" target="_blank"><img src="assets/img/icons/logo-abastible.svg"
                alt="">abastible.cl</a></li>
        </ul>
      </div>
    </div>
    <a href="index.html" class="logo-footer"><img src="assets/img/logo.png" alt="" /></a>
  </div>
</footer>