<div class="bg-white bg-white-custom pb-0">
  <section class="section-desafio6">
    <div class="box-header-desafio6 mb-4" (click)="showModalVideoIframe($event, 'mwqrLJLXSCw')">
      <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/02/850x250-v5-1.jpg" class="img-fluid"
        alt="" />
      <!-- <a [routerLink]="['/formulario-desafio7inow']"><img src="assets/img/banner-desafio7.jpg" class="img-fluid"
          alt="" /></a> -->
    </div>
    <section id="breadcrumbs">
      <div class="container">
        <div class="breadcrumbs breadcrumbs-sections">
          <div class="breadcrumbs-item">
            <a [routerLink]="['/muro']"><span class="material-icons">
                home
              </span></a>
            <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
          </div>
          <div class="breadcrumbs-item">
            <a [routerLink]="['/innovacion']">Innovación</a>
            <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
          </div>
          <div class="breadcrumbs-item">
            <span>Desafío Masivo 2022 i-NOW CERO / 100</span>
          </div>
        </div>
      </div>
    </section>

    <section class="section-title-desafio">
      <div class="container">
        <h2 class="title-desafio7">Desafío Masivo 2022 i-NOW CERO / 100</h2>
      </div>
    </section>
    <section class="section-video-desafio7">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 mx-auto">
            <div class="swiper-container" [swiper]="carouselBanner.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="content-section-video-desafio7">
                    <img src="assets/img/bg-video-desafio7.png" alt="" class="bg-video-desafio7">
                    <div class="box-video-desafio6">
                      <img src="assets/img/captura-video1.png" class="video-preview-desafio6" alt="" />
                      <button class="btn-play-video">
                        <img src="assets/img/icon-play.png" alt="" />
                      </button>
                      <video controls>
                        <source src="assets/teaser_1_v4.mp4" />
                      </video>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="content-section-video-desafio7">
                    <img src="assets/img/bg-video-desafio7.png" alt="" class="bg-video-desafio7">
                    <div class="box-video-desafio6">
                      <img src="assets/img/captura-video2.png" class="video-preview-desafio6" alt="" />
                      <button class="btn-play-video">
                        <img src="assets/img/icon-play.png" alt="" />
                      </button>
                      <video controls>
                        <source src="assets/teaser_2_v6.mp4" />
                      </video>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="content-section-video-desafio7">
                    <img src="assets/img/bg-video-desafio7.png" alt="" class="bg-video-desafio7">
                    <div class="box-video-desafio6">
                      <img src="assets/img/captura-video3.png" class="video-preview-desafio6" alt="" />
                      <button class="btn-play-video">
                        <img src="assets/img/icon-play.png" alt="" />
                      </button>
                      <video controls>
                        <source src="assets/teaser_3_v3.mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section1-desafio7">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <img src="assets/img/image1-desafio7.png" alt="" class="img-fluid">
          </div>
          <div class="col-lg-8">
            <div class="box-text-desafio-7">
              <p>Este año i-NOW tiene como objetivo continuar con el traspaso de capacidades y metodologías relacionadas
                a
                la innovación.
                Para esto, se pondrán a disposición 5 cápsulas de la metodología Pensamiento Sistemático Inventivo
                (SIT),
                desarrollada
                para ayudar a las organizaciones a lograr sus objetivos, sistematizando la creatividad. Estas capsulas
                están disponibles
                en la plataforma AULA y serán una herramienta de ayuda para generar nuevas iniciativas.</p>
              <p><b>El desafío de este año permitirá que todos los colaboradores de Abastible puedan proponer
                  ideas/proyectos que son de
                  “Cero Costo” para la empresa en cuanto a inversión y gasto.</b></p>
              <p>“Cero Costo ” se entiende como un proyecto que no supera los $ 10 millones en inversión.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-title1-desafio7">
      <img src="assets/img/bg-text-desafio7.png" alt="" class="bg-section-title-desafio7">
      <div class="container">
        <h3 class="title-desafio7-2">Podrán participar de forma independiente, postulando sus ideas con foco en la
          <b>Optimización de procesos y/o solución a problemas de la compañía</b>
        </h3>
      </div>
    </section>
    <section class="section2-desafio7">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 mx-auto">
            <div class="box-text-desafio-7 text-center">
              <p>El desafío busca nuevas propuestas para solucionar problemáticas de la empresa, o generar eficiencias
                mediante el rediseño de procesos operativos, administrativos u otros.</p>
            </div>
            <img src="assets/img/title1-desafio7.png" alt="" class="title3-img-desafio7">
          </div>
        </div>
      </div>
    </section>
    <section class="section3-desafio7">
      <div class="container">
        <div class="box-text-desafio-7 text-center">
          <p>El desafío está compuesto por 2 etapas que se desarrollarán en el 2do semestre.</p>
        </div>
        <div class="item-etapa-desafio7">
          <div class="row">
            <div class="col-lg-5">
              <img src="assets/img/etapa1-desafio7.png" alt="" class="img-fluid">
            </div>
            <div class="col-lg-7">
              <div class="box-text-desafio-7">
                <p>En esta etapa debes postular tus ideas que sean proyectos que no superen los $ 10 millones en
                  inversión.
                  Puedes inscribir todas
                  las ideas que quieras. <b>¡Participa las veces que quieras!</b></p>
              </div>
            </div>
          </div>
          <img src="assets/img/line-separate-desafio7.png" alt="" class="img-separate-item-etapa-desafio7 img-fluid">
        </div>
        <div class="item-etapa-desafio7">
          <div class="row">
            <div class="col-lg-5">
              <img src="assets/img/etapa2-desafio7.png" alt="" class="img-fluid">
            </div>
            <div class="col-lg-7">
              <div class="box-text-desafio-7">
                <p>Si tu idea quedó entre las seleccionadas, esta es la recta final, en la que los participantes deberán
                  afinar detalles, y
                  preparar la presentación que harán frente al Comité de Gerentes. En esta presentación, seleccionaremos
                  a los dos
                  ganadores del Desafío i-NOW 2021</p>
              </div>
            </div>
          </div>
          <img src="assets/img/line-separate-desafio7.png" alt="" class="img-separate-item-etapa-desafio7 img-fluid">
        </div>
      </div>
    </section>
    <section class="section4-desafio7">
      <div class="container">
        <h3 class="title-desafio7-3 text-center">Las ideas serán evaluadas según 3 criterios:</h3>
        <div class="row">
          <div class="col-lg-4">
            <div class="item-sostenibilidad-custom1">
              <img src="assets/img/icon-info1-desafio7.png" alt="">
              <div class="box-item-sostenibilidad-custom1">
                <h3>Novedosas</h3>
                <p>Esto significa que sean soluciones nuevas para la compañía</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="item-sostenibilidad-custom1">
              <img src="assets/img/icon-info2-desafio7.png" alt="">
              <div class="box-item-sostenibilidad-custom1">
                <h3>Viables</h3>
                <p>Deben ser factibles de implementar</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="item-sostenibilidad-custom1">
              <img src="assets/img/icon-info3-desafio7.png" alt="">
              <div class="box-item-sostenibilidad-custom1">
                <h3>Valor</h3>
                <p>Estas soluciones deben generar un beneficio cuantificable para la empresa</p>
              </div>
            </div>
          </div>
        </div>
        <img src="assets/img/line-separate-desafio7.png" alt="" class="img-separate-item-etapa-desafio7 img-fluid">
      </div>
    </section>
    <section class="section5-desafio7">
      <div class="container">
        <img src="assets/img/title2-desafio7.png" alt="" class="img-fluid d-block mx-auto">
        <div class="box-text-desafio-7">
          <p>Con el objetivo de facilitar la participación por parte de los colaboradores, existirán dos canales de
            ingreso de ideas:</p>
        </div>
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <div class="row">
              <div class="col-lg-6">
                <div class="item-sostenibilidad-custom1">
                  <img src="assets/img/icon-info4-desafio7.png" alt="">
                  <div class="box-item-sostenibilidad-custom1">
                    <h3>Físico</h3>
                    <p>En todas las plantas y oficinas se va a disponer de formularios impresos para ingresar las ideas
                      que serán facilitados
                      por los embajadores i-NOW.</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="item-sostenibilidad-custom1">
                  <img src="assets/img/icon-info5-desafio7.png" alt="">
                  <div class="box-item-sostenibilidad-custom1">
                    <h3>Digital</h3>
                    <p>Puntos virtuales en los que los colaboradores podrán ingresar sus ideas. Estos son: Tótems,
                      Sección i-NOW en Intranet y
                      Correo i-NOW.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section6-desafio7">
      <div class="container">
        <h3 class="title-section6-desafio7">El desafío tendrá a dos ganadores.
          <b>Los ganadores del desafío i-NOW 2022 obtendrán un gran premio de:</b>
        </h3>
        <img src="assets/img/title3-desafio7.png" alt="" class="img-title-section6-desafio7">
        <div class="box-buttons-section6-desafio7">
          <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2022/08/Bases-desaf%C3%ADo-i-NOW-2022-Cero100.pdf"
            target="_blank" class="btn-design1">Bases del desafío</a>
          <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2022/07/Formulario-de-ideación.pdf"
            target="_blank" class="btn-design1">Formulario de ideación para descargar</a>
        </div>
      </div>
    </section>
    <section class="section-custom5">
      <div class="container">
        <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">
          Ideas (111)
        </h3>
        <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
        <div class="row row-ideas-desafio6">
          <div class="col-lg-3" *ngFor="let idea of ideas; let i = index">
            <div class="card-design-notice card-design-notice-desafio {{
                        idea.semifinalista
                      }}" (click)="
                        openModalEvento(
                          idea.title,
                          idea.nombre_del_colaborador,
                          idea.email_del_colaborador,
                          idea.planta_u_oficina,
                          idea.nombre_de_la_idea,
                          idea.descripcion,
                          idea.beneficios,
                          idea.retos,
                          idea.costos,
                          idea.semifinalista
                        )
                      ">
              <div class="card-img" *ngIf="idea.semifinalista != 1">
                <img [src]="'assets/img/cat1-desafio6.png'" alt="" />
                <div class="diagonal-cut"></div>
              </div>
              <div class="card-img" *ngIf="idea.semifinalista == 1">
                <img [src]="'assets/img/oportunidad-de-mejora2.png'" alt="" />
                <div class="diagonal-cut"></div>
              </div>
              <div class="card-content">
                <h4 class="card-title" [innerHTML]="
                            idea.title.length > 40
                              ? (idea.title | slice: 0:40) + '..'
                              : idea.title
                          "></h4>
                <div class="card-text">
                  <p [innerHTML]="
                              idea.descripcion.length > 100
                                ? (idea.descripcion | slice: 0:100) + '..'
                                : idea.descripcion
                            ">
                  </p>
                </div>
                <a class="card-link">Leer más</a>
              </div>
            </div>
          </div>
          <div class="col-12 box-btn-news" *ngIf="hideVerMas" data-aos="fade-up" data-aos-duration="700"
            data-aos-delay="100">
            <a (click)="nextPage()" class="btn-custom-new">
              <span>VER MÁS</span>
            </a>
          </div>
        </div>
      </div>
    </section>
    <div class="box-footer-desafio6">
      <img src="assets/img/footer-desafio7.jpg" class="img-fluid" alt="" />
    </div>
  </section>

</div>
<!-- <a [routerLink]="['/formulario-desafio6' ]" class="btn-mailto-inow"><img src="assets/img/icons/mail.svg" alt=""><span>Cuéntanos
    tu idea</span></a> -->
<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>

<div id="modal-eventos" class="modal-eventos" *ngIf="modalEventos">
  <div class="overlay-modal" (click)="closeEventosModal()"></div>
  <div class="modal-content">
    <button type="button" class="close" aria-label="Close" (click)="closeEventosModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body modal-body-eventos">
      <img [src]="modalImage" alt="" class="img-modal" />
      <div class="box-content-body-modal">
        <h2 class="title-modal" [innerHTML]="modalTitle"></h2>
        <h6 class="subtitle-modal"><b>Autor</b>: {{ nombre_del_colaborador }} - {{ email_del_colaborador }}</h6>
        <h6 class="subtitle-modal" *ngIf="planta_u_oficina">
          <b>Planta u oficina</b>: {{ planta_u_oficina }}
        </h6>
        <h5 class="title-int-modal">Descripción</h5>
        <div class="box-text-modal" [innerHTML]="descripcion"></div>
        <h5 class="title-int-modal">Beneficios</h5>
        <div class="box-text-modal" [innerHTML]="beneficios"></div>
        <h5 class="title-int-modal">Retos</h5>
        <div class="box-text-modal" [innerHTML]="retos"></div>
        <h5 class="title-int-modal">Costos</h5>
        <div class="box-text-modal" [innerHTML]="costos"></div>
      </div>
    </div>
  </div>
</div>

<div class="modal-custom3-text">
  <div class="overlay-modal"></div>
  <div class="box-custom3-text">
    <button class="close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="title-custom3-text">Levantamiento del proceso</h3>
    <div class="box-text-custome-text">
      <p>Entrevistas y reuniones de validación con los dueños y responsables del proceso con el objetivo de
        elaborar el
        informe de levantamiento de la situación actual y el diagrama de flujos de las actividades realizadas.
      </p>
    </div>
  </div>
</div>

<div id="modal-video-youtube" class="modal-video" [ngClass]="modalVideoIframe == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideoIframe()"></div>
  <button class="close" (click)="closeModalVideoIframe()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <iframe width="560" height="315" [src]="urlSafe" class="iframe-video" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>

<!-- <a [routerLink]="['/formulario-desafio7inow' ]" class="btn-mailto-inow"><img src="assets/img/icons/mail.svg"
    alt=""><span>Cuéntanos
    tu idea</span></a> -->