import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import anime from 'animejs/lib/anime.es.js';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as uuid from 'uuid';

// Services
import { AuthService } from '@services/auth';
import { ActivitiesService } from '@services/activities';
import { UsersService } from '@services/users';
import { PuntoPrensaService } from '@services/punto-prensa';
import { CumpleanosService } from '@services/cumpleanos';
import { GruposService } from '@services/grupos';
import { LoadingService } from '@services/loading';
import { AppComponent } from '../../../../app.component';
import * as $ from 'jquery';

@Component({
  selector: 'app-comite-paritario',
  templateUrl: './comite-paritario.component.html',
  styleUrls: ['./comite-paritario.component.scss'],
})
export class ComiteParitarioComponent implements OnInit {
  activities: string[];
  reactions: string[];
  currentPage: number;
  loading = true;
  commentForm: FormGroup;
  userLogged: any;
  avatar_logged: any;
  name_logged: any;
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  modalImgShow = false;
  modalImgSrc: string;
  users: any;
  showListUsers = false;
  textIndex = 0;
  name = 'Angular';
  message = '';
  showEmojiPicker = false;
  nameGroup: any;
  descriptionGroup: any;
  bannerGroup: any;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set = 'twitter';
  loggedIn = false;
  hasInGroup = false;
  setHash = uuid.v4();
  membersGroup: any;
  totalMembersGroup: any;

  constructor(
    private authService: AuthService,
    private activitiesService: ActivitiesService,
    private usersService: UsersService,
    private puntoPrensaService: PuntoPrensaService,
    private cumpleanosService: CumpleanosService,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private gruposService: GruposService,
    private formBuilder: FormBuilder,
    private title: Title,
    private http: HttpClient,
    public appComponent: AppComponent,
    private router: Router,
  ) {
    this.commentForm = this.formBuilder.group({
      content: this.formBuilder.control(null, [
        Validators.required,
        Validators.minLength(1),
      ]),
    });
    this.appComponent.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.userLogged = this.authService.user;
        this.avatar_logged = this.authService.user['avatar_url'];
        this.name_logged = this.authService.user['user_display_name'];
      }
      this.loggedIn = loggedIn;
    });
    if (this.loggedIn) {
      this.gruposService.validateUser(7).subscribe(
        (res) => {
          if (res) {
            this.hasInGroup = true;
          } else {
            this.hasInGroup = false;
          }
        },
        (err) => {
          console.error(err);
        }
      );
    } else {
      this.loggedIn = false;
    }
  }

  ngOnInit() {
    this.title.setTitle('Comité Paritario | Intranet Abastible');
    localStorage.setItem('back_title_page', "Grupos");
    localStorage.setItem('back_route_link', "grupos");
    localStorage.setItem('back_section_route_title', "Comite Paritario");
    localStorage.setItem('back_section_route_link', "comite-paritario");
    this.gruposService.getInfoGrupo(7).subscribe(
      (res) => {
        this.nameGroup = res['name'];
        this.descriptionGroup = res['description'];
        this.bannerGroup = res['banner'];
        this.membersGroup = res['group_members_result']['members'];
        this.totalMembersGroup = res['group_members_result']['count'];
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.gruposService.getActivityGrupos(7, 1).subscribe(
      (res) => {
        this.loading = false;
        this.currentPage = 1;
        this.activities = res['activities'];
        this.reactions = res['reactions'];
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    $("body").delegate(".item-navbar-tab", "click", function (e) {
      e.preventDefault();
      var idContent = $(this).data("id");
      if (!$(this).hasClass("active")) {
        $(".item-navbar-tab").removeClass("active")
        $(this).addClass("active");
        $(".item-tab-content-custom1").fadeOut(300);
        $(idContent).fadeIn(300);
      }
    });
  }
  getReactionIcon(reaction_id): string {
    const reactionIndex = this.reactions.findIndex(
      (element) => element['id'] == reaction_id
    );

    return this.reactions[reactionIndex]['img_url'];
  }

  hoverReaction(event): void {
    event.stopPropagation();

    anime({
      targets: event.target,
      scale: 1.2,
      duration: 500,
    });
  }

  unHoverReaction(event): void {
    event.stopPropagation();

    anime({
      targets: event.target,
      scale: 1,
      duration: 300,
    });
  }

  showReactions(element: string): void {
    const el = document.querySelector(element) as HTMLElement;
    const reactions = el.querySelectorAll('.active-reaction');

    anime.remove(element);

    anime({
      targets: element,
      opacity: [0, 1],
      delay: 200,
      duration: 1200,
      begin: function () {
        el.style.display = 'block';
      },
    });

    anime({
      targets: reactions,
      translateY: [10, 0],
      opacity: [0, 1],
      delay: anime.stagger(100, { start: 300 }),
      duration: 700,
      easing: 'easeOutBack',
    });
  }

  showCommentReactions(element: string): void {
    const el = document.querySelector(element) as HTMLElement;
    const reactions = el.querySelectorAll('.active-reaction');

    anime.remove(element);

    anime({
      targets: element,
      opacity: [0, 1],
      delay: 200,
      duration: 1200,
      begin: function () {
        el.style.display = 'block';
      },
    });

    anime({
      targets: reactions,
      translateY: [10, 0],
      opacity: [0, 1],
      delay: anime.stagger(100, { start: 300 }),
      duration: 700,
      easing: 'easeOutBack',
    });
  }

  hideReactions(event, element: string): void {
    const isChild = this.isChildOf(
      document.getElementById(element),
      event.toElement
    );

    if (isChild) {
      return;
    }

    anime.remove(element);

    anime({
      targets: element,
      opacity: 0,
      delay: 200,
      duration: 800,
      complete: function () {
        const el = document.querySelector(element) as HTMLElement;
        const reactions = el.querySelectorAll('.active-reaction');

        el.style.display = 'none';
        reactions.forEach(function (item: HTMLElement) {
          item.style.opacity = '0';
          item.style.transform = 'translateY(10px)';
        });
      },
    });
  }

  isChildOf(parent, child) {
    let node = child.parentNode;

    while (node != null) {
      if (node == parent) {
        return true;
      }
      node = node.parentNode;
    }

    return false;
  }

  likeActivity(activity_id, reaction_id): void {
    this.activitiesService.likeActivity(activity_id, reaction_id).subscribe(
      (res) => {
        const currentActivityIndex = this.activities.findIndex(
          (element) => element['id'] == activity_id
        );

        this.activities[currentActivityIndex]['likes'] = res.reaction_count
          ? res.reaction_count
          : 0;
        this.activities[currentActivityIndex]['liked'] = res.liked;
        this.activities[currentActivityIndex]['like_id'] = reaction_id;
      },
      (err) => {
        console.error(err);
      }
    );

    return;
  }

  likeComment(activity_id, comment_id, reaction_id): void {
    this.activitiesService.likeComment(comment_id, reaction_id).subscribe(
      (res) => {
        console.log(res)
        const currentCommentIndex = this.activities.findIndex(
          (element) => element['id'] == activity_id
        );
        const currentCommentIntIndex = this.activities[currentCommentIndex]['comments'].findIndex(
          (element) => element['id'] == comment_id
        );
        this.activities[currentCommentIndex]['comments'][currentCommentIntIndex]['likes'] = res.reaction_count
          ? res.reaction_count
          : 0;
        this.activities[currentCommentIndex]['comments'][currentCommentIntIndex]['liked'] = res.liked;
        this.activities[currentCommentIndex]['comments'][currentCommentIntIndex]['like_id'] = reaction_id;
      },
      (err) => {
        console.error(err);
      }
    );

    return;
  }

  parseCommentContent(content: string): string {
    return content.replace(/\n/g, '<br />');
  }

  showCommentAntiquity(timestamp: string): string {
    const diff = moment().diff(moment(timestamp, 'YYYY-MM-DD HH:mm:ss'));
    const duration = moment.duration(diff);

    const diffInHours = duration.asHours();
    const diffInMinutes = duration.asMinutes();
    const diffInDays = duration.asDays();
    const diffInMonths = duration.asMonths();

    if (diffInMinutes < 3) {
      return `Hace un momento`;
    } else if (diffInHours < 1) {
      return `Hace ${parseInt(diffInMinutes.toString())}mins`;
    } else if (diffInHours > 1 && diffInDays < 1) {
      return `Hace ${parseInt(diffInHours.toString())}h`;
    } else if (diffInDays >= 1 && diffInMonths < 1) {
      return `Hace ${parseInt(diffInDays.toString())}d`;
    } else if (diffInMonths >= 1) {
      const formatedDate = moment(timestamp, 'YYYY-MM-DD HH:mm:ss').format(
        'DD/MM/YYYY'
      );

      return formatedDate;
    }
  }

  showActivityComments(index) {
    if (this.activities[index]['showComments'])
      this.activities[index]['showComments'] = false;
    else this.activities[index]['showComments'] = true;

    return false;
  }

  getActivityCommentsCount(comments: string[]): number {
    if (!comments) return 0;

    return Object.keys(comments).length;
  }

  activityHasComments(comments: string[]): boolean {
    if (!comments) return false;

    return true;
  }

  activityHasImage(content): boolean {
    return /<img .*?>/.test(content);
  }

  getActivityImage(content: any): string {
    const image = content.match(/<img .*?>/);
    if (!image) return '';

    const url = image[0].match(/src\s*=\s*"(.+?)"/);

    return url[1];

    // return this.domSanitizer.sanitize(
    //   SecurityContext.URL,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl(image)
    // );
  }

  contentFilter(content: string): string {
    return content.replace(/<img .*?>/g, '');
  }

  nextPage() {
    this.currentPage = this.currentPage + 1;

    this.gruposService.getActivityGrupos(7, this.currentPage).subscribe(
      (res) => {
        console.log([...this.activities, res['activities']]);
        this.activities = [...this.activities, ...res['activities']];
      },
      (err) => {
        console.error(err);
      }
    );
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }

  uploadActivityComment(post, id) {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user.token;
    if (this.previewUrl == null) {
      const content = this.commentForm.value.content;
      this.activitiesService
        .uploadComment(
          id,
          post,
          user['display_name'],
          user['user_email'],
          user['ID'],
          content
        )
        .subscribe(
          (comments) => {
            const currentActivityIndex = this.activities.findIndex(
              (element) => element['id'] == id
            );

            this.activities[currentActivityIndex]['comments'] = comments;
            this.commentForm.reset();
          },
          (err) => {
            console.error(err);
          }
        );

    } else {
      const formData = new FormData();
      var fileData64 = this.previewUrl;
      var tipeFile = this.fileData['type'];
      var fileUrlNoData64 = fileData64.replace("data:" + tipeFile + ";base64,", "");
      formData.append('file', this.previewUrl);
      formData.append('name', this.fileData['name']);
      formData.append('type', this.fileData['type']);
      formData.append('urlImg', fileUrlNoData64);
      console.log(this.fileData);
      this.http.post('https://intranet.abastible.cl/wp/wp-json/v2/upload-img/', formData,
        {
          headers: new HttpHeaders({ Authorization: `Bearer ${token}` }),
        })
        .subscribe(res => {
          console.log(res);
          const content = this.commentForm.value.content + `<img src="${res}" class="img-content-comment">`;
          this.activitiesService
            .uploadComment(
              id,
              post,
              user['display_name'],
              user['user_email'],
              user['ID'],
              content
            )
            .subscribe(
              (comments) => {
                const currentActivityIndex = this.activities.findIndex(
                  (element) => element['id'] == id
                );

                this.activities[currentActivityIndex]['comments'] = comments;
                this.commentForm.reset();
                this.fileData = null;
                this.previewUrl = null;
                this.fileUploadProgress = null;
                this.uploadedFilePath = null;
              },
              (err) => {
                console.error(err);
              }
            );
        })
    }



    return;
  }
  fullImgModal(src: string) {
    this.modalImgShow = true;
    this.modalImgSrc = src;
  }
  closeFullImgModal() {
    this.modalImgShow = false;
  }

  onCommentKeyup(event) {
    const value = event.target.value;
    let lastCharacter = value.charAt(value.length - 1);
    console.log(event.keyCode)
    if (event.key == '@') {
      this.usersService.getUsers().subscribe(
        (res) => {
          this.users = res;
          this.showListUsers = true;
        },
        (err) => {
          console.error(err);
        }
      );
    } else if (event.keyCode == 8 && lastCharacter === '@') {
      this.usersService.getUsers().subscribe(
        (res) => {
          this.users = res;
          this.showListUsers = true;
        },
        (err) => {
          console.error(err);
        }
      );
    } else {
      this.showListUsers = false;
    }
    return false;
  }

  tagUser(user) {
    let commentValue = this.commentForm.controls.content.value;

    this.commentForm.controls.content.setValue(`${commentValue}${user.name}`);
    this.showListUsers = false;
  }

  toggleEmojiPicker() {
    console.log(this.showEmojiPicker);
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event, idmessage) {
    const { message } = this;
    console.log(message);
    console.log(`${event.emoji.native}`)
    const text = `${message}${event.emoji.native}`;
    this.message = text;
    $("#" + idmessage).val(`${this.message}`);
    console.log("#" + idmessage);
    // this.showEmojiPicker = false;
  }

  onFocus() {
    console.log('focus');
    this.showEmojiPicker = false;
  }
  onBlur() {
    console.log('onblur')
  }
}
