import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  constructor(private httpClient: HttpClient) { }

  getListReactions(activity_id: any): Observable<Record<string, any>> {
    return this.httpClient
      .post(
        `${environment2.apiUrl}/get-list-reactions/?activity_id=${activity_id}`, null
      )
      .pipe((result) => {
        return result;
      });
  }

  getListReactionsComment(comment_id: any): Observable<Record<string, any>> {
    return this.httpClient
      .post(
        `${environment2.apiUrl}/get-list-reactions-comment/?comment_id=${comment_id}`, null
      )
      .pipe((result) => {
        return result;
      });
  }

  deleteActivity(activity_id: any): Observable<Record<string, any>> {
    return this.httpClient
      .post(
        `${environment2.apiUrl}/delete-activity/?activity_id=${activity_id}`, null
      )
      .pipe((result) => {
        return result;
      });
  }

  getFotosConcursosApp(): Observable<Record<string, any>> {
    return this.httpClient
      .post(
        `${environment2.apiUrl}/get-fotos-concursos-app`, null
      )
      .pipe((result) => {
        return result;
      });
  }

  getLatest(): Observable<Record<string, any>> {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }
    console.log(user_id);
    return this.httpClient
      .post(
        `${environment2.apiUrl}/latest-activities/`,
        {
          user_id: user_id
        })
      .pipe((result) => {
        return result;
      });
  }

  getDestaqued(): Observable<Record<string, any>> {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }
    console.log(user_id);
    return this.httpClient
      .post(
        `${environment2.apiUrl}/destaqued-activities/`,
        {
          user_id: user_id
        })
      .pipe((result) => {
        return result;
      });
  }

  getPage(page: number): Observable<Record<string, any>> {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }
    return this.httpClient
      .post(
        `${environment2.apiUrl}/page-activities/`,
        {
          page: page,
          user_id: user_id
        }
      )
      .pipe((result) => {
        return result;
      });
  }

  getDescubre(): Observable<Record<string, any>> {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }
    return this.httpClient
      .post(
        `${environment2.apiUrl}/descubre/`, null
      )
      .pipe((result) => {
        return result;
      });
  }

  getBanners(): Observable<Record<string, any>> {
    return this.httpClient
      .post(
        `${environment2.apiUrl}/banners/`, null
      )
      .pipe((result) => {
        return result;
      });
  }

  /*postFile(fileToUpload: File): Observable<Record<string, any>> {
    const endpoint = `${environment2.apiUrl}/upload-img/`;
    const formData: FormData = new FormData();
    const user = JSON.parse(localStorage.getItem('user2'));
    console.log(fileToUpload);
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    console.log(formData);
    return this.httpClient
      .post(endpoint, formData, { headers: new HttpHeaders({ Authorization: `Bearer ${token}` }) })
      .pipe((result) => {
        console.log(result);
        return result;
      });
  } */

  uploadComment(
    id: number,
    post_id: number,
    author: string,
    author_email: string,
    author_id: number,
    content: string
  ): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user2'));

    return this.httpClient
      .post(
        `${environment2.apiUrl}/activity/upload-comment/`,
        {
          id: id,
          post_id: post_id,
          author: author,
          author_email: author_email,
          author_id: author_id,
          content: content,
        }
      )
      .pipe((result) => {
        return result;
      });
  }

  likeActivity(
    activity_id: number,
    reaction_id: number
  ): Observable<Record<string, any>> {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }
    return this.httpClient
      .post(
        `${environment2.apiUrl}/activity/upload-like`,
        {
          id: reaction_id,
          activity_id: activity_id,
          user_id: user_id
        }
      )
      .pipe((result) => {
        return result;
      });
  }
  likeComment(
    comment_id: number,
    reaction_id: number
  ): Observable<Record<string, any>> {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(
        `${environment2.apiUrl}/like_comment`,
        {
          id: reaction_id,
          comment_id: comment_id,
          user_id: user_id
        }
      )
      .pipe((result) => {
        return result;
      });
  }
  removeComment(activity_id: number, comment_id: number): Observable<Record<string, any>> {
    return this.httpClient
      .post(
        `${environment2.apiUrl}/remove-comment`, { activity_id: activity_id, comment_id: comment_id }
      )
      .pipe((result) => {
        return result;
      });
  }
}
