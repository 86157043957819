import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { NewsService } from '@services/news';
import { LoadingService } from '@services/loading';

@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.scss'],
})
export class BeneficiosAbastibleComponent implements OnInit {
  landing: any;
  landing_descripcion_general: any;
  item1: any;
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  addClassFixed = false;
  selectedIndex: any[] = [];
  videoinBanner: any;
  sections: any;
  modalTextShow: any;
  modalText: any;
  modalTitulo: any;
  documentos: any;
  titlePage: any;
  routeLink: any;
  news1: any;
  beneficios1: any;
  beneficios2: any;
  beneficios3: any;
  beneficios4: any;
  beneficios5: any;
  carouselCharlas = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private landingService: LandingService,
    private title: Title,
    private route: ActivatedRoute,
    private NewsService: NewsService,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
    $("body").delegate(".content-ejes-estrategicos", "mousemove", function (e) {
      console.log($('img.icon-mano-dark', this).offset().left);
      console.log(e.pageX - $(this).offset().left);
      var xOld = e.pageX - $(this).offset().left + 50;
      if (xOld > $('img.icon-mano-dark', this).offset().left) {
        var x = e.pageX - $(this).offset().left + 50;
        $('.content-ejes-estrategicos-gray', this).css({ width: x });
        $('img.icon-mano-dark', this).css({ left: x });
      }
    });
    $("body").delegate(".item-tab", "click", function () {
      var idContent = $(this).data("id");
      if (!$(this).hasClass("active")) {
        $(".item-tab").removeClass("active");
        $(this).addClass("active");
        $(".item-tab-content").fadeOut(300)
        if ($(this).hasClass("item-tab-content-link1")) {
          $(".item-tab-content1").fadeIn(300);
        }
        if ($(this).hasClass("item-tab-content-link2")) {
          $(".item-tab-content2").fadeIn(300);
        }
        if ($(this).hasClass("item-tab-content-link3")) {
          $(".item-tab-content3").fadeIn(300);
        }
        if ($(this).hasClass("item-tab-content-link4")) {
          $(".item-tab-content4").fadeIn(300);
        }
        if ($(this).hasClass("item-tab-content-link5")) {
          $(".item-tab-content5").fadeIn(300);
        }
      }
    });
  }
  init(): void {
    this.title.setTitle('Beneficios | Intranet Abastible');
    this.paramsID = 71567;
    this.slug = this.params['slug'];
    this.titlePage = "Beneficios";
    this.routeLink = "beneficios";
    localStorage.setItem('back_title_page', this.titlePage);
    localStorage.setItem('back_route_link', this.routeLink);
    localStorage.removeItem('back_section_route_title');
    localStorage.removeItem('back_section_route_link');


    this.landingService.getInfoNew(this.paramsID).subscribe(
      (res) => {
        this.landing = res;
        this.videoinBanner = res.video_induccion;
        this.documentos = res.documentos;
        console.log("res", res)
        this.sections = res.sections;
        this.openModalHash()
      },
      (err) => {
        console.error(err);
      }
    );

    this.NewsService.getNews("beneficios-y-clima-organizacional", 1).subscribe(
      (res) => {
        this.news1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.NewsService.getBeneficios("educacion").subscribe(
      (res) => {
        this.beneficios1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.NewsService.getBeneficios("familia").subscribe(
      (res) => {
        this.beneficios2 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.NewsService.getBeneficios("otros").subscribe(
      (res) => {
        this.beneficios3 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.NewsService.getBeneficios("salud").subscribe(
      (res) => {
        this.beneficios4 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.NewsService.getBeneficios("betterfly").subscribe(
      (res) => {
        this.beneficios5 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
  }
  openModalHash() {
    setTimeout(() => {
      this.route.fragment.subscribe((fragment: string) => {
        localStorage.setItem('back_section_route_link', '#' + fragment);
        $("#" + fragment + " .btn-custom-new").trigger("click");
        console.log("My hash fragment is here => ", fragment)
        console.log($("#" + fragment + " .btn-custom-new").length);
      })
    }, 5);
  }
  scroll(id) {
    let el = document.getElementById(id);
    this.modalTextShow = false;
    var body = document.body;
    body.classList.remove("modal-open");
    body.classList.remove("modal-single-content-open");
    el.scrollIntoView({
      behavior: 'smooth'
    });
  }

  textMin(text: string) {
    var str = text;
    var res = str.substring(0, 200);//get first 5 chars
    return res + '...';;
  }

  contentFilter(content: string): string {
    return content.replace(/<img .*?>/g, '');
  }

  setSelected(id: number) {
    if (this.selectedIndex[id] == true) {
      this.selectedIndex[id] = false;
    } else {
      this.selectedIndex[id] = true;
    }
  }
  fullTextModal(titulo: string, text: string, section: string) {
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "#" + section;
    var newurlSet = window.location.protocol + "//" + window.location.host + window.location.pathname + "-" + section;
    const date = new Date();
    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }
    let day = date.getDate();
    let month = n((date.getMonth() + 1));
    let year = date.getFullYear();
    let currentDate = `${day}/${month}/${year}`;
    
    this.authService.analitics(currentDate, newurlSet).subscribe(
      (data) => {
        console.log('data ', data);
      },
      (err) => {
        console.log('err ', err);
      }
    );
    window.history.pushState({ path: newurl }, '', newurl);
    localStorage.setItem('back_section_route_link', '#' + section);
    localStorage.setItem('back_section_route_title', titulo);
    this.modalTextShow = true;
    this.modalTitulo = titulo;
    this.modalText = text;
    var body = document.body;
    body.classList.add("modal-open");
    body.classList.add("modal-single-content-open");
  }
  closeFullTextModal() {
    this.modalTextShow = false;
    var body = document.body;
    body.classList.remove("modal-open");
    body.classList.remove("modal-single-content-open");
  }
}
