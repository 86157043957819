<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs breadcrumbs-sections">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <!-- <div class="breadcrumbs-item">
        <a [routerLink]="['/grupos']">Grupos</a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div> -->
      <div class="breadcrumbs-item">
        <span>Techo</span>
      </div>
    </div>
  </div>
</section>

<section id="techo">
  <div class="banner-techo">
    <img src="assets/img/banner-techo.jpg" alt="">
  </div>
  <div class="section-techo1">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class="box-img-design1-techo">
            <img src="assets/img/icon-techo.png" alt="">
          </div>
        </div>
        <div class="col-lg-5">
          <div class="box-design4">
            <div class="box-text-design4">
              <p>Por tercer año consecutivo, en alianza con TECHO-Chile, estamos dando vida a la <b>Campaña Chile
                  Comparte Calor</b>, que busca
                apoyar con recargas de gas a campamentos, villas y cocinas comunitarias que hoy son fuente de alimento
                para cientos de
                familias vulnerables.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section-techo5">
    <img src="assets/img/bg-section4.png" alt="" class="img-bg-section4-1">
    <div class="section-content-techo5">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <div class="content-section-techo5-int">
              <a href="https://app.payku.cl/botonpago/index?idboton=19973&verif=ff88cdf8&a=6637p788a" target="_blank"
                class="btn-design-techo4-1">Botón de pago</a>
              <div class="box-qr-techo">
                <h6 class="title-box-qr-techo">Acceso rápido</h6>
                <img src="assets/img/icon-qr.jpg" alt="">
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="box-section-techo4-1">
              <h6>También puedes hacer tu aporte a través de</h6>
              <h3>DESCUENTO POR PLANILLA</h3>
              <h4>Para dudas contacta a Karina Jerez</h4>
              <h5><a href="mailto:karina.jerez@abastible.cl">karina.jerez@abastible.cl</a></h5>
              <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2021/07/Carta-descuento-aporte-campa%C3%B1a-Techo-2021.pdf"
                target="_blank" class="btn-design-techo4-2">Descarga el formulario</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section-techo6">
    <img src="assets/img/img-section-techo1.png" alt="">
    <div class="container">
      <div class="box-center-section-techo6">
        <img src="assets/img/icon-comparte-calor.png" alt="">
      </div>
    </div>
  </div>
  <section class="section-custom5">
    <div class="container">
      <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">NOTICIAS</h3>
      <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
      <div class="swiper-container" #carouselNoticias [swiper]="carouselCharlas.config">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let new of news; let i = index">
            <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
              <div class="card-img">
                <img src="{{ new.img }}" alt="">
                <div class="diagonal-cut"></div>
              </div>
              <div class="card-content">
                <h4 class="card-title" [innerHTML]="new.title"></h4>
                <div class="card-text">
                  <p [innerHTML]="new.text"></p>
                </div>
                <a [routerLink]="['/noticia/' + new.slug ]" class="card-link">Leer más</a>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev">
          <span class="material-icons">
            keyboard_arrow_left
          </span>
        </div>
        <div class="swiper-button-next">
          <span class="material-icons">
            keyboard_arrow_right
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
          <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'comparte-calor' }" class="btn-custom-new">
            <span>VER MÁS</span>
          </a>
        </div>
      </div>
    </div>
  </section>

  <div class="container mb-5" id="charlas">
    <h3 class="title-custom-new3 text-center" data-aos="fade-up" data-aos-duration="700">Galerías</h3>
    <span class="line-title-custom-new mx-auto mb-5" data-aos="fade-up" data-aos-duration="700"
      data-aos-delay="100"></span>
    <div class="swiper-container" [swiper]="carouselCharlas.config">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let item of galerias; let i = index">
          <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
            class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
            data-aos="fade-up" data-aos-duration="700">
            <div class="card-img">
              <img src="{{ item.feature_img }}" alt="">
              <div class="diagonal-cut"></div>
            </div>
            <div class="card-content">
              <h4 class="card-title" [innerHTML]="item.post_title"></h4>
            </div>
          </a>
        </div>
      </div>
      <div class="swiper-button-prev">
        <span class="material-icons">
          keyboard_arrow_left
        </span>
      </div>
      <div class="swiper-button-next">
        <span class="material-icons">
          keyboard_arrow_right
        </span>
      </div>
    </div>
    <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
      <a [routerLink]="['/galeria-image-section/comparte-calor' ]" class="btn-custom-new">
        <span>VER MÁS</span>
      </a>
    </div>
  </div>

  <section class="section-custom5" *ngIf="galeriasVideos.length != 0">
    <div class="container">
      <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">
        Videos
      </h3>
      <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
      <div #carouselVideos class="swiper-container" [swiper]="carouselCharlas.config">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let item of galeriasVideos; let i = index">
            <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
              (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
              <div class="card-img">
                <img src="{{ item.img }}" alt="" />
                <div class="diagonal-cut"></div>
                <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                  class="btn-play-desing1" />
              </div>
              <div class="card-content">
                <h4 class="card-title">{{ item.title }}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev">
          <span class="material-icons">
            keyboard_arrow_left
          </span>
        </div>
        <div class="swiper-button-next">
          <span class="material-icons">
            keyboard_arrow_right
          </span>
        </div>
      </div>
      <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
        <a [routerLink]="['/galeria-videos-section/techo']" [queryParams]="1" class="btn-custom-new">
          <span>VER MÁS</span>
        </a>
      </div>
    </div>
  </section>

</section>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>