import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { NoticiasService } from '@services/noticias';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss'],
})
export class NoticiasAbastibleComponent implements OnInit {
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  selectedIndex: any[] = [];
  news1: any;
  news1Res: any;
  currentPage1 = 1;
  catNews = "all";
  categoriesFilter: any;
  categorySlug: any;
  hasCategorySlug: any;
  valueSearch = "";
  back_title_page: any;
  back_route_link: any;
  back_section_route_link: any;
  back_section_route_title: any;
  has_back_section = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private NoticiasService: NoticiasService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
    $("body").delegate(".content-ejes-estrategicos", "mousemove", function (e) {
      console.log($('img.icon-mano-dark', this).offset().left);
      console.log(e.pageX - $(this).offset().left);
      var xOld = e.pageX - $(this).offset().left + 50;
      if (xOld > $('img.icon-mano-dark', this).offset().left) {
        var x = e.pageX - $(this).offset().left + 50;
        $('.content-ejes-estrategicos-gray', this).css({ width: x });
        $('img.icon-mano-dark', this).css({ left: x });
      }
    });
    $("body").delegate(".pin-map", "click", function () {
      var dataPin = $(this).attr("href");
      $(".pin-map, .box-text-modal-map").removeClass("active")
      $(this).addClass("active");
      $("." + dataPin).addClass("active");
    });
  }
  init(): void {
    this.title.setTitle('Noticias | Intranet Abastible');
    this.paramsID = 29622;
    this.slug = this.params['slug'];
    console.log(location.origin);
    if (localStorage.getItem('back_title_page')) {
      this.back_title_page = localStorage.getItem('back_title_page');
      this.back_route_link = "https://intranet.abastible.cl/" + localStorage.getItem('back_route_link')

      if (localStorage.getItem('back_section_route_link')) {
        this.has_back_section = true;
        this.back_section_route_title = localStorage.getItem('back_section_route_title');
        this.back_section_route_link = this.back_route_link + localStorage.getItem('back_section_route_link');
      } else {
        this.has_back_section = false;
      }
    }
    var that = this;
    // localStorage.removeItem('back_title_page');
    // localStorage.removeItem('back_route_link');
    $("body").removeClass(".modal-open")
    $("body").removeClass(".modal-single-content-open")
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['categoryslug']) {
        this.categorySlug = queryParams['categoryslug'];
        this.hasCategorySlug = true;
      }
    });


    this.NoticiasService.getNews(this.catNews, 1, this.valueSearch).subscribe(
      (res) => {
        this.news1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.NoticiasService.getCategoriesNews().subscribe(
      (res) => {
        this.categoriesFilter = res;
        console.log(res);
        if (this.hasCategorySlug) {
          setTimeout(() => {
            that.catNews = that.categorySlug;
            $(".box-categories a").removeClass("active")
            $(".link-cat.link-cat-" + that.categorySlug).addClass('active');
            that.NoticiasService.getNews(that.catNews, 1, that.valueSearch).subscribe(
              (res) => {
                that.news1 = res;
                console.log(res);
              },
              (err) => {
                console.error(err);
              }
            );
            console.log(that.catNews)
          }, 1000);
        }
      },
      (err) => {
        console.error(err);
      }
    );

  }

  nextPage() {
    this.currentPage1 = this.currentPage1 + 1;

    this.NoticiasService.getNews(this.catNews, this.currentPage1, this.valueSearch).subscribe(
      (res) => {
        this.news1Res = res;
        this.news1 = [...this.news1, ...this.news1Res];
        console.log([...this.news1, res]);
        console.log(this.news1Res)
      },
      (err) => {
        console.error(err);
      }
    );
  }

  setCategoryList(event, slug: string) {
    this.catNews = slug;
    $(".box-categories a").removeClass("active")
    event.target.classList.add('active');
    this.NoticiasService.getNews(this.catNews, 1, this.valueSearch).subscribe(
      (res) => {
        this.news1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    console.log(this.catNews)
  }

  onSubmit(value: any) {
    this.valueSearch = value
    this.NoticiasService.getNews(this.catNews, 1, this.valueSearch).subscribe(
      (res) => {
        this.news1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  textMin(text: string) {
    var str = text;
    var res = str.substring(0, 200);//get first 5 chars
    return res + '...';;
  }

  contentFilter(content: string): string {
    return content.replace(/<img .*?>/g, '');
  }

  setSelected(id: number) {
    if (this.selectedIndex[id] == true) {
      this.selectedIndex[id] = false;
    } else {
      this.selectedIndex[id] = true;
    }
  }
}
