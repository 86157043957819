<section class="navbar-custom2">
    <div class="container">
        <div id="carousel-navbar">
            <div (click)="scroll(section.slug)" class="item-navbar-custom2 item-directorio-administracion"
                *ngFor="let section of sections; let i = index">
                <h6 class="title-navbar-custom">{{ section.titulo }}</h6>
            </div>
        </div>
    </div>
</section>
<div class="bg-white bg-white-custom">
    <section class="section-banner-custom-new" id="section0" style="background-image: url(assets/img/img-test2.jpg);">
        <div class="overlay-banner-custom-new"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="title-banner-custom-new" data-aos="fade-right" data-aos-duration="700">Beneficios
                    </h2>
                    <span class="line-title-custom-new-banner" data-aos="fade-right" data-aos-duration="700"
                        data-aos-delay="400"></span>
                    <!-- <div class="box-text-banner-custon-new" data-aos="fade-right" data-aos-duration="700">
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
          </div> -->
                </div>
            </div>
        </div>
    </section>
    <div id="animationWindow"></div>
    <section id="breadcrumbs">
        <div class="container">
            <div class="breadcrumbs breadcrumbs-sections">
                <div class="breadcrumbs-item">
                    <a [routerLink]="['/muro']"><span class="material-icons">
                            home
                        </span></a>
                    <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
                </div>
                <div class="breadcrumbs-item">
                    <span>Beneficios</span>
                </div>
            </div>
        </div>
    </section>
    <div *ngFor="let section of sections; let i = index">
        <section class="section-custom-new" id="{{section.slug}}">
            <div class="container">
                <div class="row">
                    <div class="" [ngClass]="{'col-lg-3': section.video, 'col-lg-4': !section.video }">
                        <div class="box-title-custom-new">
                            <h3 class="title-custom-new" data-aos="fade-up" data-aos-duration="700">{{ section.titulo }}
                            </h3>
                            <span class="line-title-custom-new" data-aos="fade-right" data-aos-duration="700"
                                data-aos-delay="400"></span>
                        </div>
                    </div>
                    <div class="" [ngClass]="{'col-lg-6': section.video, 'col-lg-8': !section.video }">
                        <div class="box-content-custom-new" data-aos="fade-up" data-aos-duration="700"
                            data-aos-delay="200">
                            <div class="text-custom-new">
                                <p [innerHTML]="textMin(section.descripcion_corta)"></p>
                            </div>
                            <span (click)="fullTextModal(section.titulo , section.texto, section.slug)"
                                class="btn-custom-new">
                                <span>VER MÁS</span>
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                                    style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                                    <g>
                                        <g>
                                            <path
                                                d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-img-section-custom-new" *ngIf="section.video">
                <div class="diagonal-cut"></div>
                <img src="{{ section.video }}" *ngIf="section.titulo == 'Beneficios para colaboradores'" alt="">
                <video autoplay [muted]="'muted'" *ngIf="section.titulo != 'Beneficios para colaboradores'" loop>
                    <source src="{{ section.video }}">
                </video>
            </div>
        </section>

        <section class="section-image-divider" *ngIf="section.imagen_separacion"
            [ngStyle]="{ 'background-image': 'url(' + section.imagen_separacion + ')'}">
        </section>
    </div>
    <div>
        <div class="tabs-search">
            <button data-tab="#news-general" class="item-tab-search active">Noticias</button>
        </div>
        <div class="tabs-content-search">
            <div class="item-tab-content-search" id="news-general">
                <section class="section-custom5">
                    <div class="container">
                        <!-- <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">NOTICIAS</h3>
                        <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span> -->
                        <div class="swiper-container" #carouselNoticias [swiper]="carouselCharlas.config">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" *ngFor="let new1 of news1; let i = index">
                                    <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                                        <div class="card-img">
                                            <img src="{{ new1.img }}" alt="">
                                            <div class="diagonal-cut"></div>
                                        </div>
                                        <div class="card-content">
                                            <h4 class="card-title" [innerHTML]="new1.title"></h4>
                                            <div class="card-text">
                                                <p [innerHTML]="new1.text"></p>
                                            </div>
                                            <a [routerLink]="['/noticia/' + new1.slug ]" class="card-link">Leer más</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-button-prev">
                                <span class="material-icons">
                                    keyboard_arrow_left
                                </span>
                            </div>
                            <div class="swiper-button-next">
                                <span class="material-icons">
                                    keyboard_arrow_right
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700"
                                data-aos-delay="100">
                                <a [routerLink]="['/noticias']"
                                    [queryParams]="{categoryslug: 'beneficios-y-clima-organizacional' }"
                                    class="btn-custom-new">
                                    <span>VER MÁS</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>

    <section class="section-custom-new3">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 mx-auto">
                    <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">DOCUMENTOS</h3>
                    <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700"
                        data-aos-delay="100"></span>
                    <div class="content-documents-custom-new3">
                        <a href="{{ documento.link }}" class="item-document-custom-new3"
                            *ngFor="let documento of documentos; let i = index" target="_blank" data-aos="fade-up"
                            data-aos-duration="700" data-aos-delay="0">
                            <h3 class="title-document-custom-new3">{{ documento.titulo }}</h3>
                            <div class="box-acctions-custom-new3">
                                <span class="view-document">
                                    <img src="assets/img/file.svg" alt="">
                                </span>
                                <span class="download-document">
                                    <img src="assets/img/download.svg" alt="">
                                </span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<div class="modal-img-full modal-single-content" [ngClass]="modalTextShow == true ? 'active-modal' : ''">
    <div class="overlay-modal" (click)="closeFullTextModal()"></div>
    <div class="box-modal-text-full">
        <div class="modal-header-single-content">
            <button class="btn-back-single-content" (click)="closeFullTextModal()">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                    style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                    <g>
                        <g>
                            <path
                                d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                        </g>
                    </g>
                </svg>
            </button>
            <h3 class="title-header-modal">Beneficios / <span [innerHTML]="modalTitulo"></span></h3>
        </div>
        <div class="modal-text">
            <div class="modal-content-text">
                <div [innerHTML]="modalText"></div>
                <div *ngIf="modalTitulo == 'Beneficios para colaboradores'">
                    <div class="modal-tabs">
                        <div id="tab1-content" class="item-tab active item-tab-content-link1"><img class="item-tab-img"
                                src="assets/img/icons/icon-vida-sana.png" alt="" />
                            <!-- <h3 class="item-tab-title">VIDA SANA</h3> -->
                        </div>
                        <div id="tab2-content" class="item-tab item-tab-content-link2"><img class="item-tab-img"
                                src="assets/img/icons/icon-familia.png" alt="" />
                            <!-- <h3 class="item-tab-title">FAMILIA</h3> -->
                        </div>
                        <div id="tab3-content" class="item-tab item-tab-content-link3"><img class="item-tab-img"
                                src="assets/img/icons/icon-flexibilidad.png" alt="" />
                            <!-- <h3 class="item-tab-title">FLEXIBILIDAD</h3> -->
                        </div>
                        <div id="tab4-content" class="item-tab item-tab-content-link4"><img class="item-tab-img"
                                src="assets/img/icons/icon-desarrollo.png" alt="" />
                            <!-- <h3 class="item-tab-title">DESARROLLO</h3> -->
                        </div>
                        <div id="tab5-content" class="item-tab item-tab-content-link5" *ngIf="beneficios5.length > 0">
                            <img class="item-tab-img" src="assets/img/icons/icon-financiero.png" alt="" />
                            <!-- <h3 class="item-tab-title">BIENESTAR FINANCIERO</h3> -->
                        </div>
                    </div>
                    <div class="modal-content-tabs">
                        <!-- SALUD -->
                        <div id="tab1" class="item-tab-content item-tab-content1" style="display: block;">
                            <div class="row">
                                <div class="col-lg-4" *ngFor="let beneficio of beneficios4; let i = index">
                                    <div class="box-item-tab-content">

                                        <p><b>{{beneficio.title}}</b></p>

                                        <p [innerHTML]="beneficio.text"></p>

                                        <p *ngIf="beneficio.tag">{{beneficio.tag}}</p>

                                        <p>
                                            <i>Contacto: {{beneficio.contact}}</i>
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- FAMILIA -->
                        <div id="tab2" class="item-tab-content item-tab-content2">
                            <div class="row">
                                <div class="col-lg-4" *ngFor="let beneficio of beneficios2; let i = index">
                                    <div class="box-item-tab-content">

                                        <p><b>{{beneficio.title}}</b></p>

                                        <p [innerHTML]="beneficio.text"></p>

                                        <p *ngIf="beneficio.tag">{{beneficio.tag}}</p>

                                        <p>
                                            <i>Contacto: {{beneficio.contact}}</i>
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- EDUCACIÓN -->
                        <div id="tab3" class="item-tab-content item-tab-content3">
                            <div class="row">
                                <div class="col-lg-4" *ngFor="let beneficio of beneficios1; let i = index">
                                    <div class="box-item-tab-content">

                                        <p><b>{{beneficio.title}}</b></p>

                                        <p [innerHTML]="beneficio.text"></p>

                                        <p *ngIf="beneficio.tag">{{beneficio.tag}}</p>

                                        <p>
                                            <i>Contacto: {{beneficio.contact}}</i>
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- OTROS -->
                        <div id="tab4" class="item-tab-content item-tab-content4">
                            <div class="row">
                                <div class="col-lg-4" *ngFor="let beneficio of beneficios3; let i = index">
                                    <div class="box-item-tab-content">

                                        <p><b>{{beneficio.title}}</b></p>

                                        <p [innerHTML]="beneficio.text"></p>

                                        <p *ngIf="beneficio.tag">{{beneficio.tag}}</p>

                                        <p>
                                            <i>Contacto: {{beneficio.contact}}</i>
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- BETTERFLY -->
                        <div id="tab5" class="item-tab-content item-tab-content5" *ngIf="beneficios5.length > 0">
                            <div class="row">
                                <div class="col-lg-4" *ngFor="let beneficio of beneficios5; let i = index">
                                    <div class="box-item-tab-content">

                                        <p><b>{{beneficio.title}}</b></p>

                                        <p [innerHTML]="beneficio.text"></p>

                                        <p *ngIf="beneficio.tag">{{beneficio.tag}}</p>

                                        <p>
                                            <i>Contacto: {{beneficio.contact}}</i>
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div class="menu-mobile">
                        <div class="content-menu-mobile">
                            <a href="#menu-mobile1" class="link-collapse-menu-mobile"><img
                                    src="assets/img/icon-camera.svg" alt="" /></a>
                            <a href="#menu-mobile2" class="link-collapse-menu-mobile"><img
                                    src="assets/img/icon-camera.svg" alt="" /></a>
                            <a href="#" class="btn-design-float"><span></span></a>
                            <a href="#menu-mobile3" class="link-collapse-menu-mobile"><img
                                    src="assets/img/icon-camera.svg" alt="" /></a>
                            <a href="#menu-mobile4" class="link-collapse-menu-mobile"><img
                                    src="assets/img/icon-camera.svg" alt="" /></a>
                        </div>
                    </div>
                    <div class="container box-footer">
                        <div class="box-list-footer">
                            <div class="list-footer">
                                <h3 class="title-list-footer">Conoce Abastible</h3>
                                <ul>
                                    <li><a [routerLink]="['/conoce-abastible']"
                                            fragment="presentacion-de-la-empresa-historia">Presentación de la
                                            empresa/historia</a></li>
                                    <li><a [routerLink]="['/conoce-abastible']"
                                            fragment="mision-vision-valores">Misión -Visión - Valores</a></li>
                                    <li><a [routerLink]="['/conoce-abastible']"
                                            fragment="directorio-y-administracion">Directorio
                                            y Administración</a></li>
                                    <li><a [routerLink]="['/conoce-abastible']"
                                            fragment="productos-y-servicios">Productos y Servicios</a></li>
                                    <li><a [routerLink]="['/conoce-abastible']" fragment="proceso-productivo">Proceso
                                            Productivo</a></li>
                                    <li><a [routerLink]="['/conoce-abastible']"
                                            fragment="soluciones-energeticas">Soluciones Energéticas</a></li>
                                </ul>
                            </div>
                            <div class="list-footer">
                                <h3 class="title-list-footer">Control Interno</h3>
                                <ul>
                                    <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1"
                                            target="_blank">Canal de
                                            Denuncias</a></li>
                                    <li><a [routerLink]="['/control-interno']" fragment="auditoria-interna">Auditoría
                                            interna</a></li>
                                    <li><a [routerLink]="['/control-interno']"
                                            fragment="seguridad-de-la-informacion">Seguridad de la
                                            información</a></li>
                                    <li><a [routerLink]="['/control-interno']" fragment="gestion-de-riesgo">Gestión
                                            de Riesgo</a></li>
                                    <li><a [routerLink]="['/control-interno']" fragment="gestion-de-procesos">Gestión de
                                            Procesos</a></li>
                                </ul>
                            </div>
                            <div class="list-footer">
                                <h3 class="title-list-footer">Seguridad</h3>
                                <ul>
                                    <li><a [routerLink]="['/seguridad']" fragment="integridad-operacional">Integridad
                                            Operacional</a></li>
                                    <li><a [routerLink]="['/seguridad']" fragment="gestion-de-calidad">Gestión de
                                            Calidad</a></li>
                                    <li><a [routerLink]="['/seguridad']"
                                            fragment="servicio-nacional-de-emergencias">Servicio Nacional de
                                            Emergencias</a></li>
                                    <li><a [routerLink]="['/seguridad']" fragment="manejo-manual-de-carga">Manejo Manual
                                            de Carga</a></li>
                                    <li><a [routerLink]="['/seguridad']" fragment="medio-ambiente">Medio Ambiente</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="list-footer">
                                <h3 class="title-list-footer">Beneficios</h3>
                                <ul>
                                    <li><a [routerLink]="['/beneficios']"
                                            fragment="beneficios-para-colaboradores">Beneficios para
                                            colaboradores</a></li>
                                    <li><a [routerLink]="['/beneficios']" fragment="clima-organizacional">Clima
                                            organizacional</a></li>
                                </ul>
                            </div>
                            <!--<div class="list-footer">
                                <h3 class="title-list-footer">Gobierno Corporativo</h3>
                                <ul>
                                <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1" target="_blank">Canal de Denuncias</a></li>
                                <li><a [routerLink]="['/gobierno-corporativo']" fragment="section2">Compliance</a></li>
                            </ul>
                            </div> -->
                            <div class="list-footer">
                                <h3 class="title-list-footer">Soluciones
                                    Energéticas</h3>
                                <ul>
                                    <li><a [routerLink]="['/soluciones-energeticas']"
                                            fragment="soluciones-energeticas">Soluciones
                                            Energéticas</a></li>
                                </ul>
                            </div>
                            <!-- <div class="list-footer">
                                <h3 class="title-list-footer">Innovación</h3>
                                <ul>
                                    <li><a [routerLink]="['/innovacion']" fragment="i-now">I-NOW</a></li>
                                    <li><a [routerLink]="['/innovacion']"
                                            fragment="laboratorio-de-innovacion">Laboratorio de Innovación</a></li>
                                    <li><a [routerLink]="['/innovacion']" fragment="soluciones-energeticas">Soluciones
                                            Energéticas</a></li>
                                </ul>
                            </div> -->
                            <div class="list-footer list-footer-rd">
                                <h3 class="title-list-footer">Social</h3>
                                <ul>
                                    <li><a href="https://www.facebook.com/AbastibleCL" target="_blank"><img
                                                src="assets/img/icons/icon-facebook.svg" alt="">AbastibleCL</a></li>
                                    <li><a href="https://www.instagram.com/laenergiadevivir/" target="_blank"><img
                                                src="assets/img/icons/icon-instagram.svg" alt="">/laenergiadevivir</a>
                                    </li>
                                    <li><a href="https://www.linkedin.com/company/abastible-s.a./" target="_blank"><img
                                                src="assets/img/icons/icon-linkedin.svg"
                                                alt="">/company/abastible-s.a.</a></li>
                                    <li><a href="https://www.youtube.com/user/AbastibleChileSA" target="_blank"><img
                                                src="assets/img/icons/icon-youtube.svg" alt="">/AbastibleChileSA</a>
                                    </li>
                                    <li><a href="https://www.abastible.cl/" target="_blank"><img
                                                src="assets/img/icons/logo-abastible.svg" alt="">abastible.cl</a></li>
                                </ul>
                            </div>
                        </div>
                        <a href="index.html" class="logo-footer"><img src="assets/img/logo.png" alt="" /></a>
                    </div>
                </footer>

            </div>
        </div>
    </div>
</div>