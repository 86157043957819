import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import anime from 'animejs/lib/anime.es.js';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../../../app.component';
import { NgImageSliderModule } from 'ng-image-slider';

// Services
import { AuthService } from '@services/auth';
import { ActivitiesService } from '@services/activities';
import { UsersService } from '@services/users';
import { PuntoPrensaService } from '@services/punto-prensa';
import { CumpleanosService } from '@services/cumpleanos';
import { LoadingService } from '@services/loading';
import { VotarService } from '@services/vota';
import * as $ from 'jquery';
import { pipe } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  activities: string[];
  reactions: string[];
  currentPage: number;
  loading = true;
  commentForm: FormGroup;
  votarForm: FormGroup;
  userLogged: any;
  avatar_logged: any;
  name_logged: any;
  userLogged2: any;
  avatar_logged2: any;
  name_logged2: any;
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  modalImgShow = false;
  modalImgSrc: string;
  users: any;
  showListUsers = false;
  imgBanner: any;
  imgBannerLength = 0;
  textIndex = 0;
  indexBanner = 0;
  imageObject: Array<object> = [{
    image: 'https://i.picsum.photos/id/580/1020/600.jpg',
    thumbImage: 'https://i.picsum.photos/id/580/400/350.jpg',
    alt: 'alt of image',
    title: 'title of image'
  }, {
    image: 'https://i.picsum.photos/id/838/1020/600.jpg',
    thumbImage: 'https://i.picsum.photos/id/838/400/350.jpg',
    title: 'Image title',
    alt: 'Image alt'
  }, {
    image: 'https://i.picsum.photos/id/93/1020/600.jpg',
    thumbImage: 'https://i.picsum.photos/id/93/400/350.jpg',
    title: 'Image title',
    alt: 'Image alt'
  }, {
    image: 'https://i.picsum.photos/id/543/1020/600.jpg',
    thumbImage: 'https://i.picsum.photos/id/543/400/350.jpg',
    title: 'Image title',
    alt: 'Image alt'
  }, {
    image: 'https://i.picsum.photos/id/613/1020/600.jpg',
    thumbImage: 'https://i.picsum.photos/id/613/400/350.jpg',
    title: 'Image title',
    alt: 'Image alt'
  }, {
    image: 'https://i.picsum.photos/id/609/1020/600.jpg',
    thumbImage: 'https://i.picsum.photos/id/609/400/350.jpg',
    title: 'Image title',
    alt: 'Image alt'
  }, {
    image: 'https://i.picsum.photos/id/717/1020/600.jpg',
    thumbImage: 'https://i.picsum.photos/id/717/400/350.jpg',
    title: 'Image title',
    alt: 'Image alt'
  }
  ];
  name = 'Angular';
  message = '';
  showEmojiPicker = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set = 'twitter';
  hash: any;
  hashLocal: any;
  loggedIn = false;
  descubreArray1: any;
  descubreArray2: any;
  descubreArray3: any;
  descubreArray4: any;
  modalVideo = false;
  modalSrcVideo: any;
  galleryPost = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 3,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  galleryPost2 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 6,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  has_next_img = false;
  has_prev_img = false;
  reactions_list: any;
  modalReaction = false;
  items: any;
  reactions_list_comment: any;
  logged_user_id: any;
  fotosConcursosApp: any;
  modalReactionComment = false;
  showVotacion = false;
  arrayVotar = [];
  urlSafe: SafeResourceUrl;
  modalVideoIframe = false;
  modalSrcVideoIframe: string = "";

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private activitiesService: ActivitiesService,
    private usersService: UsersService,
    private puntoPrensaService: PuntoPrensaService,
    private cumpleanosService: CumpleanosService,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    public sanitizer: DomSanitizer,
    private title: Title,
    private http: HttpClient,
    private appComponent: AppComponent,
    private votarService: VotarService
  ) {
    this.commentForm = this.formBuilder.group({
      content: this.formBuilder.control(null, [
        Validators.required,
        Validators.minLength(1),
      ]),
    });
    this.votarForm = this.formBuilder.group({
      votar_section: this.formBuilder.control(null, [
        Validators.required
      ]),
    });
  }

  ngOnInit() {
    this.title.setTitle('Muro | Intranet Abastible');
    console.log("this.imgBannerLength", this.imgBannerLength);
    localStorage.removeItem('back_title_page');
    localStorage.removeItem('back_route_link');
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl("https://swiftcdn6.global.ssl.fastly.net/projects/6132fa5888ed7/index.html?cb=hk2jqlsh96gp4wgnnwns9");
    this.activitiesService.getLatest().subscribe(
      (res) => {
        this.loading = false;
        this.currentPage = 1;
        this.activities = res['activities'];
        this.reactions = res['reactions'];
        setTimeout(() => {
          $(".card-text-design3 a").attr("target", "_blank")
        }, 1000);
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.usersService.getUsers().subscribe(
      (res: any) => {
        console.log(res);
        this.items = res;
      },
      (err) => {
        console.error(err);
      }
    );
    this.activitiesService.getDescubre().subscribe(
      (res) => {
        this.descubreArray1 = res[0];
        this.descubreArray2 = res[1];
        this.descubreArray3 = res[2];
        this.descubreArray4 = res[3];
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

    this.activitiesService.getBanners().subscribe(
      (res) => {
        this.imgBanner = res;
        this.imgBannerLength = this.imgBanner.length
        console.log("Banner", res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.activitiesService.getFotosConcursosApp().subscribe(
      (res) => {
        this.fotosConcursosApp = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.appComponent.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.userLogged = this.authService.user;
        this.avatar_logged = this.authService.user['avatar_url'];
        this.name_logged = this.authService.user['user_display_name'];
        this.logged_user_id = this.authService.user['ID'];
        this.activitiesService.getLatest().subscribe(
          (res) => {
            this.loading = false;
            this.currentPage = 1;
            this.activities = res['activities'];
            this.reactions = res['reactions'];
            console.log(res);
          },
          (err) => {
            console.error(err);
          }
        );
      }

      this.loggedIn = loggedIn;
    });
    this.votarService.checkVotarEvento().subscribe(
      (res: any) => {
        if (res == 0) {
          this.showVotacion = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );
    this.votarService.getListVotarEvento().subscribe(
      (res: any) => {
        this.arrayVotar = res;
      },
      (err) => {
        console.error(err);
      }
    );



    // if (this.appComponent.loggedIn) {
    //   this.loggedIn = true;
    //   this.userLogged = JSON.parse(localStorage.getItem('user'));
    //   this.avatar_logged = this.userLogged['avatar_url'];
    //   this.name_logged = this.userLogged['user_display_name'];
    //   console.log(this.userLogged);
    //   console.log(this.avatar_logged);
    // }
  }


  getUrlSafeYoutube(youtube_id: any) {
    let prevText = "https://www.youtube.com/embed/";
    let srcVideo = prevText + youtube_id;
    return this.sanitizer.bypassSecurityTrustResourceUrl(srcVideo);
  }

  submitVotarForm() {
    const votar_section = this.votarForm.controls.votar_section.value;
    if (votar_section) {
      this.votarService.votarEvento(votar_section).subscribe(
        (res: any) => {
          this.showVotacion = false;
          this.arrayVotar = res;
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }
  showModalReactions(event, activity_id: any) {
    event.stopPropagation();

    this.activitiesService.getListReactions(activity_id).subscribe(
      (res: any) => {
        console.log(res);
        this.reactions_list = res;
        this.modalReaction = true;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  showModalReactionsComments(event, comment_id: any) {
    event.stopPropagation();

    this.activitiesService.getListReactionsComment(comment_id).subscribe(
      (res: any) => {
        console.log(res);
        this.reactions_list_comment = res;
        this.modalReactionComment = true;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  deleteActivity(activity_id: any) {
    this.activitiesService.deleteActivity(activity_id).subscribe(
      (res: any) => {
        console.log(res);
        document.getElementById('activity' + activity_id).remove()
      },
      (err) => {
        console.error(err);
      }
    );
  }

  closeModalReactions() {
    this.modalReaction = false;
    $("#modal-reactions-list").removeClass("active-modal");
  }

  closeModalReactionsComment() {
    this.modalReactionComment = false;
    $("#modal-reactions-list-comment").removeClass("active-modal");
  }

  getDinamicIdGallery(id: number) {
    var idDinamic = "gallery-single" + id;
    return idDinamic;
  }

  fullImgModal(src: string, idGallery: number, idImgGallery: number, activityId: number) {
    console.log(idGallery);
    console.log(idImgGallery);
    $(".box-modal-img-full").html(" ");
    $(".gallery-single" + activityId).each(function () {
      var srcImage = $(this).attr("src");
      var idImg = $(this).attr("id");
      var description = $(this).attr("alt");
      console.log(srcImage);
      $(".box-modal-img-full").append(`
        <div id="${idImg}" class="gallery-single${idImg}-1" style="display:none;">
          <img src="${srcImage}"  data-gallery="gallery-single${idGallery}"  alt="">
          <p id="description-${idImg}">${description}</p>
        </div>
      `)
    });
    $(".box-modal-img-full #img-gallery-single" + idImgGallery).addClass("active").fadeIn();
    if ($(".box-modal-img-full #img-gallery-single" + idImgGallery).next().length) {
      this.has_next_img = true;
    } else {
      this.has_next_img = false;
    }
    if ($(".box-modal-img-full #img-gallery-single" + idImgGallery).prev().length) {
      this.has_prev_img = true;
    } else {
      this.has_prev_img = false;
    }
    this.modalImgShow = true;
    // this.modalImgSrc = src;
  }

  prevFullImgModal() {
    var elemActive = $(".box-modal-img-full div.active");
    var elemPrev = elemActive.prev();
    elemActive.removeClass("active").fadeOut(300);
    setTimeout(() => {
      elemPrev.addClass("active").fadeIn();
    }, 300);
    this.has_next_img = true;
    if (elemPrev.prev().length) {
      this.has_prev_img = true;
    } else {
      this.has_prev_img = false;
    }
  }
  nextFullImgModal() {
    var elemActive = $(".box-modal-img-full div.active");
    var elemNext = elemActive.next()
    elemActive.removeClass("active").fadeOut(300);
    setTimeout(() => {
      elemNext.addClass("active").fadeIn();
    }, 300);
    this.has_prev_img = true;
    if (elemNext.next().length) {
      this.has_next_img = true;
    } else {
      this.has_next_img = false;
    }
  }
  closeFullImgModal() {
    this.modalImgShow = false;
  }

  getReactionIcon(reaction_id): string {
    const reactionIndex = this.reactions.findIndex(
      (element) => element['id'] == reaction_id
    );

    return this.reactions[reactionIndex]['img_url'];
  }

  hoverReaction(event): void {
    event.stopPropagation();

    anime({
      targets: event.target,
      scale: 1.2,
      duration: 500,
    });
  }

  unHoverReaction(event): void {
    event.stopPropagation();

    anime({
      targets: event.target,
      scale: 1,
      duration: 300,
    });
  }

  showReactions(element: string): void {
    const el = document.querySelector(element) as HTMLElement;
    const reactions = el.querySelectorAll('.active-reaction');

    anime.remove(element);

    anime({
      targets: element,
      opacity: [0, 1],
      delay: 200,
      duration: 1200,
      begin: function () {
        el.style.display = 'block';
      },
    });

    anime({
      targets: reactions,
      translateY: [10, 0],
      opacity: [0, 1],
      delay: anime.stagger(100, { start: 300 }),
      duration: 700,
      easing: 'easeOutBack',
    });
  }

  showCommentReactions(element: string): void {
    const el = document.querySelector(element) as HTMLElement;
    const reactions = el.querySelectorAll('.active-reaction');

    anime.remove(element);

    anime({
      targets: element,
      opacity: [0, 1],
      delay: 200,
      duration: 1200,
      begin: function () {
        el.style.display = 'block';
      },
    });

    anime({
      targets: reactions,
      translateY: [10, 0],
      opacity: [0, 1],
      delay: anime.stagger(100, { start: 300 }),
      duration: 700,
      easing: 'easeOutBack',
    });
  }

  hideReactions(event, element: string): void {
    const isChild = this.isChildOf(
      document.getElementById(element),
      event.toElement
    );

    if (isChild) {
      return;
    }

    anime.remove(element);

    anime({
      targets: element,
      opacity: 0,
      delay: 200,
      duration: 800,
      complete: function () {
        const el = document.querySelector(element) as HTMLElement;
        const reactions = el.querySelectorAll('.active-reaction');

        el.style.display = 'none';
        reactions.forEach(function (item: HTMLElement) {
          item.style.opacity = '0';
          item.style.transform = 'translateY(10px)';
        });
      },
    });
  }

  isChildOf(parent, child) {
    let node = child.parentNode;

    while (node != null) {
      if (node == parent) {
        return true;
      }
      node = node.parentNode;
    }

    return false;
  }

  likeActivity(activity_id, reaction_id): void {
    this.activitiesService.likeActivity(activity_id, reaction_id).subscribe(
      (res) => {
        console.log(res);
        const currentActivityIndex = this.activities.findIndex(
          (element) => element['id'] == activity_id
        );

        this.activities[currentActivityIndex]['likes'] = res.reaction_count
          ? res.reaction_count
          : 0;
        this.activities[currentActivityIndex]['liked'] = res.liked;
        this.activities[currentActivityIndex]['like_id'] = reaction_id;
      },
      (err) => {
        console.error(err);
      }
    );

    return;
  }

  likeComment(activity_id, comment_id, reaction_id): void {
    const user = JSON.parse(localStorage.getItem('user'));
    const user_id = parseInt(user['ID']);
    this.activitiesService.likeComment(comment_id, reaction_id).subscribe(
      (res) => {
        console.log(res)
        const currentCommentIndex = this.activities.findIndex(
          (element) => element['id'] == activity_id
        );
        const currentCommentIntIndex = this.activities[currentCommentIndex]['comments'].findIndex(
          (element) => element['id'] == comment_id
        );
        this.activities[currentCommentIndex]['comments'][currentCommentIntIndex]['likes'] = res.reaction_count
          ? res.reaction_count
          : 0;
        this.activities[currentCommentIndex]['comments'][currentCommentIntIndex]['liked'] = res.liked;
        this.activities[currentCommentIndex]['comments'][currentCommentIntIndex]['like_id'] = reaction_id;
      },
      (err) => {
        console.error(err);
      }
    );

    return;
  }

  parseCommentContent(content: string): string {
    return content.replace(/\n/g, '<br />');
  }

  showCommentAntiquity(timestamp: string): string {
    const diff = moment().diff(moment(timestamp, 'YYYY-MM-DD HH:mm:ss'));
    const duration = moment.duration(diff);

    const diffInHours = duration.asHours();
    const diffInMinutes = duration.asMinutes();
    const diffInDays = duration.asDays();
    const diffInMonths = duration.asMonths();

    if (diffInMinutes < 3) {
      return `Hace un momento`;
    } else if (diffInHours < 1) {
      return `Hace ${parseInt(diffInMinutes.toString())}mins`;
    } else if (diffInHours > 1 && diffInDays < 1) {
      return `Hace ${parseInt(diffInHours.toString())}h`;
    } else if (diffInDays >= 1 && diffInMonths < 1) {
      return `Hace ${parseInt(diffInDays.toString())}d`;
    } else if (diffInMonths >= 1) {
      const formatedDate = moment(timestamp, 'YYYY-MM-DD HH:mm:ss').format(
        'DD/MM/YYYY'
      );

      return formatedDate;
    }
  }

  showActivityComments(index) {
    if (this.activities[index]['showComments'])
      this.activities[index]['showComments'] = false;
    else this.activities[index]['showComments'] = true;

    return false;
  }

  getActivityCommentsCount(comments: string[]): number {
    if (!comments) return 0;

    return Object.keys(comments).length;
  }

  activityHasComments(comments: string[]): boolean {
    if (!comments) return false;

    return true;
  }

  activityHasImage(content): boolean {
    return /<img .*?>/.test(content);
  }
  activityHasLink(content): boolean {
    return /<a .*?>/.test(content);
  }

  getActivityLink(content: any): string {
    return "0";
    const link = content.match(/<a .*?>/);
    if (!link) return '';

    const url = link[0].match(/href\s*=\s*"(.+?)"/);
    return url[1];

    // return this.domSanitizer.sanitize(
    //   SecurityContext.URL,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl(image)
    // );
  }

  renderTitleCumpleanos(content: string): string {
    if (content.includes(",")) {
      let porciones = content.split(',');
      let reorganize = porciones[1] + " " + porciones[0];
      return reorganize;
    } else {
      return content;
    }
  }

  getActivityLinkVideo(content: any, content_new: any): string {
    if (content_new) {
      var contnet_new2 = content_new.slice(0, -1)
      var contnet_new3 = contnet_new2.slice(1)
      var lastLetters2 = contnet_new3.substr(contnet_new3.length - 3); // => "Tabs1"
      if (lastLetters2 == "mp4" && content_new) {
        return contnet_new3;
      }
    }
    const link = content.match(/<a .*?>/);
    if (!link) return '0';

    const url = link[0].match(/href\s*=\s*"(.+?)"/);
    var lastLetters = url[1].substr(url[1].length - 3); // => "Tabs1"
    if (lastLetters == "mp4") {
      return url[1];
    } else {
      return "0";
    }

    // return this.domSanitizer.sanitize(
    //   SecurityContext.URL,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl(image)
    // );
  }

  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }

  showModalVideoIframe(event, srcVideo: string) {
    event.stopPropagation();
    let prevText = "https://www.youtube.com/embed/";
    this.modalVideoIframe = true;
    this.modalSrcVideoIframe = prevText + srcVideo;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
  }
  closeModalVideoIframe() {
    this.modalVideoIframe = false;
    this.modalSrcVideoIframe = '';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
    $("#modal-video-youtube").removeClass("active-modal");
  }

  getActivityImage(content: any): string {
    const image = content.match(/<img .*?>/);
    if (!image) return '';

    const url = image[0].match(/src\s*=\s*"(.+?)"/);

    return url[1];

    // return this.domSanitizer.sanitize(
    //   SecurityContext.URL,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl(image)
    // );
  }

  contentFilter(content: string): string {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = content.replace(/<img .*?>/g, '');
    return tmp.textContent || tmp.innerText || "";
  }

  nextPage() {
    this.currentPage = this.currentPage + 1;

    this.activitiesService.getPage(this.currentPage).subscribe(
      (res) => {
        console.log([...this.activities, res['activities']]);
        this.activities = [...this.activities, ...res['activities']];
      },
      (err) => {
        console.error(err);
      }
    );
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  get_index_banner(index: number) {
    if (this.indexBanner < this.imgBannerLength) {
      let indexSet = (index / 4) - 1;
      let imgSetUrl = this.imgBanner[indexSet];
      if (imgSetUrl) {
        // this.indexBanner = indexCount + 1;
        return imgSetUrl.img;
      }
    }
  }
  get_enlace_banner(index: number) {
    if (this.indexBanner < this.imgBannerLength) {
      let indexSet = (index / 4) - 1;
      let imgSetUrl = this.imgBanner[indexSet];
      if (imgSetUrl) {
        return [imgSetUrl.enlace, true];
      } else {
        return ["", false];
      }
    }
  }

  preview() {
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      console.log(this.previewUrl);
    }
  }

  uploadActivityComment(post, id) {
    const user = JSON.parse(localStorage.getItem('user'));
    const user_id = parseInt(user['ID']);
    if (this.previewUrl == null) {
      const content = this.commentForm.controls.content.value;
      this.activitiesService
        .uploadComment(
          id,
          post,
          user['display_name'],
          user['user_email'],
          user_id,
          content
        )
        .subscribe(
          (comments) => {
            const currentActivityIndex = this.activities.findIndex(
              (element) => element['id'] == id
            );

            this.activities[currentActivityIndex]['comments'] = comments;
            this.commentForm.reset();
          },
          (err) => {
            console.error(err);
          }
        );

    } else {
      const formData = new FormData();
      var fileData64 = this.previewUrl;
      var tipeFile = this.fileData['type'];
      var fileUrlNoData64 = fileData64.replace("data:" + tipeFile + ";base64,", "");
      formData.append('file', this.previewUrl);
      formData.append('name', this.fileData['name']);
      formData.append('type', this.fileData['type']);
      formData.append('urlImg', fileUrlNoData64);
      console.log(this.fileData);
      this.http.post('https://intranet.abastible.cl/wp/wp-json/v2/upload-img/', formData)
        .subscribe(res => {
          console.log(res);
          const content = this.commentForm.controls.content.value; + `<img src="${res}" class="img-content-comment">`;
          this.activitiesService
            .uploadComment(
              id,
              post,
              user['display_name'],
              user['user_email'],
              user_id,
              content
            )
            .subscribe(
              (comments) => {
                const currentActivityIndex = this.activities.findIndex(
                  (element) => element['id'] == id
                );

                this.activities[currentActivityIndex]['comments'] = comments;
                this.commentForm.reset();
                this.fileData = null;
                this.previewUrl = null;
                this.fileUploadProgress = null;
                this.uploadedFilePath = null;
              },
              (err) => {
                console.error(err);
              }
            );
        })
    }



    return;
  }

  onCommentKeyup(event) {
    const value = event.target.value;
    let lastCharacter = value.charAt(value.length - 1);
    console.log(lastCharacter)
    if (event.key == '@') {
      this.usersService.getUsers().subscribe(
        (res) => {
          this.users = res;
          this.showListUsers = true;
        },
        (err) => {
          console.error(err);
        }
      );
    } else if (event.keyCode == 8 && lastCharacter === '@') {
      this.usersService.getUsers().subscribe(
        (res) => {
          this.users = res;
          this.showListUsers = true;
        },
        (err) => {
          console.error(err);
        }
      );
    } else {
      this.showListUsers = false;
    }
    return false;
  }

  tagUser(user) {
    let commentValue = this.commentForm.controls.content.value;

    this.commentForm.controls.content.setValue(`${commentValue}${user.name}`);
    this.showListUsers = false;
  }

  toggleEmojiPicker() {
    console.log(this.showEmojiPicker);
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event, idmessage) {
    const { message } = this;
    const text = `${event.emoji.native}`;
    this.message = text;
    let commentValue = this.commentForm.controls.content.value;
    let messageFinish = commentValue + this.message;
    this.commentForm.controls.content.setValue(`${messageFinish}`);
    $(".input-item-comments").val("");
    console.log(messageFinish);
    console.log(idmessage);
    $("#" + idmessage).val(messageFinish);
    // this.showEmojiPicker = false;
  }

  onFocus() {
    console.log('focus');
    this.showEmojiPicker = false;
  }
  onBlur() {
    console.log('onblur')
  }

  getPostDate(date_notified): string {
    return moment(date_notified, 'YYYY-MM-DD HH:mm:ss').format(
      'dddd,D MMMM'
    );
  }
}
