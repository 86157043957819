<section class="navbar-custom2">
  <div class="container">
    <div id="carousel-navbar">
      <div (click)="scroll('section0')" class="item-navbar-custom2 item-video-de-induccion-abastible" data-position="0">
        <h6 class="title-navbar-custom">VIDEO DE INDUCCIÓN</h6>
      </div>
      <div (click)="scroll(section.slug)" class="item-navbar-custom2 item-directorio-administracion"
        *ngFor="let section of sections; let i = index">
        <h6 class="title-navbar-custom">{{ section.titulo }}</h6>
      </div>
    </div>
  </div>
</section>
<div class="bg-white bg-white-custom">
  <section class="section-banner-custom-new" id="section0" *ngFor="let videoinBannerFor of videoinBanner; let i = index"
    style="background-image: url(assets/img/img-test2.jpg);">
    <div class="overlay-banner-custom-new"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <h2 class="title-banner-custom-new" data-aos="fade-right" data-aos-duration="700">Conoce Abastible</h2>
          <span class="line-title-custom-new-banner" data-aos="fade-right" data-aos-duration="700"
            data-aos-delay="400"></span>
          <div class="box-text-banner-custon-new" data-aos="fade-right" data-aos-duration="700">
            <p>{{ videoinBannerFor.texto }}</p>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="box-content-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="200">
            <video poster="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/introduccion.png" controls
              style="object-fit: cover; object-position: left;">
              <source src="{{ videoinBannerFor.video }}">
            </video>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div id="animationWindow"></div>
  <section id="breadcrumbs">
    <div class="container">
      <div class="breadcrumbs breadcrumbs-sections">
        <div class="breadcrumbs-item">
          <a [routerLink]="['/muro']"><span class="material-icons">
              home
            </span></a>
          <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
        </div>
        <div class="breadcrumbs-item">
          <span>Conoce Abastible</span>
        </div>
      </div>
    </div>
  </section>
  <div *ngFor="let section of sections; let i = index">
    <section class="section-custom-new" id="{{section.slug}}">
      <div class="container">
        <div class="row">
          <div class=""
            [ngClass]="{'col-lg-3': (section.video || section.imagen), 'col-lg-4': (!section.video && !section.imagen) }">
            <div class="box-title-custom-new">
              <h3 class="title-custom-new" data-aos="fade-up" data-aos-duration="700">{{ section.titulo }}</h3>
              <span class="line-title-custom-new" data-aos="fade-right" data-aos-duration="700"
                data-aos-delay="400"></span>
            </div>
          </div>
          <div class="" [ngClass]="{'col-lg-6': section.video, 'col-lg-8': !section.video }">
            <div class="box-content-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="200">
              <div class="text-custom-new">
                <p [innerHTML]="textMin(section.descripcion_corta)"></p>
              </div>
              <span (click)="fullTextModal(section.titulo , section.texto, section.slug)" class="btn-custom-new">
                <span>VER MÁS</span>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                  style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                  <g>
                    <g>
                      <path
                        d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                    </g>
                  </g>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="box-img-section-custom-new" *ngIf="section.video">
        <div class="diagonal-cut"></div>
        <img src="{{ section.video }}" alt="">
        <!-- <video autoplay [muted]="'muted'" loop>
          <source src="{{ section.video }}">
        </video> -->
      </div>
      <div class="box-img-section-custom-new" *ngIf="section.imagen">
        <div class="diagonal-cut"></div>
        <img [src]="section.imagen" alt="" class="img-fluid">
      </div>
    </section>

    <section class="section-image-divider" *ngIf="section.imagen_separacion"
      [ngStyle]="{ 'background-image': 'url(' + section.imagen_separacion + ')'}">
    </section>
  </div>
  <div>
    <div class="tabs-search">
      <button data-tab="#news-general" class="item-tab-search active">Noticias</button>
    </div>
    <div class="tabs-content-search">
      <div class="item-tab-content-search" id="news-general">
        <section class="section-custom5" *ngIf="news1.length > 0">
          <div class="container">
            <!-- <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">NOTICIAS</h3>
            <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span> -->
            <div class="swiper-container" #carouselNoticias [swiper]="carouselCharlas.config">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let new1 of news1; let i = index">
                  <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                    <div class="card-img">
                      <img src="{{ new1.img }}" alt="">
                      <div class="diagonal-cut"></div>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title" [innerHTML]="new1.title"></h4>
                      <div class="card-text">
                        <p [innerHTML]="new1.text"></p>
                      </div>
                      <a [routerLink]="['/noticia/' + new1.slug ]" class="card-link">Leer más</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <span class="material-icons">
                  keyboard_arrow_left
                </span>
              </div>
              <div class="swiper-button-next">
                <span class="material-icons">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'centro-de-aprendizaje' }"
                  class="btn-custom-new">
                  <span>VER MÁS</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <!-- <section class="section-custom5">
    <div class="container">
      <h6 class="subtitle-custom-new3" data-aos="fade-up" data-aos-duration="700">Nuestras</h6>
      <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">NOTICIAS</h3>
      <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
      <div class="row">
        <div class="col-lg-4">
          <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
            <div class="card-img">
              <img src="assets/img/image-conozca1.jpg" alt="">
              <div class="diagonal-cut"></div>
            </div>
            <div class="card-content">
              <h4 class="card-title">Título de noticia</h4>
              <div class="card-text">
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure aliquid est distinctio tempora
                  dignissimos, quibusdam optio qui. Veniam, quos. In eius vero quos nulla rem, ex possimus repellendus
                  incidunt magnam.</p>
              </div>
              <a href="#" class="card-link">Leer más</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
            <div class="card-img">
              <img src="assets/img/image-conozca1.jpg" alt="">
              <div class="diagonal-cut"></div>
            </div>
            <div class="card-content">
              <h4 class="card-title">Título de noticia</h4>
              <div class="card-text">
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure aliquid est distinctio tempora
                  dignissimos, quibusdam optio qui. Veniam, quos. In eius vero quos nulla rem, ex possimus repellendus
                  incidunt magnam.</p>
              </div>
              <a href="#" class="card-link">Leer más</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
            <div class="card-img">
              <img src="assets/img/image-conozca1.jpg" alt="">
              <div class="diagonal-cut"></div>
            </div>
            <div class="card-content">
              <h4 class="card-title">Título de noticia</h4>
              <div class="card-text">
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure aliquid est distinctio tempora
                  dignissimos, quibusdam optio qui. Veniam, quos. In eius vero quos nulla rem, ex possimus repellendus
                  incidunt magnam.</p>
              </div>
              <a href="#" class="card-link">Leer más</a>
            </div>
          </div>
        </div>
        <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
          <a href="#" class="btn-custom-new">
            <span>VER MÁS</span>
          </a>
        </div>
      </div>
    </div>
  </section> -->
  <!-- <section class="section-custom-new2">
        <div class="container">
            <div class="row row-custom-new2">
                <div class="col-lg-3 col-custom-new2" data-aos="fade-up" data-aos-duration="700" data-aos-delay="0">
                    <div class="box-custom-new2">
                        <img src="assets/img/img-test2.jpg" alt="" class="img-custom-new2">
                        <div class="box-content-custom-new2">
                            <h6 class="subtitle-custom-new2">Sector</h6>
                            <h4 class="title-custom-new2">Forestal</h4>
                            <span class="line-title-custom-new2"></span>
                            <a href="#" class="btn-custom-new btn-custom-new2">
                                <span>VER MÁS</span> 
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                                    <g>
                                        <g>
                                            <path d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"/>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-custom-new2" data-aos="fade-up" data-aos-duration="700" data-aos-delay="200">
                    <div class="box-custom-new2">
                        <img src="assets/img/img-test2.jpg" alt="" class="img-custom-new2">
                        <div class="box-content-custom-new2">
                            <h6 class="subtitle-custom-new2">Sector</h6>
                            <h4 class="title-custom-new2">Forestal</h4>
                            <span class="line-title-custom-new2"></span>
                            <a href="#" class="btn-custom-new btn-custom-new2">
                                <span>VER MÁS</span> 
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                                    <g>
                                        <g>
                                            <path d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"/>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-custom-new2" data-aos="fade-up" data-aos-duration="700" data-aos-delay="400">
                    <div class="box-custom-new2">
                        <img src="assets/img/img-test2.jpg" alt="" class="img-custom-new2">
                        <div class="box-content-custom-new2">
                            <h6 class="subtitle-custom-new2">Sector</h6>
                            <h4 class="title-custom-new2">Forestal</h4>
                            <span class="line-title-custom-new2"></span>
                            <a href="#" class="btn-custom-new btn-custom-new2">
                                <span>VER MÁS</span> 
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                                    <g>
                                        <g>
                                            <path d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"/>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-custom-new2" data-aos="fade-up" data-aos-duration="700" data-aos-delay="600">
                    <div class="box-custom-new2">
                        <img src="assets/img/img-test2.jpg" alt="" class="img-custom-new2">
                        <div class="box-content-custom-new2">
                            <h6 class="subtitle-custom-new2">Sector</h6>
                            <h4 class="title-custom-new2">Forestal</h4>
                            <span class="line-title-custom-new2"></span>
                            <a href="#" class="btn-custom-new btn-custom-new2">
                                <span>VER MÁS</span> 
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                                    <g>
                                        <g>
                                            <path d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"/>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

  <!-- <section class="section-custom-new3">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <h6 class="subtitle-custom-new3" data-aos="fade-up" data-aos-duration="700">Accionistas</h6>
          <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">JUNTA DE ACCIONISTAS 2020</h3>
          <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
          <div class="content-documents-custom-new3">
            <a href="#" class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
              data-aos-delay="0">
              <h3 class="title-document-custom-new3">Estados Financieros Consolidados</h3>
              <div class="box-acctions-custom-new3">
                <span class="view-document">
                  <img src="assets/img/file.svg" alt="">
                </span>
                <span class="download-document">
                  <img src="assets/img/download.svg" alt="">
                </span>
              </div>
            </a>
            <a href="#" class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
              data-aos-delay="100">
              <h3 class="title-document-custom-new3">Estados Financieros Consolidados</h3>
              <div class="box-acctions-custom-new3">
                <span class="view-document">
                  <img src="assets/img/file.svg" alt="">
                </span>
                <span class="download-document">
                  <img src="assets/img/download.svg" alt="">
                </span>
              </div>
            </a>
            <a href="#" class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
              data-aos-delay="200">
              <h3 class="title-document-custom-new3">Estados Financieros Consolidados</h3>
              <div class="box-acctions-custom-new3">
                <span class="view-document">
                  <img src="assets/img/file.svg" alt="">
                </span>
                <span class="download-document">
                  <img src="assets/img/download.svg" alt="">
                </span>
              </div>
            </a>
            <a href="#" class="item-document-custom-new3" target="_blank" data-aos="fade-up" data-aos-duration="700"
              data-aos-delay="300">
              <h3 class="title-document-custom-new3">Estados Financieros Consolidados</h3>
              <div class="box-acctions-custom-new3">
                <span class="view-document">
                  <img src="assets/img/file.svg" alt="">
                </span>
                <span class="download-document">
                  <img src="assets/img/download.svg" alt="">
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="secion-custom-new4">
    <div class="container">
      <h6 class="subtitle-custom-new3" data-aos="fade-up" data-aos-duration="700">Nuestro</h6>
      <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">EQUIPO</h3>
      <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
      <div class="row">
        <div class="col-lg-2 col-md-4 col-6 box-equipo-custom1" data-aos="fade-up" data-aos-duration="700">
          <div class="overlay-equipo-custom1"></div>
          <img src="assets/img/image-conozca1.jpg" alt="" class="img-box-equipo-custom1">
          <div class="box-content-equipo-custom1">
            <h3 class="title-equipo-custom1" data-aos="fade-up" data-aos-duration="700">Pedro Perez Perez</h3>
            <span class="line-title-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
              data-aos-delay="100"></span>
            <div class="box-text-equipo-custom1">
              <p>Gerente General</p>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-4 col-6 box-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
          data-aos-delay="100">
          <div class="overlay-equipo-custom1"></div>
          <img src="assets/img/image-conozca1.jpg" alt="" class="img-box-equipo-custom1">
          <div class="box-content-equipo-custom1">
            <h3 class="title-equipo-custom1" data-aos="fade-up" data-aos-duration="700">Pedro Perez Perez</h3>
            <span class="line-title-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
              data-aos-delay="100"></span>
            <div class="box-text-equipo-custom1">
              <p>Gerente General</p>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-4 col-6 box-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
          data-aos-delay="200">
          <div class="overlay-equipo-custom1"></div>
          <img src="assets/img/image-conozca1.jpg" alt="" class="img-box-equipo-custom1">
          <div class="box-content-equipo-custom1">
            <h3 class="title-equipo-custom1" data-aos="fade-up" data-aos-duration="700">Pedro Perez Perez</h3>
            <span class="line-title-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
              data-aos-delay="100"></span>
            <div class="box-text-equipo-custom1">
              <p>Gerente General</p>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-4 col-6 box-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
          data-aos-delay="300">
          <div class="overlay-equipo-custom1"></div>
          <img src="assets/img/image-conozca1.jpg" alt="" class="img-box-equipo-custom1">
          <div class="box-content-equipo-custom1">
            <h3 class="title-equipo-custom1" data-aos="fade-up" data-aos-duration="700">Pedro Perez Perez</h3>
            <span class="line-title-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
              data-aos-delay="100"></span>
            <div class="box-text-equipo-custom1">
              <p>Gerente General</p>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-4 col-6 box-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
          data-aos-delay="400">
          <div class="overlay-equipo-custom1"></div>
          <img src="assets/img/image-conozca1.jpg" alt="" class="img-box-equipo-custom1">
          <div class="box-content-equipo-custom1">
            <h3 class="title-equipo-custom1" data-aos="fade-up" data-aos-duration="700">Pedro Perez Perez</h3>
            <span class="line-title-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
              data-aos-delay="100"></span>
            <div class="box-text-equipo-custom1">
              <p>Gerente General</p>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-4 col-6 box-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
          data-aos-delay="500">
          <div class="overlay-equipo-custom1"></div>
          <img src="assets/img/image-conozca1.jpg" alt="" class="img-box-equipo-custom1">
          <div class="box-content-equipo-custom1">
            <h3 class="title-equipo-custom1" data-aos="fade-up" data-aos-duration="700">Pedro Perez Perez</h3>
            <span class="line-title-equipo-custom1" data-aos="fade-up" data-aos-duration="700"
              data-aos-delay="100"></span>
            <div class="box-text-equipo-custom1">
              <p>Gerente General</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</div>

<div class="modal-img-full modal-single-content" [ngClass]="modalTextShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullTextModal()"></div>
  <div class="box-modal-text-full">
    <div class="modal-header-single-content">
      <button class="btn-back-single-content" (click)="closeFullTextModal()">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"
          fill="#ff7000">
          <g>
            <g>
              <path
                d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
            </g>
          </g>
        </svg>
      </button>
      <h3 class="title-header-modal">Conoce Abastible / <span [innerHTML]="modalTitulo"></span></h3>
      <!-- <button class="close" (click)="closeFullTextModal()">
        <img src="assets/img/btn-close.svg" alt="">
      </button> -->
    </div>
    <div class="modal-text">
      <div class="modal-content-text">
        <div [innerHTML]="newContentModal" *ngIf="modalTitulo != 'Misión – Visión- Valores'"></div>
        <div *ngIf="modalTitulo == 'Soluciones Energéticas'">
          <div class="tabs-search">
            <button data-tab="#testimoniales" class="item-tab-search active">Testimoniales</button>
            <button data-tab="#news-search" class="item-tab-search ">Noticias</button>
          </div>
          <div class="tabs-content-search">
            <div class="item-tab-content-search" id="testimoniales">
              <section class="section-custom5">
                <div class="container" id="charlas">
                  <!-- <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/03/Testimoniales.png" alt=""
                    class="img-title-soluciones"> -->
                  <div class="swiper-container" [swiper]="carouselGalerias.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let item of galeriaVideosTestimoniales; let i = index">
                        <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                          (click)="showModalVideo($event, item.video_url)" data-aos-duration="700">
                          <div class="card-img">
                            <img [src]="item.img" alt="">
                            <div class="diagonal-cut"></div>
                            <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                              class="btn-play-desing1">
                          </div>
                          <div class="card-content">
                            <h4 class="card-title" [innerHtml]="item.title"></h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                    <a [routerLink]="['/galeria-videos-section/testimoniales-soluciones-energetica' ]" [queryParams]="1"
                      class="btn-custom-new">
                      <span>VER MÁS</span>
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div class="item-tab-content-search" id="news-search" style="display: none;">
              <section class="section-custom5" id="noticias">
                <div class="container">
                  <!-- <h3 class="title-custom-new3 text-center" data-aos="fade-up" data-aos-duration="700">NOTICIAS
                  </h3>
                  <span class="line-title-custom-new mx-auto" data-aos="fade-up" data-aos-duration="700"
                    data-aos-delay="100"></span>-->
                  <div class="swiper-container" [swiper]="carouselGalerias2.config">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let new2 of news2; let i = index">
                        <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                          <div class="card-img">
                            <img src="{{ new2.img }}" alt="">
                            <div class="diagonal-cut"></div>
                          </div>
                          <div class="card-content">
                            <h4 class="card-title" [innerHTML]="new2.title"></h4>
                            <div class="card-text">
                              <p [innerHTML]="(new2.text.length>120) ? (new2.text | slice:0:120)+'..' : (new2.text)">
                              </p>
                            </div>
                            <a [routerLink]="['/noticia/' + new2.slug ]" class="card-link">Leer más</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev">
                      <span class="material-icons">
                        keyboard_arrow_left
                      </span>
                    </div>
                    <div class="swiper-button-next">
                      <span class="material-icons">
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="100" class="col-12 box-btn-news">
                      <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'soluciones-energeticas' }"
                        class="btn-custom-new btn-more-ideas"><span>VER TODAS LAS NOTICIAS</span></a>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div *ngIf="modalTitulo == 'Misión – Visión- Valores'">
          <div class="section-mision-vision-valores">
            <div class="items-mv">
              <div class="item-mv item-mv1">
                <div class="item-mv-content">
                  <div class="item-mv-content-left">
                    <div class="item-mv-content-left-text">
                      <p>Ser una compañía reconocida, valorada y querida por la contribución que hace a la prosperidad
                        de las personas y la
                        sociedad, a través del fortalecimiento del espíritu emprendedor y el apoyo a las pequeñas y
                        medianas empresas para que
                        se transformen en un motor de desarrollo social y económico.</p>
                    </div>
                  </div>
                  <div class="item-mv-content-center">
                    <h3 class="item-mv-content-center-title">Visión</h3>
                  </div>
                  <div class="item-mv-content-right">
                    <img src="assets/img/mision-vision-valores/banner-mv2.png" alt="" class="item-mv-content-right-img">
                  </div>
                </div>
              </div>
              <div class="item-mv item-mv2">
                <div class="item-mv-content">
                  <div class="item-mv-content-left">
                    <div class="item-mv-content-left-text">
                      <p>Ponemos a las personas y a las pequeñas y medianas empresas al centro de nuestro quehacer,
                        entregándoles energía y
                        soporte a sus proyectos y emprendimientos. Estimulamos la creación de un movimiento social que
                        convoque a todos los
                        actores de nuestra sociedad, para apoyar el fortalecimiento y expansión de las Pymes.</p>
                    </div>
                  </div>
                  <div class="item-mv-content-center">
                    <h3 class="item-mv-content-center-title">Misión</h3>
                  </div>
                  <div class="item-mv-content-right">
                    <img src="assets/img/mision-vision-valores/banner-mv1.png" alt="" class="item-mv-content-right-img">
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="section-mision-vision-valores-container">
                <div class="item-mv2-full">
                  <div class="items-mv2-title">
                    <h3 class="items-mv2-title-title">Valores</h3>
                  </div>
                </div>
                <div class="items-mv2">
                  <div class="item-mv2-1">
                    <img src="assets/img/mision-vision-valores/icon-mv1.png" alt="" class="item-mv2-img">
                    <h3 class="item-mv2-title">Empatía</h3>
                    <div class="item-mv2-text">
                      <p>Estamos conscientes que si ponemos las necesidades de nuestros colaboradores, proveedores y
                        clientes al centro,
                        estableceremos relaciones de mutuo beneficio con la sociedad y el medio ambiente.</p>
                    </div>
                  </div>
                  <div class="item-mv2-1">
                    <img src="assets/img/mision-vision-valores/icon-mv2.png" alt="" class="item-mv2-img">
                    <h3 class="item-mv2-title">Espíritu Emprendedor</h3>
                    <div class="item-mv2-text">
                      <p>Creemos que el desarrollo y bienestar de la sociedad se pueden construir mediante la iniciativa
                        y
                        el emprendimiento</p>
                    </div>
                  </div>
                  <div class="item-mv2-1">
                    <img src="assets/img/mision-vision-valores/icon-mv3.png" alt="" class="item-mv2-img">
                    <h3 class="item-mv2-title">Colaboración</h3>
                    <div class="item-mv2-text">
                      <p>Sabemos que la colaboración es la única forma en que podemos lograr nuestros objetivos y
                        propósito,
                        ya que no solamente
                        dependen de nosotros.</p>
                    </div>
                  </div>
                  <div class="item-mv2-1">
                    <img src="assets/img/mision-vision-valores/icon-mv4.png" alt="" class="item-mv2-img">
                    <h3 class="item-mv2-title">Integridad</h3>
                    <div class="item-mv2-text">
                      <p>Buscamos el desarrollo de la confianza basado en la honestidad, la transparencia y el trabajo
                        bien
                        hecho, por eso
                        hacemos lo que decimos y cumplimos nuestros compromisos.</p>
                    </div>
                  </div>
                  <div class="item-mv2-1">
                    <img src="assets/img/mision-vision-valores/icon-mv5.png" alt="" class="item-mv2-img">
                    <h3 class="item-mv2-title">Seguridad</h3>
                    <div class="item-mv2-text">
                      <p>Para Abastible las personas son un fin en sí mismo. Su integridad física, emocional y
                        psicológica
                        están
                        ante todo.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div class="menu-mobile">
            <div class="content-menu-mobile">
              <a href="#menu-mobile1" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#menu-mobile2" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#" class="btn-design-float"><span></span></a>
              <a href="#menu-mobile3" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
              <a href="#menu-mobile4" class="link-collapse-menu-mobile"><img src="assets/img/icon-camera.svg"
                  alt="" /></a>
            </div>
          </div>
          <div class="container box-footer">
            <div class="box-list-footer">
              <div class="list-footer">
                <h3 class="title-list-footer">Conoce Abastible</h3>
                <ul>
                  <li><a [routerLink]="['/conoce-abastible']"
                      fragment="presentacion-de-la-empresa-historia">Presentación de la
                      empresa/historia</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="mision-vision-valores">Misión -Visión -
                      Valores</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="directorio-y-administracion">Directorio
                      y Administración</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="productos-y-servicios">Productos y Servicios</a>
                  </li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="proceso-productivo">Proceso Productivo</a></li>
                  <li><a [routerLink]="['/conoce-abastible']" fragment="soluciones-energeticas">Soluciones
                      Energéticas</a></li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Control Interno</h3>
                <ul>
                  <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1"
                      target="_blank">Canal de
                      Denuncias</a></li>
                  <li><a [routerLink]="['/control-interno']" fragment="auditoria-interna">Auditoría interna</a>
                  </li>
                  <li><a [routerLink]="['/control-interno']" fragment="seguridad-de-la-informacion">Seguridad de la
                      información</a></li>
                  <li><a [routerLink]="['/control-interno']" fragment="gestion-de-riesgo">Gestión de Riesgo</a>
                  </li>
                  <li><a [routerLink]="['/control-interno']" fragment="gestion-de-procesos">Gestión de Procesos</a>
                  </li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Seguridad</h3>
                <ul>
                  <li><a [routerLink]="['/seguridad']" fragment="integridad-operacional">Integridad Operacional</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="gestion-de-calidad">Gestión de Calidad</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="servicio-nacional-de-emergencias">Servicio Nacional de
                      Emergencias</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="manejo-manual-de-carga">Manejo Manual de Carga</a></li>
                  <li><a [routerLink]="['/seguridad']" fragment="medio-ambiente">Medio Ambiente</a></li>
                </ul>
              </div>
              <div class="list-footer">
                <h3 class="title-list-footer">Beneficios</h3>
                <ul>
                  <li><a [routerLink]="['/beneficios']" fragment="beneficios-para-colaboradores">Beneficios para
                      colaboradores</a></li>
                  <li><a [routerLink]="['/beneficios']" fragment="clima-organizacional">Clima organizacional</a></li>
                </ul>
              </div>
              <!--<div class="list-footer">
                    <h3 class="title-list-footer">Gobierno Corporativo</h3>
                    <ul>
                      <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1" target="_blank">Canal de Denuncias</a></li>
                      <li><a [routerLink]="['/gobierno-corporativo']" fragment="section2">Compliance</a></li>
                  </ul>
                </div> -->
              <div class="list-footer">
                <h3 class="title-list-footer">Soluciones
                  Energéticas</h3>
                <ul>
                  <li><a [routerLink]="['/soluciones-energeticas']" fragment="soluciones-energeticas">Soluciones
                      Energéticas</a></li>
                </ul>
              </div>
              <!-- <div class="list-footer">
                <h3 class="title-list-footer">Innovación</h3>
                <ul>
                  <li><a [routerLink]="['/innovacion']" fragment="i-now">I-NOW</a></li>
                  <li><a [routerLink]="['/innovacion']" fragment="laboratorio-de-innovacion">Laboratorio de
                      Innovación</a></li>
                  <li><a [routerLink]="['/innovacion']" fragment="soluciones-energeticas">Soluciones Energéticas</a>
                  </li>
                </ul>
              </div> -->
              <div class="list-footer list-footer-rd">
                <h3 class="title-list-footer">Social</h3>
                <ul>
                  <li><a href="https://www.facebook.com/AbastibleCL" target="_blank"><img
                        src="assets/img/icons/icon-facebook.svg" alt="">AbastibleCL</a></li>
                  <li><a href="https://www.instagram.com/laenergiadevivir/" target="_blank"><img
                        src="assets/img/icons/icon-instagram.svg" alt="">/laenergiadevivir</a></li>
                  <li><a href="https://www.linkedin.com/company/abastible-s.a./" target="_blank"><img
                        src="assets/img/icons/icon-linkedin.svg" alt="">/company/abastible-s.a.</a></li>
                  <li><a href="https://www.youtube.com/user/AbastibleChileSA" target="_blank"><img
                        src="assets/img/icons/icon-youtube.svg" alt="">/AbastibleChileSA</a></li>
                  <li><a href="https://www.abastible.cl/" target="_blank"><img src="assets/img/icons/logo-abastible.svg"
                        alt="">abastible.cl</a></li>
                </ul>
              </div>
            </div>
            <a href="index.html" class="logo-footer"><img src="assets/img/logo.png" alt="" /></a>
          </div>
        </footer>
      </div>
    </div>
  </div>
</div>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>