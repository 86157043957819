<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Perfil</span>
      </div>
    </div>
  </div>
</section>
<section class="p-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-9">
        <section id="box-profile">
          <div class="row">
            <div class="col-lg-6">
              <div class="box-profile">
                <div class="box-profile-img">
                  <div class="img-box-profile img-box-profile2" [innerHTML]="avatar_logged"></div>
                  <!-- <img src="{{avatar_logged}}" class="img-box-profile img-box-profile2" alt=""> -->
                  <a href="#" class="btn-profile" id="edit-profile">Editar Perfil</a>
                </div>
                <div class="box-profile-content">
                  <h3 class="title-box-profile">{{nickname}}</h3>
                  <h6 class="cargo-box-profile">{{cargo}}</h6>
                  <!-- <p>Rhoncus ipsum eget tempus. <br>Praesent fermentum sa rhoncus.</p> -->
                </div>
              </div>
            </div>
            <div class="col-lg-6 my-auto">
              <div class="box-info-user-activity">
                <div class="row">
                  <div class="col-4">
                    <div class="box-activity">
                      <h3 class="title-box-activity">{{commentCounter}}</h3>
                      <h6 class="text-box-activity">Comentarios</h6>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="box-activity">
                      <h3 class="title-box-activity">{{likesCounter}}</h3>
                      <h6 class="text-box-activity">Me gusta</h6>
                    </div>
                  </div>
                  <!-- <div class="col-4">
                    <div class="box-activity">
                      <h3 class="title-box-activity">71</h3>
                      <h6 class="text-box-activity">Siguiendo</h6>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="col-12 box-notifications">
              <h3 class="title-notifications">Notificaciones</h3>
              <ng-container *ngFor="let notification of notifications; let i = index">
                <div class="item-notification" *ngIf='notification.notice_id' id="notification{{notification.id}}">
                  <div class="photo-item-notification" [innerHTML]="avatar_logged"></div>
                  <!-- <h5 class="name-item-notification">Pedro Juan</h5> -->
                  <h6 class="action-item-notification" [innerHTML]="noHtml(notification.description)"></h6>
                  <h6 class="date-item-notification">{{notification.date_notified | date:'d/M/y h:mm a'}}</h6>
                  <a [routerLink]="['/noticia/' + notification.slug ]" class="link-item-notification">Ver</a>
                  <button class="delete-notification" (click)="deleteNotification(notification.id)">X</button>
                  <div></div>
                </div>
              </ng-container>
              <div class="col-12 box-btn-notifications mt-4" data-aos="fade-up" data-aos-duration="700"
                data-aos-delay="100">
                <a (click)="nextPage()" class="btn-custom-new mx-auto">
                  <span>VER MÁS</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="col-lg-3 box-info-user">
        <app-sidebar></app-sidebar>
      </div>
    </div>
  </div>
</section>

<div class="modal-edit-perfil">
  <div class="overlay-modal-edit"></div>
  <div class="content-modal">
    <div class="content-modal-header">
      <h3 class="title-modal">Editar Perfil</h3>
      <button class="btn-close-modal"><span></span><span></span></button>
    </div>
    <div class="content-modal-body">
      <form [formGroup]="form" (ngSubmit)="submitForm()">
        <div class="box-form">
          <div class="form-group form-group-file">
            <label for="image_profile">
              <div [innerHTML]="avatar_logged"></div>
              <!-- <img src="{{avatar_logged}}" alt=""> -->
              <h3 class="cambiar-inagen-perfil">Cambiar imagen de perfil</h3>
            </label>
            <input type="file" (change)="this.onFilechange($event)" formControlName="async-upload" name="async-upload"
              id="image_profile">
          </div>
          <div class="form-group">
            <label for="first_name">Nombre</label>
            <input type="text" id="first_name" formControlName="first_name" value="{{firstName}}">
          </div>
          <div class="form-group">
            <label for="last_name">Apellido</label>
            <input type="text" id="last_name" formControlName="last_name" value="{{lastName}}">
          </div>
          <div class="form-group">
            <label for="nickname">Alias</label>
            <input type="text" formControlName="nickname" id="nickname" value="{{nickname}}">
          </div>
          <div class="form-group">
            <label for="nickname">Cargo</label>
            <input type="text" formControlName="cargo" id="cargo" value="{{cargo}}">
          </div>
          <div class="box-btn-submit">
            <button type="submit" class="btn-submit">Editar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div id="modal-success" class="modal-success" *ngIf="modalSuccess">
  <div class="overlay-modal" (click)="closeSuccessModal()"></div>
  <div class="modal-content">
    <button type="button" class="close" aria-label="Close" (click)="closeSuccessModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
      <h2 class="title-success">Los datos han sido actualizados</h2>
    </div>
  </div>
</div>