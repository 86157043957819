<div class="menu-scroll-link" [ngClass]="{'menu-scroll-link-fixed':addClassFixed}">
  <div class="container">
    <div class="links-scroll swiper-container" [swiper]="linksScrollSwiper.config" [(index)]="linksScrollSwiper.index">
      <div class="swiper-wrapper">
        <div *ngFor="let item of landing; let i = index" class="swiper-slide">
          <p (click)="scroll(get_slug(item.titulo[0])+'-'+slug)">
            {{ item.titulo }}</p>
        </div>
      </div>
    </div>
    <!-- <ul class="links-scroll" [swiper]="linksScrollSwiper.config" [(index)]="linksScrollSwiper.index">
       <li *ngFor="let item of landing; let i = index">
         <p (click)="scroll(get_slug(item.titulo[0])+'-'+slug)" href="#{{ get_slug(item.titulo[0]) }}-{{ slug }}">
           {{ item.titulo }}</p>
       </li>
     </ul> -->
  </div>
</div>

<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>{{ titulo }}</span>
      </div>
    </div>
  </div>
</section>

<section class="p-section" id="{{ slug }}">
  <div class="container">
    <div class="row">
      <div class="col-lg-9">
        <div class="box-conozca-abastible">
          <div class="row box-conozca-abastible-row">
            <div class="col-lg-6 text-center">
              <h1 class="title1">{{ titulo }}</h1>
            </div>
            <!-- <div class="col-lg-6">
               <p>
                 {{ landing_descripcion_general }}
               </p>
             </div> -->
          </div>
          <div class="row row-items-landing">
            <div class="col-12 col-lg-6 box-scroll-conozca" *ngFor="let item of landing; let i = index"
              id="{{ get_slug(item.titulo[0]) }}-{{ slug }}">
              <ng-container *ngIf="item != undefined">
                <div class="card-design-conozca">
                  <img src="{{ item.imagen }}" alt="" class="card-img" />

                  <h3 class="card-title">{{ item.titulo }}</h3>
                  <div class="card-text">
                    <!-- <p>{{ item.descripcion }}</p> -->
                    <ng-container *ngIf="item.video != undefined">
                      <video controls>
                        <source src="{{ item.video }}">
                      </video>
                    </ng-container>
                    <a href="contenido/{{ get_slug(item.titulo[0]) }}-{{ slug }}" class="custom-link">Ver más</a>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 box-info-user">
        <app-sidebar></app-sidebar>
      </div>
    </div>
  </div>
</section>