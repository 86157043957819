<div class="bg-white bg-white-custom">

  <section class="section-banner-custom-new" id="section0" style="background-image: url(assets/img/img-test2.jpg);">
    <div class="overlay-banner-custom-new"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-10">
          <h2 class="title-banner-custom-new" data-aos="fade-right" data-aos-duration="700">¿Cómo nos convertimos en la
            mejor empresa para trabajar en Chile?</h2>
          <span class="line-title-custom-new-banner" data-aos="fade-right" data-aos-duration="700"
            data-aos-delay="400"></span>
          <!-- <div class="box-text-banner-custon-new" data-aos="fade-right" data-aos-duration="700">
            <p>¿Cómo nos convertimos en la mejor empresa para trabajar en Chile?</p>
          </div> -->
        </div>
      </div>
    </div>
  </section>
  <section id="breadcrumbs">
    <div class="container">
      <div class="breadcrumbs breadcrumbs-sections">
        <div class="breadcrumbs-item">
          <a [routerLink]="['/muro']"><span class="material-icons">
              home
            </span></a>
          <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
        </div>
        <div class="breadcrumbs-item">
          <a [routerLink]="['/innovacion']">Innovación</a>
          <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
        </div>
        <div class="breadcrumbs-item">
          <span>¿Cómo nos convertimos en la mejor empresa para trabajar en Chile?</span>
        </div>
      </div>
    </div>
  </section>
  <div class="modal-section-custom1">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 mx-auto">
          <h3 class="title-custom-new3 mb-5" data-aos="fade-up" data-aos-duration="700">¿Cómo nos convertimos en la
            mejor
            empresa
            para trabajar en Chile?</h3>
        </div>
        <div class="col-lg-6 modal-section-custom1-img">
          <img
            src="https://i2.wp.com/intranet.abastible.cl/wp/wp-content/uploads/2019/02/DSC_4972.jpg?fit=3394%2C1442&ssl=1"
            alt="" style="object-position: right;">
          <div class="cut-diagonal"></div>
        </div>
        <div class="modal-section-custom1-text col-lg-6">
          <p>Tiene como objetivo invitar a todos los colaboradores a aportar ideas innovadoras relacionadas a cómo hacer
            de Abastible
            un mejor lugar para trabajar, con foco en las siguientes categorías:</p>
          <ul>
            <li>Calidad de Vida</li>
            <li>Calidad de relaciones personales</li>
            <li>Participación</li>
          </ul>
          <a href="https://intranet.abastible.cl/wp/wp-content/uploads/2019/04/Bases-primer-desafio-INOW-3-Abril-2017.pdf"
            target="blank">Descargar
            bases</a>
        </div>
      </div>
    </div>
  </div>

  <div class="content-video">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-12 mx-auto">
          <video controls poster="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/Captura11.png"
            src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/05/i-Now-activacion.mp4"
            class="video-desafio"></video>
        </div>
      </div>
    </div>
  </div>

  <section class="section-custom5">
    <div class="container">
      <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">Ideas Ganadoras</h3>
      <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
      <div class="row">
        <div class="col-lg-3" *ngFor="let idea_ganadora of ideas_ganadoras; let i = index">
          <div class="card-design-notice card-design-notice-desafio"
            (click)="openModalEvento(idea_ganadora.title,idea_ganadora.content,idea_ganadora.autor,idea_ganadora.img)">
            <div class="card-img">
              <img [src]="idea_ganadora.img" alt="">
              <div class="diagonal-cut"></div>
            </div>
            <div class="card-content">
              <h4 class="card-title"
                [innerHTML]="(idea_ganadora.title.length>40) ? (idea_ganadora.title | slice:0:40)+'..' : (idea_ganadora.title)">
              </h4>
              <div class="card-text">
                <p
                  [innerHTML]="(idea_ganadora.content.length>100) ? (idea_ganadora.content | slice:0:100)+'..' : (idea_ganadora.content)">
                  En la
                  actualidad existe un apoyo de Abastible para realizar…</p>
              </div>
              <a class="card-link">Leer más</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-custom5">
    <div class="container">
      <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">Quick Wins</h3>
      <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
      <div class="swiper-container" [swiper]="carouselWins.config">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let idea_quick of ideas_quick; let i = index">
            <div class="card-design-notice card-design-notice-desafio"
              (click)="openModalEvento(idea_quick.title,idea_quick.content,idea_quick.autor,idea_quick.img)">
              <div class="card-img">
                <img [src]="idea_quick.img" alt="">
                <div class="diagonal-cut"></div>
              </div>
              <div class="card-content">
                <h4 class="card-title"
                  [innerHTML]="(idea_quick.title.length>40) ? (idea_quick.title | slice:0:40)+'..' : (idea_quick.title)">
                </h4>
                <div class="card-text">
                  <p
                    [innerHTML]="(idea_quick.content.length>100) ? (idea_quick.content | slice:0:100)+'..' : (idea_quick.content)">
                    En la
                    actualidad existe un apoyo de Abastible para realizar…</p>
                </div>
                <a class="card-link">Leer más</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-custom5">
    <div class="container">
      <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">Ideas (429)</h3>
      <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
      <div class="row">
        <div class="col-lg-3" *ngFor="let idea of ideas; let i = index">
          <div class="card-design-notice card-design-notice-desafio"
            (click)="openModalEvento(idea.title,idea.content,idea.autor,idea.img)">
            <div class="card-img">
              <img [src]="idea.img" alt="">
              <div class="diagonal-cut"></div>
            </div>
            <div class="card-content">
              <h4 class="card-title"
                [innerHTML]="(idea.title.length>40) ? (idea.title | slice:0:40)+'..' : (idea.title)"></h4>
              <div class="card-text">
                <p [innerHTML]="(idea.content.length>100) ? (idea.content | slice:0:100)+'..' : (idea.content)">En la
                  actualidad existe un apoyo de Abastible para realizar…</p>
              </div>
              <a class="card-link">Leer más</a>
            </div>
          </div>
        </div>
        <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
          <a (click)="nextPage()" class="btn-custom-new">
            <span>VER MÁS</span>
          </a>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- <a [routerLink]="['/formulario-desafio6' ]" class="btn-mailto-inow"><img src="assets/img/icons/mail.svg" alt=""><span>Cuéntanos
    tu idea</span></a> -->
<div id="modal-eventos" class="modal-eventos" *ngIf="modalEventos">
  <div class="overlay-modal" (click)="closeEventosModal()"></div>
  <div class="modal-content">
    <button type="button" class="close" aria-label="Close" (click)="closeEventosModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body modal-body-eventos">
      <img [src]="modalImage" alt="" class="img-modal">
      <div class="box-content-body-modal">
        <h2 class="title-modal" [innerHTML]="modalTitle"></h2>
        <h6 class="subtitle-modal">Autor: {{ modalAutor }}</h6>
        <div class="box-text-modal" [innerHTML]="modalContent">
        </div>
      </div>
    </div>
  </div>
</div>