import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GaleriasService {

  constructor(private httpClient: HttpClient) { }
  galeriaImagenes(slug: string, paged: number, search: string): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/galerias/?slug=${slug}&paged=${paged}&search=${search}`, null)
      .pipe((result) => {
        return result;
      });
  }
  galeriaVideos(id: number, paged: number, search: string): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/galeria-videos/?id_gallery=${id}&paged=${paged}&search=${search}`, null)
      .pipe((result) => {
        return result;
      });
  }
  galeriaVideosSeguridad(id: number, paged: number, search: string): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/galeria-videos-by-id/?id_gallery=${id}&paged=${paged}&search=${search}`, null)
      .pipe((result) => {
        return result;
      });
  }
  galeriaVideosByCategory(slug: string, paged: number, per_page: number, search: string): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/galeria-videos-by-category/?slug=${slug}&paged=${paged}&per_page=${per_page}&search=${search}`, null)
      .pipe((result) => {
        return result;
      });
  }
  getSingleGallery(id: number): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/single-galeria/?id_gallery=${id}`, null)
      .pipe((result) => {
        return result;
      });
  }
  galeriaByCategory(slug: string, paged: number, search: string): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/galerias_by_category/?slug=${slug}&paged=${paged}&search=${search}`, null)
      .pipe((result) => {
        return result;
      });
  }
}
