<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <a [routerLink]="['/documentos']">Documentos</a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item breadcrumbs-item-letter">
        <span>{{ params }}</span>
      </div>
    </div>
  </div>
</section>

<section class="p-section" id="{{ slug }}">
  <div class="container">
    <div class="row">
      <div class="col-lg-9">
        <div class="box-conozca-abastible">
          <div class="row box-conozca-abastible-row">
            <div class="col-8 m-auto">
              <h1 class="title1">Documentos</h1>
            </div>
            <div class="col-4">
              <a [routerLink]="['/documentos']" class="back-documents">
                <div class="item-documentos">
                  <img src="assets/img/icons/icon-documents.svg" alt="">
                  <span class="letra-documentos">{{ params }}</span>
                </div>
                <p class="text-back-documents">Volver</p>
              </a>
            </div>
          </div>
          <div class="row box-documentos-single">
            <div class="col-sm-6 col-lg-4 col-documentos-single" *ngFor="let item of documentos; let i = index">
              <a href="{{item.link_archivo}}" target="_blank" class="item-documentos-single">
                <img src="assets/img/icons/icon-doc.svg" *ngIf="item.ext == '.doc'" alt="">
                <img src="assets/img/icons/icon-pdf.svg"
                  *ngIf="item.ext == '.pdf' || item.ext == '.png' || item.ext == '.zip'" alt="">
                <img src="assets/img/icons/icon-ppt.svg" *ngIf="item.ext == '.ppt'" alt="">
                <img src="assets/img/icons/icon-pptx.svg" *ngIf="item.ext == 'pptx'" alt="">
                <div class="item-documentos-single-content {{item.ext}}">
                  <p [innerHTML]="item.titulo"></p>
                </div>
              </a>
            </div>
            <!-- <div class="col-lg-4 col-documentos-single">
              <a href="#" class="item-documentos-single">
                <img src="assets/img/icons/icon-doc.svg" alt="">
                <div class="item-documentos-single-content">
                  <p>Documento.doc</p>
                </div>
              </a>
            </div>
            <div class="col-lg-4 col-documentos-single">
              <a href="#" class="item-documentos-single">
                <img src="assets/img/icons/icon-pdf.svg" alt="">
                <div class="item-documentos-single-content">
                  <p>Documento.pdf</p>
                </div>
              </a>
            </div>
            <div class="col-lg-4 col-documentos-single">
              <a href="#" class="item-documentos-single">
                <img src="assets/img/icons/icon-pdf.svg" alt="">
                <div class="item-documentos-single-content">
                  <p>Documento.pdf</p>
                </div>
              </a>
            </div>
            <div class="col-lg-4 col-documentos-single">
              <a href="#" class="item-documentos-single">
                <img src="assets/img/icons/icon-doc.svg" alt="">
                <div class="item-documentos-single-content">
                  <p>Documento.doc</p>
                </div>
              </a>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-lg-3 box-info-user">
        <app-sidebar></app-sidebar>
      </div>
    </div>
  </div>
</section>