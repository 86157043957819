<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <a [routerLink]="['/grupos']">Grupos</a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>{{ params }}</span>
      </div>
    </div>
  </div>
</section>

<iframe width="100%" height="100vh" frameBorder="0" [src]="urlSafe"></iframe>