import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { SearchService } from '@services/search';
import { LoadingService } from '@services/loading';
import { GruposService } from '@services/grupos';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchGruposComponent implements OnInit {
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  selectedIndex: any[] = [];
  news1: any;
  news1Res: any;
  documents1: any;
  documents1Res: any;
  galerias: any;
  galeriasRes: any;
  galeriasVideos: any;
  galeriasVideosRes: any;
  currentPage1 = 1;
  catNews = "all";
  categoriesFilter: any;
  busqueda: any;
  valueSearch = "";
  urlSafe: SafeResourceUrl;
  modalVideoIframe = false;
  modalSrcVideoIframe: string = "";
  modalVideo = false;
  modalSrcVideo: any;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private gruposService: GruposService,
    private title: Title,
    public sanitizer: DomSanitizer,
    private sanitized: DomSanitizer,
    private searchService: SearchService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
  }
  init(): void {
    this.title.setTitle('Busqueda | Intranet Abastible');
    this.paramsID = 29622;
    this.busqueda = this.params['busqueda'];
    var that = this;

    $("body").removeClass(".modal-open")
    $("body").removeClass(".modal-single-content-open")

    console.log(this.busqueda);
    console.log(that.busqueda);
    $(".item-tab-search").click(function (e) {
      e.preventDefault();
      var dataContent = $(this).data("tab");
      if (!$(this).hasClass("active")) {
        $(".item-tab-search").removeClass("active");
        $(this).addClass("active");
        $(".item-tab-content-search").fadeOut(300)
        setTimeout(() => {
          $(dataContent).fadeIn(300)
        }, 300);
      }
    });
    this.searchService.getResults(1, 6, this.busqueda).subscribe(
      (res) => {
        this.news1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.searchService.getResults2(1, 6, this.busqueda).subscribe(
      (res) => {
        this.documents1 = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.searchService.getResults3(1, 6, this.busqueda).subscribe(
      (res) => {
        this.galerias = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );
    this.searchService.getResults4(1, 6, this.busqueda).subscribe(
      (res) => {
        this.galeriasVideos = res;
        console.log(res);
      },
      (err) => {
        console.error(err);
      }
    );

  }

  nextPage() {
    this.currentPage1 = this.currentPage1 + 1;
    this.searchService.getResults(this.currentPage1, 6, this.busqueda).subscribe(
      (res) => {
        this.news1Res = res;
        this.news1 = [...this.news1, ...this.news1Res];
        console.log([...this.news1, res]);
        console.log(this.news1Res)
      },
      (err) => {
        console.error(err);
      }
    );
    this.searchService.getResults2(this.currentPage1, 6, this.busqueda).subscribe(
      (res) => {
        this.documents1Res = res;
        this.documents1 = [...this.documents1, ...this.documents1Res];
        console.log([...this.documents1, res]);
        console.log(this.documents1Res)
      },
      (err) => {
        console.error(err);
      }
    );
    this.searchService.getResults3(this.currentPage1, 6, this.busqueda).subscribe(
      (res) => {
        this.galeriasRes = res;
        this.galerias = [...this.galerias, ...this.galeriasRes];
        console.log([...this.galerias, res]);
        console.log(this.galeriasRes)
      },
      (err) => {
        console.error(err);
      }
    );
    this.searchService.getResults4(this.currentPage1, 6, this.busqueda).subscribe(
      (res) => {
        this.galeriasVideosRes = res;
        this.galeriasVideos = [...this.galeriasVideos, ...this.galeriasVideosRes];
        console.log([...this.galeriasVideos, res]);
        console.log(this.galeriasVideosRes)
      },
      (err) => {
        console.error(err);
      }
    );
  }

  onSubmit(value: any) {
    this.busqueda = value;
    this.router.navigate(['/busqueda/' + this.busqueda]);
    // this.busqueda = value
    // this.searchService.getResults(1, 6, this.busqueda).subscribe(
    //   (res) => {
    //     this.news1 = res;
    //     console.log(res);
    //   },
    //   (err) => {
    //     console.error(err);
    //   }
    // );
  }

  textMin(text: string) {
    var str = text;
    var res = str.substring(0, 200);//get first 5 chars
    return res + '...';;
  }

  contentFilter(content: string): string {
    return content.replace(/<img .*?>/g, '');
  }

  setSelected(id: number) {
    if (this.selectedIndex[id] == true) {
      this.selectedIndex[id] = false;
    } else {
      this.selectedIndex[id] = true;
    }
  }
  showModalVideoIframe(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideoIframe = true;
    this.modalSrcVideoIframe = srcVideo;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
  }
  closeModalVideoIframe() {
    this.modalVideoIframe = false;
    this.modalSrcVideoIframe = '';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalSrcVideoIframe);
    $("#modal-video-youtube").removeClass("active-modal");
  }
  showModalVideo(event, srcVideo: string) {
    event.stopPropagation();
    this.modalVideo = true;
    this.modalSrcVideo = srcVideo;
  }
  closeModalVideo() {
    this.modalVideo = false;
    this.modalSrcVideo = '';
    $("#modal-video").removeClass("active-modal");
  }
}
