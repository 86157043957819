import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CumpleanosService {

  constructor(private httpClient: HttpClient) { }
  getInfoCumpleanos(): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/cumpleanos/`, null)
      .pipe((result) => {
        return result;
      });
  }
  getInfoMonthCumpleanos(): Observable<Record<string, any>> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.httpClient
      .post(`${environment2.apiUrl}/cumpleanos-mes/`, null)
      .pipe((result) => {
        return result;
      });
  }
}
