import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Router } from '@angular/router';
import * as uuid from 'uuid';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ConcursoService {
    myId = uuid.v4();
    user: Object = {};
    constructor(private router: Router, private httpClient: HttpClient) { }

    getPhotos(page: number): Observable<Record<string, any>> {
        const user = JSON.parse(localStorage.getItem('user'));

        return this.httpClient
            .post(`${environment2.apiUrl}/get-fotos-concurso?page=${page}`, null)
            .pipe((result) => {
                return result;
            });
    }
}
